<template>
  <div>
    <div class="payment-method mt-6" v-if="merchant.name">
      <p class="text-sm text-black md:mb-1">Merchant name</p>
      <h3 class="text-black md:text-lg font-semibold">
        {{ merchant.name }}
      </h3>
      <Hr />
    </div>

    <div class="payment-method mt-6" v-if="merchant.createdAt">
      <p class="text-sm text-black md:mb-1">Date created</p>
      <h3 class="text-black md:text-lg font-semibold">
        {{ dayjs(merchant.createdAt).format("MMM D YYYY | hh:mmA") }}
      </h3>
      <Hr />
    </div>

    <div class="payment-method mt-4">
      <p class="text-sm text-black md:mb-1">KYC Status</p>
      <h3 class="md:text-lg font-semibold" :class="[merchant.kycStatus.toLowerCase()]">
        <span v-if="merchant.kycStatus"> {{ merchant.kycStatus }}</span>
        <span v-else>No KYC submitted</span>
      </h3>
    </div>

    <div class="payment-method mt-4">
      <p class="text-sm text-black md:mb-1">Merchant Full address</p>
      <h3 class="md:text-lg font-semibold">
        <span v-if="merchant.address"> {{ merchant.address }}</span>
        <span v-if="merchant.lga"> {{ ", " + merchant.lga }}</span>
        <span v-if="merchant.state"> {{ ", ", + merchant.state }}</span>
      </h3>
    </div>

    <div class="my-6 flex items-center justify-center">
      <SecondaryButton text="Approve" background="bg-brandPurple" color="white" @click="updateKYCStatus('APPROVED')"
        class="mr-2" width="w-24" :loading="approveLoading" />
      <SecondaryButton text="Decline" background="bg-brandRed" @click="updateKYCStatus('DECLINED')" class="mr-2"
        color="white" width="w-24" :loading="declineLoading" />
    </div>
  </div>
</template>

<script>
import { UPDATE_MERCHANT_KYC_STATUS } from "@/utils/api/merchants";
import { SecondaryButton } from "@/UI/Button";
import Hr from "@/UI/Hr";
import { mapActions } from "vuex";
import dayjs from "dayjs";
export default {
  components: {
    SecondaryButton,
    Hr,
  },
  data() {
    return {
      approveLoading: false,
      declineLoading: false,
    };
  },
  props: {
    merchant: {
      type: Object,
      default: () => { },
      required: true,
    },
  },
  computed: {
    dayjs() {
      return dayjs;
    },
  },
  methods: {
    ...mapActions("notification", ["showAlert"]),
    updateKYCStatus(status) {
      if (status === "APPROVED") {
        this.approveLoading = true;
      } else {
        this.declineLoading = true;
      }
      UPDATE_MERCHANT_KYC_STATUS({
        merchantId: this.merchant.id,
        newKycStatus: status,
      })
        .then(() => {
          this.approveLoading = false;
          this.declineLoading = false;
          this.showAlert({
            description: `KYC status updated successfully`,
            display: true,
            type: "success",
          });
          this.merchant.kycStatus = status;
          this.$emit("close");
        })
        .catch(() => {
          this.approveLoading = false;
          this.declineLoading = false;
        });
    },
  },
};
</script>

<style></style>