<template>
    <div class="fixed right-sidebar-container " v-if="display">
        <div class="overlay" @click="$emit('close')"></div>
        <div class="sidebar-view">
            <div class="sidebar-header">
                <div class="cursor-pointer">
                    <!-- <i class="ri-close-fill"></i> -->
                    <i class="ri-arrow-left-line" @click="$emit('handleBack')" v-if="showBack"></i>
                </div>
                <div class="header-description">{{ title }}</div>
                <div class="close-icon-container cursor-pointer" @click="$emit('close')">
                    <!-- <i class="ri-more-fill"></i> -->
                    <i class="ri-close-fill"></i>
                </div>
            </div>
            <div class="sidebar-body scrollbar">
                <slot name="body" />
            </div>
            <div class="sidebar-footer" v-if="!hideFooter">
                <slot name="footer" />
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        width: {
            type: [String, Number],
            default: "460px",
        },
        display: {
            type: Boolean,
            default: false,
            required: false,
        },
        title: {
            type: String,
            default: "",
            required: false,
        },
        showHeaderOptions: {
            type: Boolean,
            default: true,
        },
        hideFooter:{
            type: Boolean,
            default: false,
            required: false
        },
        showBack:{
            type: Boolean,
            default: false,
            required: false
        }
    },
};
</script>
<style scoped>
.right-sidebar-container {
    position: fixed;
    left: 0;
    right: 0;
    /*height: calc(100vh - 56px); */
    width: 100%;
    bottom: 0;
    display: grid;
    grid-template-columns: 1fr 460px ;
    height: calc(var(--vh, 1vh) * 100);
    top:0;
    z-index: 20
}
.right-sidebar-container .overlay{
  background-color: rgb(76, 74, 82, 0.7);

}
.sidebar-body{
    overflow: auto !important;
    height: calc((var(--vh, 1vh) * 100) - 45px);
}
.right-sidebar-container .sidebar-view {
    background: white;
    border: 1px solid #ced0d9;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -4px rgba(0, 0, 0, 0.1);
}

.right-sidebar-container .sidebar-view .sidebar-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #f8fafc;
    height: 45px;
    padding: 0 16px
}

.right-sidebar-container .sidebar-view .sidebar-header .header-description {
    color: #121926;
    font-size: 12px;
    font-weight: 400;
}

.right-sidebar-container .sidebar-view .sidebar-header .close-icon-container {
    width: 24px;
    height: 24px;
    background: #f8f4f4;
    border-radius: 50%;
    display: grid;
    place-items: center;
}

.right-sidebar-container .sidebar-view .sidebar-header .close-icon-container i {
    color: #697586;
}

@media (max-width: 768px) {
    .right-sidebar-container{
        grid-template-columns: 1fr 100%;
    }
    
}
</style>
  