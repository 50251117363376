<template>
  <div>
    <!-- <v-select
      :options="unfeaturedSubCategoryMerchants"
      class="w-full"
      label="name"
      v-model="singleSubCategory.categoryId"
      :reduce="(category) => category.id"
    ></v-select> -->
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import vSelect from "vue-select";
import Pagination from "@/UI/Pagination";
export default {
  components: {
    "v-select": vSelect,
    EmptyState: () => import("@/UI/EmptyState"),
    Pagination,
  },

  props: {
    subCategoryId: {
      type: String,
      default: "",
      required: true,
    },
  },

  data: () => ({
    loading: false,
    params: {
      page: 0,
      pageSize: 20,
      subCategoryIds: "",
      from: "",
      to: "",
    },
    paginateLoading: false,
    handleMore: false,
  }),

  computed: {
    ...mapState({
      unfeaturedSubCategoryMerchants: (state) =>
        state?.merchants?.unfeaturedSubCategoryMerchants,
    }),
  },

  mounted() {
    this.listUnfeaturedEligibleMerchants();
    console.log(this.subCategoryId)
  },

  methods: {
    ...mapActions("merchants", ["getUnfeaturedEligibleMerchants"]),
    ...mapActions("loading", ["setLoading"]),
    listUnfeaturedEligibleMerchants(type) {
      this.params.subCategoryIds = this.subCategoryId;
      type === "paginate"
        ? (this.paginateLoading = true)
        : this.setLoading(true);
      this.getUnfeaturedEligibleMerchants({
        params: this.params,
        type: type,
      })
        .then((res) => {
          if (res.data.length === this.params.pageSize) {
            this.handleMore = true;
          } else {
            this.handleMore = false;
          }
          type === "paginate"
            ? (this.paginateLoading = false)
            : (this.loading = false);
          this.setLoading(false);
        })
        .catch(() => {
          type === "paginate"
            ? (this.paginateLoading = false)
            : (this.loading = false);
          this.setLoading(false);
        });
    },
  },
};
</script>

<style>
</style>