import {
  GET_CUSTOMERS,
  GET_EMPLOYMENT_VERIFICATION,
  GET_ALL_COMPANIES,
  MAKE_COMPANY_ELIGIBLE,
  MAKE_COMPANY_INELIGIBLE,
  GET_BANK_STATEMENTS,
  GET_CUSTOMER_DETAILS,
  GET_EMPLOYMENT_INFORMATION,
  GET_CUSTOMERS_ISSUED_CARDS,
  // CUSTOMER_FINANCIAL_HEALTH,
} from "@/utils/api/customers";

export default {
  getAllCustomers({ commit }, payload) {
    return new Promise((resolve, reject) => {
      GET_CUSTOMERS(payload.params)
        .then((res) => {
          payload.type === "paginate" ? commit("ADD_CUSTOMERS", res.data.data) : commit("GET_CUSTOMERS", res.data.data);
          // commit("GET_CUSTOMERS", res.data.data);
          //   console.log(res.data.data);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getAllCustomersIssuedCards({ commit }, payload) {
    return new Promise((resolve, reject) => {
      GET_CUSTOMERS_ISSUED_CARDS(payload)
        .then((res) => {
          payload.type === "paginate" ? commit("ADD_CUSTOMERS_ISSUED_CARDS", res.data.data) : commit("GET_CUSTOMERS_ISSUED_CARDS", res.data.data);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getAllEmploymentVerification({ commit }, payload) {
    return new Promise((resolve, reject) => {
      GET_EMPLOYMENT_VERIFICATION(payload.params)
        .then((res) => {
          payload.type === "paginate" ? commit("ADD_EMPLOYMENT_VERIFICATION", res.data.data) : commit("GET_EMPLOYMENT_VERIFICATION", res.data.data);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getAllBankStatments({ commit }, payload) {
    return new Promise((resolve, reject) => {
      GET_BANK_STATEMENTS(payload.params)
        .then((res) => {
          payload.type === "paginate" ? commit("ADD_BANK_STATEMENT", res.data.data) : commit("GET_BANK_STATEMENT", res.data.data);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getAllCompanies({ commit }, payload) {
    return new Promise((resolve, reject) => {
      GET_ALL_COMPANIES(payload.params)
        .then(({ data }) => {
          payload.type === "paginate" ? commit("ADD_COMPANY", data.data.content) : commit("GET_COMPANY", data.data.content);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  makeCompanyEligible({ }, payload) {
    return new Promise((resolve, reject) => {
      MAKE_COMPANY_ELIGIBLE(payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  makeCompanyIneligible({ }, id) {
    return new Promise((resolve, reject) => {
      MAKE_COMPANY_INELIGIBLE(id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getCustomerDetails({ }, payload) {
    return new Promise((resolve, reject) => {
      GET_CUSTOMER_DETAILS(payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getEmploymentInformation({ }, payload) {
    return new Promise((resolve, reject) => {
      GET_EMPLOYMENT_INFORMATION(payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
