<template>
  <div>
    <!-- create order form modal -->
    <Modal :display="showModal" @close="closeModal">
      <div class="form">
        <h4 class="text-lg font-semibold text-black">New partner</h4>

        <Input
          width="w-full"
          placeholder="First name"
          class="mt-8"
          v-model="partner.firstName"
          errorText="Partner first name is required"
          :validation="rules.firstName"
          @valid="valid.firstName = $event"
        />
        <Input
          width="w-full"
          placeholder="Last name"
          class="mt-8"
          v-model="partner.lastName"
          errorText="Partner last name is required"
          :validation="rules.lastName"
          @valid="valid.lastName = $event"
        />
        <vue-tel-input
          v-model="partner.phoneNumber"
          :enabled-country-code="false"
          :input-options="{ showDialCode: false, tabindex: 0 }"
          :required="true"
          :valid-characters-only="true"
          :mode="'international'"
          @input="checkPhoneNumber"
          defaultCountry="NG"
          class="mt-8"
          wrapper-classes="border-0 focus:outline-none pl-12 text-brand_dark_blue"
          placeholder="Phone Number"
        ></vue-tel-input>
        <p
          v-if="phoneIsValid === false"
          class="text-brandRed text-sm pl-2 py-1"
        >
          Invalid phone number for the country code selected
        </p>

        <Select
          class="mt-8"
          placeholder="Commission flag"
          v-model="partner.commissionFlag"
          :options="commissioned"
          :reduce="(type) => type.value"
        />

        <Button
          text="Create new partner"
          width="w-full"
          class="my-12"
          :loading="loading"
          :disabled="disableButton"
          @click="createPartner()"
        />
      </div>
    </Modal>
    <!-- success modal -->
    <Modal :display="showPartnerSuccess" @close="showPartnerSuccess = false">
      <PartnerSuccess :details="partnerDetails" />
    </Modal>
  </div>
</template>
<script>
import { CREATE_NEW_PARTNER } from "@/utils/api/partner.js";
import { mapActions, mapState } from "vuex";
import { Select, Input } from "@/UI/Input";
import { Button } from "@/UI/Button";
import { Modal } from "@/UI/Modals";
import PartnerSuccess from "./PartnerSuccessModal";
import { VueTelInput } from "vue-tel-input";
export default {
  components: {
    Input,
    Select,
    Button,
    Modal,
    PartnerSuccess,
    VueTelInput,
  },
  data: () => ({
    loading: false,
    commissioned: [
      { label: "Commission", value: "COMMISSIONED" },
      { label: "Non-commissioned", value: "NON_COMMISSIONED" },
    ],
    partner: {
      firstName: "",
      lastName: "",
      phoneNumber: "",
      commissionFlag: "",
    },
    showPartnerSuccess: false,
    partnerDetails: {},
    phoneIsValid: true,
    canSubmit: false,
    valid: {
      firstName: false,
      lastName: false,
      phoneNumber: false,
      commissionFlag: false,
    },
  }),
  computed: {
    ...mapState({
      showModal: (state) => state?.partner?.showPartnerModal,
      merchantOutletId: (state) => state?.auth?.user?.merchantOutlet.id,
    }),
    rules() {
      return {
        firstName: this.partner.firstName !== "",
        lastName: this.partner.lastName !== "",
        phoneNumber: this.canSubmit === true,
        commissionFlag: this.partner.commissionFlag !== "",
      };
    },
    disableButton() {
      return Object.values(this.rules).includes(false);
    },
  },
  methods: {
    ...mapActions("partner", [
      "showPartnerModal",
      "showPartnerSuccessModal",
      "getAllPartnerRegistration",
    ]),
    createPartner() {
      this.loading = true;
      CREATE_NEW_PARTNER(this.partner)
        .then((response) => {
          this.loading = false;
          this.getAllPartnerRegistration({
            params: {
              pageSize: 20,
              page: 0,
              from: "",
              to: "",
            },
          });
          this.showPartnerModal(false);
          this.showPartnerSuccess = true;
          this.partnerDetails = response.data.data;
          this.partner = {
            firstName: "",
            lastName: "",
            phoneNumber: "",
          };
        })
        .catch((error) => {
          this.loading = false;
          this.showPartnerModal(false),
            console.error("There was an error!", error);
        });
    },
    closeModal() {
      this.showPartnerModal(false);
      this.partner = {
        firstName: "",
        lastName: "",
        phoneNumber: "",
      };
    },
    checkPhoneNumber(number, obj) {
      this.phoneIsValid = obj.valid;
      if (this.phoneIsValid) {
        this.canSubmit = true;
      } else {
        this.canSubmit = false;
      }
    },
  },
};
</script>
<style src="vue-tel-input/dist/vue-tel-input.css"></style>
<style>
.vti__dropdown-list {
  z-index: 20 !important;
}
.vue-tel-input {
  border: 0px;
  border-bottom: 1px solid #bbb !important;
}
.vti__dropdown-list {
  z-index: 20 !important;
}
.vue-tel-input {
  border: 0px;
  border-bottom: 1px solid black !important;
  border-radius: 0px !important;
}
.vue-tel-input:focus-within {
  outline: none !important;
  box-shadow: none !important;
  border-color: black;
}
</style>
