<template>
    <div class="currency-container">
        <Card class="h-full">
            <h5 class="text-lg font-semibold">Wire Currency Mappings</h5>
            <div class="flex items-center justify-end"
            ><Button text="Create new Currency" class="my-4"
                    @click="showCreateCurrencyModal = true" /></div>
            <div class="flex justify-center" v-if="loading">
                <Spinner />
            </div>
            <div v-else>
                <div class="grid grid-cols-3 gap-4">
                    <div class="source">
                        Source Currency
                    </div>
                    <div class="source">
                        Destination Currency
                    </div>
                    <div class="grid grid-cols-3 gap-4">
                        <div class="source">Rate</div>
                        <div class="source">Status</div>
                        <div>Actions</div>
                    </div>
                </div>
                <Hr />
                <div v-for="currency in currencies" :key="currency.id">
                    <div class="grid grid-cols-3 gap-4">
                        <div class="source grid grid-cols-4">
                            <img :src="currency.sourceCurrencyCode.icon" alt="" class="h-6 w-6 rounded-full">
                            <p>{{ currency.sourceCurrencyCode.name }}</p>
                            <p>{{ currency.sourceCurrencyCode.sign }}</p>
                            <p>{{ currency.sourceCurrencyCode.code }}</p>
                        </div>
                        <div class="source grid grid-cols-4">
                            <img :src="currency.destinationCurrencyCode.icon" alt="" class="h-6 w-6 rounded-full">
                            <p>{{ currency.destinationCurrencyCode.name }}</p>
                            <p>{{ currency.destinationCurrencyCode.sign }}</p>
                            <p>{{ currency.destinationCurrencyCode.code }}</p>
                        </div>
                        <div class="grid grid-cols-3 gap-4">
                            <div class="source">
                                {{ currency.exchangeRate }}
                            </div>
                            <div class="source">
                                {{ currency.active }}
                            </div>
                            <div>
                                <SecondaryButton text="Update" p="px-4 py-1" fontSize="text-sm" width="w-auto"
                                    @click="handleUpdate(currency)" />
                            </div>
                        </div>
                    </div>
                    <Hr />
                </div>
            </div>
        </Card>
        <Modal :display="showCreateCurrencyModal || showUpdateMode" @close="handleCloseCurrencyModal">
            <p class="text-lg font-semibold">Create a new currency rate</p>
            <Select :disabled="showUpdateMode" v-model="currencyForm.sourceCurrencyCode" placeholder="Source currency"
                class="mt-6" :options="currencyList" label="code" :reduce="(item) => item.code" z-index="40" />
            <Select :disabled="showUpdateMode" v-model="currencyForm.destinationCurrencyCode"
                z-index="38" 
                placeholder="Destination currency" class='mt-6' :options="currencyList" label="code"
                :reduce="(item) => item.code" />
            <Select class="mt-6" :options="activeStatusList" v-model="currencyForm.active" placeholder="Choose status" />
            <Input number type="number" v-model="currencyForm.exchangeRate" class="mt-12" placeholder="Exchange rate"
                width="w-full" />
            <Button class="mt-12" text="Submit" width="w-full" :loading="newCurrencyLoading" @click="handleSubmit" />
        </Modal>
    </div>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import Hr from "@/UI/Hr";
import { Button, SecondaryButton } from '@/UI/Button'
import { Modal } from '@/UI/Modals'
import { Input, Select, CurrencyInput } from '@/UI/Input';
import Spinner from '@/UI/Spinner'
export default {
    components: {
        Hr,
        Button,
        SecondaryButton,
        Modal,
        Input,
        Select,
        CurrencyInput,
        Spinner
    },
    data: () => ({
        currencyForm: {
            sourceCurrencyCode: '',
            destinationCurrencyCode: '',
            active: '',
            exchangeRate: '',
        },
        newCurrencyLoading: false,
        showCreateCurrencyModal: false,
        loading: false,
        showUpdateMode: false,
        activeStatusList: ['true', 'false'],
        editId: ''
    }),
    computed: {
        ...mapState({
            currencies: (state) => state.wire.currencyMappings,
            currencyList: (state) => state.wire.currencyList
        }),
        ...mapGetters('auth', ['getUserPermissions'])
    },
    methods: {
        ...mapActions('wire', ['getCurrencyMappings', 'createNewCurrencyMappings', 'updateCurrencyyMap', 'getAllCurrencyAction']),
        handleSubmit() {
            if (this.showUpdateMode) {
                this.handleUpdateCurrency()
            } else {
                this.handleCreateCurrency()
            }
        },
        handleCreateCurrency() {
            this.newCurrencyLoading = true;
            this.createNewCurrencyMappings(this.currencyForm)
                .then((res) => {
                    this.handleGetCurrency();
                    this.showCreateCurrencyModal = false;
                    this.newCurrencyLoading = false;
                }).catch(() => {
                    this.newCurrencyLoading = false;
                })
        },
        handleUpdateCurrency() {
            this.newCurrencyLoading = true;
            let payload = {
                id: this.editId,
                active: this.currencyForm.active,
                exchangeRate: this.currencyForm.exchangeRate
            };
            this.updateCurrencyyMap(payload)
                .then((res) => {
                    this.handleGetCurrency();
                    this.showUpdateMode = false;
                    this.newCurrencyLoading = false;
                }).catch(() => {
                    this.newCurrencyLoading = false;
                })
        },
        handleGetCurrency() {
            this.loading = true;
            this.getCurrencyMappings().then(() => {
                this.loading = false
            }).catch(() => {
                this.loading = false
            })
        },
        handleUpdate(currency) {
            this.currencyForm.sourceCurrencyCode = currency.sourceCurrencyCode.code
            this.currencyForm.destinationCurrencyCode = currency.destinationCurrencyCode.code;
            this.currencyForm.exchangeRate = currency.exchangeRate;
            this.currencyForm.active = currency.active
            this.editId = currency.id;
            this.showUpdateMode = true
        },
        handleCloseCurrencyModal() {
            this.showUpdateMode = false;
            this.showCreateCurrencyModal = false;
            this.currencyForm.sourceCurrencyCode = ''
            this.currencyForm.destinationCurrencyCode = ''
            this.currencyForm.exchangeRate = ''
            this.editId = ''
        }
    },
    mounted() {
        this.handleGetCurrency()
        this.getAllCurrencyAction()
    }
}
</script>
<style scoped>
.currency-container {
    min-height: calc(100vh - 10rem);

}

.source {
    border-right: 1px solid rgb(242, 237, 253)
}

.source p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>