<template>
  <Partners />
</template>
<script>
  import Partners from "@/components/bnpl/Partners";
  export default {
    components: {
      Partners,
    },
  };
</script>
