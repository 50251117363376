<template>
  <transition :name="currentAnimation">
    <div class="fixed modal-container" v-if="display" @click="clickTarget">
      <transition :name="currentAnimation">
        <div class="modal-body bg-white p-6" v-if="display">
          <div class="flex justify-end">
            <img
              class="h-3 w-3 cursor-pointer"
              src="https://res.cloudinary.com/zillaafrica/image/upload/v1623525601/customer/Union_yx6ulk.svg"
              alt="cancel"
              @click="handleCloseIcon"
            />
          </div>
          <div class="completed mt-8">
            <!-- <img
              class="h-32 w-32 mx-auto"
              src="https://res.cloudinary.com/zillaafrica/image/upload/q_auto/v1624100575/customer/Group_707_1_tpsp0o.svg"
              alt="success"
              v-if="type === 'success'"
            /> -->
            <!-- <img
              class="h-28 w-28 mx-auto"
              src="https://res.cloudinary.com/zillaafrica/image/upload/q_auto/v1629045373/Group_12919_be2tin.svg"
              alt="success"
              v-if="type === 'error'"
            /> -->
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="128"
              height="128"
              fill="none"
              viewBox="0 0 130 130"
              class="h-32 w-32 mx-auto"
              v-if="type === 'success'"
            >
              <circle cx="65" cy="65.127" r="64.885" fill="url(#a)" />
              <path
                d="m33.569 72.188 17.21 15.193 39.029-48.218"
                stroke="#fff"
                stroke-width="6"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <defs>
                <linearGradient
                  id="a"
                  x1="-221.501"
                  y1="-22.639"
                  x2="206.793"
                  y2="53.621"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#6930C3" />
                  <stop offset=".396" stop-color="#6844C6" />
                  <stop offset="1" stop-color="#63DFDF" />
                </linearGradient>
              </defs>
            </svg>
            <svg
              v-if="type === 'error'"
              xmlns="http://www.w3.org/2000/svg"
              width="128"
              height="128"
              fill="none"
              viewBox="0 0 130 130"
              class="h-32 w-32 mx-auto"
            >
              <circle cx="64.885" cy="64.885" r="64.885" fill="url(#a)" />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M120 65c0 30.376-24.624 55-55 55S10 95.376 10 65s24.624-55 55-55 55 24.624 55 55Zm-55.429 9.557c-3.157 0-5.571-2.414-5.571-5.571V40.57C59 37.414 61.414 35 64.571 35c3.158 0 5.572 2.414 5.572 5.571v28.415c0 3.157-2.414 5.571-5.572 5.571Zm-3.9 18.758c.929 1.114 2.415 1.671 3.9 1.671 1.486 0 2.972-.557 4.272-1.671 1.114-.929 1.671-2.415 1.671-3.9 0-.186-.046-.372-.093-.557a2.332 2.332 0 0 1-.092-.558c-.093-.185-.14-.37-.186-.557a2.62 2.62 0 0 0-.186-.557 4.075 4.075 0 0 0-.278-.464c-.093-.14-.186-.279-.279-.464-.186-.372-.557-.743-.743-.929-2.043-2.043-5.757-2.043-7.8 0-.371.371-.557.557-.743.929a4.079 4.079 0 0 1-.278.464 4.09 4.09 0 0 0-.279.464c-.186.372-.371.743-.371 1.114-.186.372-.186.743-.186 1.115 0 1.485.557 2.785 1.671 3.9Z"
                fill="#fff"
              />
              <defs>
                <linearGradient
                  id="a"
                  x1="-221.616"
                  y1="-22.882"
                  x2="206.678"
                  y2="53.379"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#6930C3" />
                  <stop offset=".396" stop-color="#6844C6" />
                  <stop offset="1" stop-color="#63DFDF" />
                </linearGradient>
              </defs>
            </svg>
            <h3
              class="text-lg md:text-xl font-bold mt-6 text-black text-center"
            >
              {{ description }}
            </h3>
            <Button
              class="mt-12"
              :text="buttonText ? buttonText : 'Close'"
              @click="closeDisplay"
              width="w-full"
            />
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>
<script>
  import { mapState, mapActions } from "vuex";
  // import Button from "@/UI/Button";
  import { Button } from "@/UI/Button";

  export default {
    components: {
      Button,
    },
    data: () => ({
      matchWidth: "slideUp",
    }),

    computed: {
      ...mapState("notification", {
        type: (state) => state.modal.type,
        description: (state) => state.modal.description,
        display: (state) => state.modal.display,
        title: (state) => state.modal.title,
        buttonText: (state) => state?.modal?.buttonText,
        callback: (state) => state.modal.callback,
      }),
      currentAnimation() {
        if (window.matchMedia("(max-width: 1024px)").matches) {
          return "slideUp";
        } else {
          return "fade";
        }
      },
    },
    // watch: {
    //   display(value) {
    //     // console.log(value, "dislay value");
    //     if (value) {
    //       document.body.style.overflow = "hidden";
    //       console.log(document.body.style.overflow);
    //     } else {
    //       document.body.style.overflow = "auto";
    //     }
    //   },
    // },
    // mounted() {
    //   console.log(this.display, "display");
    // },
    methods: {
      ...mapActions("notification", ["showAlert"]),
      closeDisplay() {
        if (this.callback) {
          this.callback();
        }
        this.showAlert({
          description: "",
          display: false,
          type: "",
          title: "",
          buttonText: "Close",
          callback: null,
        });

        // this.$emit("close");
      },
      handleCloseIcon(){
        this.showAlert({
          description: "",
          display: false,
          type: "",
          title: "",
          buttonText: "Close",
          callback: null,
        });
      },
      clickTarget(e) {
        if (e.target.className.includes("modal-container")) {
          this.handleCloseIcon();
        }
      },
    },
    // beforeDestroy() {
    //   document.body.style.overflow = "auto";
    // },
  };
</script>
<style scoped>
  @import "../../../assets/styles/animation.css";
  .modal-container {
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(235, 227, 255, 0.8);
    z-index: 9999;
  }
  .modal-body {
    position: fixed;
    bottom: 10px;
    /* border-top-left-radius: 20px;
    border-top-right-radius: 20px; */
    border-radius: 20px;
    max-height: 80vh;
    overflow-y: auto;
    width: 95%;
    left: 2.5%;
    right: 2.5%;
    z-index: 9999;

  }

  @media (min-width: 1024px) {
    .modal-container {
      display: grid;
      place-items: center;
    }

    .modal-body {
      position: initial;
      width: 30%;
      max-width: 28rem;
      border-radius: 20px;
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: all 0.4s cubic-bezier(0.45, 0.25, 0.6, 0.95);
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    transform: translate(0%, -10px);
    opacity: 0;
  }
  /* .slideUp-enter-active,
  .slideUp-leave-active {
    transition: all 0.4s ease-out;
    transform: translateX(0);
  }
  .slideUp-enter,
  .slideUp-leave-to {
    opacity: 0;
    transform: translateY(90%);
  } */
</style>
