<template>
  <Card class="h-full purchase-container">
    <div class="flex items-center justify-between w-full">
      <h5 class="text-lg font-semibold">Users</h5>
      <Button text="Roles" p="p-2" @click="showRoleModal = true" width="w-44" />
    </div>
    <Hr />
    <div class="flex items-center justify-end"></div>
    <Spinner class="text-center" v-if="loading" />
    <div class="table-container" v-else>
      <div>
        <div class="payment-table">
          <div class="payment-table__col font-semibold">S/N</div>
          <div class="payment-table__col font-semibold">Id</div>
          <div class="payment-table__col font-semibold">Name</div>
          <div class="payment-table__col font-semibold">Email</div>
          <div class="payment-table__col font-semibold">Action</div>
        </div>
        <Hr />
      </div>
      <EmptyState description="No records found" v-if="!adminUserList.length" />

      <div v-for="(customer, index) in adminUserList" :key="customer.id">
        <div class="payment-table">
          <div class="payment-table__col">
            {{ index + 1 }}
          </div>
          <div class="payment-table__col">
            {{ customer.id }}
          </div>
          <div class="payment-table__col">{{ customer.firstName }} {{ customer.lastName }}</div>
          <div class="payment-table__col">
            {{ customer.email || "N/A" }}
          </div>
          <div>
            <SecondaryButton text="View User" @click="handleViewUser(customer)" />
          </div>
        </div>
        <Hr />
      </div>
      <!-- <Pagination :handleMore="handleMore" :loading="paginateLoading" @paginate="handlePagination" /> -->
    </div>
    <Modal :display="showRoleModal" size="large" @close="showRoleModal = false">
      <Roles />
    </Modal>
    <!-- modal to view user roles roles -->
    <Modal :display="showSingleUserRole" title="Assign role" size="large" @close="showSingleUserRole = false">
      <div class="modal-body">
        <div class="flex items-center justify-between">
          <p class="text-black text-lg">{{ currentUser.user.email }}</p>
          <SecondaryButton text="Assign Roles" @click="showAssignRoleModal = true" />
        </div>

        <p class="mt-4 font-semibold">Roles asiggned</p>
        <Hr />
        <div v-if="currentUser.roles.length">
          <div class="role-table-row" v-for="roles in currentUser.roles" :key="roles.id">
            <div class="flex items-center justify-between">
              <p class="text-grey">{{ roles.roleName.replace(/_/g, " ") | capitalize }}</p>
              <SecondaryButton background="#d8534e" color="white" text="Delete" :loading="removeRoleLoading" @click="handleRemoveRole(roles.id)" />
            </div>
          </div>
          <Hr />
        </div>
        <EmptyState description="No roles assigned to this user" v-else />
      </div>
    </Modal>
    <!-- modal to assign roles to user -->
    <Modal :display="showAssignRoleModal" @close="showAssignRoleModal = false">
      <div class="modal-body">
        <Select
          :options="adminRoles"
          class="w-full mt-6"
          placeholder="Choose Role for user"
          label="name"
          :reduce="(role) => role.id"
          v-model="selectedRoleId"
        />
        <Button text="Submit" width="w-full" class="mt-12" :loading="selectRoleLoading" @click="assignRole" />
      </div>
    </Modal>
  </Card>
</template>
<script>
  import { mapActions, mapState } from "vuex";
  import { Button, SecondaryButton } from "@/UI/Button";
  import { LargeModal, Modal } from "@/UI/Modals";
  import Hr from "@/UI/Hr";
  import { LIST_ADMIN_USERS, ASSIGN_ADMIN_ROLE, GET_ROLES_BY_ID, REMOVE_ROLE_FROM_USER } from "@/utils/api/adminAuthorization";
  import "vue-select/dist/vue-select.css";
  import vSelect from "vue-select";
  import Pagination from "@/UI/Pagination";
  import { Select } from "@/UI/Input";

  export default {
    components: {
      Button,
      Hr,
      LargeModal,
      Modal,
      Pagination,
      SecondaryButton,
      Select,
      "v-select": vSelect,
      Roles: () => import("./Roles.vue"),
      Spinner: () => import("@/UI/Spinner"),
      EmptyState: () => import("@/UI/EmptyState"),
    },
    data: () => ({
      showRoleModal: false,
      loading: false,
      params: {
        page: 0,
        pageSize: 20,
      },
      adminUserList: [],
      currentUser: {
        user: "",
        roles: [],
      },
      showSingleUserRole: false,
      showAssignRoleModal: false,
      selectedRoleId: "",
      selectRoleLoading: false,
      removeRoleLoading: false,
    }),
    computed: {
      ...mapState({
        adminRoles: (state) => state.admin.adminRoles,
      }),
    },

    mounted() {
      this.getAdminRoles();
      this.getPermissions();
      this.listUsers();
    },
    methods: {
      ...mapActions("admin", ["getPermissions", "getAdminRoles"]),
      ...mapActions("loading", ["setLoading"]),
      ...mapActions("notification", ["showToast"]),
      listUsers() {
        this.loading = true;
        LIST_ADMIN_USERS({ params: this.params })
          .then(({ data }) => {
            this.adminUserList = data.data;
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      },
      handleViewUser(user) {
        this.setLoading(true);
        GET_ROLES_BY_ID(user.id)
          .then(({ data }) => {
            this.setLoading(false);
            this.currentUser.user = user;
            this.currentUser.roles = data.data;
            this.showSingleUserRole = true;
          })
          .catch(() => {
            this.setLoading(false);
          });
      },
      assignRole() {
        this.selectRoleLoading = true;
        let data = {
          resourceRoleId: this.selectedRoleId,
          principalId: this.currentUser?.user?.id,
        };
        ASSIGN_ADMIN_ROLE(data)
          .then(() => {
            this.showSingleUserRole;
            this.selectRoleLoading = false;
            this.showAssignRoleModal = false;
            this.showToast({
              display: true,
              description: "Role assigned successfullt",
              type: "success",
            });
            this.showSingleUserRole = false;
          })
          .catch(() => {
            this.selectRoleLoading = false;
          });
      },
      handleRemoveRole(id) {
        // this.removeRoleLoading = true;
        this.setLoading(true);
        REMOVE_ROLE_FROM_USER(id)
          .then(() => {
            // this.removeRoleLoading = false;
            this.setLoading(false);
            this.showToast({
              display: true,
              description: "Role removed successfullt",
              type: "success",
            });
            this.showSingleUserRole = false;
            // this.handleViewUser(this.currentUser?.user?.id);
          })
          .catch(() => {
            this.setLoading(false);
            // this.removeRoleLoading = false;
          });
      },
    },
  };
</script>
<style scoped>
  .purchase-container {
    min-height: calc(100vh - 10rem);
  }
  .payment-table {
    display: grid;
    grid-template-columns: 2.5rem 15rem 1fr 1fr 15rem;
    grid-column-gap: 10px;
    align-items: center;
  }
  .payment-table__col {
    padding: 0.8rem 0.5rem;
    /* white-space: nowrap; */
    font-size: 0.9rem;
  }
  .table-container {
    overflow: auto;
  }
  .modal-body {
    min-height: 36rem;
  }
  .role-table-row {
    padding: 0.8rem 0.5rem;
    /* border-bottom: 1px solid; */
  }
</style>
