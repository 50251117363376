<template>
    <div class="h-full">
        <div class="flex justify-center" v-if="beneficiaryLoading">
            <Spinner />
        </div>
        <div class="table-container" v-else>
            <table id="tamplate-table" class="w-full table-auto" >
                <thead>
                    <tr>
                        <th class="text-xs">S/N</th>
                        <th class="text-xs">Id</th>
                        <th class="text-xs">Name</th>
                        <th class="text-xs">Number</th>
                        <th class="text-xs">Status</th>
                        <th class="text-xs">Date</th>
                        <th class="text-xs">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <EmptyState class="mx-auto w-full my-10" description="No records found"
                    v-if="beneficiaryData.length === 0" />
                    <tr v-for="(beneficiary, index) in beneficiaryData" :key="beneficiary.id">
                        <td class="text-xs" >{{index + 1}}</td>
                        <td class="text-xs">{{beneficiary.id}}</td>
                        <td class="text-xs">{{beneficiary.name}}</td>
                        <td class="text-xs">{{beneficiary.number}}</td>
                        <td class="text-xs">{{beneficiary.status}}</td>
                        <td class="text-xs">{{dayjs(beneficiary.createdAt).format("MMM D YYYY | hh:mmA") }}</td>
                        <td class="text-xs"><Button text="Make payout" outline @click="makePayout(beneficiary)"/></td>
                    </tr>
                </tbody>
            </table>
            <Pagination class="view-more"  :meta="pageMeta" :loading="paginateLoading" @nextPage="handlePagination" />
        </div>
    </div>
</template>
<script>
import Spinner from '@/UI/Spinner'
import dayjs from 'dayjs'
import Pagination from "@/UI/Pagination";
import {GET_BENEFICIARIES} from '@/utils/api/wire'
import {Button} from '@/UI/Button'
export default {
    components:{
        Spinner,
        EmptyState: () => import("@/UI/EmptyState"),
        Spinner,
        Button,
        Pagination
    },
    computed: {
        dayjs() {
            return dayjs
        }
    },
    data:() => ({
        beneficiaryLoading:false,
        beneficiaryData:[],
        pageMeta:{},
        showPagination: false,
        paginateLoading: false,
        handleMore: false,
        params:{
            searchQuery:'',
            page: 0,
            pageSize: 10,
            from: "",
            to: "",
        },
    }),
    methods:{
        getBeneficiatyList(type){
            type === "paginate" ? (this.paginateLoading = true) : (this.beneficiaryLoading = true);

            GET_BENEFICIARIES(this.params)
                .then(({data}) => {
                    this.beneficiaryData.push(...data.data);
                    this.pageMeta = data.meta
                type === "paginate" ? (this.paginateLoading = false) : (this.beneficiaryLoading = false);

            }).catch(() => {
                type === "paginate" ? (this.paginateLoading = false) : (this.beneficiaryLoading = false);


            })
        },
        makePayout(data){
            this.$emit('beneficiaryPayout', data)
        },
        handlePagination() {
            this.paginateLoading = true;
            this.params.page++;
            // this.$router.replace({
            //   ...this.$router.currentRoute,
            //   query: {
            //     data: JSON.stringify(this.params),
            //   },
            // });
            this.getBeneficiatyList("paginate");
        },
    },
    mounted(){
        this.getBeneficiatyList()
    }
}
</script>
<style scoped>

</style>