<template>
    <div>
        <div class="flex flex-col lg:flex-row justify-between gap-4">
            <div class="flex-1">
                <p class="text-xl font-bold" v-if="paymentDetails.senderBusinessName">Sender: {{paymentDetails.senderBusinessName}}</p>
                <div class="flex flex-col md:flex-row gap-2 justify-between mt-2">
                    <p class="text-lg">Initiated by:</p>
                    <p class="font-semibold text-lg  capitalize">{{ paymentDetails.initiatorUserName | capitalize}}</p>
                 </div>
            </div>
            <div class="flex flex-col md:flex-row  items-center gap-4  mt-4 md:mt-0">
               <div class="flex gap-2 items-center justify-between">
                <Button text="Update payment details" outline @click="showUpdateModal = true" v-if="getUserPermissions && getUserPermissions.includes($completePermissionFormat('wire_payment_update'))"/>
                <Button text="Update payment status" @click="showSelectedPayment = true" v-if="paymentDetails.wirePaymentStatus != 'APPROVED' && paymentDetails.wirePaymentStatus !== 'DECLINED' && (getUserPermissions && getUserPermissions.includes($completePermissionFormat('wire_payment_update')))" />
               </div>
                <div  class="flex gap-2 items-center justify-between">
                    <Button text="View wire account" @click="$router.push(`/account/${paymentDetails.wireAccountId}`)"/>
                    <Button :loading="loadingReceipt" text="Download reciept" @click="downloadReceipt"/>
                </div>
            </div>
           
        </div>
        <div v-if="Object.keys(this.paymentDetails).length" class="mt-4">
            <Card class="grid  md:grid-cols-2 gap-3">
                <div v-if="paymentDetails.beneficiary">
                    <p class=" text-lg md:text-xl font-semibold">Beneficiary Details</p>
                    <p class="text-sm" >Account number: <span class="font-semibold">{{paymentDetails.beneficiary.accountNumber}}</span> </p>
                    <p class="text-sm md:text-base">Beneficiary name: <span class="font-semibold">{{paymentDetails.beneficiary.name}}</span> </p>
                    <p class="mt-2 text-sm md:text-base">Bank Name: <span class="font-semibold">{{paymentDetails.beneficiary.bankName}}</span> </p>
                    <p class="mt-2 text-sm md:text-base" v-if="paymentDetails.beneficiary.phoneNumber">Phone: <span class="font-semibold">{{paymentDetails.beneficiary.phoneNumber}}</span> </p>
                    <p class="mt-2 text-sm md:text-base" v-if="paymentDetails.beneficiary.accountName">Account name: <span class="font-semibold" >{{paymentDetails.beneficiary.accountname}}</span> </p>
                    <p class="mt-2" v-if="paymentDetails.beneficiary.businessRegistrationNumber">Business registration number: <span class="font-semibold" >{{paymentDetails.beneficiary.businessRegistrationNumber}}</span> </p>
                    <p class="mt-2" v-if="paymentDetails.beneficiary.email">Email: <span class="font-semibold" >{{paymentDetails.beneficiary.email}}</span> </p>
                    <p class="mt-2" v-if="paymentDetails.beneficiary.financialSystemCode">Financial system code: <span class="font-semibold" >{{paymentDetails.beneficiary.financialSystemCode}}</span> </p>
                    <p class="mt-2" v-if="paymentDetails.senderAddress && paymentDetails.senderAddress">Address: <span class="font-semibold">{{paymentDetails.senderAddress.main}}</span> </p>
                    <p class="mt-2" v-else>Address: <span class="font-semibold">{{paymentDetails.beneficiary.address}}</span> </p>
                    <p class="mt-2">Country: <span class="font-semibold">{{paymentDetails.beneficiary.country | capitalize}}</span> </p>
                    <p class="mt-2">SWIFT/ROUTING: <span class="font-semibold">{{paymentDetails.beneficiary.swiftCode ? paymentDetails.beneficiary.swiftCode  : paymentDetails.beneficiary.routingNumber }}</span> </p>
                </div>


                <div>
                    <p class="text-sm md:text-lg">Amount: <span class="text-lg md:text-xl font-semibold" v-if="paymentDetails.receiveCurrency">{{paymentDetails.receiveAmount | formatMoney(paymentDetails.receiveCurrency.sign)}}</span> </p>
                    <p class="text-sm md:text-lg mt-2">Naira charge: <span class="font-semibold text-lg md:text-xl ">{{paymentDetails.sendAmount | formatMoney(paymentDetails.sendCurrency.sign)}}</span> </p>
                    <p class="mt-2 text-sm md:text-lg">Date payment was created: <span class="font-semibold">{{dayjs(paymentDetails.createdAt).format("MMM D YYYY | hh:mmA") }}</span>  </p>
                    <p class="mt-2 text-sm md:text-lg">Date payment was completed: <span class="font-semibold" v-if="paymentDetails.completedAt">{{dayjs(paymentDetails.completedAt).format("MMM D YYYY | hh:mmA") }}</span>  </p>
                    <p class="mt-2 text-sm md:text-lg">Fee: <span class="font-semibold">{{paymentDetails.fee | formatMoney}}</span></p>
                    <p class="mt-2 text-sm md:text-lg">Payment Status: <span class="font-semibold" :class="[paymentDetails.wirePaymentStatus.toLowerCase()]" v-if="paymentDetails.wirePaymentStatus">{{paymentDetails.wirePaymentStatus}}</span></p>
                </div>
            </Card>
            <Card class="mt-2">
                <div class="grid md:grid-cols-2 gap-2">
                    <div>
                        <p class="text-sm md:text-lg">Initiator type: <span class="font-semibold">{{paymentDetails.initiatorType}}</span> </p>
                        <p class="mt-2 text-sm md:text-lg">Initiator email: <span class="font-semibold">{{paymentDetails.initiatorEmail}}</span> </p>
                        <p class="mt-2 text-sm md:text-lg">Initiator username: <span class="font-semibold">{{paymentDetails.initiatorUserName | capitalize}}</span> </p>
                        <p class="mt-2 text-sm md:text-lg">Schedule Payment type: <span class="font-semibold">{{paymentDetails.wirePaymentScheduleType || 'NIL' }}</span> </p>
                        <p class="mt-2 text-sm md:text-lg" v-if="paymentDetails.scheduledRate">Scheduled rate: <span class="font-semibold">{{paymentDetails.scheduledRate | formatMoney}}</span> </p>
                        <p class="mt-2 text-sm md:text-lg" v-if="paymentDetails.scheduledDate">Scheduled Date: <span class="font-semibold">{{dayjs(paymentDetails.scheduledDate).format("MMM D YYYY | hh:mmA")}}</span></p>
                        <p class="mt-2 text-sm md:text-lg">Purpose of payment: <span class="font-semibold">{{paymentDetails.purposeOfPayment || 'NIL'}}</span></p>
                        <p class="mt-2 text-sm md:text-lg" v-if="paymentDetails.purposeOfPaymentCode">Purpose of payment code: <span class="font-semibold">{{paymentDetails.purposeOfPaymentCode}}</span></p>
                        <div class="mt-2 flex items-center gap-2 md:block">
                            <p>Invoices:</p>
                            <div class="flex items-center gap-2">
                                <Button p="p-2" @click="downloadItem(invoice)" :text="`Invoice ${index + 1}`" v-for="(invoice, index) in paymentDetails.invoiceMediaId" :key="invoice"/>
                            </div>
                        </div>
                    </div>
                    <div v-if="paymentDetails.serviceProviderDetails">
                        <p class="text-lg md:text-xl font-semibold">Service Provider details</p>
                        <p class="mt-2 text-sm md:text-lg">Tracking Code: <span class="font-semibold">{{paymentDetails.serviceProviderDetails.trackingCode || 'NIL'}}</span></p>
                        <p class="mt-2 text-sm md:text-lg">Tracking Code Type: <span class="font-semibold">{{paymentDetails.serviceProviderDetails.trackingCodeType || 'NIL'}}</span></p>
                    </div>
                </div>
            </Card>
        </div>  
        <RightSidebar :display="showSelectedPayment" @close="closeUpdateModal" v-if="showSelectedPayment">
            <template #body>
            <div class="payment-link-container-body">
                <p class="text-lg font-semibold">Update payment status</p>
                <div class="flex items-center gap-1  md:gap-2 mt-4">
                    <Button :outline="paymentStatus != status" :text="status" v-for="(status, index) in paymentStatusToShow" :key="status + index" @click="confirmUpdateStatus(status)"/>
                </div>
                <div v-if="paymentStatus == 'COMPLETED'">
                    <Input v-model="serviceProviderDetails.trackingCode" class="mt-4 w-full" placeholder="Tracking code" width="w-full" v-if="paymentStatus == 'COMPLETED' && paymentStatus !== ''"/>
                    <Select :options="trackingCodeType" :reduce="(value) => value.value" class="mt-4" width="w-full" placeholder="Tracking code type" v-model="serviceProviderDetails.trackingCodeType" v-if="paymentStatus == 'COMPLETED' && paymentStatus !== ''"/>
                    <Input class="mt-4" width="w-full" placeholder="Processing channel" v-model="serviceProviderDetails.processingChannel" v-if="paymentStatus == 'COMPLETED' && paymentStatus !== ''"/>
                    <CurrencyInput required label="Transaction cost in send currency" placeholder="Transaction cost in send currency" class="mt-4 w-full"  width="w-full" v-model="transactionCostInSendCurrency" v-if="paymentStatus == 'COMPLETED'"/>
                    <Input class="mt-4" width="w-full" placeholder="Service provider reference" v-model="serviceProviderDetails.serviceProviderReference" v-if="paymentStatus == 'COMPLETED' && paymentStatus !== ''"/>
                    <SecondaryButton class="mt-6"  text="Complete payment" width="w-full" @click="showConfirmUpdateStatus = true"/>
                </div>
                <div v-if="paymentStatus === 'DECLINED'" >
                    <Input v-model="failureReason" class="mt-6" width="w-full" placeholder="Failure reason..."
                    v-if="paymentStatus === 'DECLINED'" />
                    <SecondaryButton :disabled="!failureReason"  class="mt-6"  text="Decline payment" width="w-full" @click="showConfirmUpdateStatus = true"/>
                </div>
           
            </div>
        </template>
        </RightSidebar>
        <Modal :display="showConfirmUpdateStatus" @close="showConfirmUpdateStatus = false">
            <p class="font-semibold text-xl">Move status to <strong>{{paymentStatus}}</strong>?</p>
            <p>This action is irrevisible</p>
            <div class="mt-6 flex items-center justify-end gap-3">
                <Button text="Close" outline @click="showConfirmUpdateStatus = false"/>
                <Button text="Confirm" @click="handleUpdate" :loading="updateLoading"/>
            </div>
        </Modal>
        <right-sidebar display title="Update payment details" v-if="showUpdateModal" @close="showUpdateModal = false">
            <template #body>
                <div class="payment-link-container-body">
                    <Input v-model="serviceProviderDetails.trackingCode" class="mt-4 w-full" placeholder="Tracking code" width="w-full"/>
                    <Select :options="trackingCodeType" :reduce="(value) => value.value" class="mt-4" width="w-full" placeholder="Tracking code type" v-model="serviceProviderDetails.trackingCodeType" />
                    <Input class="mt-4" width="w-full" placeholder="Processing channel" v-model="serviceProviderDetails.processingChannel" />
                    <Input class="mt-4" width="w-full" placeholder="Service provider reference" v-model="serviceProviderDetails.serviceProviderReference"/>
                    <Button :loading="detailsLoading" text="Complete" width="w-full" class="mt-6" @click="updatePaymentDetails"/>
                </div>
            </template>
        </right-sidebar>
        <!-- <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="true"
            :paginate-elements-by-height="1400"
            filename="payment receipt"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-format="a4"
            pdf-orientation="portrait"
            pdf-content-width="auto"
            ref="html2Pdf"
        >
            <template slot="pdf-content">
                <Reciept :paymentDetails="paymentDetails" />
            </template>
         </vue-html2pdf> -->
    </div>
</template>
<script>
import {mapActions} from 'vuex'
import {VIEW_PAYMENT_DETAILS, UPDATE_PAYMENT_DETAILS, GET_PAYMENT_RECIEPT} from '@/utils/api/wire'
import dayjs from 'dayjs'
import Hr from '@/UI/Hr'
import {Button, SecondaryButton} from '@/UI/Button'
import { Modal,RightSidebar  } from '@/UI/Modals'
import { Select, Input, CurrencyInput } from '@/UI/Input'
import {  UPDATE_WIRE_PAYMENT_COMPLETE, UPDATE_WIRE_PAYMENT_DEClINED, UPDATE_WIRE_PAYMENT_SCHEDULE, UPDATE_WIRE_PAYMENT_PROCESSING } from '@/utils/api/wire'
import VueHtml2pdf from 'vue-html2pdf'
import Reciept from './Reciept.vue'
import {mapGetters} from 'vuex'

export default {
    components:{
        Hr,
        Button,
        Select,
        Input,
        CurrencyInput,
        Modal,
        RightSidebar,
        VueHtml2pdf,
        Reciept,
        SecondaryButton 
    },
    data:() => ({
        paymentDetails:{},
        showSelectedPayment:false,
        updateLoading: false,
        paymentStatus: '',
        failureReason: "",
        transactionCostInSendCurrency:0,
        paymentStatusList: ['DECLINED', 'COMPLETED', 'PROCESSING', 'IN_REVIEW', 'SCHEDULED'],
        // trackingCodeType :['UETR', 'IMAD', 'OMAD'],
        trackingCodeType :[{label: 'UETR', value : 'UETR'}, {label :'IMAD', value:'IMAD'}, {label:'OMAD', value:'OMAD'}, {label:'NONE', value: null}],
        serviceProviderDetails:{
            processingChannel:null,
            serviceProviderReference: null,
            trackingCode:null,
            trackingCodeType:null
        },
        showConfirmUpdateStatus: false,
        detailsLoading: false,
        showUpdateModal: false,
        loadingReceipt: false
    }),
    computed:{
        dayjs(){
            return dayjs
        },
        paymentStatusToShow() {
            // First check if the wirePaymentScheduleType is false
            const hasScheduledType = !!this.paymentDetails.wirePaymentScheduleType;

            if (this.paymentDetails.wirePaymentStatus === 'IN_REVIEW') {
                return hasScheduledType ? ['SCHEDULED', 'PROCESSING', 'DECLINED'] : ['PROCESSING', 'DECLINED'];
            }

            if (this.paymentDetails.wirePaymentStatus === 'SCHEDULED') {
                return hasScheduledType ? ['PROCESSING', 'COMPLETED', 'DECLINED'] : ['PROCESSING', 'COMPLETED', 'DECLINED'];
            }

            if (this.paymentDetails.wirePaymentStatus === 'PROCESSING') {
                return ['COMPLETED', 'DECLINED'];
            }

            return [];  // Default empty array for any other statuses not explicitly covered
        },
        ...mapGetters('auth', ['getUserPermissions'])
    },
    methods:{
        ...mapActions('loading', ['setLoading']),
        ...mapActions('notification', ['showAlert']),
        getPaymentDetails(){
            this.setLoading(true)
            let params = {
                wirePaymentId: this.$route.params.id,
                // wireAccountId: this.$route.query.account_id
            }

            VIEW_PAYMENT_DETAILS(params)
                .then(({data}) => {
                    this.paymentDetails = data.data
                    this.setLoading(false)
                }).catch((e) => {
                    console.log(e)
                    this.setLoading(false)
                })
        },
        downloadItem(id) {
            window.open(
            `${process.env.VUE_APP_BASE_URL}/media/image/${id}`,
            "_blank" // open in a new window.
            );
      },
      closeUpdateModal() {
            this.showSelectedPayment = false;
            this.failureReason = "";
            this.paymentStatus = "";
    },
    handleUpdate() {
        if((this.paymentStatus === 'COMPLETED' && !this.transactionCostInSendCurrency) || (this.transactionCostInSendCurrency >  this.paymentDetails.sendAmount)  ){  
            this.showConfirmUpdateStatus  = false
            return this.showAlert({
                display: true,
                title:'Error',
                description:'Enter a valid transaction cost in send value',
                type:'error'
            })

        }

        if(this.paymentStatus === 'DECLINED' && !this.failureReason){
            this.showConfirmUpdateStatus  = false
            return this.showAlert({
                display: true,
                title:'Error',
                description:'Enter reason for failure',
                type:'error'
            })

        }


            this.updateLoading = true;
            const payload = {
                id: this.paymentDetails.id,
                wirePaymentStatus: this.paymentStatus,
                failureReason: this.failureReason,
                serviceProviderDetails: this.serviceProviderDetails
            }
            if (this.paymentStatus == "DECLINED") {
                delete payload.wirePaymentStatus;
                delete payload.serviceProviderDetails;
                UPDATE_WIRE_PAYMENT_DEClINED(payload)
                .then(() => {
                    this.updateLoading = false;
                    this.statusSuccess()
                    this.showSelectedPayment = false;
                    this.getPaymentDetails();
                }).catch(() => {
                    this.updateLoading = false
                })
                return
            }
            if(this.paymentStatus == 'COMPLETED'){
                payload.transactionCostInSendCurrency = this.transactionCostInSendCurrency
                UPDATE_WIRE_PAYMENT_COMPLETE(payload)
                .then(() => {
                    this.updateLoading = false;
                    this.statusSuccess()
                    this.showSelectedPayment = false;
                    this.getPaymentDetails();
                }).catch(() => {
                    this.updateLoading = false
                })
            }


            if(this.paymentStatus === 'SCHEDULED'){
                UPDATE_WIRE_PAYMENT_SCHEDULE(this.paymentDetails.id)
                .then(() => {
                    this.updateLoading = false;
                    this.statusSuccess()
                    this.showSelectedPayment = false;
                    this.getPaymentDetails();
                }).catch(() => {
                    this.updateLoading = false
                })
            }

            if(this.paymentStatus === 'PROCESSING'){
                let data = {
                    id: this.paymentDetails.id,
                    isRateScheduledPayment: this.paymentDetails.wirePaymentScheduleType === 'RATE'
                }
                UPDATE_WIRE_PAYMENT_PROCESSING(data)
                .then(() => {
                    this.updateLoading = false;
                    this.statusSuccess()
                    this.showSelectedPayment = false;
                    this.getPaymentDetails();
                }).catch(() => {
                    this.updateLoading = false
                })
            }
        },
        confirmUpdateStatus(status){
            this.paymentStatus = status;
            if(!['COMPLETED', 'DECLINED'].includes(status)){
                this.showConfirmUpdateStatus = true
            }
        },
        statusSuccess(){
            this.showAlert({
                display: true,
                title: 'Success',
                description:'Status change successful',
                type:'success',
                icon:'success'
            })
            this.showConfirmUpdateStatus = false
        },
        updatePaymentDetails(){
            this.detailsLoading = true;
            const data = {
                id: this.paymentDetails.id,
                serviceProviderDetails: this.serviceProviderDetails
            }
            UPDATE_PAYMENT_DETAILS(data)
                .then(() => {
                        this.showAlert({
                        display: true,
                        title: 'Success',
                        description:'Status change successful',
                        type:'success',
                        icon:'success'
                    })
                    this.detailsLoading = false;
                    this.showUpdateModal = false
                }).catch(() => {
                    this.detailsLoading = false;
                    this.showUpdateModal = false
                })
        },
        async downloadPdf() {
            this.$refs.html2Pdf.generatePdf();
        },
        async downloadReceipt(){
            this.loadingReceipt = true
            GET_PAYMENT_RECIEPT(this.paymentDetails.id)
                .then(({data}) => {
            this.generatedStatementMediaId = data.data.generatedStatementMediaId
                window.open(
                `${process.env.VUE_APP_BASE_URL}/media/file/${data.data.generatedStatementMediaId}`,
                "_blank"
                );
                this.loadingReceipt = false
            }).catch(() => {
                this.loadingReceipt = false
            })
        }
    },
    mounted(){
        this.getPaymentDetails()
    }
}
</script>
<style scoped>
.payment-link-container-body {
    padding: 16px 24px;
  }

.completed{
    color: #055F3A;
}

.processing, .in_review {
    color: #DC6803;
}

.declined {
    color: #912018;
}
</style>