export default {
  SET_CURRENCY_MAPPINGS(state, payload) {
    state.currencyMappings = payload;
  },
  GET_ALL_REGISTRATON_DATA(state, payload){
    state.allRegistrationData = payload;
  },
  ADD_ALL_REGISTRATON_DATA(state, payload){
    state.allRegistrationData.push(...payload);
  },
  GET_ALL_PENDING_REGISTRATON_DATA(state, payload) {
    state.allPendingRegistrationData = payload;
  },
  ADD_ALL_PENDING_REGISTRATON_DATA(state, payload) {
    state.allPendingRegistrationData.push(...payload);
  },
  SET_CURRENCIES(state, payload){
    state.currencyList = payload;
  }
};
