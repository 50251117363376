<template>
    <div>
       
          <div>
            <p class="text-grey-2 text-sm">Who are you sending to?</p>
            <SearchInput placeholder="Account number, name or email" class="my-2" v-model="params.searchQuery" autofocus @keyup="handleSearch" :disabled="loading" p="p-3"/>
            <!-- <Button width="w-full" text="Add new beneficiary" outline class="mt-3" preIcon="ri-add-circle-line" p="p-2" @click="handleNextStep('new')"/> -->
            <div class="add-beneficiary-button flex items-center justify-between w-full p-3">
                <div class="flex items-center" @click="handleNextStep('new')">
                    <div class="beneficiary-icon"><i class="ri-add-line"></i></div>
                    <span class="description text-dark">Add new beneficiary</span>
                </div>
                <i class="ri-arrow-drop-right-line"></i>
            </div>
            <p class="my-4 font-semibold text-dark">Recents</p>
            <div v-if="loading">
                <div v-for="i in 8" :key="i" class="payment-link-container-body">
                  <Skeleton width="7rem" height="1rem" />
                  <Skeleton width="12rem" height="1rem" class="mt-3" />
                </div>
            </div>
            <EmptyState class="mt-16" description="You don't have any beneficiary yet" v-if="!beneficiaryData.length && !loading" >
                <template #img>
                    <img class="text-center mx-auto"
                        src="https://res.cloudinary.com/zillaafrica/image/upload/v1676411732/customer/purchase_history_h3jok7.svg"
                        alt="" />
                </template>
            </EmptyState>
            <div v-else>
                <div class="recent-beneficiary-container items-center cursor-pointer" v-for="beneficiary in beneficiaryData" :key="beneficiary.key" @click="handleNextStep(beneficiary)">
                    <div>
                        <p class="text-dark text-sm">{{beneficiary.accountName}}</p>
                        <p class="text-xs text-grey capitalize">{{ beneficiary.bankName.toUpperCase() }} - {{beneficiary.accountNumber}}</p>
                    </div>
                    <i class="ri-arrow-right-s-line"></i>
                </div>
                <Pagination class="view-more" :meta="pageMeta" @nextPage="handlePagination" />
            </div>
        </div>
    </div>
</template>
<script>
import {mapActions, mapState} from 'vuex'
import {SearchInput} from '@/UI/Input'
import {Button} from '@/UI/Button'
import {GET_USER_PAYMENT_BENEFICIARY, GET_SINGLE_BENEFICIARY_DETAILS} from '@/utils/api/wire'
export default {
    components:{
        SearchInput,
        Button,
        EmptyState: () => import("@/UI/EmptyState"),
        Pagination: () => import("@/UI/Pagination"),
    },
    props:{
        wireAccountData:{
            type: Object,
            default:() => {}
        }
    },
    data:() => ({
        searchBeneficiary:'',
        loading: false,
        params:{
            searchQuery:'',
            page: 0,
            pageSize: 10,
            from: "",
            to: "",
        },
        timer: null,
        paginateLoading: false,
        beneficiaryData:[],
        pageMeta:[]
    }),
    computed:{
        ...mapState({
            // beneficiaryData:(state) => state.beneficiary.beneficiaryData,
            // pageMeta:(state) => state.beneficiary.beneficiaryListMeta,
        })
    },
    methods:{
        ...mapActions('beneficiary', ['getBeneficiaryAction', 'getBeneficiaryDetailsAction']),
        ...mapActions('loading', ['setLoading']),
        getBeneficiary(type){
            type === "paginate" ? (this.paginateLoading = true) : (this.loading = true);
            GET_USER_PAYMENT_BENEFICIARY(this.wireAccountData.wireAccount.id, this.params).then((res) => {
                this.beneficiaryData.push(...res.data.data);
                this.pageMeta = res.data.meta
                type === "paginate" ? (this.paginateLoading = false) : (this.loading = false);

            }).catch(() => {
                type === "paginate" ? (this.paginateLoading = false) : (this.loading = false);

            })
        },
        handleNextStep(type){
            if(type === 'new' ){
                this.$emit('beneficiary', 'new')
            }else{
                this.setLoading({
                    display: true,
                    title:'Please wait...',
                    description:'Getting beneficiary details'
                })
                GET_SINGLE_BENEFICIARY_DETAILS(type.id, this.wireAccountData.wireAccount.id).then((res) => {
                    this.$emit('beneficiary', {...res.data.data });
                    this.setLoading(false)
                }).catch(() => {
                    this.setLoading(false)
                })
            }
        },
        handleSearch(){
            const callBeneficiary = () =>{
                this.getBeneficiary();
                this.beneficiaryData = []
            } 
            this.debounce(callBeneficiary)

        },
        debounce(func, type){
          if (this.timer) {
            clearTimeout(this.timer); // clear any pre-existing timer
          }
          this.timer = setTimeout(() => {
            func()
          }, 300);
      },
      handlePagination() {
            this.paginateLoading = true;
            this.params.page++;
            this.getBeneficiary("paginate");
        },

    },
    mounted(){
        // if(!this.beneficiaryData.length){
            this.getBeneficiary()
        // }
    }
}
</script>
<style scoped>
.recent-beneficiary-container{
    padding: 0.8rem 0;
    border-top: 1px solid #E3E8EF;
    border-bottom: 1px solid #E3E8EF;
    display: flex;
    justify-content: space-between;
}
.add-beneficiary-button{
    border: 1px solid #E3E8EF;
    cursor: pointer;
    border-radius: 8px

}

.add-beneficiary-button .description{
    font-weight: 500;
    font-size: 12px
}

.beneficiary-icon{
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background: #F8F8F8;
    margin-right: 1rem;
    display: grid;
    place-items: center

}
</style>