<template>
    <VerifyBank />
  </template>
  <script>
  import VerifyBank from "@/components/bnpl/VerifyBankStatement/VerifyBankStatement.vue";
  export default {
    components: {
      VerifyBank,
    },
  };
  </script>
  