<template>
  <Card class="purchase-container">
    <h5 class="text-lg font-semibold">Customer Employment Information</h5>
    <Hr />
    
  </Card>
</template>
<script>
  import { mapActions } from "vuex";
  import Hr from "@/UI/Hr";

  export default {
    components: {
      Hr,
      Spinner: () => import("@/UI/Spinner"),
    },
    data: () => ({
      loading: false,
    }),
    mounted() {
      this.getCustomerEmployment();
    },
    methods: {
      ...mapActions("customers", ["getEmploymentInformation"]),
      getCustomerEmployment() {
        this.loading = true;
        this.getEmploymentInformation(this.$route.params.id)
          .then(() => {
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      },
    },
  };
</script>
<style scoped>
  .purchase-container {
    min-height: calc(100vh - 6rem);
  }
</style>
