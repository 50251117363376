import axios from "@/plugins/axios";

export const CREATE_NEW_PARTNER = (data) => {
  return axios.post(`/bnpl/partner-registration`, data);
};

export const GET_PARTNER_REGISTRATION = (payload) => {
  return axios.get(`bnpl/partner-registration`, { params: payload.params });
};

export const GET_ALL_PARTNERS = (payload) => {
  return axios.get(`bnpl/partner`, { params: payload.params });
};

export const RESEND_ACTIVATION_SMS = (id) => {
  return axios.put(`/bnpl/partner-registration/${id}/resend-activation-sms`);
};

export const GET_SINGLE_PARTNER_MERCHANTS = (payload) => {
  return axios.get(`/bnpl/merchant/referrer/${payload.id}`, { params: payload.params });
};

export const GET_SINGLE_PARTNER_CUSTOMERS = (payload) => {
  return axios.get(`/bnpl/customer/referrer/${payload.id}`, { params: payload.params });
};




