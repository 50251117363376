<template>
    <SingleWireAccount/>
</template>
<script>
import SingleWireAccount from '@/components/Account/SingleAccount'
export default {
    components:{
        SingleWireAccount
    }
}
</script>