<template>
  <Card class="purchase-container h-full">
    <BackNav @click="$router.push('/')" />
    <div>
      <div class="flex justify-between items-center">
        <h5 class="text-lg font-semibold">Customer Details</h5>
        <SecondaryButton text="View issued cards" class="w-40" @click="$router.push(`/customers/cards/${$route.params.id}`)" />
      </div>
      <Hr />
      <Spinner class="text-center" v-if="loading" />
      <div v-else>
        <div class="grid grid-cols-2">
          <div class="flex items-center">
            <div class="profile-image mr-6">
              <p class="text-white text-sm" v-if="!customerData.customerFacialImageId">No profile Image</p>
              <img :src="getImage(customerData.customerFacialImageId)" alt="image" v-else />
            </div>
            <p class="capitalize" v-if="customerData.status" :class="[customerData.status.toLowerCase()]">
              {{ customerData.status }}
            </p>
          </div>
          <div class="grid grid-cols-2">
            <div class="credit-grid px-2" v-if="customerWallet.creditWallet">
              <p class="font-semibold">Credit wallet</p>
              <p>Balance: {{ customerWallet.creditWallet.balance | formatMoney }}</p>
              <p>Credit Limit: {{ customerWallet.creditWallet.creditLimit | formatMoney }}</p>
            </div>
            <div class="px-2" v-if="customerWallet.instaPayWallet">
              <p class="font-semibold">Insta wallet</p>
              <p>Balance: {{ customerWallet.instaPayWallet.balance | formatMoney }}</p>
              <p>Account Number: {{ customerWallet.instaPayWallet.accountNumber }}</p>
              <p>Account Name: {{ customerWallet.instaPayWallet.accountName }}</p>
            </div>
          </div>
        </div>

        <div>
          <div>
            <Hr />
            <h5 class="text-lg font-semibold">Profile Details</h5>
            <Hr />
            <p class="mt-4">
              Name:
              {{ customerData.firstname }}
              {{ customerData.lastname && customerData.lastname }}
            </p>
            <p class="mt-4">Email: {{ customerData.email ? customerData.email : "N/A" }}</p>
            <p class="mt-4">BVN: {{ customerData.bvn ? customerData.bvn : " N/A" }}</p>
            <p class="mt-4">Address:{{ customerData.address ? customerData.address : " N/A" }}</p>
            <p class="mt-4">LGA: {{ customerData.lga ? customerData.lga : " N/A" }}</p>
            <p class="mt-4">
              State:
              {{ customerData.state ? customerData.state : "N/A" }}
            </p>
            <p class="mt-4">
              Phone:
              {{ customerData.phoneNumber ? customerData.phoneNumber : "N/A" }}
            </p>
            <p class="mt-4">
              Completed Identity verification:
              {{ customerData.identityVerificationCompleted }}
            </p>
          </div>
          <!-- employment section -->
          <div v-if="showEmploymentData" class="mt-8">
            <Hr />
            <h5 class="text-lg font-semibold">Customer Employment Details</h5>
            <Hr />
            <Spinner class="text-center" v-if="employmentLoading" />
            <p class="mt-4">
              Verification status :
              <span
                v-if="employmentData.verificationStatus"
                :class="[employmentData.verificationStatus.toLowerCase()]"
                class="capitalize text-base font-normal"
                >{{ employmentData.verificationStatus }}</span
              >
            </p>
            <p class="mt-4">Employer name: {{ employmentData.employerName }}</p>
            <p class="mt-4">
              Website:
              {{ employmentData.employerWebsite ? websites.employerWebsite : "N/A" }}
            </p>
            <p class="mt-4">
              Office address:
              {{ employmentData.officeAddress ? employmentData.officeAddress : "N/A" }}
            </p>
            <p class="mt-4">LGA : {{ employmentData.lga }}</p>
            <p class="mt-4">State : {{ employmentData.state }}</p>
            <p class="mt-4">
              Verificaiton type :
              {{ employmentData.verificationType ? employmentData.verificationType : "N/A" }}
            </p>
            <p class="mt-4">
              Date created :
              {{ employmentData.createdAt ? dayjs(employmentData.createdAt).format("MMM D YYYY | hh:mmA") : "N/A" }}
            </p>

            <div class="customer-emails mt-12" v-if="employmentData.employmentEmailAddress">
              <p class="font-bold text-lg">Customer Employment Emails</p> 
              </div>
              <!-- employment email -->
              <div class="customer-emails md:grid grid-cols-2 w-1/2" v-for="email in employmentData.employmentEmailAddress" :key="email.id">
                <div class="mt-4 ">
                  <p>Email</p>
                  <p>{{ email.workEmail }}</p>
                </div>
               <div class="mt-4 ">
                <p>Status</p>
                <p :class="[email.status.toLowerCase()]">{{ email.status }}</p>
               </div>
              </div>
            </div>
            <!-- employment docuyment -->
            <div class="customer-emails mt-12 w-1/2" v-if="employmentDocument.length">
              <p class="font-bold text-lg">Customer Employment Documents</p>
              <Hr />
              <div class="grid grid-cols-2">
                <p>Type</p>
                <p>Action</p>
              </div>
              <Hr />
              <div class="mt-4 customer-emails grid grid-cols-2 p-2" v-for="document in employmentDocument" :key="document.id">
                <p>{{ document.documents[0].type }}</p>
                <div>
                  <SecondaryButton text="View document" fontSize="text-xs" class="w-40" @click="downloadItem(document.documents[0])" />
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </Card>
</template>

<script>
  import { mapActions } from "vuex";
  import Hr from "@/UI/Hr";
  import { SecondaryButton } from "@/UI/Button";
  import dayjs from "dayjs";
  import getImage from "@/mixins/getImage";
  import { BackNav } from "@/UI/Navigation";
  import { GET_EMPLOYMENT_EMAIL, GET_EMPLOYMENT_DOCUMENT, GET_CUSTOMER_WALLETS } from "@/utils/api/customers";
  export default {
    mixins: [getImage],
    components: {
      Hr,
      SecondaryButton,
      Spinner: () => import("@/UI/Spinner"),
      BackNav,
    },
    data: () => ({
      customerData: {},
      loading: false,
      employmentLoading: false,
      employmentData: {
        employmentEmailAddress: [],
      },
      showEmploymentData: false,
      employmentDocument: [],
      customerWallet: {},
    }),
    computed: {
      dayjs() {
        return dayjs;
      },
    },
    mounted() {
      this.getSingleCustomer();
      this.getCustomerEmployment();
      this.getCustomerWallet();
    },
    methods: {
      ...mapActions("customers", ["getCustomerDetails", "getEmploymentInformation"]),
      getSingleCustomer() {
        this.loading = true;
        this.getCustomerDetails(this.$route.params.id)
          .then((res) => {
            this.customerData = res.data;
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      },
      getCustomerEmployment() {
        this.employmentLoading = true;
        this.getEmploymentInformation(this.$route.params.id)
          .then((res) => {
            this.showEmploymentData = true;
            this.employmentData = res.data;
            if (res.data.verificationType === "WORK_EMAIL") {
              this.getCustomerEmploymentEmail();
            } else {
              this.getCustomerEmploymentDocuments();
            }
            this.employmentLoading = false;
          })
          .catch(() => {
            this.employmentLoading = false;
          });
      },
      getCustomerEmploymentEmail() {
        GET_EMPLOYMENT_EMAIL(this.$route.params.id).then(({ data }) => {
          this.employmentData.employmentEmailAddress = data.data;
        });
      },
      getCustomerEmploymentDocuments() {
        GET_EMPLOYMENT_DOCUMENT(this.$route.params.id).then(({ data }) => {
          this.employmentDocument = data.data;
        });
      },
      downloadItem(document) {
        window.open(
          `${process.env.VUE_APP_BASE_URL}/media/image/${document.id}`,
          "_blank" // open in a new window.
        );
      },
      getCustomerWallet() {
        GET_CUSTOMER_WALLETS(this.$route.params.id).then(({ data }) => {
          this.customerWallet = data.data;
        });
      },
    },
  };
</script>
<style scoped>
  .purchase-container {
    min-height: calc(100vh - 10rem);
  }
  .profile-image {
    height: 8rem;
    width: 8rem;
    border-radius: 50%;
    display: grid;
    place-items: center;
    background: #d0d0d0;
    overflow: hidden;
  }

  .profile-image p {
    color: whiite;
  }
  .customer-emails {
    border-bottom: 1px solid rgba(242, 237, 253, 0.6);
  }

  .credit-grid {
    border-right: 1px solid rgba(242, 237, 253, 0.6);
    border-left: 1px solid rgba(242, 237, 253, 0.6);
  }
</style>
