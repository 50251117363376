<template>
  <div>
    <Card>
      <div class="flex justify-between">
        <h4 class="font-bold text-lg">Merchant Categories</h4>
        <div>
          <Button text="Add new" @click="showAddNew = true" />
        </div>
      </div>
      <div class="table-container mt-8">
        <table class="w-full table-auto">
          <thead>
            <tr>
              <th>S/N</th>
              <th>Icon</th>
              <th>Name</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(category, index) in featuredCategories"
              :key="category.id"
            >
              <td>{{ index + 1 }}</td>
              <td><img :src="category.iconUrl" class="h-12 w-12" alt="" /></td>
              <td>{{ category.name }}</td>
              <td :class="[category.status.toLowerCase()]">
                {{ category.status | capitalize }}
              </td>
              <td class="flex">
                <SecondaryButton
                  text="Update"
                  @click="updateCategory(category)"
                />
                <SecondaryButton
                  text="View Merchants"
                  class="ml-3"
                  @click="viewMerchants(category, '')"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Card>
    <!-- modal for adding new category -->
    <Modal
      :display="showAddNew"
      @close="showAddNew = false"
      title="Add new category"
      :class="showAddNew"
    >
      <Input placeholder="Name" width="w-full" v-model="newCategory.name" />
      <Upload
        class="mt-8"
        text="Upload Icon"
        v-model="newCategory.iconId"
        @upload="handleUpload"
        :fileType="['png']"
      />
      <Button
        text="Submit"
        width="w-full"
        class="mt-12"
        :loading="addNewLoading"
        @click="addNewCategory"
      />
    </Modal>
    <!-- modal for updating category -->
    <Modal
      :display="updateSingle"
      :title="`Update ${singleCategory.name} category`"
      @close="updateSingle = false"
    >
      <Input
        placeholder="Name"
        width="w-full"
        v-model="singleCategory.name"
        class="mt-4"
      />
      <Select
        type="Status"
        class="mt-6"
        placeholder="Select status"
        :reduce="(value) => value.value"
        :options="statusOptions"
        v-model="singleCategory.status"
      />
      <Upload
        class="mt-6"
        :fileType="['png']"
        @upload="handleSingleUpload"
      />
      <Button
        type="submit"
        text="Update"
        width="w-full"
        class="mt-12"
        :loading="updateLoading"
        @click="handleUpdateCategory"
      />
    </Modal>
    <Modal
      :display="viewMerchantsModal"
      @close="closeModal"
      :title="`Merchants in ${singleCategory.name} category`"
    >
      <p v-if="merchants.length === 0" class="text-center my-5">
        There are no merchants in this category
      </p>

      <div v-else>
        <div class="grid grid-cols-2 gap-4 py-6 justify-between">
          <div
            v-for="item in merchants"
            :key="item.id"
            class="flex justify-between items-center bg-lightGrey-1 shadow rounded py-2"
          >
            <div class="flex items-center px-2">
              <!-- <img class="w-6 h-6" :src="item.iconUrl" alt="logo" /> -->
              <img
                v-if="item.logoId"
                class="icon w-6 h-6"
                :src="file(item.logoId)"
                alt="merchant logo"
              />
              <img
                v-else
                class="icon w-6 h-6"
                :src="require('@/assets/images/logo.png')"
                alt="merchant logo"
              />
              <p class="font-semibold text-sm capitalize mx-2">
                {{ item.name }}
              </p>
            </div>
            <img
              class="h-2 w-2 cursor-pointer mr-2"
              src="https://res.cloudinary.com/zillaafrica/image/upload/v1623525601/customer/Union_yx6ulk.svg"
              alt="cancel"
              @click="deleteCategory(item.id)"
            />
          </div>
        </div>
        <Pagination
          :handleMore="handleMore"
          :loading="paginateLoading"
          @paginate="handlePagination"
        />
      </div>
    </Modal>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { SecondaryButton, Button } from "@/UI/Button";
import { Modal } from "@/UI/Modals";
import { Input, Upload, Select } from "@/UI/Input";
import Pagination from "@/UI/Pagination";
import {
  CREATE_NEW_CATEGORY,
  UPDATE_CATEGORY,
  GET_MERCHANTS_BY_CATEGORY,
  REMOVE_NEW_MERCHANT_CATEGORY,
} from "@/utils/api/merchants";
import file from "@/mixins/file";
export default {
  mixins: [file],
  components: {
    SecondaryButton,
    Button,
    Modal,
    Input,
    Upload,
    Select,
    Pagination,
  },
  data: () => ({
    showAddNew: false,
    addNewLoading: false,
    updateLoading: false,
    newCategory: {
      name: "",
      iconId: "",
    },
    singleCategory: {},
    updateSingle: false,
    statusOptions: [
      { label: "Active", value: "ACTIVE" },
      { label: "Inactive", value: "INACTIVE" },
    ],
    params: {
      page: 0,
      pageSize: 10,
    },
    viewMerchantsModal: false,
    paginateLoading: false,
    handleMore: false,
  }),
  computed: {
    ...mapState({
      featuredCategories: (state) => state?.merchants?.featuredCategories,
      merchants: (state) => state?.merchants?.allCategoryMerchants,
    }),
  },
  mounted() {
    this.getCategories();
  },
  methods: {
    ...mapActions("merchants", [
      "getFeaturedCategories",
      "getMerchantByCategory",
    ]),
    ...mapActions("loading", ["setLoading"]),
    ...mapActions("notification", ["showToast"]),
    getCategories() {
      this.setLoading(true);
      this.getFeaturedCategories()
        .then(() => {
          this.setLoading(false);
        })
        .catch(() => {
          this.setLoading(false);
        });
    },
    addNewCategory() {
      console.log(this.newCategory);
      this.addNewLoading = true;
      CREATE_NEW_CATEGORY(this.newCategory)
        .then(() => {
          this.addNewLoading = false;
          this.showAddNew = false;
          this.showToast({
            display: true,
            description: `${this.newCategory.name} added as a new category`,
            type: "success",
          });
          this.getCategories();
        })
        .catch(() => {
          this.addNewLoading = false;
        });
    },
    handleUpload(e) {
      this.newCategory.iconId = e.id;
    },
    handleSingleUpload(e) {
      this.singleCategory.iconId = e.id;
    },
    updateCategory(data) {
      this.singleCategory = data;
      console.log(data);
      this.updateSingle = true;
    },
    handleUpdateCategory() {
      this.updateLoading = true;
      UPDATE_CATEGORY(this.singleCategory)
        .then(() => {
          this.updateLoading = false;
          this.updateSingle = false;
          this.getCategories();
        })
        .catch(() => {
          this.updateLoading = false;
        });
    },
    // viewMerchants(category) {
    //   this.singleCategory = category;
    //   this.setLoading(true);
    //   this.getMerchantByCategory(category.id)
    //     .then((res) => {
    //       this.viewMerchantsModal = true;
    //       this.setLoading(false);
    //     })
    //     .catch(() => {
    //       this.setLoading(false);
    //     });
    // },
    viewMerchants(category, type) {
      // if it is pagination type show loading for pagination else general loading
      type === "paginate"
        ? (this.paginateLoading = true)
        : this.setLoading(true);;
      this.singleCategory = category;
      this.getMerchantByCategory({
        params: this.params,
        id: category.id,
        type: type,
      })
        .then((res) => {
          if (res.data.length === this.params.pageSize) {
            this.handleMore = true;
          } else {
            this.handleMore = false;
          }
          type === "paginate"
            ? (this.paginateLoading = false)
            : (this.loading = false);
          this.viewMerchantsModal = true;
          this.setLoading(false);
        })
        .catch(() => {
          type === "paginate"
            ? (this.paginateLoading = false)
            : (this.loading = false);
          this.setLoading(false);
        });
    },
    handlePagination() {
      this.paginateLoading = true;
      this.params.page++;
      this.viewMerchants(this.singleCategory, "paginate");
    },
    deleteCategory(id) {
      this.setLoading(true);
      REMOVE_NEW_MERCHANT_CATEGORY({
        merchantId: id,
        featuredCategoryId: this.singleCategory.id,
      })
        .then(() => {
          this.viewMerchants(this.singleCategory);
          this.setLoading(false);
          this.showToast({
            display: true,
            description: `Merchant removed successfully`,
            type: "success",
          });
        })
        .catch(() => {
          this.addNewLoading = false;
          this.setLoading(false);
        });
    },
    closeModal() {
      this.viewMerchantsModal = false;
      this.params.page = 0;
    }
  },
};
</script>
<style scoped>
/* @import "~@/assets/styles/table.css"; */
</style>
