<template>
  <div>
    <Card class="w-full rounded mb-6 text-dark" background="bg-layoutGrey">
      <p>
        <span class=""></span> Order title:
        <span class="font-semibold">{{
          purchaseDetail.orderTitle | capitalize
        }}</span>
      </p>
      <p>
        <span class=""></span> Order amount:
        <span class="font-semibold">{{
          purchaseDetail.amountOfOrder | formatMoney
        }}</span>
      </p>
      <div class="flex justify-between items-center">
        <p>
          <span class=""></span> Payment plan:
          <span class="font-semibold">{{ purchaseDetail.paymentPlan }}</span>
        </p>
        <p
          class="text-pending font-semibold"
          v-if="purchaseDetail.checkoutMode === 'ANONYMOUS'"
        >
          {{ purchaseDetail.checkoutMode }}
        </p>
      </div>
    </Card>
    <div v-if="purchaseDetail.paymentStatus === 'SUCCESSFUL'">
      <div class="font-semibold mb-3">Select a refund reason</div>
      <Checkbox :options="refundReasons" @selected="setRefundReason" />
      <Input
        v-if="selectedReason === 'Others'"
        width="w-full"
        placeholder="Enter your reason here"
        class="mt-8"
        v-model="reason"
        errorText="Please enter your reason"
        :validation="rules.others"
      />
      <div class="flex items-center my-6 justify-between">
        <div>
          <p class="font-semibold">Apply Merchant Penalty</p>
        </div>

        <div>
          <SwitchInput
            id="tfa"
            @change="applyMerchantPenalty = !applyMerchantPenalty"
            :value="applyMerchantPenalty"
          />
        </div>
      </div>
      <Button
        text="Refund"
        p="p-2"
        width="w-full"
        :loading="loading"
        :disabled="disableButton"
        @click="initiateRefund"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { Button } from "@/UI/Button";
import { Input, CheckboxInput } from "@/UI/Input";
import SwitchInput from "@/UI/SwitchInput";
import { REFUND_MERCHANT, GET_REFUND_REASONS } from "@/utils/api/purchases.js";
export default {
  components: {
    Button,
    Input,
    Checkbox,
    SwitchInput,
  },
  data: () => ({
    refundReasons: ["Price issue", "Wrong order", "Others"],
    reason: "",
    selectedReason: "",
    applyMerchantPenalty: true,
    loading: false
  }),
  props: {
    purchaseDetail: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  computed: {
    rules() {
      return {
        others: this.reason !== "",
      };
    },
    disableButton() {
      return Object.values(this.rules).includes(false);
    },
  },
  mounted() {
    // console.log(this.purchaseDetail);
    this.getRefundReasons();
  },
  methods: {
    ...mapActions("notification", ["showAlert"]),
    getRefundReasons() {
      GET_REFUND_REASONS()
        .then((res) => {
          this.refundReasons = res.data.data;
          this.refundReasons.push("Others");
        })
        .catch((err) => {});
    },
    setRefundReason(e) {
      if (e === "Others") {
        this.selectedReason = "Others";
        this.reason = "";
      } else {
        this.selectedReason = "";
        this.reason = e;
      }
    },
    initiateRefund() {
      this.loading = true;
      REFUND_MERCHANT({
        purchaseId: this.purchaseDetail.id,
        refundReason: this.reason,
        applyMerchantPenalty: this.applyMerchantPenalty,
      })
        .then((res) => {
          this.loading = false;
          this.$emit("success");
          this.showAlert({
            description: "Merchant successfully refunded",
            display: true,
            type: "success",
          });
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
};
</script>