<template>
  <div
    class="card transition-all"
    :class="[
      p,
      background.includes('bg-') ? background : `bg-${background}`,
      shadow ? 'shadow' : null,
      disabled ? 'pointer-events-none cursor-no-drop opacity-50' : null,
      borderColor ? `border ${borderColor}` : null,
      width
    ]"
    :style="{ 'border-radius': isNaN(radius) ? radius : `${radius}px` }"
    @click="$emit('click')"
  >
    <slot />
  </div>
</template>
  <script>
export default {
  props: {
    p: {
      type: String,
      default: "p-6",
    },
    background: {
      type: String,
      default: "bg-white",
    },
    shadow: {
      type: Boolean,
      default: false,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    radius: {
      type: [String, Number],
      default: "8px",
      required: false,
    },
    borderColor: {
      type: String,
      default: "border-grey-1",
      required: false,
    },
    width:{
      type: String,
      default:'w-auto'
    }
  },
};
</script>
<style scoped>
  .card {
    border-radius: 8px;
  }
  .shadow {
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  }
</style>
  