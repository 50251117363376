<template>
  <Card :p="p" class="flex searchContainer items-center" :width="width">
    <img
      class="h-4 w-auto mr-4"
      src="https://res.cloudinary.com/zillaafrica/image/upload/v1623838508/customer/Union_1_x261eq.svg"
      alt="search"
    />
    <!-- <div class="line mr-4 h-4"></div> -->
    <input
      class="border-none outline-none w-full"
      type="text"
      @input="$emit('input', $event.target.value)"
      @keyup="$emit('keyup', value)" 
      :value="value"
      :placeholder="placeholder"
    />
    <i class="ri-close-circle-line text-grey-6 cursor-pointer" v-if="value.length" @click="$emit('input', ''),$emit('clear')"></i>
  </Card>
</template>
<script>
  export default {
    props: {
      placeholder: {
        type: String,
        default: "Search",
      },
      width: {
        type: String,
        default: "w-auto",
      },
      value: {
        type: String,
        default: "",
      },
      p: {
        type: String,
        default: "p-1",
      },
    },
  };
</script>
<style scoped>
  .searchContainer {
    border: 1px solid rgba(138, 116, 192, 0.1);
  }
  .line {
    /* height: 1.2rem; */
    width: 1px;
    background-color: #7e7694;
  }
</style>
