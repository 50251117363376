<template>
    <div>
        <p class="font-semibold text-lg">Preview beneficiary’s detail</p>
        <div class="border border-grey-7 rounded bg-lightGrey-1 p-4 mt-4">
            <div class="grid grid-cols-2 text-sm">
                <p class="text-grey text-xs">Name</p>
                <p class="text-grey text-right text-xs font-semibold">{{ userData.beneficiary.name }}</p>
            </div>
            <div class="grid grid-cols-2 text-sm mt-3">
                <p class="text-grey text-xs">Swift code/routing no</p>
                <p class="text-grey text-right text-xs font-semibold">{{userData.beneficiary.swiftCode || userData.beneficiary.routingNumber}}</p>
            </div>
            <div class="grid grid-cols-2 text-sm mt-3">
                <p class="text-grey text-xs">Country</p>
                <p class="text-grey text-right text-xs font-semibold">{{userData.beneficiary.country | capitalize}}</p>
            </div>
            <div class="grid grid-cols-2 text-sm mt-3">
                <p class="text-grey text-xs">Account number/IBAN</p>
                <p class="text-grey text-right text-xs font-semibold">{{userData.beneficiary.accountNumber}}</p>
            </div>
            <div class="grid grid-cols-2 text-sm mt-3">
                <p class="text-grey text-xs">Bank name</p>
                <p class="text-grey text-right text-xs font-semibold">{{userData.beneficiary.bankName}}</p>
            </div>
            <div class="grid grid-cols-2 text-sm mt-3" v-if="userData.beneficiary.businessRegistrationNumber">
                <p class="text-grey text-xs">Business registration number</p>
                <p class="text-grey text-right text-xs font-semibold">{{userData.beneficiary.businessRegistrationNumber}}</p>
            </div>
            <div class="grid grid-cols-2 text-sm mt-3" v-if="userData.beneficiary.email">
                <p class="text-grey text-xs">Email address </p>
                <p class="text-grey text-right text-xs font-semibold break-words">{{userData.beneficiary.email}}</p>
            </div>
            <div class="grid grid-cols-2 text-sm mt-3" v-if="userData.beneficiary.phoneNumber">
                <p class="text-grey text-xs">Phone number</p>
                <p class="text-grey text-right text-xs font-semibold">{{userData.beneficiary.phoneNumber}}</p>
            </div>
            <div class="grid grid-cols-2 text-sm mt-3">
                <p class="text-grey text-xs">Address</p>
                <p class="text-grey text-right text-xs font-semibold">{{userData.beneficiary.address}}</p>
            </div>
            <div class="grid grid-cols-2 text-sm mt-3" v-if="userData.beneficiary.financialSystemCode">
                <p class="text-grey text-xs">IFSC</p>
                <p class="text-grey text-right text-xs font-semibold">{{userData.beneficiary.financialSystemCode}}</p>
            </div>
            <div class="mt-4 text-grey-6 inline-flex items-center cursor-pointer" @click="$emit('edit')">
                <p class="text-xs font-semibold">EDIT <i class="ri-edit-line"></i></p>
            </div>
        </div>
        <Button text="Continue" @click="$emit('nextStep')" class="mt-4"/>

    </div>
</template>
<script>
import {Button} from '@/UI/Button'
export default {
    props:{
        userData:{
            type: Object,
            default: () => {
                beneficiary:{}
            },
            required: true
        }
    },
    components:{
        Button
    }
}
</script>