import axios from "@/plugins/axios";

export const GET_CUSTOMERS = (payload) => {
  return axios.get("/bnpl/customer", { params: payload });
};

export const GET_CUSTOMERS_ISSUED_CARDS = (payload) => {
  return axios.get(`/bnpl/v1/issued-card/admin/user/${payload.userId}`, { params: payload.params });
};

export const GET_ISSUED_CARD_DETAILS = (cardId) => {
  return axios.get(`/bnpl/v1/issued-card/admin/cardId/${cardId}`);
};

export const UNBLOCK_ISSUED_CARD = (cardId) => {
  return axios.put(`/issued-card/v1/issued-card/id/${cardId}/unblock`);
};

export const GET_EMPLOYMENT_VERIFICATION = (payload) => {
  return axios.get("/bnpl/employment-document", { params: payload });
};

export const GET_BANK_STATEMENTS = (payload) => {
  return axios.get("/bnpl/account-financial-health/filter", {
    params: payload,
  });
};

export const CUSTOMER_FINANCIAL_HEALTH = (id) => {
  return axios.get(`/bnpl/account-financial-health/customer/${id}`);
};

export const GET_PDF_FILE_DETAILS = (accountFinancialHealthId) => {
  return axios.get(`/bnpl/account-financial-health/${accountFinancialHealthId}/get-pdf-file-details`);
};

export const UPDATE_FINANCIAL_HEALTH_STATUS = (payload) => {
  return axios.put(`/bnpl/account-financial-health/update-admin-review-status`, payload);
};

export const UPDATE_EMPLOYMENT_VERIFICATION = (id, status) => {
  return axios.put(`/bnpl/employment-document/${id}/id/decide`, status);
};

export const REOPEN_DOCUMENT = (id) => {
  return axios.put(`/bnpl/employment-document/${id}/id/open`);
};

export const GET_ALL_COMPANIES = (payload) => {
  return axios.get(`/bnpl/company-profile`, { params: payload });
};

export const MAKE_COMPANY_ELIGIBLE = (id) => {
  return axios.put(`/bnpl/company-profile/${id}/make-eligible`);
};

export const MAKE_COMPANY_INELIGIBLE = (id) => {
  return axios.put(`/bnpl/company-profile/${id}/make-not-eligible`);
};

export const GET_CUSTOMER_DETAILS = (customerId) => {
  return axios.get(`/bnpl/customer/${customerId}`);
};

export const GET_EMPLOYMENT_INFORMATION = (customerId) => {
  return axios.get(`/bnpl/employment-information/customer/${customerId}`);
};

export const GET_EMPLOYMENT_EMAIL = (customerId) => {
  return axios.get(`/bnpl/employment-email-address/customer/${customerId}`);
};

export const GET_EMPLOYMENT_DOCUMENT = (customerId) => {
  return axios.get(`/bnpl/employment-document/customer/${customerId}`);
};

export const GET_CUSTOMER_WALLETS = (customerId) => {
  return axios.get(`/bnpl/customer/${customerId}/wallets`);
};
