<template>
  <div class="purchase-container">
    <Card class="h-full">
      <div class="flex justify-between items-center py-4 table-container">
        <h5 class="text-lg font-semibold">Merchant List</h5>
        <Hr />
        <div class="flex items-center">
          <SearchInput placeholder="Email" class="mr-2" v-model="params.merchantUserEmail" />
          <SearchInput placeholder="Merchant Id" class="mr-2" v-model="params.merchantId" />
          <SearchInput placeholder="Phone Number" class="mr-2" v-model="params.phoneNumber" />
          <Card class="searchContainer w-44 mr-2" p="px-3">
            <Select type="filter" placeholder="Featured status" v-model="params.featuredStatus" :options="status"
              :reduce="(type) => type.value" />
          </Card>
          <DatePicker class="mr-4" v-model="range" is-range :model-config="modelConfig" :masks="masks">
            <template v-slot="{ inputValue, togglePopover }">
              <Card class="flex items-center border searchContainer w-full" p="px-1.5 py-1">
                <button class="p-2 focus:outline-none" @click="togglePopover()">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
                    <path
                      d="M11.09 2h-.272v-.25c0-.425-.354-.75-.818-.75-.464 0-.818.325-.818.75V2H4.818v-.25C4.818 1.325 4.464 1 4 1c-.464 0-.818.325-.818.75V2h-.273C1.845 2 1 2.775 1 3.75v7c0 .975.845 1.75 1.91 1.75h8.18c1.065 0 1.91-.775 1.91-1.75v-7C13 2.775 12.155 2 11.09 2Zm-1.363-.25c0-.15.11-.25.273-.25.164 0 .273.1.273.25v1c0 .15-.11.25-.273.25-.164 0-.273-.1-.273-.25v-1ZM4 1.5c.164 0 .273.1.273.25v1c0 .15-.11.25-.273.25-.164 0-.273-.1-.273-.25v-1c0-.15.11-.25.273-.25Zm-1.09 1h.272v.25c0 .425.354.75.818.75.464 0 .818-.325.818-.75V2.5h4.364v.25c0 .425.354.75.818.75.464 0 .818-.325.818-.75V2.5h.273c.764 0 1.364.55 1.364 1.25v.75H1.544v-.75c0-.7.6-1.25 1.364-1.25Zm9.544 8.25c0 .7-.6 1.25-1.363 1.25H2.909c-.764 0-1.364-.55-1.364-1.25V5h10.91v5.75Z"
                      fill="#9D9D9D" stroke="#9D9D9D" stroke-width=".1" stroke-linejoin="round" />
                    <path
                      d="M3.727 7h6.546c.163 0 .273-.1.273-.25s-.11-.25-.273-.25H3.727c-.163 0-.272.1-.272.25s.109.25.272.25ZM3.727 8.75h6.546c.163 0 .273-.1.273-.25s-.11-.25-.273-.25H3.727c-.163 0-.272.1-.272.25s.109.25.272.25ZM3.727 10.5h6.546c.163 0 .273-.1.273-.25s-.11-.25-.273-.25H3.727c-.163 0-.272.1-.272.25s.109.25.272.25Z"
                      fill="#9D9D9D" stroke="#9D9D9D" stroke-width=".1" stroke-linejoin="round" />
                  </svg>
                </button>
                <p v-if="inputValue.start === null" @click="togglePopover()" class="text-grey">
                  Date filter
                </p>
                <p v-else @click="togglePopover()" class="text-sm text-grey">
                  {{ inputValue.start }} - {{ inputValue.end }}
                </p>
              </Card>
            </template>
          </DatePicker>
          <Button text="Search" p="p-2" @click="handleSearch" />
          <!-- <Card class="searchContainer w-44" p="px-3"> -->
          <!-- </Card> -->
        </div>
      </div>
      <Spinner class="text-center" v-if="loading" />
      <div class="table-container" v-else>
        <table id="template-table" class="w-full table-auto">
          <thead>
            <tr>
              <th>S/N</th>
              <th>Name</th>
              <th>Id</th>
              <th>Email</th>
              <th>KYC Status</th>
              <th>Phone Number</th>
              <th>Featured Status</th>
              <th>Address</th>
              <th>Mode</th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody>
            <EmptyState description="No records found" v-if="allMerchants.length === 0" />
            <tr v-else v-for="(merchant, index) in allMerchants" :key="merchant.id">
              <td>{{ index + 1 }}</td>
              <td class="capitalize">{{ merchant.name }}</td>
              <td>{{ merchant.id }}</td>
              <td class="lowercase">{{ merchant.creatorEmail }}</td>
              <td>
                <span :class="[merchant.kycStatus.toLowerCase()]" v-if="merchant.kycStatus">{{ merchant.kycStatus
                }}</span>
                <span v-else class="closed">Not available</span>
              </td>
              <td>
                <span v-if="merchant.creatorPhoneNumber">{{
                  merchant.creatorPhoneNumber
                }}</span>
                <span v-else>N/A</span>
              </td>
              <td>
                <span v-if="merchant.featuredStatus === 'ELIGIBLE'" class="text-success">{{ merchant.featuredStatus
                }}</span>
                <span v-else-if="merchant.featuredStatus === 'PENDING_APPROVAL'" class="pending">{{
                  merchant.featuredStatus | truncate(10) }}</span>
                <span v-else-if="merchant.featuredStatus === 'NOT_ELIGIBLE'" class="text-brandRed">{{
                  merchant.featuredStatus | truncate(10) }}</span>
                <span v-else>{{ merchant.featuredStatus | truncate(10) }}</span>
              </td>
              <td>
                {{ merchant.address | truncate(10) }} {{ ", " + merchant.state }}
              </td>
              <td>
                {{ merchant.dashboardMode | capitalize }}
              </td>
              <td class="flex items-center">
                <SecondaryButton class="mr-2" text="More" @click="showMerchants(merchant)" />
                <SecondaryButton background="bg-brandPurple" color="text-lightPurple" text="View KYC" width="w-20"
                  @click="viewMerchantKyc(merchant)" />
              </td>
            </tr>
          </tbody>
        </table>

        <Pagination :handleMore="handleMore" :loading="paginateLoading" @paginate="handlePagination" />
      </div>
    </Card>
    <!-- view merchant modal -->
    <Modal :display="openModal" @close="done" :title="`${selectedMerchant.name}`" size="large">

      <div :class="[
        selectedMerchant.featuredStatus === 'ELIGIBLE' ||
          selectedMerchant.featuredStatus === 'NOT_ELIGIBLE'
          ? 'grid-cols-1'
          : 'grid-cols-2',
      ]" class="grid gap-4 flex-end">
        <SecondaryButton v-if="selectedMerchant.featuredStatus !== 'ELIGIBLE'" text="Enable Featured" width="w-full"
          @click="enableFeatured()" />
        <SecondaryButton v-if="selectedMerchant.featuredStatus !== 'NOT_ELIGIBLE'" text="Disable Featured" width="w-full"
          @click="disableMerchantFeatured()" />
      </div>
      <div>
        <h2 class="font-semibold mt-6">Details</h2>
        <p class="font-semibold text-sm">
          Description -
          <span class="font-normal" v-if="selectedMerchant.featuredDescription">{{ selectedMerchant.featuredDescription
          }}</span>
          <span v-else class="font-normal">No business description</span>
        </p>
        <p class="font-semibold text-sm">
          Website -
          <a :href="selectedMerchant.businessWebsite" v-if="selectedMerchant.businessWebsite" class="font-normal">{{
            selectedMerchant.businessWebsite }}</a>
          <span v-else class="font-normal">No website</span>
        </p>

        <p class="font-semibold text-sm">
          Twitter Handle -
          <a v-if="selectedMerchant.twitterHandle" :href="selectedMerchant.twitterHandle" class="font-normal">{{
            selectedMerchant.twitterHandle }}</a>
          <span v-else class="font-normal">No twitter handle</span>
        </p>
        <p class="font-semibold text-sm">
          Instagram Handle -
          <a v-if="selectedMerchant.instagramHandle" :href="selectedMerchant.instagramHandle" class="font-normal">{{
            selectedMerchant.instagramHandle }}</a>
          <span v-else class="font-normal">No Instagram handle</span>
        </p>

        <p class="font-semibold text-sm" v-if="selectedMerchant.storeLocationTypes">
          Store Locations -
          <span v-for="(location, index) in selectedMerchant.storeLocationTypes" :key="index" class="font-normal">
            {{ location }},</span>
        </p>
      </div>
      <h2 class="font-semibold mt-6">Categories</h2>
      <div v-if="showCategories === true" class="flex items-center mb-5">
        <v-select :options="featuredCategories" class="w-full mr-5" label="name" v-model="selectedCategory"></v-select>
        <SecondaryButton text="Add" width="w-24" @click="addCategory()" :loading="addNewLoading" />
      </div>
      <p v-if="categories.length === 0" class="text-center">
        This merchant currently has no category
      </p>

      <div v-else>
        <div class="grid grid-cols-2 gap-4 pb-6 pt-2 justify-between">
          <div v-for="item in categories" :key="item.id"
            class="flex justify-between items-center bg-lightGrey-1 shadow rounded py-2">
            <div class="flex items-center">
              <img class="w-6 h-6" :src="item.iconUrl" alt="logo" />
              <p class="font-semibold text-sm capitalize mr-2">
                {{ item.name }}
              </p>
            </div>
            <img v-if="showCategories === true" class="h-2 w-2 cursor-pointer mr-2"
              src="https://res.cloudinary.com/zillaafrica/image/upload/v1623525601/customer/Union_yx6ulk.svg" alt="cancel"
              @click="deleteCategory(item.id)" />
          </div>
        </div>
      </div>

      <div class="w-full mx-auto mt-4">
        <div class="flex justify-center items-center mb-2">
          <SecondaryButton v-if="showCategories === false"
            :text="categories.length === 0 ? 'Add New' : 'Update Categories'" @click="showCategories = true" />
        </div>
      </div>

      <div>
        <h2 class="font-semibold mt-6">SubCategories</h2>
        <p class="text-center" v-if="featuredSubCategories.length < 1">
          No sub categories for this merchant
        </p>
        <div v-else class="grid grid-cols-2 gap-4 pb-6 pt-2 justify-between">
          <div v-for="item in featuredSubCategories" :key="item.id"
            class="flex justify-between items-center bg-lightGrey-1 shadow rounded py-2">
            <div class="flex items-center px-2">
              <img class="w-6 h-6" :src="item.subCategory.icon" alt="logo" />
              <p class="font-semibold text-sm capitalize ml-2">
                {{ item.subCategory.name }}
              </p>
            </div>
            <img v-if="showSubCategories === true" class="h-2 w-2 cursor-pointer mr-2"
              src="https://res.cloudinary.com/zillaafrica/image/upload/v1623525601/customer/Union_yx6ulk.svg" alt="cancel"
              @click="deleteSubCategory(item.id)" />
          </div>
        </div>
        <div class="flex justify-center items-center mb-2 mt-4">
          <SecondaryButton v-if="showSubCategories === false" :text="featuredSubCategories.length < 1
            ? 'Add New SubCategories'
            : 'Update SubCategories'
            " @click="showSubCategories = true" />
        </div>

        <div v-if="showSubCategories === true" class="flex items-center mb-5">
          <v-select :options="subCategories" class="w-full" label="name" multiple :required="!selectedSubCategories"
            :reduce="(category) => category.id" v-model="selectedSubCategories" :closeOnSelect="false">
            <template #option="{ name, category }">
              <span class="font-semibold">{{ name }}</span><em class="ml-2 capitalize"> - {{ category.name }}</em>
            </template>
          </v-select>
          <SecondaryButton text="Add" width="w-24" @click="addMerchantToSubCategory()" :loading="addNewSubLoading" />
        </div>

        <!-- <div class="flex justify-center items-center mb-2">
          <SecondaryButton
            v-if="showCategories || showSubCategories"
            text="Done"
            width="w-24"
            @click="done()"
          />
        </div> -->
      </div>
      <Button text="Port merchant to wire" class="my-4" p="p-2" :loading="portLoading" @click="portMerchantToWire(false)" v-if="!selectedMerchant.wireActivated"/>

    </Modal>
    <!-- featured merchant modal -->
    <Modal :display="enableMerchantFeaturedModal" @close="enableMerchantFeaturedModal = false"
      :title="`Enable feature for ${selectedMerchant.name}`" size="large">
      <div class="md:grid grid-cols-2 gap-4">
        <Input width="w-full" placeholder="Description" class="mt-8" v-model="merchantFeatured.featuredDescription"
          errorText="Description is required" :validation="rules.description" />
        <Input width="w-full" placeholder="Website" class="mt-8" v-model="merchantFeatured.website"
          errorText="Please enter a valid website url" :validation="rules.website" />
        <Input width="w-full" placeholder="Instagram handle" class="mt-8" v-model="merchantFeatured.instagramHandle" />
        <Input width="w-full" placeholder="Twitter handle" class="mt-8" v-model="merchantFeatured.twitterHandle" />
        <v-select :options="featuredCategories" placeholder="Select categories" class="w-full mt-6" label="name"
          :required="!merchantFeatured.category" v-model="selectedCategories" multiple></v-select>
        <v-select :options="storeTypes" class="w-full mt-6" placeholder="Choose store types" label="type"
          :required="!merchantFeatured.storeLocationTypes" v-model="merchantFeatured.storeLocationTypes"
          multiple></v-select>
      </div>

      <div class="w-full mx-auto mt-4">
        <div class="flex justify-center items-center mb-2 mt-6">
          <Button text="Enable merchant featured" class="mt-6" @click="enableMerchantFeatured"
            :disabled="disableButton" />
        </div>
      </div>
    </Modal>
    <Modal :display="viewKycModal" @close="viewKycModal = false" title="KYC Details">
      <viewKyc :merchant="selectedMerchant" @close="closeKycStatus" />
    </Modal>
    <!-- add merchant number before portging -->
    <Modal :display="showAddMerchantNumberModal" @close="showAddMerchantNumberModal = false">
      <p class="font-semibold text-lg">Please add the merchant number</p>
        <Input number type="number" v-model="merchantNumber" placeholder="Add merchant Phone number" width="w-full" class="mt-4"/>
        <Button text="Port Merchant" width="w-full" class="mt-4" :loading="portLoading" @click="portMerchantToWire(true)"/>
    </Modal>
  </div>
</template>
<script>
import vSelect from "vue-select";
import { mapActions, mapState } from "vuex";
import Hr from "@/UI/Hr";
import SwitchInput from "@/UI/SwitchInput";
import { SecondaryButton, Button } from "@/UI/Button";
import { Select, Input, SearchInput } from "@/UI/Input";
import Pagination from "@/UI/Pagination";
import { DatePicker } from "v-calendar";
import {
  GET_MERCHANT_CATEGORIES,
  ADD_NEW_MERCHANT_CATEGORY,
  REMOVE_NEW_MERCHANT_CATEGORY,
  ENABLE_MERCHANT_FEATURED,
  DISABLE_MERCHANT_FEATURED,
  GET_MERCHANT_FEATURED_SUB_CATEGORIES,
  ADD_MERCHANT_TO_SUBCATEGORY,
  REMOVE_MERCHANT_FROM_SUBCATEGORY,
} from "@/utils/api/merchants";
import {UPDATE_MERCHANT_FOR_WIRE} from '@/utils/api/wire'
import { Modal } from "@/UI/Modals";
export default {
  components: {
    Hr,
    SecondaryButton,
    Pagination,
    Modal,
    SwitchInput,
    Input,
    Button,
    Select,
    DatePicker,
    Spinner: () => import("@/UI/Spinner"),
    EmptyState: () => import("@/UI/EmptyState"),
    viewKyc: () => import("./viewKyc.vue"),
    "v-select": vSelect,
    SearchInput
  },
  data: () => ({
    paginateLoading: false,
    handleMore: false,
    newSubCategory: {},
    params: {
      page: 0,
      pageSize: 20,
      from: "",
      to: "",
      featuredStatus: "",
      merchantUserEmail: "",
      merchantId: "",
      phoneNumber: ""
    },
    showAddMerchantNumberModal: false,
    subCategoriesParams: {
      page: 0,
      pageSize: 20,
      from: "",
      to: "",
      merchantId: "",
      status: "ACTIVE",
    },
    subparams: {
      page: 0,
      pageSize: 50,
      from: "",
      to: "",
      status: "",
    },
    status: [
      { label: "All", value: "" },
      { label: "Eligible", value: "ELIGIBLE" },
      { label: "Not Eligible", value: "NOT_ELIGIBLE" },
      { label: "Pending", value: "PENDING_APPROVAL" },
    ],
    modelConfig: {
      type: "string",
      mask: "iso",
      start: {
        timeAdjust: "00:00:00",
      },
      end: {
        timeAdjust: "23:59:59",
      },
    },
    range: {
      start: "",
      end: "",
    },
    masks: {
      input: "DD MMM YY",
    },
    loading: false,
    openModal: false,
    categories: [],
    selectedCategory: {},
    selectedSubCategories: [],
    selectedMerchant: {},
    featuredCategories: [],
    featuredSubCategories: [],
    showCategories: false,
    addNewLoading: false,
    addNewSubLoading: false,
    enableMerchantFeaturedModal: false,
    merchantFeatured: {
      featuredDescription: "",
      storeLocationTypes: [],
      website: "",
    },
    selectedCategories: [],
    storeTypes: ["OFFLINE", "ONLINE", "SOCIAL_COMMERCE"],
    viewKycModal: false,
    showSubCategories: false,
    portLoading: false,
    merchantNumber:''
  }),
  computed: {
    ...mapState({
      allMerchants: (state) => state.merchants.allMerchants,
      subCategories: (state) => state?.merchants?.allMerchantSubCategories,
      // featuredCategories: (state) => state?.merchants?.featuredCategories,
      // loading: (state) => state.loading.loading,
    }),
    rules() {
      return {
        description: this.merchantFeatured.featuredDescription.length > 0,
        categories: this.selectedCategories.length > 0,
        storeTypes: this.merchantFeatured.storeLocationTypes.length > 0,
        website:
          /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm.test(
            this.merchantFeatured.website
          ) || this.merchantFeatured.website.length === 0,
      };
    },
    disableButton() {
      return Object.values(this.rules).includes(false);
    },
  },
  mounted() {
    if(Object.keys(this.$route.query).length){
      this.params = this.$route.query
    }
    this.getMerchants();
    this.getAllSubCategories();
  },
  methods: {
    ...mapActions("merchants", [
      "getAllMerchants",
      "getMerchantSubCategories",
      "getFeaturedCategories",
    ]),
    ...mapActions("loading", ["setLoading"]),
    ...mapActions("notification", ["showToast", "showAlert"]),
    getCategories() {
      this.setLoading(true);
      this.getFeaturedCategories()
        .then((res) => {
          const allFeaturedCategories = res.data;
          this.setLoading(false);
          this.featuredCategories = allFeaturedCategories;
          // this.featuredCategories = allFeaturedCategories.filter(
          //   (featuredCategory) => {
          //     return !this.categories.find((category) => {
          //       return featuredCategory.id === category.id;
          //     });
          //   }
          // );
        })
        .catch(() => {
          this.setLoading(false);
        });
    },

    getAllSubCategories(type) {
      type === "paginate"
        ? (this.paginateLoading = true)
        : this.setLoading(true);
      this.getMerchantSubCategories({
        params: this.subparams,
        type: type,
      })
        .then((res) => {
          // if (res.data.length === this.subparams.pageSize) {
          //   this.handleMore = true;
          // } else {
          //   this.handleMore = false;
          // }
          type === "paginate"
            ? (this.paginateLoading = false)
            : (this.loading = false);
          this.setLoading(false);
        })
        .catch(() => {
          type === "paginate"
            ? (this.paginateLoading = false)
            : (this.loading = false);
          this.setLoading(false);
        });
    },

    getSubCategories() {
      this.subCategoriesParams.merchantId = this.selectedMerchant.id;
      this.setLoading(true);
      GET_MERCHANT_FEATURED_SUB_CATEGORIES(this.subCategoriesParams)
        .then((res) => {
          const allFeaturedSubCategories = res.data.data;
          this.setLoading(false);
          this.featuredSubCategories = allFeaturedSubCategories;
        })
        .catch(() => {
          this.setLoading(false);
        });
    },
    addCategory() {
      this.addNewLoading = true;
      ADD_NEW_MERCHANT_CATEGORY({
        merchantId: this.selectedMerchant.id,
        featuredCategoryId: this.selectedCategory.id,
      })
        .then(() => {
          this.addNewLoading = false;
          this.showToast({
            display: true,
            description: `Category added successfully`,
            type: "success",
          });
          this.showMerchants(this.selectedMerchant);
        })
        .catch(() => {
          this.addNewLoading = false;
        });
    },

    addMerchantToSubCategory() {
      console.log(this.selectedSubCategories);
      this.addNewSubLoading = true;
      ADD_MERCHANT_TO_SUBCATEGORY({
        merchantId: this.selectedMerchant.id,
        subCategoryIds: this.selectedSubCategories,
      })
        .then(() => {
          this.addNewSubLoading = false;
          this.selectedSubCategories = [];
          this.showToast({
            display: true,
            description: `Subcategory added successfully`,
            type: "success",
          });
          this.showMerchants(this.selectedMerchant);
        })
        .catch(() => {
          this.addNewSubLoading = false;
        });
    },
    deleteCategory(id) {
      REMOVE_NEW_MERCHANT_CATEGORY({
        merchantId: this.selectedMerchant.id,
        featuredCategoryId: id,
      })
        .then(() => {
          this.showToast({
            display: true,
            description: `Category removed successfully`,
            type: "success",
          });
          this.showMerchants(this.selectedMerchant);
        })
        .catch(() => {
          this.addNewLoading = false;
        });
    },
    getMerchants(type) {
      // console.log(type);
      type === "paginate"
        ? (this.paginateLoading = true)
        : (this.loading = true);
      this.getAllMerchants({ params: this.params, type: type })
        .then((data) => {
          if (data.data.length === this.params.pageSize) {
            this.handleMore = true;
          } else {
            this.handleMore = false;
          }
          type === "paginate"
            ? (this.paginateLoading = false)
            : (this.loading = false);
        })
        .catch(() => {
          type === "paginate"
            ? (this.paginateLoading = false)
            : (this.loading = false);
        });
    },
    handlePagination() {
      this.paginateLoading = true;
      this.params.page++;
      this.getMerchants("paginate");
    },
    clearSearch() {
      this.params = {
        page: 0,
        pageSize: 20,
        from: "",
        to: "",
        featuredStatus: "",
        merchantUserEmail: "",
        merchantId: "",
        phoneNumber: ""
      };
      this.$router.replace({
      ...this.$router.currentRoute,
      query: {},
      });
      this.range = {
          start: "",
          end: "",
      };
      this.handleGetAllRegistration();
    },
    handleSearch() {
      this.params.page = 0;
      this.params.from = this.range.start;
      this.params.to = this.range.end;
      this.$router.replace({
                ...this.$router.currentRoute,
                query: {
                ...this.params
                },
        });
      this.getMerchants();
    },
    showMerchants(merchant) {
      this.selectedMerchant = merchant;
      this.getSubCategories();
      this.setLoading(true);

      GET_MERCHANT_CATEGORIES(merchant.id)
        .then((res) => {
          this.openModal = true;
          this.setLoading(false);
          this.categories = res.data.data;
          this.getCategories();
        })
        .catch(() => {
          this.setLoading(false);
        });
    },
    disableMerchantFeatured() {
      this.openModal = false;
      this.setLoading(true);
      DISABLE_MERCHANT_FEATURED(this.selectedMerchant.id)
        .then((res) => {
          this.openModal = false;
          this.setLoading(false);
          this.getMerchants();
          this.showAlert({
            description: "Merchant featured disabled successfully",
            display: true,
            type: "success",
          });
          this.done();
        })
        .catch(() => {
          this.setLoading(false);
        });
    },
    enableMerchantFeatured() {
      this.merchantFeatured.merchantId = this.selectedMerchant.id;
      const categoriesArray = [];
      const categories = this.selectedCategories;
      if (categories.length > 0) {
        categories.forEach((role) => {
          categoriesArray.push(role.id);
        });
        this.merchantFeatured.categories = categoriesArray;
      }
      if (this.merchantFeatured.website === "") {
        this.merchantFeatured.website = null;
      } else {
        const website = this.merchantFeatured.website.toLowerCase();
        if (
          website.indexOf("http://") == 0 ||
          website.indexOf("https://") == 0
        ) {
          this.merchantFeatured.website = website;
        } else {
          this.merchantFeatured.website = "http://" + website;
        }
      }
      ENABLE_MERCHANT_FEATURED(this.merchantFeatured)
        .then((res) => {
          this.enableMerchantFeaturedModal = false;
          this.openModal = false;
          this.setLoading(false);
          this.getMerchants();
          this.showAlert({
            description: "Merchant featured enabled successfully",
            display: true,
            type: "success",
          });
          this.done();
        })
        .catch(() => {
          this.setLoading(false);
        });
    },
    enableFeatured() {
      // console.log(this.selectedMerchant);
      this.enableMerchantFeaturedModal = true;
      if (this.selectedMerchant.featuredDescription) {
        this.merchantFeatured.featuredDescription =
          this.selectedMerchant.featuredDescription;
      }
      if (this.selectedMerchant.businessWebsite) {
        this.merchantFeatured.website = this.selectedMerchant.businessWebsite;
      }
      this.merchantFeatured.instagramHandle =
        this.selectedMerchant.instagramHandle;
      this.merchantFeatured.twitterHandle = this.selectedMerchant.twitterHandle;
      if (this.categories.length > 0) {
        this.selectedCategories = this.categories;
      }
      if (this.selectedMerchant.storeLocationTypes) {
        this.merchantFeatured.storeLocationTypes =
          this.selectedMerchant.storeLocationTypes;
      }
    },
    viewMerchantKyc(merchant) {
      this.selectedMerchant = merchant;
      this.viewKycModal = true;
    },
    done() {
      this.openModal = false;
      this.showCategories = false;
      this.selectedCategory = {};
      this.selectedCategories = [];
      (this.showSubCategories = false), (this.selectedSubCategories = []);
      this.selectedMerchant = {};
      this.merchantFeatured = {
        featuredDescription: "",
        storeLocationTypes: [],
        website: "",
      };
    },
    closeKycStatus() {
      // this.getMerchants();
      this.viewKycModal = false;
    },
    deleteSubCategory(id) {
      REMOVE_MERCHANT_FROM_SUBCATEGORY(id)
        .then(() => {
          this.showToast({
            display: true,
            description: `Subcategory removed successfully`,
            type: "success",
          });
          this.showMerchants(this.selectedMerchant);
        })
        .catch(() => {
          this.addNewLoading = false;
        });
    },
    portMerchantToWire(setData){
      if(!this.selectedMerchant.creatorPhoneNumber && !setData){
        this.showAddMerchantNumberModal = true;
        this.openModal = false
        return
      }
      this.portLoading = true;
      UPDATE_MERCHANT_FOR_WIRE({
        merchantId:this.selectedMerchant.id, 
        phoneNumber: this.merchantNumber
      }).then(() => {
        this.portLoading = false;
        this.showAddMerchantNumberModal = false
        this.showToast({
            display: true,
            description: `Merchant ported to wire successfully`,
            type: "success",
          });

      }).catch(() => {
      this.portLoading = false
      })
    }
  },
};
</script>
<style src="vue-select/dist/vue-select.css"></style>
<style scoped>
.purchase-container {
  min-height: calc(100vh - 10rem);
}

.searchContainer {
  border: 1px solid rgba(138, 116, 192, 0.1) !important;
  width: 12rem;
}

.payment-table {
  display: grid;
  grid-template-columns: 2.5rem 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-column-gap: 10px;
  align-items: center;
}

.payment-table__col {
  padding: 0.8rem 0.5rem;
  /* white-space: nowrap; */
  font-size: 0.9rem;
}

@media (max-width: 768px) {
  .table-container {
    overflow: auto;
  }
}
</style>
