export default {
  GET_MERCHANTS(state, payload) {
    state.allMerchants = payload;
  },
  ADD_MERCHANTS(state, payload) {
    state.allMerchants.push(...payload);
  },
  FEATURED_CATEGORIES(state, payload) {
    state.featuredCategories = payload
  },
  GET_CATEGORY_MERCHANTS(state, payload) {
    state.allCategoryMerchants = payload;
  },
  ADD_CATEGORY_MERCHANTS(state, payload) {
    state.allCategoryMerchants.push(...payload);
  },
  GET_MERCHANT_SUBCATEGORIES(state, payload) {
    state.allMerchantSubCategories = payload;
  },
  ADD_MERCHANT_SUBCATEGORIES(state, payload) {
    state.allMerchantSubCategories.push(...payload);
  },
  GET_SUBCATEGORY_MERCHANTS(state, payload) {
    state.allSubCategoryMerchants = payload;
  },
  ADD_SUBCATEGORY_MERCHANTS(state, payload) {
    state.allSubCategoryMerchants.push(...payload);
  },
  GET_UNFEATURED_ELIGIBLE_MERCHANTS(state, payload) {
    state.unfeaturedSubCategoryMerchants = payload;
  },
  ADD_UNFEATURED_ELIGIBLE_MERCHANTS(state, payload) {
    state.unfeaturedSubCategoryMerchants.push(...payload);
  },
};
