export default {
  SHOW_PARTNER_MODAL(state, payload) {
    state.showPartnerModal = payload;
  },
  SHOW_PARTNER_SUCCESS_MODAL(state, payload) {
    state.partnersSuccess = payload;
  },
  GET_PARTNER_REGISTRATION(state, payload) {
    state.partnerRegistration = payload;
  },
  ADD_PARTNER_REGISTRATION(state, payload) {
    state.partnerRegistration.push(...payload);
  },
  GET_PARTNER(state, payload) {
    state.partners = payload;
  },
  ADD_PARTNER(state, payload) {
    state.partners.push(...payload);
  },
  GET_PARTNER_MERCHANTS(state, payload) {
    state.partnerMerchants = payload;
  },
  ADD_PARTNER_MERCHANTS(state, payload) {
    state.partnerMerchants.push(...payload);
  },
  GET_PARTNER_CUSTOMERS(state, payload) {
    state.partnerCustomers = payload;
  },
  ADD_PARTNER_CUSTOMERS(state, payload) {
    state.partnerCustomers.push(...payload);
  }
  
  

};
