export default {
    isAuthenticated: (state) => !!state.token,
    ActiveUser: (state) => state.user,
    authStatus: (state) => state.status,
    getUserPermissions:(state) => {
      if(!state.token) return false
      const arrayToken = state.token.split('.');
      const tokenPayload = JSON.parse(atob(arrayToken[1]));
      return tokenPayload.permissions
    }
  };
  