<template>
  <div>
    <div class="flex items-center justify-between mt-4">
      <h1 class="text-lg font-semibold">Roles</h1>
      <Button text="Create Roles" p="p-2" @click="showAddRole = true" />
    </div>
    <div class="-auto">
      <Hr />
      <div class="grid grid-cols-2">
        <div><p>Name</p></div>
        <div><p>Action</p></div>
      </div>
      <Hr />
      <div v-for="roles in adminRoles" :key="roles.id">
        <div class="grid line grid-cols-2">
          <div>
            <p>{{ roles.name.replace(/_/g, " ") | capitalize }}</p>
          </div>
          <div class="flex items-center">
            <SecondaryButton text="More" width="px-6" class="ml-4" outline @click="handleViewMore(roles)" />
          </div>
        </div>
        <Hr />
      </div>
    </div>
    <!-- modal for creating a role -->
    <Modal :display="showAddRole" title="Create Role" @close="showAddRole = false">
      <div class="modal-body mt-6">
        <Input placeholder="Role name" width="w-full" v-model="permission.name" />
        <v-select
          v-model="permission.permissionNames"
          :options="adminPermissions"
          class="w-full mt-6"
          placeholder="Choose permissions"
          multiple
          label="name"
          :reduce="(permission) => permission.name"
        ></v-select>
        <Button width="w-full" text="Create" class="mt-8" @click="createRole" :loading="permissionLoading" />
      </div>
    </Modal>
    <!-- modal for viewing role permissions -->
    <Modal :display="showSingleRoleModal" :title="singleRole.name.replace(/_/g, ' ')" @close="handleShowSingleRoleModal">
      <div v-if="!showAddPermission">
        <div class="flex items-center justify-between">
          <p class="font-semibold text-lg">Permissions</p>
          <div class="flex items-center">
            <SecondaryButton @click="showAddPermission = true" text="Add permissions" class="mr-4" />
            <SecondaryButton
              text="Delete Selected permissions"
              :loading="removePermissionLoading"
              p="p-2"
              v-if="selectedPermissions.length"
              @click="removePermission"
              background="#d8534e"
              color="white"
            />
          </div>
        </div>
        <div class="modal-body mt-6">
          <div v-for="role in singleRole.permissions" :key="role.id">
            <div class="flex items-center">
              <input type="checkbox" name="role.name" :value="role.id" @change="handleSelectCheckbox" class="mr-4" />
              <div>
                <p>{{ role.name.replace(/_/g, " ") | capitalize }}</p>
              </div>
            </div>
            <Hr />
          </div>
        </div>
      </div>
      <div class="modal-body" v-else>
        <p class="text sm text-grey cursor-pointer" @click="showAddPermission = false; addPermissionToRole = []">Back</p>
        <v-select
          v-model="addPermissionToRole"
          :options="permissionForSelectedRole"
          class="w-full mt-6"
          placeholder="Choose permissions"
          multiple
          label="name"
          :reduce="(permission) => permission.id"
        ></v-select>
        <Button width="w-full" text="Submit" class="mt-12" :loading="addPermissionLoading" @click="addPermission" />
      </div>
    </Modal>
  </div>
</template>
<script>
  import { mapState, mapActions } from "vuex";
  import Hr from "@/UI/Hr";
  import { Button, SecondaryButton } from "@/UI/Button";
  import { Modal } from "@/UI/Modals";
  import { Input } from "@/UI/Input";
  import vSelect from "vue-select";
  import "vue-select/dist/vue-select.css";
  import { CREATE_ADMIN_ROLE, REMOVE_PERMISSION_FROM_ROLE, ADD_PERMISSION_TO_ROLE } from "@/utils/api/adminAuthorization";
  export default {
    components: {
      Hr,
      Button,
      Modal,
      SecondaryButton,
      Input,
      "v-select": vSelect,
    },
    data: () => ({
      permission: {
        name: "",
        permissionNames: "",
      },
      permissionLoading: false,
      showAddRole: false,
      singleRole: { name: "" },
      showSingleRoleModal: false,
      selectedPermissions: [],
      removePermissionLoading: false,
      showAddPermission: false,
      addPermissionLoading: false,
      addPermissionToRole: [],
    }),

    computed: {
      ...mapState({
        adminRoles: (state) => state.admin.adminRoles,
        adminPermissions: (state) => state.admin.adminPermissions,
      }),
      permissionForSelectedRole() {
        return this.adminPermissions.filter((item) => !this.singleRole?.permissions.some((item2) => item.id === item2.id));
      },
    },
    methods: {
      ...mapActions("admin", ["getPermissions", "getAdminRoles"]),
      ...mapActions("notification", ["showToast"]),
      ...mapActions("admin", ["getPermissions", "getAdminRoles"]),

      createRole() {
        this.permissionLoading = true;
        CREATE_ADMIN_ROLE(this.permission)
          .then((data) => {
            this.getAdminRoles();
            this.showAddRole = false;
            this.permissionLoading = false;
          })
          .catch(() => {
            this.permissionLoading = false;
          });
      },
      handleEditRole(role) {
        this.permission = role;
        this.showAddRole = true;
      },
      handleViewMore(role) {
        this.showSingleRoleModal = true;
        this.singleRole = role;
      },
      handleSelectCheckbox(e) {
        if (e.target.checked) {
          this.selectedPermissions.push(e.target.value);
        } else {
          let index = this.selectedPermissions.findIndex((item) => item === e.target.value);
          if (this.selectedPermissions.length > 0) {
            this.selectedPermissions.splice(index, 1);
          } else {
            this.selectedPermissions = [];
          }
        }
      },
      removePermission(id) {
        this.removePermissionLoading = true;
        REMOVE_PERMISSION_FROM_ROLE({
          resourceRoleId: this.singleRole?.id,
          permissionIds: this.selectedPermissions,
        })
          .then(() => {
            this.removePermissionLoading = false;
            this.showToast({
              display: true,
              description: "Permission(s) succesfully deleted",
              type: "success",
            });
            this.showSingleRoleModal = false;
            this.getAdminRoles();
          })
          .catch(() => {
            this.removePermissionLoading = false;
          });
      },
      addPermission() {
        this.addPermissionLoading = true;
        ADD_PERMISSION_TO_ROLE({
          resourceRoleId: this.singleRole?.id,
          permissionIds: this.addPermissionToRole,
        })
          .then(() => {
            this.addPermissionLoading = false;
            this.showToast({
              display: true,
              description: "Permission(s) succesfully added to role",
              type: "success",
            });
            this.getAdminRoles().then(() => {
              this.showAddPermission = false;
              this.showSingleRoleModal = false;
            });
          })
          .catch(() => {
            this.addPermissionLoading = false;
          });
      },
      handleShowSingleRoleModal() {
        this.showSingleRoleModal = false;
        this.showAddPermission = false;
        this.addPermissionToRole = []
      },
    },
  };
</script>
<style scoped>
  .line {
    border-bottom: 1px;
  }
  .modal-body {
    min-height: 36rem;
  }
</style>
