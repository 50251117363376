<!-- <template>
    <div >
        <div class='range-slider'>
          <input type="range" min="1000" max="1000000"  step="100" v-model="sliderMin">
          <input type="range" min="1000" max="1000000"  step="100"  v-model="sliderMax">
        </div>
      </div>
      
</template>    
<script>
    export default {
    data:() => ({
        minAngle: 1000,
        maxAngle: 10000000
    }),
    props:{
        min:{
            type: [Number, String]
        },
        max:{
            type:[Number, String]
        }
    },
    watch:{
        min(value){
            this.sliderMin = value
        },
        max(value) {
            this.sliderMax = value
        }
    },
    computed: {
        sliderMin: {
            get: function() {
                var val = parseInt(this.minAngle);
                return val;
            },
            set: function(val) {
                val = parseInt(val);
                if (val > this.maxAngle) {
                    this.maxAngle = val;
                }
                this.minAngle = val;
                this.$emit('update:min', val.toString())
            }
        },
        sliderMax: {
            get: function() {
                var val = parseInt(this.maxAngle);
                return val;
            },
            set: function(val) {
                val = parseInt(val);
                if (val < this.minAngle) {
                    this.minAngle = val;
                }
                this.maxAngle = val;
                this.$emit('update:max', val.toString())
            }
    }
  }
}
</script> 
<style scoped>
    .range-slider {
        width: 100%;
        margin: auto;
        text-align: center;
        position: relative;
        height: 2rem;
      }
      
      .range-slider input[type=range] {
        position: absolute;
        left: 0;
        bottom: 0;
      }
      
      input[type=number] {
        border: 1px solid #ddd;
        text-align: center;
        font-size: 1.6em;
        -moz-appearance: textfield;
      }
      
      input[type=number]::-webkit-outer-spin-button,
      input[type=number]::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
      
      input[type=number]:invalid,
      input[type=number]:out-of-range {
        border: 2px solid #6956c8;
      }
      
      input[type=range] {
        -webkit-appearance: none;
        width: 100%;
      }
      
      input[type=range]:focus {
        outline: none;
      }
      
      input[type=range]:focus::-webkit-slider-runnable-track {
        background: #6956c8;
      }
      
      input[type=range]:focus::-ms-fill-lower {
        background: #6956c8;
      }
      
      input[type=range]:focus::-ms-fill-upper {
        background: #6956c8;
      }
      
      input[type=range]::-webkit-slider-runnable-track {
        width: 100%;
        height: 5px;
        cursor: pointer;
        animate: 0.2s;
        background: #6956c8;
        border-radius: 1px;
        box-shadow: none;
        border: 0;
      }
      
      input[type=range]::-webkit-slider-thumb {
        z-index: 2;
        position: relative;
        box-shadow: 0px 0px 0px #000;
        border: 1px solid #6956c8;
        height: 18px;
        width: 18px;
        border-radius: 25px;
        background: #6956c8;
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: -7px;
      }
      
    </style>
     -->

     <template>
        <div class="content">
          <div id="my-slider" :se-min="minThreshold" :se-step="step" :se-min-value="min" :se-max-value="max" :se-max="maxThreshold" class="slider">
            <div class="slider-touch-left">
              <span></span>
            </div>
            <div class="slider-touch-right">
              <span></span>
            </div>
            <div class="slider-line">
              <span></span>
            </div>
          </div>
        </div>
        </template>
        
        <script>
        import ZbRangeSlider from './ZbRangeSlider'
        
        export default {
          props: {
            minThreshold: {
              type: Number,
              default: 1000
            },
            maxThreshold: {
              type: Number,
              default: 1000000
            },
            step: {
              type: Number,
              default: 1
            },
            min: {
              type: [Number, String],
              required: true
            },
            max: {
              type: [Number, String],
              required: true
            }
          },
          data: function () {
            return {
              instance: undefined
            }
          },
          watch:{
            max(){
                this.adjustChange()
            },
            min(){
                this.adjustChange()
            }
          },
          mounted: function () {
            // this.instance = new ZbRangeSlider('my-slider')
            // this.instance.onChange = (min, max) => this.updateValues(min, max)
            this.adjustChange()
          },
          destroyed: function () {
        
          },
          methods: {
            updateValues: function (min, max) {
              this.$emit('update:min', min.toString())
              this.$emit('update:max', max.toString())
            },
            adjustChange(){
                this.$nextTick(() => {
                    this.instance = new ZbRangeSlider('my-slider')
                    this.instance.onChange = (min, max) => this.updateValues(min, max)
            })
           
          }
          },

          
        }
        </script>
        
        <style>
        .slider {
          display: block;
          position: relative;
          height: 36px;
          width: 100%;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          -o-user-select: none;
          user-select: none;
        }
        .slider .slider-touch-left,
        .slider .slider-touch-right {
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          display: block;
          position: absolute;
          height: 36px;
          width: 36px;
          padding: 6px;
          z-index: 2;
        }
        .slider .slider-touch-left span,
        .slider .slider-touch-right span {
          display: block;
          width: 100%;
          height: 100%;
          background: #f0f0f0;
          border: 1px solid #6956c8;
          border-radius: 50%;
        }
        .slider .slider-line {
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          position: absolute;
          width: calc(100% - 36px);
          left: 18px;
          top: 16px;
          height: 4px;
          border-radius: 4px;
          background: #f0f0f0;
          z-index: 0;
          overflow: hidden;
        }
        .slider .slider-line span {
          display: block;
          height: 100%;
          width: 0%;
          background: #6956c8;
        }
        </style>