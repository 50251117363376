<template>
  <SingleSenderInfo/>
</template>

<script>
import SingleSenderInfo from '../../components/Account/SingleSenderInfo.vue';
export default {
components:{
    SingleSenderInfo
}
}
</script>

<style>

</style>