<template>
  <right-sidebar :display="display" @close="handleClose" title="Send payment" @handleBack="handlePreviousStep" :showBack="nextActiveStep !== 'beneficiary'">
    <template #body>
      <div class="payment-link-container-body relative">
        <p class="text-grey-2 text-sm" v-if="nextActiveStep === 'one' || nextActiveStep == 'two'">Fill beneficiary’s information</p>
        <!-- <keep-alive> -->
        <FirstStep :wireAccountData="wireAccountData" @nextStep="handleNextStep" v-if="nextActiveStep === 'one'" :userData="userData"/>
        <second-step :wireAccountData="wireAccountData" @nextStep="handleNextStep" v-if="nextActiveStep === 'two'" :userData="userData"/>
        <Summary :wireAccountData="wireAccountData" v-if="nextActiveStep === 'summary'" :userData="userData" @success="handleClose"/>
        <Beneficiary :wireAccountData="wireAccountData" v-if="nextActiveStep === 'beneficiary'" @beneficiary="handleBeneficiary"/>
      <!-- </keep-alive> -->
      <beneficiary-preview @edit="handleEdit" v-if="nextActiveStep === 'preview'" :userData="userData" @nextStep="nextActiveStep = 'two'"/>

        <!-- <ReviewRate v-if="nextActiveStep === 'reviewRate'" @success="handleClose"/> -->
      </div>
    </template>
  </right-sidebar>
</template>
<script>
  import { RightSidebar } from "@/UI/Modals";
  import { Input, Select, CurrencyInput } from "@/UI/Input";
  export default {
    components: {
      RightSidebar,
      Input,
      Select,
      CurrencyInput,
      FirstStep: () => import("./FirstStep"),
      SecondStep:() => import('./SecondStep.vue'),
      Summary:() => import('./Summary'),
      Beneficiary:() => import('./Beneficiary.vue'),
      BeneficiaryPreview:() => import('./BeneficiaryPreview.vue')
      // ReviewRate:() => import('./ReviewRate')
    },
    props: {
      display: {
        type: Boolean,
        required: false,
        value: false,
      },
      externalBeneficiary:{
        type: Object,
        default(){
          return {}
        },
        required: false
      },
      wireAccountData:{
        type: Object,
        default:() => {}
      }
    },
    data:() => ({
        nextActiveStep: 'beneficiary',
        userData:{},
        beneficiaryData:{}
    }),
    methods:{
        handleNextStep(value){
            this.nextActiveStep = value.step
            if(value.step === 'two'){
              this.userData.beneficiary = value.data
            }else{
              this.userData = {...this.userData, ...value.data.beneficiary, ...value.data.scheduleData}
            }
        },
        handleBeneficiary(data){
          if(data === 'new'){
            this.nextActiveStep = 'one'
          }else{
            // this.beneficiaryData = data;
            // this.userData.beneficiary = data
            // if((data.countrySpecificData.collectIfcs && !data.financialSystemCode) || (data.countrySpecificData.collectBusinessRegistrationNumber && !data.businessRegistrationNumber)){
            //   this.nextActiveStep = 'one'
            // }else{
            //   this.nextActiveStep = 'two'
            //   this.$router.replace({
            //       ...this.$route.params,
            //       query:{
            //         step: 'skip_one'
            //       }
            //   })
            // }

            this.$nextTick(() => {
              this.beneficiaryData = data;
              this.userData.beneficiary = data
              if((data.countrySpecificData.collectIfcs && !data.financialSystemCode) 
              || (data.countrySpecificData.collectBusinessRegistrationNumber && (!data.businessRegistrationNumber || isNaN(data.businessRegistrationNumber) || data.businessRegistrationNumber.length != 10))
              || (data.countrySpecificData.collectIban && data.accountNumber.length < 15)){
                this.nextActiveStep = 'one'
              }else{
                this.nextActiveStep = 'preview'
                // this.$router.replace({
                //     ...this.$route.params,
                //     query:{
                //       step: 'skip_one'
                //     }
                // })
              }
              this.$forceUpdate()
            })
          }
        },
        handlePreviousStep(){
          switch(this.nextActiveStep){
            case 'one':
            if(this.$route.query.preview_step){
                this.nextActiveStep = 'preview'
              }else{
                this.nextActiveStep = 'beneficiary';
                this.userData = {}
              }
              break;
            case 'two':
              if(this.$route.query?.step === 'skip_one'){
                if(this.$route.query?.step === 'skip_one'){
                this.nextActiveStep = 'beneficiary'
                this.userData = {}
              }else{
                this.nextActiveStep = 'preview';
              }
              }else{
                this.nextActiveStep = 'one';
              }
              break;
              case 'summary':
                this.nextActiveStep = 'two'
              break;
              case 'preview':
                this.nextActiveStep = 'beneficiary';
                this.userData = {}
              break;
          }
          if(Object.keys(this.$route.query).length){
            this.$router.replace({
              ...this.$route.params,
              query:{}
            })
          }
        },
        handleClose(){
          this.$emit('close');
          this.$router.replace({
              ...this.$route.params,
              query:{}
          })
        },
        handleEdit(){
          this.$router.replace({
              ...this.$route.params,
                query:{
                  preview_step: true
                }
            })
          this.nextActiveStep = 'one';
        },
    },
    mounted(){
      if(Object.keys(this.externalBeneficiary).length){
        this.userData.beneficiary = this.externalBeneficiary
        this.nextActiveStep = 'two'
      }
      
    }
  };
</script>
<style scoped>
  .payment-link-container-body {
    padding: 16px 24px;
  }
</style>
