<template>
  <div class="w-full grid justify-items-end">
    <div class=" w-24 border border-grey-6 bg-light-grey rounded my-5 mx-0 md:mr-3 p-2 text-grey">
      <div class=" text-center mx-auto" v-if="loading">
        <Spinner />
      </div>
      <div v-else class="mx-auto">
        <span v-if="meta.totalPages > 0" @click="incrementPage()" :class="Number(meta.currentPage) + 1 < meta.totalPages ? 'text-dark cursor-pointer' : 'text-grey cursor-not-allowed'">
          <span class="text-sm">{{ Number(meta.currentPage) + 1 }} / {{
            meta.totalPages
          }}</span>
          <i :class="Number(meta.currentPage) + 1 < meta.totalPages ? 'text-dark cursor-pointer' : 'text-grey cursor-not-allowed'"
            class="ri-arrow-right-s-line ml-4 float-right" ></i>
        </span>
        <span class="text-sm cursor-pointer" @click="incrementPage()" v-else>View More</span>
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from "@/UI/Spinner";
export default {
  components: {
    Spinner,
  },
  data() {
    return {
      loading: false
    }
  },
  props: {
    meta: {
      type: Object,
      default: () => { },
      required: true,
    },
  },
  watch: {
    'meta.currentPage'(value) {
      this.loading = false;
    }
  },
  methods: {
    incrementPage() {
      let page = Number(this.meta.currentPage) + 1;
      if (page < this.meta.totalPages) {
        this.loading = true;
        this.$emit("nextPage");
      } else if (this.meta.totalPages < 0) {
        this.loading = true;
        this.$emit("nextPage");
      }
    }
  }
};
</script>
