<template>
  <div class="px-4">
    <div class="flex justify-between text-right w-full min-h-64  px-2 mt-3  my-4 ">
      <!-- <p class="text-right underline text-brandPurple font-semibold my-2 cursor-pointer mr-4" @click="$emit('skip')">Skip</p> -->

      <!-- <button class="bg-[#EEF2F6] text-[#697586] rounded-full p-1  size-8 cancel" @click="back"> back<font-awesome-icon :icon="['fas', 'arrow-left']" /></button>
      <button class=" text-[#6F00FF] underline  rounded-sm py-3 px-4 text-sm font-semibold skip" @click="next">skip</button> -->
    </div>
    <h2 class=" font-semibold w-full  text-xl mb-4">Director's details</h2>

    <div class="bg-white rounded-lg px-6 min-w-96  w-full"> 
         
      <p class="text-center">No business director added. Add your business director(s) to complete this process.</p>
      
      <div class="w-full flex justify-center text-right mt-4">
            <Button text="Add new director " type="submit" @click="next"  class="mr-2" p="p-2" />
        </div>
    </div>
  </div>
</template>

<script>
import { Button } from '@/UI/Button';
export default {
  components:{
    Button
  },
  data(){
    return{
      
    }
  },
  methods: {
    back() {
        this.$emit('back');
      },
      next() {
        this.$emit('next');
      }
  }
}
</script>

<style scoped>
.cancel{
        background: #EEF2F6;
        color: #697586;
    }
 .skip{
        color: #6F00FF;
    }
    .continue-Btn-area{
        min-height: 80px;
        border-top: 1px solid #E3E8EF
    }
  .continue-Btn{
    box-shadow: 0px 0px 0px 3px #9386D847;
    box-shadow: 0px 1px 2px 0px #FFFFFF4D inset;
    border: 1px solid #412C96;
    height: 48px;
    padding: 0px 16px ;
    gap: 8px;
    border-radius: 8px ;
    background: #6956C8;
    color: #FFFFFF;
    font-size: 13px;
    font-weight: 600;
    line-height: 15.6px;
    letter-spacing: 0.03em;
    text-align: center;
    }
    .ellipse{
      background: url('/src/assets/images/logo.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 40px;
      border: solid;
    }
    
</style>