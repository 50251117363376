<template>
    <div>
        <Spinner v-if="disputesLoading"/>
        <div class="table-container" v-else>
            <table id="tamplate-table" class="w-full table-auto">
                <thead>
                    <tr>
                        <th>S/N</th>
                        <th>Id</th>
                        <th>Init. Name</th>
                        <th>Init. email</th>
                        <th>Targt. Name</th>
                        <th>Targt. Email</th>
                        <th>Wire acc.</th>
                        <th>Date</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <EmptyState class="mx-auto w-full my-10" description="No records found"
                        v-if="allRemovalDisputes.length === 0" />
                        <tr v-for="(removal, index) in allRemovalDisputes" :key="removal.id" @click="setSinglePayout(payout)" class="cursor-pointer">
                            <td>{{index + 1}}</td>
                            <td>{{removal.id}}</td>
                            <td>{{removal.initiatedByUserName}}</td>
                            <td>{{removal.initiatedByUserEmail}}</td>
                            <td>{{removal.targetUserName}}</td>
                            <td>{{removal.targetUserEmail}}</td>
                            <td>{{removal.wireAccountName}}</td>
                            <td>{{dayjs(removal.createdAt).format("MMM D YYYY | hh:mmA") }}</td>
                            <td>
                                <div class="flex items-center">
                                    <SecondaryButton
                                        text="Complete"
                                        background="#007E33"
                                        color="white"
                                        @click="openRemoveModal = true, selectedUser = removal"
                                     />
                                    <SecondaryButton text="Cancel"
                                        background="#ff4444"
                                        color="white"
                                        class="ml-2"
                                        @click="openCancelModal = true, selectedUser = removal"
                                    />
                                    <SecondaryButton 
                                        text="Send email" 
                                        class="ml-2" 
                                        @click="selectedUser = removal, openEmailModal = true"
                                        outline/>
                                </div>
                            </td>
                        </tr>
                </tbody>
            </table>
            <!-- confirm -->
            <Modal :display="openRemoveModal" @close="openRemoveModal = false">
                <p class="text-lg font-semibold">Are you sure you want to confrim removing {{selectedUser.targetUserName}}?</p>
                <Button class="mt-8 ml-auto" text="Confirm delete" :loading="completeRemovalLoading" @click="completeRemoval"/>
            </Modal>
            <!-- cancel -->
            <Modal :display="openCancelModal" @close="openCancelModal = false">
                <p class="text-lg font-semibold">Are you sure you want to cancel removing {{selectedUser.targetUserName}}?</p>
                <Button class="mt-8 ml-auto" text="Confirm delete" :loading="cancelInitatedLoading" @click="cancelInitiatedRemoval"/>
            </Modal>
            <!-- email -->
            <Modal :display="openEmailModal" @close="openEmailModal = false">
                <p class="text-lg font-semibold">Are you sure you want to send email to {{selectedUser.targetUserName}}?</p>
                <Button class="mt-8 ml-auto" text="Confirm delete" :loading="ownerEmailLoading" @click="sendOwnerEmail"/>
            </Modal>
        </div>
    </div>
</template>
<script>
import {GET_INITIATED_OWNER_REMOVAL , COMPLETE_INITAITED_OWNER_REMOVAL, CANCEL__INITIATED_OWNER_REMOVAL,  SEND_ACCOUNT_DELETION_EMAIL} from '@/utils/api/wire'
import Spinner from '@/UI/Spinner'
import {Button, SecondaryButton} from '@/UI/Button'
import dayjs from 'dayjs'
import {Modal} from '@/UI/Modals'
import {mapActions} from 'vuex'
export default {
    components:{
        Spinner,
        Button,
        SecondaryButton,
        Modal,
        EmptyState: () => import("@/UI/EmptyState"),
    },
    data:() => ({
        allRemovalDisputes:[],
        disputesLoading: false,
        completeRemovalLoading: false,
        selectedUser:{},
        openRemoveModal: false,
        openCancelModal: false,
        cancelInitatedLoading: false,
        openEmailModal: false,
        ownerEmailLoading: false
    }),
    computed:{
        dayjs(){
            return dayjs
        }
    },
    methods:{
        ...mapActions('notification', ['showAlert']),
        getAllUserRemoval(){
            this.disputesLoading = true;
            GET_INITIATED_OWNER_REMOVAL()
                .then(({data}) => {
                    this.disputesLoading = false;
                    this.allRemovalDisputes = data.data
                }).catch(() => {
                    this.disputesLoading = false
                })
        },
        completeRemoval(){
            this.completeRemovalLoading = true;
            COMPLETE_INITAITED_OWNER_REMOVAL({deletionLogId : this.selectedUser.id})
                .then(() => {
                    this.completeRemovalLoading = false;
                    this.openRemoveModal = false;
                    this.getAllUserRemoval()
                }).catch(() => {
                    this.completeRemovalLoading = false
                })
        },
        cancelInitiatedRemoval(){
            this.cancelInitatedLoading = true;
            CANCEL__INITIATED_OWNER_REMOVAL({deletionLogId : this.selectedUser.id})
                .then(() => {
                    this.cancelInitatedLoading = false;
                    this.openCancelModal = false;
                    this.getAllUserRemoval()
                }).catch(() => {
                    this.cancelInitatedLoading = false
                })

        },
        sendOwnerEmail(){
            this.ownerEmailLoading = true;
            SEND_ACCOUNT_DELETION_EMAIL({deletionLogId : this.selectedUser.id})
                .then(() => {
                    this.ownerEmailLoading = false;
                    this.openEmailModal = false;
                    this.getAllUserRemoval()
                }).catch(() => {
                    this.ownerEmailLoading = false
                })
        }
    },
    mounted(){
        this.getAllUserRemoval()
    }
}
</script>
<style scoped>
.purchase-container {
    min-height: calc(100vh - 10rem);
}
.searchContainer {
    border: 1px solid rgba(138, 116, 192, 0.1) !important;
    width: 12rem;
}
</style>