<template>
  <div>
    <img
      class="h-14 w-14 md:h-20 md:w-20 mx-auto md:mt-8"
      src="https://res.cloudinary.com/zillaafrica/image/upload/v1624100575/customer/Group_707_1_tpsp0o.svg"
      alt="success"
    />

    <h4 class="mt-8 text-lg md:text-xl font-semibold text-center text-black">
      Partner has been successfully initiated
    </h4>
    <p class="mt-4 mb-8 text-black md:text-lg text-center">
      A new partner
      <span class="font-semibold"
        >{{ details.firstName }} {{ details.lastName }}</span
      >
      has been created with phone number -
      <span class="font-semibold">{{ details.phoneNumber }}.</span>
      They can complete their registration using the link below
    </p>
    <div class="my-6 justify-center md:flex items-center">
      <input
        type="text"
        disabled
        class="rounded-lg p-2.5 mr-4 w-full"
        v-model="onboardingLink"
      />
      <Button
        text="Copy"
        p="px-6 py-2"
        @click="copy(onboardingLink)"
        width="w-full md:w-44"
        class="mt-3 md:mt-0"
      />
    </div>
  </div>
</template>
<script>
import { Button } from "@/UI/Button";
import copy from "@/mixins/copy";

export default {
  mixins: [copy],
  components: {
    Button,
  },
  props: ["details"],
  computed: {
    onboardingLink() {
      return `${process.env.VUE_APP_PARTNER_BASE_URL}/complete-registration/${this.details.id}`;
    },
  },
};
</script>
<style scoped>
input {
  background: #f2f2f2;
}
</style>
