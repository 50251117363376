<template>
    <RightSidebar display @close="$emit('close')" title="Wallet history">
        <template #body>
            <div class="payment-link-container-body">
                <div v-if="walletHistory.length" class="table-container">
                    <div class="table-section">
                      <div v-for="(wallet) in walletHistory" :key="wallet.id"
                        class="grid grid-cols-2 content-center py-4 wallet-row">
                        <div>
                          <p v-if="wallet.product" class="font-semibold capitalize">
                            {{ getTransactionName(wallet) | capitalize }}
                          </p>
                          <p v-else class="font-semibold" :class="[wallet.walletActivityType.toLowerCase()]">
                            {{ 'Wallet top-up' }}
                          </p>
                          <p class="text-sm text-grey">
                            {{ dayjs(wallet.createdAt).format("MMM D YYYY | hh:mmA") }}
                          </p>
                        </div>
                        <div class="flex flex-col items-end justify-center">
                          <p :class="[
                            wallet.walletActivityType.toLowerCase() === 'credit'
                              ? 'text-success'
                              : 'failed',
                          ]" class="font-semibold">
                            {{ wallet.amount | formatMoney('₦') }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <Pagination v-if="showPagination" class="view-more" :meta="pageMeta" @nextPage="handlePagination" />
                  </div>
            </div>
        </template>
    </RightSidebar>
</template>
<script>
import { GET_ACCOUNT_WALLET_HISTORY } from '@/utils/api/wire'
import {RightSidebar} from '@/UI/Modals'
import {mapActions} from 'vuex'
import dayjs from 'dayjs'
export default {
    props:{
        display: {
            type: Boolean,
            default: false,
            required: false
        },
        accountDetails:{
            type: Object,
            default:() => {}
        }
    },
    components:{
        RightSidebar,
        Pagination: () => import("@/UI/Pagination")
    },
    data:() => ({
        walletHistory:[],
        params:{
            from:'',
            to:'',
            pageSize: 20,
            page:0
        },
        showPagination:false,
        pageMeta:{}
    }),
    computed:{
        dayjs(){
            return dayjs
        }
    },
    methods:{
        ...mapActions('loading', ['setLoading']),
        getWalletHistory(type){
            if(type !== 'paginate')this.setLoading(true);
            GET_ACCOUNT_WALLET_HISTORY(this.accountDetails.wireAccount.id, this.params)
                .then((res) => {
                    this.walletHistory.push(...res.data.data);
                    this.pageMeta = res.data.meta;
                    this.showPagination = res.data.data.length === this.params.pageSize ? true : false;
                    this.setLoading(false)
                }).catch(() => {
                    this.setLoading(false)
                })
        },
    handlePagination() {
      this.paginateLoading = true;
      this.params.page++;
      this.getWalletHistory("paginate");
    },
    getTransactionName(wallet){
      if(wallet.product === 'WIRE_ACCOUNT_CASHOUT' &&  wallet.walletActivityType === 'CREDIT') return 'Cashout Reversal'
      return wallet.product.replaceAll("_", " ")
    }
    },
    mounted(){
        this.getWalletHistory()
    }
}
</script>
<style scoped>
.table-container {
    min-height: 80vh !important;
    position: relative;
  }
  
  .view-more {
    position: absolute;
    bottom: 0;
    right: 6;
    border-top: 1px solid rgb(242, 242, 242);
    width: 100%;
    background-color: #fff;
  }
  
  .table-section {
    max-height: 60% !important;
    overflow: auto !important;
  }
  
  .payment-link-container-body {
    padding: 16px 24px;
  }
  
  .wallet-row {
    border-bottom: 1px solid #e3e8ef;
  }
  .payment-link-container-body {
    padding: 16px 24px;
  }
</style>