import Vue from "vue";
import VueRouter from "vue-router";
// import Home from "../views/Home.vue";
import Login from "@/views/login";
import store from "../store";


Vue.use(VueRouter);

const routes = [
  {
    path: "/bnpl/customers",
    name: "Home",
    component: () => import(/* webpackChunkName: "about" */ "@/views/bnpl/customers/index.vue"),
    meta: {
      layout: "default",
      requiresAuth: true,
    },
  },
  {
    path: "/bnpl/customers/company",
    name: "Company",
    component: () => import("@/views/bnpl/customers/company"),
  },
  {
    path: "/bnpl/customers/:id",
    name: "single-customer",
    component: () => import("@/views/bnpl/customers/singleCustomer"),
  },
  {
    path: "/bnpl/customers/cards/:id",
    name: "customer-issued-cards",
    component: () => import("@/views/bnpl/customers/issuedCards"),
  },
  {
    path: "/bnpl/employment/:id",
    name: "Employment",
    component: () => import("@/views/bnpl/customers/employmentDetails"),
  },

  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      layout: "auth",
      guest: true,
    },
  },
  {
    path: "/bnpl/merchants",
    name: "Merchants",
    component: () => import("@/views/bnpl/merchants/index.vue"),
    meta: {
      layout: "default",
      requiresAuth: true,
    },
  },
  {
    path: "/bnpl/merchants/categories",
    name: "Categories",
    component: () => import("@/views//bnpl/merchants/categories"),
    meta: {
      layout: "default",
      requiresAuth: true,
    },
  },
  {
    path: "/bnpl/purchases",
    name: "purchases",

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "@/views/bnpl/purchases/index.vue"),
    meta: {
      layout: "default",
      requiresAuth: true,
    },
  },
  {
    path: "/bnpl/purchases/payments",
    name: "payments",
    component: () => import("@/views/bnpl/purchases/payments.vue"),
    meta: {
      layout: "default",
      requiresAuth: true,
    },
  },
  {
    path: "/bnpl/verify-employment",
    name: "Verify-Employment",

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "@/views/bnpl/verifyEmployment/index.vue"),
    meta: {
      layout: "default",
      requiresAuth: true,
    },
  },
  {
    path: "/bnpl/verify-bank",
    name: "Verify-Bank",

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "@/views/bnpl/verifyBankStatement"),
    meta: {
      layout: "default",
      requiresAuth: true,
    },
  },
  {
    path: "/bnpl/partner-registration",
    name: "partner-registration",
    component: () => import("@/views/bnpl/partnerRegistration/index.vue"),
    meta: {
      layout: "default",
      requiresAuth: true,
    },
  },
  {
    path: "/bnpl/partners",
    name: "partners",
    component: () => import("@/views/bnpl/partners/index.vue"),
    meta: {
      layout: "default",
      requiresAuth: true,
    },
  },
  {
    path: "/bnpl/partner-merchants/:id",
    name: "view-partner-merchants",
    component: () => import("@/views/bnpl/partners/partnerMerchant.vue"),
    meta: {
      layout: "default",
      requiresAuth: true,
    },
  },
  {
    path: "/bnpl/partner-customers/:id",
    name: "view-partner-customers",
    component: () => import("@/views/bnpl/partners/partnerCustomer.vue"),
    meta: {
      layout: "default",
      requiresAuth: true,
    },
  },
  {
    path: "/currency-mappings",
    name: "currencymappingss",
    component: () => import("@/views/currencyMappings/index.vue"),
    meta: {
      layout: "default",
      requiresAuth: true,
    },
  },
  {
    path: "/account",
    name: "wire-account",
    component: () => import("@/views/account/account.vue"),
    meta: {
      layout: "default",
      requiresAuth: true,
    },
  },
  {
    path: "/registration",
    name: "wire-registration",
    component: () => import("@/views/registration/registration.vue"),
    meta: {
      layout: "default",
      requiresAuth: true,
    },
  },
  {
    path: "/account/:id",
    name: "single-wire-account",
    component: () => import("@/views/singleWireAccount/singleWireAccount"),
    meta: {
      layout: "default",
      requiresAuth: true,
    },
  },
  {
    path: "/account/senders/:wireAccountSendersId",
    name: "single-sender-info",
    component: () => import("@/views/singleSenderInfo/SingleSenderInfo.vue"),
    meta: {
      layout: "default",
      requiresAuth: true,
    },
    props: route => ({
      wireAccountSendersId: route.params.wireAccountSendersId,
      wireAccountId: route.query.wireAccountId
    })
  },
  {
    path: "/",
    name: "wire-payments",
    component: () => import("@/views/payments/payments.vue"),
    meta: {
      layout: "default",
      requiresAuth: true,
    },
  },
  {
    path: "/payments/:id",
    name: "single-payment",
    component: () => import("@/views/singlePayments/singlePayments.vue"),
    meta: {
      layout: "default",
      requiresAuth: true,
    },
  },
  {
    path: "/payouts",
    name: "wire-payouts",
    component: () => import("@/views/payouts/payouts.vue"),
    meta: {
      layout: "default",
      requiresAuth: true,
    },
  },
  {
    path: "/rate-adjustment",
    name: "wire-rate-adjustment",
    component: () => import("@/views/rateAdjustment/rateAdjustment.vue"),
    meta: {
      layout: "default",
      requiresAuth: true,
    },
  },
  {
    path: "/owner-disputes",
    name: "owner dispute",
    component: () => import("@/views/ownersDispute/ownerDispute.vue"),
    meta: {
      layout: "default",
      requiresAuth: true,
    },
  },
  {
    path: "/users",
    name: "Users",
    component: () => import("@/views/admin/admin.vue"),
    meta: {
      layout: "default",
      requiresAuth: true,
    },
  },
  {
    path: "*",
    redirect: "/",
    name: "Not Found",
    component: import("@/views/payments/payments.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const expiryDate = new Date(store?.state?.auth?.tokenExpire);
    const currentDate = new Date().getTime();
    if (store.getters["auth/isAuthenticated"] && expiryDate > currentDate) {
      next();
      return;
    } else {
      next("/login");
    }
  } else if (to.matched.some((record) => record.meta.guest)) {
    const expiryDate = new Date(store?.state?.auth?.tokenExpire);
    const currentDate = new Date().getTime();
    if (store.getters["auth/isAuthenticated"] && expiryDate > currentDate) {
      if(store.getters["auth/getUserPermissions"].includes('bnpl-service:zilla_admin:wire_payment_fetch:1')){
        next("/");
      }else{
        next("/account");
      }
      return;
    }
    next();
  } else {
    next();
  }
});

//OTC form Directives


export default router;
