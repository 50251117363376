<template>
    <div class="checkboxes__item">
        <label class="checkbox style-b">
          <input type="checkbox"  :checked="checked" @change="handleChange"/>
          <div class="checkbox__checkmark"></div>
          <div class="checkbox__body text-dark text-sm" v-if="label.length">{{label}}</div>
        </label>
      </div>
</template>
<script>
export default {
    props:{
        value:{
            type: Boolean,
            default: false,
            required: false
        },
        label:{
            type: String,
            default: 'Label',
            required: false
        },
        checked:{
          type: Boolean,
          default: false
        }
    },
    methods:{
        handleChange(e){
            this.$emit('change', e.target.checked);
            this.$emit('input', e.target.checked)
        }
    }
}
</script>
<style scoped>
.checkboxes__item {
    width: 50%;
  }
  .checkbox.style-b {
    display: inline-block;
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .checkbox.style-b input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .checkbox.style-b input:checked ~ .checkbox__checkmark {
    background-color: #fff;
  }
  .checkbox.style-b input:checked ~ .checkbox__checkmark:after {
    opacity: 1;
  }
  .checkbox.style-b:hover input ~ .checkbox__checkmark {
    background-color: #eee;
  }
  .checkbox.style-b:hover input:checked ~ .checkbox__checkmark {
    background-color: #fff;
  }
  .checkbox.style-b .checkbox__checkmark {
    position: absolute;
    top: 3px;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: #fff;
    border: 1.5px solid #5D49BB;
    transition: background-color 0.25s ease;
    border-radius: 4px;
  }
  .checkbox.style-b .checkbox__checkmark:after {
    content: "";
    position: absolute;
    left: 5px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: solid #333;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    opacity: 0;
    transition: opacity 0.25s ease;
  }
 
</style>