<template>
  <div>
    <div class="py-4">
      <div class="flex justify-between">
        <h4 class="font-bold text-lg">Sub-Categories</h4>
        <div>
          <Button text="Add new subcategory" @click="showAddNew = true" />
        </div>
      </div>
      <div class="flex items-center justify-end mt-6">
        <Card class="searchContainer w-48 mr-2" p="px-3">
          <Select
            type="filter"
            :options="featuredCategories"
            placeholder="Merchant Category"
            label="name"
            :reduce="(category) => category.id"
            v-model="subparams.featuredCategoryId"
          />
        </Card>
        <Card class="searchContainer w-44 mr-2" p="px-3">
          <Select
            type="filter"
            placeholder="Status"
            v-model="subparams.status"
            :options="statusOptions"
            :reduce="(type) => type.value"
          />
        </Card>
        <DatePicker
          class="mr-4"
          v-model="range"
          is-range
          :model-config="modelConfig"
          :masks="masks"
        >
          <template v-slot="{ inputValue, togglePopover }">
            <Card
              class="flex items-center border searchContainer w-full"
              p="px-1.5 py-1"
            >
              <button class="p-2 focus:outline-none" @click="togglePopover()">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="none"
                >
                  <path
                    d="M11.09 2h-.272v-.25c0-.425-.354-.75-.818-.75-.464 0-.818.325-.818.75V2H4.818v-.25C4.818 1.325 4.464 1 4 1c-.464 0-.818.325-.818.75V2h-.273C1.845 2 1 2.775 1 3.75v7c0 .975.845 1.75 1.91 1.75h8.18c1.065 0 1.91-.775 1.91-1.75v-7C13 2.775 12.155 2 11.09 2Zm-1.363-.25c0-.15.11-.25.273-.25.164 0 .273.1.273.25v1c0 .15-.11.25-.273.25-.164 0-.273-.1-.273-.25v-1ZM4 1.5c.164 0 .273.1.273.25v1c0 .15-.11.25-.273.25-.164 0-.273-.1-.273-.25v-1c0-.15.11-.25.273-.25Zm-1.09 1h.272v.25c0 .425.354.75.818.75.464 0 .818-.325.818-.75V2.5h4.364v.25c0 .425.354.75.818.75.464 0 .818-.325.818-.75V2.5h.273c.764 0 1.364.55 1.364 1.25v.75H1.544v-.75c0-.7.6-1.25 1.364-1.25Zm9.544 8.25c0 .7-.6 1.25-1.363 1.25H2.909c-.764 0-1.364-.55-1.364-1.25V5h10.91v5.75Z"
                    fill="#9D9D9D"
                    stroke="#9D9D9D"
                    stroke-width=".1"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M3.727 7h6.546c.163 0 .273-.1.273-.25s-.11-.25-.273-.25H3.727c-.163 0-.272.1-.272.25s.109.25.272.25ZM3.727 8.75h6.546c.163 0 .273-.1.273-.25s-.11-.25-.273-.25H3.727c-.163 0-.272.1-.272.25s.109.25.272.25ZM3.727 10.5h6.546c.163 0 .273-.1.273-.25s-.11-.25-.273-.25H3.727c-.163 0-.272.1-.272.25s.109.25.272.25Z"
                    fill="#9D9D9D"
                    stroke="#9D9D9D"
                    stroke-width=".1"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
              <p
                v-if="inputValue.start === null"
                @click="togglePopover()"
                class="text-grey"
              >
                Date filter
              </p>
              <p v-else @click="togglePopover()" class="text-sm text-grey">
                {{ inputValue.start }} - {{ inputValue.end }}
              </p>
            </Card>
          </template>
        </DatePicker>
        <Button
          text="Search"
          p="p-2"
          @click="handleSearch"
          width="w-32"
          class="mr-2"
        />
        <Button
          text="Clear Search"
          p="p-2"
          @click="clearSearch"
          outline
          width="w-32"
        />
      </div>
    </div>

    <!-- List of sub categories -->
    <div class="table-container mt-8">
      <table class="w-full table-auto">
        <thead>
          <tr>
            <th>S/N</th>
            <th>Icon</th>
            <th>Name</th>
            <th>Status</th>
            <th>Category</th>
            <th>Click count</th>
            <th>Action</th>
          </tr>
        </thead>

        <tbody>
          <EmptyState
            description="No records found"
            v-if="subCategories.length === 0"
          />
          <tr
            v-else
            v-for="(category, index) in subCategories"
            :key="category.id"
          >
            <td>{{ index + 1 }}</td>
            <td><img :src="category.icon" class="h-12 w-12" alt="" /></td>
            <td>{{ category.name }}</td>
            <td :class="[category.status.toLowerCase()]">
              {{ category.status | capitalize }}
            </td>
            <td>
              {{ category.category.name | capitalize }}
            </td>
            <td>
              {{ category.clickCount }}
            </td>
            <td class="flex">
              <SecondaryButton text="Edit" @click="updateCategory(category)" />
              <SecondaryButton
                text="View Merchants"
                class="ml-3"
                @click="viewMerchants(category, '')"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <Pagination
        :handleMore="handleMoreSubCategories"
        :loading="subCategoriesLoading"
        @paginate="handleSubCategoryPagination"
      />
    </div>

    <!-- modal for adding new sub category -->
    <Modal
      :display="showAddNew"
      @close="showAddNew = false"
      title="Add new subcategory"
      :class="showAddNew"
    >
      <Input
        placeholder="Name"
        width="w-full"
        v-model="newSubCategory.name"
        :validation="rules.name"
      />
      <Upload
        class="mt-8"
        text="Upload Icon"
        v-model="newSubCategory.icon"
        @upload="handleUpload"
        :fileType="['png']"
      />
      <div>
        <p>Select merchant category</p>
        <v-select
          :options="featuredCategories"
          class="w-full"
          label="name"
          :required="!newSubCategory.categoryId"
          :reduce="(category) => category.id"
          v-model="newSubCategory.categoryId"
        ></v-select>
      </div>
      <Button
        text="Add"
        width="w-full"
        class="mt-12"
        :loading="addNewLoading"
        @click="addNewSubCategory"
      />
    </Modal>

    <!-- modal for updating subcategory -->
    <Modal
      :display="updateSingle"
      :title="`Update ${singleSubCategory.name} category`"
      @close="updateSingle = false"
    >
      <Input
        placeholder="Name"
        width="w-full"
        v-model="singleSubCategory.name"
        class="mt-4"
      />
      <Select
        type="Status"
        class="mt-6"
        placeholder="Select status"
        :reduce="(value) => value.value"
        :options="statusOptions"
        v-model="singleSubCategory.status"
      />
      <div class="mt-6">
        <p>Select merchant category</p>
        <v-select
          :options="featuredCategories"
          class="w-full"
          label="name"
          :required="!singleSubCategory.categoryId"
          v-model="singleSubCategory.categoryId"
          :reduce="(category) => category.id"
        ></v-select>
      </div>
      <div class="mt-6">
        <p>Icon</p>
        <Upload :fileType="['png']" @upload="handleSingleUpload" />
      </div>

      <Button
        type="submit"
        text="Update"
        width="w-full"
        class="mt-12"
        :loading="updateLoading"
        @click="handleUpdateCategory"
      />
    </Modal>

    <!-- View Merchant Modal-->
    <Modal
      :display="viewMerchantsModal"
      @close="closeModal"
      :title="`Merchants in ${singleSubCategory.name} subcategory`"
    >
      <p v-if="merchants.length === 0" class="text-center my-5">
        There are no merchants in this subcategory
      </p>

      <div v-else>
        <!-- <UnfeaturedMerchants
          :subCategoryId="singleCategory.id"
        ></UnfeaturedMerchants> -->
        <div class="grid grid-cols-2 gap-4 py-6 justify-between">
          <div
            v-for="item in merchants"
            :key="item.id"
            class="flex justify-between items-center bg-lightGrey-1 shadow rounded py-2"
          >
            <div class="flex items-center px-2">
              <!-- <img class="w-6 h-6" :src="item.iconUrl" alt="logo" /> -->
              <img
                v-if="item.logoId"
                class="icon w-6 h-6"
                :src="file(item.logoId)"
                alt="merchant logo"
              />
              <img
                v-else
                class="icon w-6 h-6"
                :src="require('@/assets/images/logo.png')"
                alt="merchant logo"
              />
              <p class="font-semibold text-sm capitalize mx-2">
                {{ item.name }}
              </p>
            </div>
          </div>
        </div>
        <Pagination
          :handleMore="handleMore"
          :loading="paginateLoading"
          @paginate="handlePagination"
        />
      </div>
    </Modal>
  </div>
</template>
  <script>
import vSelect from "vue-select";
import { mapActions, mapState } from "vuex";
import { SecondaryButton, Button } from "@/UI/Button";
import { Modal } from "@/UI/Modals";
import { Input, Upload, Select } from "@/UI/Input";
import Pagination from "@/UI/Pagination";
import dayjs from "dayjs";
import { ADD_SUBCATEGORY, UPDATE_SUBCATEGORY } from "@/utils/api/merchants";
import file from "@/mixins/file";
import { DatePicker } from "v-calendar";
import UnfeaturedMerchants from "./UnfeaturedMerchantsList.vue";

export default {
  mixins: [file],
  components: {
    SecondaryButton,
    UnfeaturedMerchants,
    Button,
    Modal,
    Input,
    Upload,
    Select,
    Pagination,
    DatePicker,
    "v-select": vSelect,
    EmptyState: () => import("@/UI/EmptyState"),
  },
  data: () => ({
    showAddNew: false,
    addNewLoading: false,
    updateLoading: false,
    loading: false,
    modelConfig: {
      type: "string",
      mask: "iso",
      start: {
        timeAdjust: "00:00:00",
      },
      end: {
        timeAdjust: "23:59:59",
      },
    },
    range: {
      start: "",
      end: "",
    },
    masks: {
      input: "DD MMM YY",
    },
    newSubCategory: {
      name: "",
      icon: "https://lighthouse-gateway.usezilla.com/media/file/a804f384-b626-47b2-8f22-9b7f4444c080",
      categoryId: "",
      iconType: "URL",
    },
    singleSubCategory: {
      name: "",
      icon: "",
      iconType: "URL",
      status: "",
      categoryId: "",
    },
    selectedSubCategory: {},
    updateSingle: false,
    singleSubCategoryId: "",
    statusOptions: [
      { label: "Active", value: "ACTIVE" },
      { label: "Inactive", value: "INACTIVE" },
    ],
    params: {
      page: 0,
      pageSize: 20,
      subCategoryIds: "",
      from: "",
      to: "",
    },
    subparams: {
      page: 0,
      pageSize: 20,
      from: "",
      to: "",
      status: "ACTIVE",
    },
    viewMerchantsModal: false,
    paginateLoading: false,
    handleMore: false,
    showSubCategories: false,
    subCategoriesLoading: false,
    handleMoreSubCategories: false,
    singleCategory: {}
  }),
  computed: {
    ...mapState({
      featuredCategories: (state) => state?.merchants?.featuredCategories,
      subCategories: (state) => state?.merchants?.allMerchantSubCategories,
      merchants: (state) => state?.merchants?.allSubCategoryMerchants,
    }),
    rules() {
      return {
        name: this.newSubCategory.name.length > 0,
        categoryId: this.selectedSubCategory.length > 0,
        icon: this.newSubCategory.icon.length > 0,
      };
    },
    disableButton() {
      return Object.values(this.rules).includes(false);
    },
    dayjs() {
      return dayjs;
    },
  },
  mounted() {
    this.getSubCategories();
  },
  methods: {
    ...mapActions("merchants", [
      "getMerchantSubCategories",
      "getMerchantBySubCategory",
    ]),
    ...mapActions("loading", ["setLoading"]),
    ...mapActions("notification", ["showToast"]),
    addNewSubCategory() {
      this.addNewLoading = true;
      ADD_SUBCATEGORY(this.newSubCategory)
        .then(() => {
          this.addNewLoading = false;
          this.showAddNew = false;
          this.showToast({
            display: true,
            description: `${this.newSubCategory.name} added as a new sub-category`,
            type: "success",
          });
          this.getSubCategories();
        })
        .catch(() => {
          this.addNewLoading = false;
        });
    },

    viewMerchants(category, type) {
      // console.log(category);
      // if it is pagination type show loading for pagination else general loading
      type === "paginate"
        ? (this.paginateLoading = true)
        : this.setLoading(true);
      this.singleCategory = category;
      this.params.subCategoryIds = category.id;
      this.getMerchantBySubCategory({
        params: this.params,
        type: type,
      })
        .then((res) => {
          if (res.data.length === this.params.pageSize) {
            this.handleMore = true;
          } else {
            this.handleMore = false;
          }
          type === "paginate"
            ? (this.paginateLoading = false)
            : (this.loading = false);
          this.viewMerchantsModal = true;
          this.setLoading(false);
        })
        .catch(() => {
          type === "paginate"
            ? (this.paginateLoading = false)
            : (this.loading = false);
          this.setLoading(false);
        });
    },

    handleUpload(e) {
      this.newSubCategory.icon = this.file(e.id);
    },
    handleSingleUpload(e) {
      this.singleSubCategory.icon = this.file(e.id);
    },
    updateCategory(data) {
      // this.singleSubCategory = data;
      this.singleSubCategory.categoryId = data.category.id;
      this.singleSubCategoryId = data.id;
      this.singleSubCategory.name = data.name;
      this.singleSubCategory.icon = data.icon;
      this.singleSubCategory.iconType = data.iconType;
      this.singleSubCategory.status = data.status;
      this.updateSingle = true;
    },

    handleUpdateCategory() {
      this.updateLoading = true;
      UPDATE_SUBCATEGORY(this.singleSubCategoryId, this.singleSubCategory)
        .then(() => {
          this.updateLoading = false;
          this.updateSingle = false;
          this.getSubCategories();
          this.showToast({
            display: true,
            description: `Category updated successfully`,
            type: "success",
          });
        })
        .catch(() => {
          this.updateLoading = false;
        });
    },

    getSubCategories(type) {
      type === "paginate"
        ? (this.subCategoriesLoading = true)
        : this.setLoading(true);
      this.getMerchantSubCategories({
        params: this.subparams,
        type: type,
      })
        .then((res) => {
          if (res.data.length === this.subparams.pageSize) {
            this.handleMoreSubCategories = true;
          } else {
            this.handleMoreSubCategories = false;
          }
          type === "paginate"
            ? (this.subCategoriesLoading = false)
            : (this.loading = false);
          this.setLoading(false);
        })
        .catch(() => {
          type === "paginate"
            ? (this.subCategoriesLoading = false)
            : (this.loading = false);
          this.setLoading(false);
        });
    },

    handlePagination() {
      this.paginateLoading = true;
      this.params.page++;
      this.viewMerchants(this.singleCategory, "paginate");
    },

    handleSubCategoryPagination() {
      this.paginateLoading = true;
      this.subparams.page++;
      this.getSubCategories("paginate");
    },

    handleSearch() {
      this.subparams.page = 0;
      this.subparams.from = this.range.start;
      this.subparams.to = this.range.end;

      this.$router.replace({
        ...this.$router.currentRoute,
        query: {
          data: JSON.stringify(this.subparams),
        },
      });
      this.getSubCategories();
    },

    clearSearch() {
      this.subparams.status = "";
      this.subparams.page = 0;
      this.subparams.from = "";
      this.subparams.to = "";
      this.$router.replace({
        ...this.$router.currentRoute,
        query: {
          data: JSON.stringify(this.subparams),
        },
      });
      this.getSubCategories();
    },

    // clearSubCategorySearch() {
    //   this.params.featuredCategoryId = "";
    //   this.params.status = "";
    //   this.params.page = 0;
    //   this.params.from = "";
    //   this.params.to = "";
    //   this.$router.replace({
    //     ...this.$router.currentRoute,
    //     query: {
    //       data: JSON.stringify(this.params),
    //     },
    //   });
    //   this.getSubCategories();
    // },

    closeModal() {
      this.viewMerchantsModal = false;
      this.params.page = 0;
    },
  },
};
</script>
<style src="vue-select/dist/vue-select.css"></style>
<style scoped>
.searchContainer {
  border: 1px solid rgba(138, 116, 192, 0.1) !important;
  width: 15rem;
}
</style>
  