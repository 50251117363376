<template>
    <Modal :display="display" @close="$emit('close')">
        <p class="text-lg font-semibold">Create a discount for this merchant</p>
        <CurrencyInput class="mt-4" width="w-full" placeholder="Adjusted rate" v-model="rateData.adjustedRate"/>
        <Select  v-model="rateData.sourceCurrencyCode" placeholder="Source currency"
        class="mt-6" :options="currencyList" label="code" :reduce="(item) => item.code" zIndex="10" />
        <Select v-model="rateData.destinationCurrencyCode"
        placeholder="Destination currency" class='mt-6' :options="currencyList" label="code"
        :reduce="(item) => item.code" zIndex="4"/>

        <Button class="mt-12" text="Submit" width="w-full" :loading="loading" @click="createDiscount"/>
    </Modal>
</template>
<script>
import {mapActions , mapState} from 'vuex'
import {Modal} from '@/UI/Modals'
import {Button} from '@/UI/Button'
import {CurrencyInput, Select} from '@/UI/Input'
import {CREATE_RATE_DISCOUNT} from '@/utils/api/wire'

export default {
    components:{
        Modal,
        Button,
        CurrencyInput,
        Select
    },
    props:{
        display: {
            type: Boolean,
            required: false,
            required: false
        }
    },
    data:() => ({
        // currencyList:[],
        rateData:{
            adjustedRate:0,
            sourceCurrencyCode:'',
            destinationCurrencyCode:'',
        },
        loading: false
    }),
    computed:{
        ...mapState({
            currencyList: (state) => state.wire.currencyList
        })
    },
    methods:{
        ...mapActions('wire', ['getAllCurrencyAction']),
        createDiscount(){
            this.loading = true;
            this.rateData.wireAccountId = this.$route.params.id
            CREATE_RATE_DISCOUNT(this.rateData)
                .then(() => {
                    this.loading = false;
                    this.$emit('success')
                }).catch(() => {
                    this.loading = false
                })
        }
    },
    mounted(){
        // if(!this.currencyList.length){
            this.getAllCurrencyAction()
        // }
    }
}
</script>