<template>
  <Admin />
</template>
<script>
  import Admin from "@/components/Admin/Admin";
  export default {
    components: {
      Admin,
    },
  };
</script>
