import { GET_PURCHASES, GET_RECOVERY_SCHEDULES } from "@/utils/api/purchases";
// import { resolve } from "core-js/fn/promise";

export default {
  getAllPurchases({ commit }, payload) {
    return new Promise((resolve, reject) => {
      GET_PURCHASES(payload.params)
        .then((res) => {
          payload.type === "paginate"
            ? commit("ADD_PURCHASES", res.data.data)
            : commit("GET_PURCHASES", res.data.data);

          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getAllRecoverySchedules({ commit }, payload) {
    return new Promise((resolve, reject) => {
      GET_RECOVERY_SCHEDULES(payload.params)
        .then((res) => {
          payload.type === "paginate"
            ? commit("ADD_SCHEDULES", res.data.data)
            : commit("GET_SCHEDULES", res.data.data);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
