<template>
  <right-sidebar display @close="$emit('close')" @handleBack="prevStep" :showBack="current > 1" title="">
    <template #body>
    <div class="payment-link-container-body">
        <CreateAccount  v-if="current === 0" @next="nextStep" @setWireAcct="setWireAcct" :showBack="false" />
        <!-- <keep-alive> -->
         <EnterBusinessInfo :businessInfoExt="businessInfoExt" @setBusinessInfo="handleSetBusinessInfo " v-if="current === 1" @next="nextStep" :wireAcctData="wireAcctData"  />
        <!-- </keep-alive> -->
        <!-- <DirectorDetails v-if="current === 2" @next="nextStep" :wireAcctData="wireAcctData"  @skip="current = 4" /> -->
        <AddDirectorsDetails v-if="current === 2" @next="nextStep" :wireAcctData="wireAcctData" @complete="$emit('close')" />
        <Complete v-if="current === 4" @complete="$emit('complete')"/>
    </div>
   </template>
  </right-sidebar>
</template>

<script>
import AddDirectorsDetails from './AddDirectorsDetails.vue'
import Complete from './Complete.vue'
import CreateAccount from './CreateAccount.vue'
import DirectorDetails from './DirectorDetails.vue'
import DirectorsList from './DirectorsList.vue'
import EnterBusinessInfo from './EnterBusinessInfo.vue'
import { RightSidebar} from '@/UI/Modals'

export default {
    components: {
        CreateAccount,
        EnterBusinessInfo,
        DirectorDetails,
        AddDirectorsDetails,
        DirectorsList,
        Complete  ,
        RightSidebar,
    },
   data(){
        return{
            current:0,
            isSideBar:false,
            wireAcctData:{
                
            },
            businessInfoExt:{}
        }
    },
 
    methods:{
        toggleSideBar(){
            this.isSideBar= !this.isSideBar
        },
        removeForm(){
            this.$emit('remove-form', true)
        },
        nextStep(){
            if(this.current < 5){
                this.current++
            }
        },
        prevStep(){
            if(this.current > 0){
                this.current--;
            }
        },
        completeForm(){
            this.$emit('remove-form', false)
        },
        handleBack() {
        // Logic for handling back action
            console.log('Handle back action');
        },
        setWireAcct(data){
            if (data && data.id) {
                this.wireAcctData = data;
            } else {
                console.error('Received data does not contain an id:', data);
            }
        },
        handleSetBusinessInfo(data){
            this.businessInfoExt = data
        }
    }
}
</script>

<style scoped>
.OTC{
    padding: 0;
}
.cancel{
    background: #EEF2F6;
    color: #697586;
}
.cancel-btn-area{
    border-bottom: 1px solid #E3E8EF;
    background: #ffffff;
    z-index: 999;
    position: fixed;
    top: 0;
    width: 40%;
    right: 0;
    margin-bottom: 40px;
}
</style>