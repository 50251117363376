<template>
  <Card class="categories-container">
    <Tabs :options="tabOptions" v-model="currentTab" class="mb-6" />
    <transition mode="out-in" name="slideX">
      <keep-alive>
        <Categories v-if="currentTab === 'Categories'" />
        <SubCategories v-if="currentTab === 'Sub-categories'" />
      </keep-alive>
    </transition>
  </Card>
</template>

<script>
import Categories from "./Categories.vue";
import SubCategories from "./SubCategories.vue";
import Tabs from "@/UI/Tab/Tab";

export default {
  components: {
    Tabs,
    Categories,
    SubCategories,
  },
  data() {
    return {
      tabOptions: ["Categories", "Sub-categories"],
      currentTab: "Categories",
    };
  },
};
</script>

<style scoped>
.categories-container {
  min-height: calc(100vh - 10rem);
}
</style>