export default {
  GET_CUSTOMERS(state, payload) {
    state.allCustomers = payload;
  },
  ADD_CUSTOMERS(state, payload) {
    state.allCustomers.push(...payload);
  },
  GET_CUSTOMERS_ISSUED_CARDS(state, payload) {
    state.allCustomersIssuedCards = payload;
  },
  ADD_CUSTOMERS_ISSUED_CARDS(state, payload) {
    state.allCustomersIssuedCards.push(...payload);
  },
  GET_COMPANY(state, payload) {
    state.allCompanies = payload;
  },
  ADD_COMPANY(state, payload) {
    state.allCompanies.push(...payload);
  },
  GET_EMPLOYMENT_VERIFICATION(state, payload) {
    state.allEmploymentDocuments = payload;
  },
  ADD_EMPLOYMENT_VERIFICATION(state, payload) {
    state.allEmploymentDocuments.push(...payload);
  },
  GET_BANK_STATEMENT(state, payload) {
    state.allBankStatements = payload;
  },
  ADD_BANK_STATEMENT(state, payload) {
    state.allBankStatements.push(...payload);
  },
};
