<template>
    <RightSidebar display @close="$emit('close')" title="Adjusted rate">
        <template #body>
            <div class="payment-link-container-body">
                <EmptyState v-if="!rateData.length" description="No Data available"/>
                <div class="mt-4 payment-details flex items-center justify-between" v-for="rate in rateData" :key="rate.wireRateAdjustmentId">
                    <div>
                        <p class="text-sm text-dark"> {{ rate.wireAccountName }}</p>
                        <p class="text-xs text-grey">Created: {{dayjs(rate.createdAt).format("MMM D YYYY")}}</p>
                        <p class="text-xs text-grey">Expired: {{dayjs(rate.expiresAt).format("MMM D YYYY")}}</p>
                    </div>
                    <div>
                        <p class="text-xs text-dark">Status: {{ rate.status }}</p>
                        <p class="text-dark text-xs">Rate: {{ rate.sourceCurrencyCode }}{{ rate.zillaRate}}</p>
                        <p class="text-dark text-xs">Adjusted Rate:  {{ rate.sourceCurrencyCode }}{{ rate.adjustedRate}}</p>
                    </div>
                </div>
            </div>
        </template>
    </RightSidebar>
</template>
<script>
import { RightSidebar } from '@/UI/Modals';
import {GET_RATE_ADJUSTMENT} from '@/utils/api/wire';
import dayjs from 'dayjs';
import {mapActions} from 'vuex'
export default{
    components:{
        RightSidebar,
        EmptyState:() => import('@/UI/EmptyState'),
    },
    data:() => ({
        rateData:[]
    }),
    computed:{
        dayjs(){
            return dayjs
        }
    },
    methods:{
        ...mapActions('loading', ['setLoading']),
        handleGetRateDiscount(){
            this.setLoading({
                display: true
            })
            GET_RATE_ADJUSTMENT(this.$route.params.id)
                .then(({data}) => {
                    this.setLoading(false)
                    this.rateData = data.data;
            }).catch(() => {
                this.setLoading(false);
        })
      }
    },
    mounted(){
        this.handleGetRateDiscount()
    }
}
</script>
<style scoped>
.payment-link-container-body {
    padding: 16px 24px;
}
.payment-details {
    padding-bottom: 8px;
    border-bottom: 1px solid #E3E8EF;
  }
</style>