<template>
    <div >
        <div v-if="showForm">
            <div class="flex justify-between text-right w-full min-h-64  px-2 mt-6  my-4 ">
        </div>
        <form @submit.prevent="handleFormSubmit">
                <p  class="text-md text-[#697586] mb-5 px-4">Details should be as registered with CAC</p>
                <div class="flex justify-between gap-4 px-4">
                    <div class="mb-4">
                        <Input  v-model="directorsInfo.firstName" label="First name " placeholder="Jon"
                            width="100%" required   errorText="Director's first name is required" :validation="rules.firstName"
                        />  
                    </div> 
                    <div class="mb-4">
                        <Input  v-model="directorsInfo.lastName" label="Last name " placeholder="Surname"
                            width="100%" required errorText="Director's last name is required" :validation="rules.lastName"
                        /> 
                    </div>
                </div>

                <div class="mb-4 px-4">
                    <Input  v-model="directorsInfo.jobTitleOrRole" label="Director’s Job title/ role" 
                        placeholder="Enter job title" width="100%" 
                    />
                </div>
                <div class="mb-4 px-4">
                    <DateInput v-model="directorsInfo.dob"  label="Director’s Date of birth" placeholder="Enter DOB" 
                        required   errorText="Director's date of birth is required"  :max-date="dobMaxDate"
                    />
                </div>
                <div class="mb-4 px-4">
                    <Input v-model="directorsInfo.email" label="Director’s Email Address"  placeholder="Enter email address" width="100%"  
                        required errorText="Director's valid email address is required" id="directorsemail" autoComplete="off" :validation="rules.email"
                    />
                </div>
                <div class="mb-4 px-4">
                    <Input v-model="directorsInfo.nationality" label="Director’s Nationality" 
                        placeholder="Enter Nationality" width="100%" required  :validation="rules.nationality"
                        errorText="Director's nationality is required"
                    />
                </div>
                <div class="mb-4 px-4">
                    <TelInput v-model="directorsInfo.phoneNumber"  label="Director’s Phone number" id="phone"
                    class="mt-4" errorText="Phone number is invalid" placeholder="0000" :validation="rules.bvn" />
                </div>
                <div class="mb-4 px-4">
                    <Input v-model="directorsInfo.bvn" label="Director’s BVN" 
                        :validation="rules.bvn"
                        errorText="Director's BVN must be 11 digits"
                        placeholder="Enter Director’s BVN" width="100%" required  maxlength="11" number 
                    />
                </div>
                <div class="mb-4 px-4">
                    <GoogleAutocomplete v-model="directorsInfo.address.main" label="Address"
                    @update="getFullAddressDetails"
                        placeholder="What is your director's address" required 
                        autoComplete="off"  id="directoraddress" errorText="Director's address is required"
                    />
                </div>
                <div class="mb-4 px-4">
                    <Input v-model="directorsInfo.address.state" label="State" :validation="rules.state"
                    placeholder="Enter state" width="100%" required errorText="Director's state is required" />
                </div>
                <div class="mb-4 px-4">
                    <Input v-model="directorsInfo.address.lga"  label="LGA / City"  :validation="rules.lga"
                    placeholder="Enter city" width="100%" errorText="Director's LGA/City name is required" required/>
                </div>
                
                <div  class="mb-4 px-4">
                    <Upload :multiple="true"    label="Proof of address" v-model="directorsInfo.proofOfAddressFileId" :value="directorsInfo.proofOfAddressFileId"
                        :fileType="['pdf', 'jpg', 'jpeg', 'png']"
                        errorText="Director's proof of address is required">
                        <template #desc>
                            <p class="text-xs text-grey mt-1">
                                Upload 1-3 documents of your payment invoice (PDF, PNG, or JPEG) - max 5MB each
                            </p>
                        </template>
                    </Upload>
                </div>
                <div class="mb-4 px-4">
                    <Upload :value="directorsInfo.identityDocFileId" label="Valid identification document "
                        :fileType="['pdf', 'jpg', 'jpeg', 'png']" required  @upload="(e) => directorsInfo.identityDocFileId = e.id"
                        errorText="Director's valid ID is required" > 
                        <template #desc>
                            <p class="text-xs text-grey mt-1">
                                Document format should be in PDF/JPEG/PNG format and at most 5mb
                            </p>
                        </template>
                    </Upload>
                </div>
                
                <div class="mb-4 px-4">
                    <DateInput v-model="directorsInfo.idCardIssueDate" errorText="Date of issue for ID is required"
                    label="Date of issue for identification document" :max-date="new Date()" required />
                </div>

                <div class="mb-4 px-4">
                    <DateInput v-model="directorsInfo.idCardExpiryDate" errorText="Date of expiry for ID is required"
                    label="Date of expiry for identification document" :min-date="new Date()" required />
                </div>

                <div class="continue-Btn w-full flex justify-end items-center mb-4  p-2 text-right h-20">
                    <Button outline v-if="updateDirector" :disabled="disableButton" text="Update director" class="mr-2" p="p-2"
                    preIcon="ri-edit-line" width="w-44" icon=""  :loading="loading" type="submit" />

                    <Button :disabled="disableButton" v-else width="w-44"  type="submit" text="Continue" p="p-2"
                        :loading="loading" />
                </div>
        </form>    
        </div>
        <div v-else>
            <div>
                <DirectorsList @next="$emit('complete')"  :directors="directors" @edit="editDirector" @add="addNewDirector" v-if="directors.length"/>
                <DirectorDetails v-else @next="showForm = true"/>
            </div>
        </div>
    </div>
</template>

<script>
import { Button } from "@/UI/Button";
import { Input, Select, DateInput, TelInput ,Upload, GoogleAutocomplete} from "@/UI/Input";
import {ADD_NEW_DIRECTOR_INFO, EDIT_WIRE_DIRECTOR_INFO} from '@/utils/api/wire'
import DirectorsList from './DirectorsList.vue';
export default {
    components:{
        Button,
        Input,
        Select,
        DateInput,
        TelInput,
        GoogleAutocomplete,
        Upload,
        DirectorsList,
        DirectorDetails:() => import('./DirectorDetails')
    },
    props:{
        wireAcctData:{
            type: Object,
            default:() => {}
        },
        extWireDirectors: {
            type: Array,
            default:() => []
        }
    },
    data(){
        return{
            placeholderText:'Upload file',
            directorsInfo: {
                id:null,
                address: {
                    main: "",
                    additionalInformation: "",
                    state: "",
                    lga: "",
                    country: ""
                },
                firstName: "",
                lastName: "",
                middleName: null, //Optional
                jobTitleOrRole: "", //Optional
                dob: "",
                email: "",
                nationality: "",
                phoneNumber: "",
                bvn: "",
                idCardIssueDate: "",
                idCardExpiryDate: "",
                identityDocFileId: "",
                proofOfAddressFileId: ""
            },
            directors: [],
            addDirectoryLoading: false,
            updateDirector: false,
            editingIndex: null,
            showForm:false,
            loading: false
        }
    },
    computed:{
        dobMaxDate() {
            const currentDate = new Date();
            const maxDate = new Date(currentDate);
            const maxDateTimeStamp = maxDate.setFullYear(currentDate.getFullYear() - 18);
            return new Date(maxDateTimeStamp);
        },
        rules() {
            return {
                firstName: this.directorsInfo.firstName.length !== 0,
                lastName: this.directorsInfo.lastName.length !== 0,
                dob: this.directorsInfo.dob.length !== 0,
                email: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
                    this.directorsInfo.email.trim()
                ),
                nationality: this.directorsInfo.nationality.length !== 0,
                phoneNumber: this.isPhoneNumberValid,
                bvn: this.directorsInfo.bvn.length === 11,
                address: this.directorsInfo.address.main.length > 0,
                state: this.directorsInfo.address.state.length !== 0,
                lga: this.directorsInfo.address.lga.length !== 0,
                proofOfAddressFileId: this.directorsInfo.proofOfAddressFileId.length !== 0,
                identityDocFileId: this.directorsInfo.identityDocFileId.length !== 0,
                idCardIssueDate: this.directorsInfo.idCardIssueDate.length !== 0,
                idCardExpiryDate: this.directorsInfo.idCardExpiryDate.length !== 0,
            };
        },
        disableButton() {
            return Object.values(this.rules).includes(false);
        },
    },
    
    methods: {
        next() {
            this.$emit('next');
        },
        handleFormSubmit() {
            // const isEditing = this.directorsInfo.id !== null;
            if (this.updateDirector) {
                this.updateDirectorInfo();
            } else {
                this.createDirector();
            }
        },
        createDirector(){
            const wireAccountId = this.wireAcctData?.id;            
          
            this.loading = true
            ADD_NEW_DIRECTOR_INFO({
                data: this.directorsInfo ,
                wireAccountId: wireAccountId
            })
            .then(({data}) => {
                let newData = data.data;
                newData.address = this.directorsInfo.address
                this.handleSubmit(newData);
                this.loading = false
            }).catch((error) => {
                this.loading = false
            })
        },
       
      
        updateDirectorInfo() {
            const wireAccountId = this.wireAcctData?.id;            
            this.loading = true;
            EDIT_WIRE_DIRECTOR_INFO({
                data: this.directorsInfo,
                wireAccountId: wireAccountId
            })
                .then((response) => {
                    this.directors.splice(this.editingIndex, 1, this.directorsInfo);
                    this.resetForm();
                    this.loading = false;
                    this.updateDirector = false;
                    this.showForm = false;
                })
                .catch((error) => {
                    this.loading = false;
                });
        },
        handleSubmit(director) {
            // if (this.updateDirector) {
            //     this.directors.splice(this.editingIndex, 1, { ...director });
            //     this.updateDirector = false;
            //     this.editingIndex = null;
            // } else {
                this.directors.push({ ...director});
            // }
            this.resetForm();

            this.showForm = false;
        },
        getFullAddressDetails(address) {
            this.directorsInfo.address.lga = address.lga;
            this.directorsInfo.address.state = address.state;
            this.directorsInfo.address.country = address.country;
        },
        resetForm() {
            this.directorsInfo = {
                id: '',
                address: {
                main: "",
                additionalInformation: "",
                state: "",
                lga: "",
                country: ""
                },
                firstName: "",
                lastName: "",
                middleName: '',
                jobTitleOrRole: "",
                dob: "",
                email: "",
                nationality: "",
                phoneNumber: "",
                bvn: "",
                idCardIssueDate: "",
                idCardExpiryDate: "",
                identityDocFileId: "",
                proofOfAddressFileId: ""
            };
        },
        editDirector(index) {
            this.directorsInfo = { ...this.directors[index] };
            this.updateDirector = true;
            this.editingIndex = index;
            this.showForm = true;
        },
        addNewDirector() {
            this.resetForm();
            this.updateDirector = false;
            this.editingIndex = null;
            this.showForm = true;
        }      
    },
    mounted(){
        if(this.extWireDirectors && this.extWireDirectors.length){
            this.directors = [...this.extWireDirectors]
        }
    }
}
</script>

<style scoped>
    .detail{
        color: #697586;
    }
    .cancel{
        background: #EEF2F6;
        color: #697586;
    }
    input, select{
        background: #E3E8EF;
        color:#9AA4B2;
        border: 1px solid  #E3E8EF;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
    }
    .opt{
        color: #697586;
    }
    .skip{
        color: #6F00FF;
    }
    .continue-Btn-area{
        min-height: 80px;
        border-top: 1px solid #E3E8EF;
        background:#FCFCFD;
    }
    .direct-details{
        border-bottom: 1px solid #E3E8EF
    }
    .continue-Btn{
        background:#FCFCFD;
        border-top: 1px solid #E3E8EF;
    }
    .create-acct{
        color: #202939;
    }
    .business-type{
        background: #E3E8EF;
    }
</style>