<template>
  <div class="card transition-all" :class="{disabled: disabled}">
    <label class="mb-1" m v-if="label.length">{{ label }} <span class="label-required text-brandPurple" v-if="required">*</span> </label>
    <DatePicker :value="value" :model-config="modelConfig" :masks="masks" @input="($event) => handleInput($event)" :min-date="minDate" :max-date="maxDate" 
      :disabled="disabled" 
      >
      <template v-slot="{ inputValue, togglePopover }">
        <Card @click="!disabled && togglePopover()" class="flex items-center justify-between border" :class="[width]" p="px-2.5 py-2">
          <div>
            <p v-if="!inputValue" class="text-grey">
              {{ placeholder }}
            </p>
            <p v-else class="text-sm text-grey">
              {{ inputValue }}
            </p>
          </div>

          <button type="button" class="px-2 py-1 focus:outline-none" :class="{disabled: disabled}">
            <i class="ri-calendar-line text-dark mr-1 text-lg"></i>
          </button>
        </Card>
      </template>
    </DatePicker>
  </div>
</template>

<script>
import { DatePicker } from "v-calendar";
export default {
  components: {
    DatePicker
  },
  props: {
    placeholder: {
      type: String,
      default: "Enter date",
    },
    required: {
      type: Boolean,
      default: false,
      required: false,
    },
    width: {
      type: String,
      default: "w-auto",
      required: false,
    },
    label: {
      type: String,
      default: "",
      required: false,
    },
    value: {
      type: [String, Date],
      default: "",
      required: false,
    },
    minDate: {
      type: Date,
      default: null,
      required: false,
    },
    maxDate: {
      type: Date,
      default: null,
      required: false,
    },
    disabled:{
      type: Boolean,
      required : false,
      default: false
    }
  },
  data() {
    return {
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD', // Uses 'iso' if missing
      },
      masks: {
        input: 'DD/MM/YYYY',
      },
    }
  },
  computed: {
    errorMessage() {
      if (!this.value && this.required) return 'Date is required.';
      return '';
    },
  },

  methods: {
    handleInput($event) {
      this.$emit('input', $event);
    }
  }
};
</script>
<style scoped>
.card {
  border-radius: 8px;
}

.shadow {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
}
.label-required{
  color: rgba(105, 86, 200, var(--tw-text-opacity))
}
.disabled {
  border: 1px solid #f8f8f8 !important;
  opacity: 0.5;
  cursor: not-allowed
}
</style>
    