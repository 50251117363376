<template>
  <div>
    <div class="flex justify-end" v-if="details.card.status === 'BLOCKED'">
      <Button
        text="Unblock"
        p="p-2"
        @click="unblockCard()"
        width="w-44"
        :loading="unblockLoading"
      />
    </div>
    <div
      class="card-image relative mx-auto mt-6"
      :class="[
        details.card.customization.skinColor.toLowerCase(),
        details.card.status === 'BLOCKED' ? 'opacity-50' : '',
      ]"
    >
      <p
        v-if="details.card.status === 'BLOCKED'"
        class="absolute right-2 top-3 text-darkGrey rounded-full bg-white text-xs px-3 py-2"
      >
        Blocked
      </p>
      <div class="logo-card flex justify-between items-center">
        <div class="logo">
          <img
            src="https://res.cloudinary.com/zillaafrica/image/upload/v1663580773/customer/Group_13111_z0tw4h.svg"
            alt=""
          />
        </div>
        <div class="brand">
          <img
            src="https://res.cloudinary.com/zillaafrica/image/upload/v1663583200/customer/Group_kmnfzv.png"
            alt=""
          />
        </div>
      </div>
      <img
        class="wave"
        src="https://res.cloudinary.com/zillaafrica/image/upload/v1663580774/customer/Union_1_gkjcox.png"
        alt=""
      />
    </div>
    <div class="md:grid grid-cols-2 mt-6">
      <!-- Card info -->
      <div>
        <div class="mt-4">
          <p class="text-grey text-xs">Holder name</p>
          <transition mode="out-in">
            <Skeleton v-if="loading" height="1rem" width="25%" class="mt-2" />
            <p
              v-else-if="
                details.card.customization.holderFirstName ||
                details.card.customization.holderLastName
              "
              class="text-dark"
            >
              {{ details.card.customization.holderFirstName }}
              {{ details.card.customization.holderLastName }}
            </p>
            <p v-else class="text-dark">N/A</p>
          </transition>
          <Hr />
        </div>
        <div class="mt-4">
          <p class="text-grey text-xs">Card Pan</p>
          <transition mode="out-in">
            <Skeleton v-if="loading" height="1rem" width="25%" class="mt-2" />
            <p v-else-if="details.card.pan" class="text-dark">
              {{ details.card.pan }}
            </p>
            <p v-else class="text-dark">N/A</p>
          </transition>
          <Hr />
        </div>
        <div class="mt-4">
          <p class="text-grey text-xs">Expiry Date</p>
          <transition mode="out-in">
            <Skeleton v-if="loading" height="1rem" width="25%" class="mt-2" />
            <p v-else-if="details.card.expirationMonth" class="text-dark">
              {{ details.card.expirationMonth }}/{{
                details.card.expirationYear
              }}
            </p>
            <p v-else class="text-dark">N/A</p>
          </transition>
          <Hr />
        </div>
        <div class="mt-4">
          <p class="text-grey text-xs">Card type</p>
          <transition mode="out-in">
            <Skeleton v-if="loading" height="1rem" width="25%" class="mt-2" />
            <p v-else-if="details.card.type" class="text-dark">
              {{ details.card.type }}
            </p>
            <p v-else class="text-dark">N/A</p>
          </transition>
          <Hr />
        </div>
        <div class="mt-4">
          <p class="text-grey text-xs">Payment Plan</p>
          <transition mode="out-in">
            <Skeleton v-if="loading" height="1rem" width="25%" class="mt-2" />
            <p v-else-if="details.paymentPlan" class="text-dark">
              {{ details.paymentPlan }}
            </p>
            <p v-else class="text-dark">N/A</p>
          </transition>
          <Hr />
        </div>
        <div class="mt-4">
          <p class="text-grey text-xs">Nickname</p>
          <transition mode="out-in">
            <Skeleton v-if="loading" height="1rem" width="25%" class="mt-2" />
            <p
              v-else-if="details.card.customization.nickname"
              class="text-dark"
            >
              {{ details.card.customization.nickname }}
            </p>
            <p v-else class="text-dark">N/A</p>
          </transition>
          <Hr />
        </div>
      </div>

      <!-- Billing address and others -->
      <div>
        <div class="mt-4">
          <p class="text-grey text-xs">Status</p>
          <transition mode="out-in">
            <Skeleton v-if="loading" height="1rem" width="25%" class="mt-2" />
            <p
              v-else-if="details.card.status"
              :class="[details.card.status.toLowerCase()]"
            >
              {{ details.card.status }}
            </p>
            <p v-else class="text-dark">N/A</p>
          </transition>
          <Hr />
        </div>

        <div class="mt-4">
          <p class="text-grey text-xs">Card Color</p>
          <transition mode="out-in">
            <Skeleton v-if="loading" height="1rem" width="25%" class="mt-2" />
            <p
              v-else-if="details.card.customization.skinColor"
              class="text-dark"
            >
              {{ details.card.customization.skinColor }}
            </p>
            <p v-else class="text-dark">N/A</p>
          </transition>
          <Hr />
        </div>

        <div class="mt-4">
          <p class="text-grey text-xs">Currency</p>
          <transition mode="out-in">
            <Skeleton v-if="loading" height="1rem" width="25%" class="mt-2" />
            <p v-else-if="details.card.currency">
              {{ details.card.currency }}
            </p>
            <p v-else class="text-dark">N/A</p>
          </transition>
          <Hr />
        </div>

        <div class="mt-4">
          <p class="text-grey text-xs">Billing Address</p>
          <transition mode="out-in">
            <Skeleton v-if="loading" height="1rem" width="25%" class="mt-2" />
            <p v-else-if="details.card.billingAddressMain">
              {{ details.card.billingAddressMain }},
              {{ details.card.billingAddressLga }},
              {{ details.card.billingAddressState }},
              {{ details.card.billingAddressCountry }}
            </p>
            <p v-else class="text-dark">N/A</p>
          </transition>
          <Hr />
        </div>

        <div class="mt-4">
          <p class="text-grey text-xs">Date created</p>
          <transition mode="out-in">
            <Skeleton v-if="loading" height="1rem" width="25%" class="mt-2" />
            <p v-else-if="details.card.createdAt">
              {{ dayjs(details.card.createdAt).format("MMM D YYYY | hh:mmA") }}
            </p>
            <p v-else class="text-dark">N/A</p>
          </transition>
          <Hr />
        </div>

        <div class="mt-4">
          <p class="text-grey text-xs">Spending limit</p>
          <transition mode="out-in">
            <Skeleton v-if="loading" height="1rem" width="25%" class="mt-2" />
            <p v-else-if="details.card.spendingLimit">
              <span class="font-semibold">{{
                details.card.spendingLimit.amountSpentSoFar | formatMoney
              }}</span>
              used of
              <span class="font-bold text-lg">{{
                details.card.spendingLimit.limitAmount | formatMoney
              }}</span> 
              <span class="pl-2" v-if="details.card.spendingLimit.limitPeriod"
                >{{ details.card.spendingLimit.limitPeriod | capitalize }}
              </span> limit.
            </p>
            <p v-else class="text-dark">N/A</p>
          </transition>
          <Hr />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Hr from "@/UI/Hr";
import {
  GET_ISSUED_CARD_DETAILS,
  UNBLOCK_ISSUED_CARD,
} from "@/utils/api/customers";
import { Button } from "@/UI/Button";
import dayjs from "dayjs";
export default {
  components: {
    Hr,
    Button,
  },
  data() {
    return {
      details: {
        card: {
          customization: {
            skinColor: "WHITE",
          },
          spendingLimit: {},
        },
      },
      loading: false,
      unblockLoading: false,
    };
  },

  props: {
    cardId: {
      type: String,
      default: "",
      required: true,
    },
  },

  computed: {
    dayjs() {
      return dayjs;
    },
  },

  mounted() {
    this.getCardDetails();
  },
  methods: {
    ...mapActions("loading", ["setLoading"]),
    getCardDetails() {
      this.loading = true;
      this.setLoading(true);
      GET_ISSUED_CARD_DETAILS(this.cardId)
        .then((res) => {
          this.loading = false;
          this.details = res.data.data;
          this.setLoading(false);
        })
        .catch(() => {
          this.loading = false;
          this.setLoading(false);
        });
    },
    unblockCard() {
      this.unblockLoading = true;
      UNBLOCK_ISSUED_CARD(this.cardId)
        .then((res) => {
          this.unblockLoading = false;
          this.$emit("unblock");
          this.getCardDetails();
        })
        .catch(() => {
          this.unblockLoading = false;
        });
    },
  },
};
</script>

<style scoped>
.card-image-container {
  max-width: 21rem;
}
.card-image {
  position: relative;
  /* display: grid; */
  margin-top: 2.75rem;
  height: 14rem;
  max-width: 21rem;
  border-radius: 19px;
  transition: all 0.3s linear;
  box-shadow: 0px 11px 4px rgba(0, 0, 0, 0.03), 0px 6px 4px rgba(0, 0, 0, 0.09),
    0px 3px 3px rgba(0, 0, 0, 0.15), 0px 1px 1px rgba(0, 0, 0, 0.17),
    0px 0px 0px rgba(0, 0, 0, 0.18);
  overflow: hidden;
  /* filter: drop-shadow(0px 77px 31px rgba(0, 0, 0, 0.03))
      drop-shadow(0px 43px 26px rgba(0, 0, 0, 0.11))
      drop-shadow(0px 19px 19px rgba(0, 0, 0, 0.19))
      drop-shadow(0px 5px 11px rgba(0, 0, 0, 0.21))
      drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.22)); */
}
.customize--card {
  max-width: 21rem;
  margin: auto;
}

.card-image .wave {
  position: absolute;
  bottom: 0;
}

.card-image .brand {
  width: 3.875rem;
  height: auto;
}
.card-image .logo {
  width: 6.875rem;
  height: auto;
}

.logo-card {
  position: absolute;
  bottom: 1.5rem;
  left: 1rem;
  right: 1rem;
  z-index: 10;
}
.black {
  background-image: url("https://res.cloudinary.com/zillaafrica/image/upload/q_auto/v1663579293/customer/ZCard00_1_oz6i2c.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.purple {
  background-image: url("https://res.cloudinary.com/zillaafrica/image/upload/q_auto/v1663344397/customer/ZCard01_2_npv5dd.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.red {
  background-image: url("https://res.cloudinary.com/zillaafrica/image/upload/q_auto/v1663579250/customer/ZWall01_Color_corrected_2_eo7hht.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.blue {
  background-image: url("https://res.cloudinary.com/zillaafrica/image/upload/q_auto/v1663579244/customer/Card_D00_1_jjizgb.png");
  background-repeat: no-repeat;
  background-size: cover;
}
</style>