export default {
  GET_PURCHASES(state, payload) {
    state.allPurchases = payload;
  },
  ADD_PURCHASES(state, payload) {
    state.allPurchases.push(...payload);
  },
  GET_SCHEDULES(state, payload) {
    state.allSchedules = payload;
  },
  ADD_SCHEDULES(state, payload) {
    state.allSchedules.push(...payload);
  },
};
