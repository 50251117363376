var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative"},[(_vm.label.length)?_c('label',{attrs:{"for":_vm.id}},[_vm._v(_vm._s(_vm.label)+" "),(_vm.required)?_c('span',{staticClass:"label-required text-brandPurple"},[_vm._v("* ")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"relative flex items-center input-wrapper input-regular placeholder-borderGrey",class:[_vm.width, _vm.displayPlaceholder || (_vm.value && !_vm.error) ? 'input-active' : _vm.error ? 'error-border' : null],style:({ width: _vm.width })},[(_vm.showPrefix)?_c('div',{staticClass:"prefix-container"},[_vm._v(" "+_vm._s(_vm.prefixText)+" ")]):_vm._e(),_c('input',{staticClass:"outline-none transition-all",class:[
        _vm.p, 
        _vm.disabled ? 'disabled' : null, 
        _vm.type === 'password' ? 'atlas-mask' : null
      ],style:({ width: '100%' }),attrs:{"autocomplete":_vm.autoComplete,"type":_vm.displayType,"name":_vm.name,"id":_vm.id,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"required":_vm.required,"autofocus":_vm.autofocus,"maxlength":_vm.maxlength,"pattern":_vm.type === 'number' ? '[0-9]*' : _vm.type === 'tel' ? '^[+\d](?:.*\d)?$' : null,"inputmode":_vm.type === 'number' ? 'numeric' : 'text'},domProps:{"value":_vm.value},on:{"keypress":function($event){return _vm.isNumber($event)},"keyup":function($event){return _vm.$emit('keyup', _vm.value)},"input":function($event){return _vm.$emit('input', $event.target.value)},"blur":_vm.displayInput,"focus":_vm.handleFocus,"focusout":function($event){_vm.displayPlaceholder = false},"change":function($event){return _vm.$emit('change')},"load":function($event){return _vm.$emit('load')}}}),(_vm.revealPassword)?_c('div',{staticClass:"absolute z-5",class:[
      _vm.displayPlaceholder || _vm.value || _vm.error
        ? 'eye-wrapper-focus'
        : 'eye-wrapper' ],on:{"click":_vm.revealText}},[_c('div',{staticClass:"eye-container"},[(_vm.showEye)?_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"16","height":"10","viewBox":"0 0 16 10","fill":"none"}},[_c('path',{attrs:{"d":"M8.00033 0C4.66699 0 1.82033 2.07333 0.666992 5C1.82033 7.92667 4.66699 10 8.00033 10C11.3337 10 14.1803 7.92667 15.3337 5C14.1803 2.07333 11.3337 0 8.00033 0ZM8.00033 8.33333C6.16033 8.33333 4.66699 6.84 4.66699 5C4.66699 3.16 6.16033 1.66667 8.00033 1.66667C9.84033 1.66667 11.3337 3.16 11.3337 5C11.3337 6.84 9.84033 8.33333 8.00033 8.33333ZM8.00033 3C6.89366 3 6.00033 3.89333 6.00033 5C6.00033 6.10667 6.89366 7 8.00033 7C9.10699 7 10.0003 6.10667 10.0003 5C10.0003 3.89333 9.10699 3 8.00033 3Z","fill":"#1F1452"}})]):_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"16","height":"10","viewBox":"0 0 16 10","fill":"none"}},[_c('path',{attrs:{"d":"M8.00033 0C4.66699 0 1.82033 2.07333 0.666992 5C1.82033 7.92667 4.66699 10 8.00033 10C11.3337 10 14.1803 7.92667 15.3337 5C14.1803 2.07333 11.3337 0 8.00033 0ZM8.00033 8.33333C6.16033 8.33333 4.66699 6.84 4.66699 5C4.66699 3.16 6.16033 1.66667 8.00033 1.66667C9.84033 1.66667 11.3337 3.16 11.3337 5C11.3337 6.84 9.84033 8.33333 8.00033 8.33333ZM8.00033 3C6.89366 3 6.00033 3.89333 6.00033 5C6.00033 6.10667 6.89366 7 8.00033 7C9.10699 7 10.0003 6.10667 10.0003 5C10.0003 3.89333 9.10699 3 8.00033 3Z","fill":"#1F1452"}})])])]):_vm._e()]),_c('div',{staticClass:"error-text"},[_c('transition',{attrs:{"name":"slideY"}},[(_vm.error)?_c('p',{staticClass:"text-brandRed text-xs mt-1"},[_vm._v(" "+_vm._s(_vm.errorText)+" ")]):_vm._e()])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }