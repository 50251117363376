<template>
    <div>
        <Tab :options="paymentMethods" v-model="selectedPaymentType" class="mt-2" @input="handleTabChange"/>
        <div class="flex items-center justify-between mt-4">
          <p class="text-grey text-xs font-semibold">1{{ beneficiary.receiveCurrency }} = {{exchangeRate}}{{'NGN'}}</p>
          <Spinner v-if="feesLoading"/>
          <p class="text-grey text-xs" v-else>Fees: <span class="font-semibold">{{beneficiary.fee.currency}}{{beneficiary.fee.fee}}</span> </p>
        </div>
        <!-- <inline-toast v-if="selectedPaymentType === 'Custom rate'" type="info" class="mt-2" :display="true" description="You can now set your payment to trigger at a specific rate. But, if it's not reached within 72 hours, you'll need to review the rate or it will be declined " :showClose="false"/> -->

        <currency-input
          required
          v-model="beneficiary.receiveAmount"
          class="mt-4" showCurrency width="w-full" 
          label="Send amount" 
          :options="getDestinationOptions()" 
          selectLabel="code"
          :optionsValue="beneficiary.receiveCurrency"
          :reduce="(category) => category.code"
          :symbol="currencySigns[beneficiary.receiveCurrency]"
          @input='handleCurrencyInput'
          :validation="beneficiary.receiveAmount >= 1000"
          :errorText="''"
          @focusout="handleFocusOut"
          @focus="showAmountError = false"
           @selectCurrency="handleSelectCurrency"
        />
        <p class="text-xs" :class="[!rules.recieveAmount ? 'text-brandRed' : 'text-dark']">{{` Minimum amount is ${beneficiary.receiveCurrency}1000`}}</p>
        <CurrencyInput required infoTooltip="You can set your payment to trigger at a specific rate. <br/> If your custom rate is not reached within 72hrs, you'll need <br/> to set a new rate or the payment will be <br/> automatically declined." :desc="`Your target rate can only be between ₦${scheduledRateIsValid.lowerLimit} - ₦${scheduledRateIsValid.upperLimit}`" errorText="" :validation="scheduledRateIsValid.valid" v-model="scheduledRate" label="Target Rate" width="w-full"  class="mt-4" v-if="selectedPaymentType === 'Custom rate'"/>
       
        <CurrencyInput disabled :value="nairaEquivalent"  width="w-full"  class="mt-4" label="Debit amount"/>
        <inline-toast :display="showErrorToast()" type="error" :showClose="false" :description="'You do not have enough funds in your wallet to complete this transaction. Please, top up your wallet'" class="mt-3"/>
        <!-- <Input label="Purpose of payment" required p="p-2" class="mt-4"  placeholder="Enter purpose of payment" type="text" v-model="beneficiary.purposeOfPayment"/> -->
        <div class="mt-4">
          <label>Payment description<span class="text-brandPurple">*</span></label>
        <textarea v-model="beneficiary.purposeOfPayment" placeholder="Enter purpose of payment" class=" purpose-textarea"/>
        <Select :options="userData.beneficiary.countrySpecificData.purposeOfPaymentCodeDetails" v-if="userData.beneficiary.countrySpecificData.purposeOfPaymentCodeDetails" required class="mt-4" formLabel="Purpose of payment code" width="w-full" :reduce="(category) => category.code" label="description" v-model="beneficiary.purposeOfPaymentCode"/>
        </div>
        <Upload :multiple="true" :maxUploadNumber="3" v-model="beneficiary.invoiceMediaId" class="mt-4" required label="Upload Invoice" placeholder="Upload invoice"  :fileType="['pdf','jpeg','jpg', 'png']" desc="Upload 1-3 documents of your payment invoice (PDF, PNG, or JPEG) - max 5MB each"/>
        <!-- <DateInput disabled v-model="scheduledDate" class="mt-4" label="Payment date" required v-if="selectedPaymentType === 'Send now'"/> -->
        <!-- <inline-toast :showClose="false" class="mt-3" :display="!!paymentProcessingPausedUntil" :description="`Your payment will be processed on the next working day, which is ${dayjs(paymentProcessingPausedUntil).format('dddd, MMMM D')}, as today is a bank holiday in United States of America.`" type="info"/> -->
        <Button text="Send money" class="mt-4" @click="handleNextStep" :disabled="disableButton || showErrorToast()"/>
    </div>
</template>
<script>
import {mapState,mapActions} from 'vuex'
import {Input, Select, Upload, TelInput, CurrencyInput, DateInput} from '@/UI/Input'
import {Button} from '@/UI/Button'
import {InlineToast} from '@/UI/Notification'
import Spinner from '@/UI/Spinner'
import Tab from '@/UI/Tab/Tab'
import dayjs from 'dayjs'
import {CALCULATE_PAYMENT_FEE} from '@/utils/api/wire'

export default {
    components:{
        Input,
        Select,
        Upload,
        Button,
        TelInput,
        InlineToast,
        CurrencyInput,
        Spinner,
        Tab,
        DateInput
    },
    props:{
      userData:{
        type: Object,
        default:() => {},
        required: false
      },
      wireAccountData:{
            type: Object,
            default:() => {}
      }
    },
    data:() => ({
      currencySigns:{},
      showAmountError:false,
      beneficiary: {
            purposeOfPayment:'',
            invoiceMediaId:'',
            receiveAmount:0,
            receiveCurrency : '',
            fee:{
                currency:'NGN',
                fee: 0
            },
            timer: null,
            minimumAmount: 1000,
            purposeOfPaymentCode:''
        },
        scheduledDate:new Date(),
        scheduledRate:0,
        isPhoneNumberValid: false,
        feesLoading: false,
      paymentMethods:['Send now','Custom rate'],
      selectedPaymentType:'Send now',

    }),
    computed:{
        ...mapState({
          currencies: (state) => state?.wire?.currencyMappings,
            // walletBalance:(state) => state?.dashboard.dashboardData?.wallet,
            // paymentProcessingPausedUntil:(state) => state?.dashboard?.dashboardData?.paymentProcessingPausedUntil
        }),
        rules(){
            return {
            
                purposeOfPayment: !!this.beneficiary.purposeOfPayment.length,
                invoice: !!this.beneficiary.invoiceMediaId.length,
                // recieveAmount: $formatCurrencyStringToInt(this.beneficiary.receiveAmount) >= 1000,
                recieveAmount: this.beneficiary.receiveAmount >= 1000,
                purposeOfPaymentCode: this.userData.beneficiary.countrySpecificData.purposeOfPaymentCodeDetails ? this.beneficiary.purposeOfPaymentCode.length : true
            }
        },
        disableButton() {
          if(this.selectedPaymentType === 'Custom rate') return (Object.values(this.rules).includes(false) || !this.scheduledRateIsValid.valid)
                return Object.values(this.rules).includes(false) ;
        },
        exchangeRate(){
          // if(this.selectedPaymentType === 'Custom rate'){
          //   return this.scheduledRate
          // }else{
            let rate = ''
            this.currencies.forEach((item) => {
                if(item.sourceCurrencyCode.code === 'NGN' && item.destinationCurrencyCode.code === this.beneficiary.receiveCurrency){
                    rate = item.exchangeRate
                }
            })
            return rate
          // }
        },
        scheduledRateIsValid(){
          let limit = (20 / 100) * this.exchangeRate;
          const upperLimit = this.exchangeRate + limit;
          const lowerLimit = this.exchangeRate - limit;
          return {
            upperLimit: Math.round(upperLimit),
            lowerLimit: Math.round(lowerLimit, 2),
            valid: this.scheduledRate <= upperLimit && this.scheduledRate >= lowerLimit
          } 
        },
        nairaEquivalent(){
          // parseFloat(this.beneficiary.receiveAmount.toString().replaceAll(',', ""))
          // return isNaN($formatCurrencyStringToInt(this.beneficiary.receiveAmount)) ? 0 : new Intl.NumberFormat().format(($formatCurrencyStringToInt(this.beneficiary.receiveAmount) * this.exchangeRate) + this.beneficiary.fee.fee)
          const exchangeRateToUse = this.selectedPaymentType === 'Custom rate' ? this.scheduledRate : this.exchangeRate
          return (this.beneficiary.receiveAmount * exchangeRateToUse) + this.beneficiary.fee.fee
        },
        paymentScheduleType(){
          return this.selectedPaymentType === 'Send now'? '' :'RATE'
        },
        dayjs(){
          return dayjs
        }
    },
    methods:{
      // ...mapActions('payments', ['getFees']),

        handleNextStep(){
            this.$emit('nextStep',{
              data:{
                beneficiary: this.beneficiary,
                scheduleData:{
                  wirePaymentScheduleType: this.paymentScheduleType,
                  scheduledRate : this.scheduledRate,
                  scheduledDate: ''
                }
              },
              // data: this.beneficiary,
              step:'summary'
            })
        },
        handleUpload(e) {
            this.beneficiary.invoiceMediaId = e.id;
        },
        isPhoneValid(value) {
            this.isPhoneNumberValid = value;
        },
        getDestinationOptions(){
            let destinationSource = [];
            this.currencies.forEach((item) => {
            if(!destinationSource.some((currency) => currency.code === item.destinationCurrencyCode.code)){
                destinationSource.push(item.destinationCurrencyCode)
            }
            })
            return destinationSource
      },
      getCurrencySymbol(){
        this.currencies.forEach((item) => {
          if(!this.currencySigns[item.destinationCurrencyCode.code] ){
            this.currencySigns[item.destinationCurrencyCode.code] = item.destinationCurrencyCode.sign
          }

          if(!this.currencySigns[item.sourceCurrencyCode.code]){
            this.currencySigns[item.sourceCurrencyCode.code] = item.sourceCurrencyCode.sign
          }
        })
      },
      handleCurrencyToSelect(value){
        this.beneficiary.receiveCurrency = value;
        this.$emit('destinationCurrency', value)
      },
      handleSelectCurrency ($event){
        this.beneficiary.receiveCurrency = $event;
        if(this.beneficiary.receiveAmount > 0){
        const functionToCall = this.handleGetFees
          this.debounce(functionToCall, 500)
        }
      },
      handleGetFees(){
          if(this.currencies.length){
            this.feesLoading = true
            let data = {
              receiveCurrency: this.beneficiary.receiveCurrency,
                amount: this.beneficiary.receiveAmount,
                wireAccountId: this.wireAccountData.wireAccount.id
            }
            if(this.selectedPaymentType === 'RATE') data.wirePaymentScheduleType = this.scheduledRate;
            CALCULATE_PAYMENT_FEE(data).then(({data}) => {
                this.feesLoading = false;
                this.beneficiary.fee = data.data.data
              }).catch(() => {
                this.feesLoading = false;
              })
          }
      },
      debounce(func, time){
          if (this.timer) {
            clearTimeout(this.timer); // clear any pre-existing timer
          }
          // const context = this; // get the current context
          this.timer = setTimeout(() => {
            func(); // call the function if time expires
          }, time);
        },
        showErrorToast(){
            // if(this.beneficiary.receiveAmount < 1000){
            //     return true
            // }else{
            // let rate = ''
            // if(this.currencies.forEach((item) => {
            //     if(item.destinationCurrencyCode.code === this.beneficiary.receiveCurrency){
            //     rate = item.exchangeRate
            //     }
            // }));
                const rate =  this.paymentScheduleType === 'RATE' ? this.scheduledRate : this.exchangeRate
            
                let currentAmount = (this.beneficiary.receiveAmount * rate) + this.beneficiary.fee.fee
                    if(currentAmount > this.wireAccountData.wallet.balance ){
                    return true
                }else{
                    return false
                }
            // }
        },
         handleCurrencyInput(){
            const functionToCall = this.handleGetFees
            this.debounce(functionToCall, 300)
        },
        handleFocusOut(){
          if(!this.rules.recieveAmount){
            this.showAmountError = true
          }
        },
        handleTabChange(){
          if(this.selectedPaymentType == 'Send now'){
            this.scheduledRate = ''
          }
        }
    },
    mounted(){
        this.handleCurrencyToSelect('USD')
        this.getCurrencySymbol()
    }
}
</script>
<style scoped>
.purpose-textarea{
  border: 1px solid #ededf0;
  color: #706d78 !important;
  border-radius: 8px;
  font-size: 18px;
  background-color: transparent;
  position: relative;
  z-index: 2;
  -webkit-appearance: none;
  background: #fcfcfd;
  transition: all 0.3s linear;
  border-radius: 8px;
  width: 100%;
  padding: 1rem;
  outline: none;
  resize: none;
}

label {
  color: #202939;
  font-weight: 400;
  font-size: 14px;
  display: block;
  margin-bottom: 8px;
}
</style>