<template>
    <div class="purchase-container">
        <Card class="h-full">
            <div class="flex items-center justify-between">
                <h5 class="text-lg font-semibold">Wire Payments</h5>
            </div>
            <Button class="my-2 md:hidden" :loading="updateLoading" text="Filters" width="w-20" @click="isFilterVisible = !isFilterVisible" />

            <div 
                class=" md:flex justify-between items-center py-4"
                v-if="isFilterVisible || isMediumScreen">
                <Hr />
                <form class="flex items-center flex-wrap gap-2" @submit.prevent="handleSearch">
                    <Input placeholder="Wire account name" p="p-2" v-model="params.wireAccountNameSearchQuery"/>
                    <Input placeholder="Ben Acc. No" p="p-2" type="number" v-model="params.beneficiaryAccountNumber"/>
                    <Input placeholder="Wire acc id" p="p-2" v-model="params.wireAccountId"/>
                    <Input placeholder="Wire pay. id" p="p-2" v-model="params.wirePaymentId"/>
                    <Input placeholder="Wire pay. id" p="p-2" v-model="params.intlBankIds"/>
                    <Input placeholder="Wire acc email" p="p-2" v-model="params.wireAccountEmail"/>
                    <Button :outline="!params.getOnlyScheduled " @click="params.getOnlyScheduled  = !params.getOnlyScheduled " text="Scheduled only" class="ml-2" type="button"/>
                    <v-select class="v-select-payment" :options="paymentStatusList" v-model="params.statuses" multiple  placeholder="status" width="w-44" p="p-2"/>
                    <v-select class="v-select-payment" multiple :options="currencyList" label="code" :reduce="(item) => item.code"  placeholder="Dest. currencies" width="w-44" p="p-2" v-model="params.destinationCurrencies" @input="handleInput"/>
                    <v-select class="v-select-payment" multiple :options="currencyList" label="code" :reduce="(item) => item.code"  placeholder="Source. currencies" width="w-44" p="p-2" v-model="params.sourceCurrencies"/>
                    <DatePicker class="mr-4" v-model="range" is-range :model-config="modelConfig" :masks="masks">
                        <template v-slot="{ inputValue, togglePopover }">
                            <Card class="flex items-center border searchContainer w-full" p="px-1.5 py-1">
                                <button class="p-2 focus:outline-none" @click="togglePopover()" type="button">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
                                        <path
                                            d="M11.09 2h-.272v-.25c0-.425-.354-.75-.818-.75-.464 0-.818.325-.818.75V2H4.818v-.25C4.818 1.325 4.464 1 4 1c-.464 0-.818.325-.818.75V2h-.273C1.845 2 1 2.775 1 3.75v7c0 .975.845 1.75 1.91 1.75h8.18c1.065 0 1.91-.775 1.91-1.75v-7C13 2.775 12.155 2 11.09 2Zm-1.363-.25c0-.15.11-.25.273-.25.164 0 .273.1.273.25v1c0 .15-.11.25-.273.25-.164 0-.273-.1-.273-.25v-1ZM4 1.5c.164 0 .273.1.273.25v1c0 .15-.11.25-.273.25-.164 0-.273-.1-.273-.25v-1c0-.15.11-.25.273-.25Zm-1.09 1h.272v.25c0 .425.354.75.818.75.464 0 .818-.325.818-.75V2.5h4.364v.25c0 .425.354.75.818.75.464 0 .818-.325.818-.75V2.5h.273c.764 0 1.364.55 1.364 1.25v.75H1.544v-.75c0-.7.6-1.25 1.364-1.25Zm9.544 8.25c0 .7-.6 1.25-1.363 1.25H2.909c-.764 0-1.364-.55-1.364-1.25V5h10.91v5.75Z"
                                            fill="#9D9D9D" stroke="#9D9D9D" stroke-width=".1" stroke-linejoin="round" />
                                        <path
                                            d="M3.727 7h6.546c.163 0 .273-.1.273-.25s-.11-.25-.273-.25H3.727c-.163 0-.272.1-.272.25s.109.25.272.25ZM3.727 8.75h6.546c.163 0 .273-.1.273-.25s-.11-.25-.273-.25H3.727c-.163 0-.272.1-.272.25s.109.25.272.25ZM3.727 10.5h6.546c.163 0 .273-.1.273-.25s-.11-.25-.273-.25H3.727c-.163 0-.272.1-.272.25s.109.25.272.25Z"
                                            fill="#9D9D9D" stroke="#9D9D9D" stroke-width=".1" stroke-linejoin="round" />
                                    </svg>
                                </button>
                                <p v-if="inputValue.start === null" @click="togglePopover()" class="text-grey">
                                    Date filter
                                </p>
                                <p v-else @click="togglePopover()" class="text-sm text-grey">
                                    {{ inputValue.start }} - {{ inputValue.end }}
                                </p>
                            </Card>
                        </template>
                    </DatePicker>
                    <Button text="Search" p="p-2" type="submit" />
                    <Button outline text="Clear filter" @click="clearSearch" p="p-2" type="button"/>
                </form>
            </div>
            <div class="flex justify-center" v-if="loading">
                <Spinner />
            </div>
            <div class="table-container overflow-x-auto" v-else>
                <table id="template-table" class="w-full table-auto min-w-[600px]">
                    <thead>
                        <tr>
                            <th class="text-xs px-2 py-1">S/N</th>
                            <th class="text-xs px-2 py-1">Name</th>
                            <th class="text-xs px-2 py-1">Id</th>
                            <th class="text-xs px-2 py-1">Beneficiary</th>
                            <th class="text-xs px-2 py-1">Scheduled</th>
                            <th class="text-xs px-2 py-1">Schedule type</th>
                            <th class="text-xs px-2 py-1">Send Amount</th>
                            <th class="text-xs px-2 py-1">Receive Amount</th>
                            <th class="text-xs px-2 py-1">Status</th>
                            <th class="text-xs px-2 py-1">Date</th>
                            <th class="text-xs px-2 py-1">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <EmptyState class="mx-auto w-full my-10" description="No records found" v-if="payments.length === 0" />
                        <tr v-for="(payment, index) in payments" :key="payment.id + index + 'payment'">
                            <td class="text-xs px-2 py-1">{{ index + 1 }}</td>
                            <td class="break-all text-xs px-2 py-1">{{ payment.wireAccountName }}</td>
                            <td class="overflow-ellipsis text-xs px-2 py-1">{{ payment.id }}</td>
                            <td class="overflow-ellipsis text-xs px-2 py-1">{{ payment.beneficiaryName }}</td>
                            <td class="break-all text-xs px-2 py-1">{{ !!payment.wirePaymentScheduleType }}</td>
                            <td class="break-all text-xs px-2 py-1">{{ payment.wirePaymentScheduleType || 'N/A' }}</td>
                            <td class="text-xs px-2 py-1">{{ payment.sendCurrency }}{{ payment.sendAmount | formatMoney('') }}</td>
                            <td class="text-xs px-2 py-1">{{ payment.receiveCurrency }}{{ payment.receiveAmount | formatMoney('') }}</td>
                            <td class="text-xs px-2 py-1">{{ payment.wirePaymentStatus }}</td>
                            <td class="text-xs px-2 py-1">{{ dayjs(payment.createdAt).format("MMM D YYYY | hh:mmA") }}</td>
                            <td class="flex items-center text-xs px-2 py-1">
                                <SecondaryButton class="ml-1" text="View" @click="$router.push(`/payments/${payment.id}`)" />
                            </td>
                        </tr>
                    </tbody>
                </table>
                
                <Pagination v-if="showPagination" 
                    :meta="pageMeta" :handleMore="handleMore" 
                    :loading="paginateLoading" @nextPage="handlePagination" 
                />
                
            </div>
            <Modal :display="showSelectedPayment" @close="closeUpdateModal">
                <p class="text-lg font-semibold">Update payment status</p>
                <Select zIndex="200" v-model="paymentStatus" class="mt-6" :options="paymentStatusToShow" />
                <Input v-model="failureReason" class="mt-6" width="w-full" placeholder="Failure reason..."
                    v-if="paymentStatus === 'DECLINED'" />
                <Input v-model="serviceProviderDetails.trackingCode" class="mt-4 w-full" placeholder="Tracking code" width="w-full" v-if="paymentStatus == 'COMPLETED' && paymentStatus !== ''"/>
                <Select :options="trackingCodeType" :reduce="(value) => value.value" class="mt-4" width="w-full" placeholder="Tracking code type" v-model="serviceProviderDetails.trackingCodeType" v-if="paymentStatus == 'COMPLETED' && paymentStatus !== ''"/>
                <Input class="mt-4" width="w-full" placeholder="Processing channel" v-model="serviceProviderDetails.processingChannel" v-if="paymentStatus == 'COMPLETED' && paymentStatus !== ''"/>
                <CurrencyInput required label="Transaction cost in send currency" placeholder="Transaction cost in send currency" class="mt-4 w-full"  width="w-full" v-model="transactionCostInSendCurrency" v-if="paymentStatus == 'COMPLETED'"/>
                <Input class="mt-4" width="w-full" placeholder="Service provider reference" v-model="serviceProviderDetails.serviceProviderReference" v-if="paymentStatus == 'COMPLETED' && paymentStatus !== ''"/>
                <Button class="mt-12" :loading="updateLoading" text="Update" width="w-full" @click="handleUpdate" />
            </Modal>
            <Modal :display="showpaymentDetails" @close="showpaymentDetails = false">
                <p class=" text-lg font-semibold">Business Info</p>
                <p class="mt-2">Name: {{selectedPayment.wireAccountName}}</p>

                <p class="text-lg font-semibold">Payment details</p>
                <p class="mt-2">ID: {{selectedPayment.id }}</p>
                <p class="mt-2">Purpose: {{selectedPayment.purposeOfPayment}}</p>
                <p class="mt-2">Status: {{selectedPayment.wirePaymentStatus}}</p>
                <p class="mt-2">Send Amount:{{ selectedPayment.sendCurrency }}{{selectedPayment.sendAmount | formatMoney('') }}</p>
                <p class="mt-2">Recieve amount: {{selectedPayment.receiveCurrency}}{{selectedPayment.receiveAmount | formatMoney('') }}</p>
                <p class="mt-2">Fee: N{{selectedPayment.fee}}</p>
                <p class="mt-2" v-if="selectedPayment.failureReason">Failure reason: {{selectedPayment.failureReason}}</p>
                <p class="text-lg font-semibold mt-4">Beneficiary</p>
                <p class="mt-2">Name: {{selectedPayment.beneficiaryName}}</p>
                <p class="mt-2">Email: {{selectedPayment.beneficiaryEmail}}</p>
                <p class="mt-2">Account No.: {{selectedPayment.beneficiaryAccountNumber}}</p>
                <p class="mt-2">Phone No.: {{selectedPayment.beneficiaryPhoneNumber}}</p>
                <p class="mt-2">Intl. bank Id: {{selectedPayment.beneficiaryIntlBankId}}</p>
                <p class="mt-2">Address: {{selectedPayment.beneficiaryAddress }}</p>


                <p class="text-lg font-semibold mt-4">Service Provider</p>
                <p class="mt-2" v-if="selectedPayment.serviceProviderDetails">Processing Channel: {{serviceProvider.processingChannel || 'N/A'}}</p>
                <p class="mt-2" v-if="selectedPayment.serviceProviderDetails">Tracking Code: {{serviceProvider.trackingCode || 'N/A'}}</p>
                <p class="mt-2" v-if="selectedPayment.serviceProviderDetails">Tracking code type: {{serviceProvider.trackingCodeType || 'N/A'}}</p>
                <p class="mt-2 flex items-center">Invoices: <SecondaryButton text="View" @click="downloadItem(payment)" class="ml-2" v-for="payment in paymentList" :key="payment"/></p>
                <p class="mt-2 flex items-center">Invoices: </p>
                <div class="flex items-center flex-wrap gap-4">
                    <SecondaryButton text="View" @click="downloadItem(payment)" class="ml-2" v-for="payment in paymentList" :key="payment"/>
                </div>

            </Modal>
        </Card>
    </div>
</template>
<script>
import { GET_ALL_PAYMENTS, UPDATE_WIRE_PAYMENT, UPDATE_WIRE_PAYMENT_COMPLETE, UPDATE_WIRE_PAYMENT_DEClINED, UPDATE_WIRE_PAYMENT_SCHEDULE, UPDATE_WIRE_PAYMENT_PROCESSING } from '@/utils/api/wire'
import Spinner from '@/UI/Spinner'
import dayjs from 'dayjs'
import { SecondaryButton, Button } from '@/UI/Button'
import { Modal } from '@/UI/Modals'
import { Select, Input, CurrencyInput } from '@/UI/Input'
import Hr from "@/UI/Hr";
import { DatePicker } from "v-calendar";
import Pagination from "@/UI/Pagination";
import file from '@/mixins/file'
import {mapState, mapActions} from 'vuex'
import { VueSelect } from "vue-select";
export default {
    mixins:[file],
    components: {
        Spinner,
        SecondaryButton,
        Modal,
        Select,
        Button,
        Input,
        Hr,
        DatePicker,
        Pagination,
        EmptyState: () => import("@/UI/EmptyState"),
        "v-select": VueSelect,
        CurrencyInput
    },
    data: () => ({
        loading: false,
        payments: [],
        selectedPayment: {},
        showSelectedPayment: false,
        updateLoading: false,
        isFilterVisible: false,
        windowWidth: window.innerWidth,
        paymentStatus: '',
        failureReason: "",
        transactionCostInSendCurrency:0,
        paymentStatusList: ['DECLINED', 'COMPLETED', 'PROCESSING', 'IN_REVIEW', 'SCHEDULED'],
        // trackingCodeType :['UETR', 'IMAD', 'OMAD'],
        trackingCodeType :[{label: 'UETR', value : 'UETR'}, {label :'IMAD', value:'IMAD'}, {label:'OMAD', value:'OMAD'}, {label:'NONE', value: null}],
        paginateLoading: false,
        handleMore: false,
        params: {
            page: 0,
            pageSize: 20,
            from: null,
            to: null,
            destinationCurrencies:null,
            statuses:null,
            wireAccountEmail:null,
            wireAccountId:null,
            wirePaymentId:null,
            beneficiaryAccountNumber:null,
            wireAccountNameSearchQuery:null,
            intlBankIds:null,
            sourceCurrencies: null,
            getOnlyScheduled : false
        },
        searchUrl:{},
        modelConfig: {
            type: "string",
            mask: "iso",
            start: {
                timeAdjust: "00:00:00",
            },
            end: {
                timeAdjust: "23:59:59",
            },
        },
        range: {
            start: "",
            end: "",
        },
        masks: {
            input: "DD MMM YY",
        },
        serviceProviderDetails:{
            processingChannel:null,
            serviceProviderReference: null,
            trackingCode:null,
            trackingCodeType:null
        },
        showpaymentDetails: false,
        showFilters: false,
        pageMeta:{},
        showPagination: false
    }),
    computed: {
        ...mapState({
            currencyList:(state) => state?.wire?.currencyList
        }),
        dayjs() {
            return dayjs
        },
        paymentList(){
            if(Object.keys(this.selectedPayment).length){
                return JSON.parse(this.selectedPayment.invoiceMediaId)
            }
        },
        serviceProvider(){
            if(Object.keys(this.selectedPayment).length){
                return JSON.parse(this.selectedPayment.serviceProviderDetails)
            }
        },
        paymentStatusToShow(){
            if(this.selectedPayment.wirePaymentStatus === 'IN_REVIEW'){
                return ['SCHEDULED', 'PROCESSING', 'DECLINED']
            }

            if(this.selectedPayment.wirePaymentStatus === 'SCHEDULED'){
                return ['PROCESSING', 'COMPLETED', 'DECLINED']
            }

            if(this.selectedPayment.wirePaymentStatus === 'PROCESSING'){
                return ['COMPLETED', 'DECLINED']
            }
        },
        isMediumScreen() {
            // Returns true if screen width is 768px or larger (md breakpoint)
            return this.windowWidth >= 768;
        }
    },
    methods: {
        ...mapActions('notification', ['showAlert']),
        handleGetAllPayments(type) {
            type === "paginate" ? (this.paginateLoading = true) : (this.loading = true);
            let initialData = Object.assign({}, this.params)
            let url = '/bnpl/v1/wire-payment?'
            Object.keys(this.params).forEach((param) => {
                // delete initialData[param]
                if(['sourceCurrencies', 'destinationCurrencies', 'statuses'].includes(param)){
                   if(this.params[param] && this.params[param].length) url += `${param}=${this.params[param].join(`&${param}=`)}&`
                }else{
                    if(this.params[param]){
                        url += `${param}=${this.params[param]}&`
                    }
                }

            })
            if(url[url.length - 1] == '&'){
               url = url.slice(0, url.length - 1);
            }
            if(!this.params.getOnlyScheduled) url +=`&getOnlyScheduled=false`

            this.searchUrl = url
            GET_ALL_PAYMENTS(url).then(({ data }) => {
                this.pageMeta = data.meta
                // if (data.data.length == this.params.pageSize) {
                //     // console.log(type);
                //     this.handleMore = true;
                // } else {
                //     this.handleMore = false;
                // }
                this.showPagination = data.data.length === this.params.pageSize ? true : false;
                type === "paginate" ? (this.paginateLoading = false) : (this.loading = false);
                type === "paginate" ? this.payments.push(...data.data) : this.payments = data.data;

            }).catch(() => {
                type === "paginate" ? (this.paginateLoading = false) : (this.loading = false);
            })
        },
        handleShowUpdate(payment) {
            this.selectedPayment = payment;
            // this.paymentStatus = payment.wirePaymentStatus
            this.showSelectedPayment = true
        },
        handleUpdate() {
            if((this.paymentStatus === 'COMPLETED' && !this.transactionCostInSendCurrency) || (this.transactionCostInSendCurrency >  this.selectedPayment.sendAmount)  )   return this.showAlert({
                display: true,
                title:'Error',
                description:'Enter a valid transaction cost in send value',
                type:'error'
            })

            this.updateLoading = true;
            const payload = {
                id: this.selectedPayment.id,
                wirePaymentStatus: this.paymentStatus,
                failureReason: this.failureReason,
                serviceProviderDetails: this.serviceProviderDetails
            }
            if (this.paymentStatus == "DECLINED") {
                delete payload.wirePaymentStatus;
                delete payload.serviceProviderDetails;
                UPDATE_WIRE_PAYMENT_DEClINED(payload)
                .then(() => {
                    this.updateLoading = false;
                    this.showSelectedPayment = false;
                    this.handleGetAllPayments();
                }).catch(() => {
                    this.updateLoading = false
                })
                return
            }
            if(this.paymentStatus == 'COMPLETED'){
                payload.transactionCostInSendCurrency = this.transactionCostInSendCurrency
                UPDATE_WIRE_PAYMENT_COMPLETE(payload)
                .then(() => {
                    this.updateLoading = false;
                    this.showSelectedPayment = false;
                    this.handleGetAllPayments();
                }).catch(() => {
                    this.updateLoading = false
                })
            }


            if(this.paymentStatus === 'SCHEDULED'){
                UPDATE_WIRE_PAYMENT_SCHEDULE(this.selectedPayment.id)
                .then(() => {
                    this.updateLoading = false;
                    this.showSelectedPayment = false;
                    this.handleGetAllPayments();
                }).catch(() => {
                    this.updateLoading = false
                })
            }

            if(this.paymentStatus === 'PROCESSING'){
                let data = {
                    id: this.selectedPayment.id,
                    isRateScheduledPayment: this.selectedPayment.wirePaymentScheduleType === 'RATE'
                }
                UPDATE_WIRE_PAYMENT_PROCESSING(data)
                .then(() => {
                    this.updateLoading = false;
                    this.showSelectedPayment = false;
                    this.handleGetAllPayments();
                }).catch(() => {
                    this.updateLoading = false
                })
            }
        },
        handlePagination() {
            this.paginateLoading = true;
            this.params.page++;
            this.$router.replace({
              ...this.$router.currentRoute,
              query: {
                data: JSON.stringify(this.params),
              },
            });
            this.handleGetAllPayments("paginate");
        },
        closeUpdateModal() {
            this.showSelectedPayment = false;
            this.failureReason = "";
            this.paymentStatus = "";
        },
        clearSearch() {
            this.params = {
                page: 0,
                pageSize: 20,
                from: null,
                to: null,
                destinationCurrencies:null,
                statuses:null,
                wireAccountEmail:null,
                wireAccountId:null,
                wirePaymentId:null,
                beneficiaryAccountNumber:null,
                wireAccountNameSearchQuery:null,
                intlBankIds:null,
                sourceCurrencies: null
            };
            this.range = {
                start: "",
                end: "",
            };
          
            this.$router.replace({
            ...this.$router.currentRoute,
            query: {},
            });
            this.handleGetAllPayments();
        },
        handleSearch() {
            this.params.page = 0;
            this.params.from = this.range.start ? this.dayjs(this.range.start).toISOString() : null;
            this.params.to = this.range.end ?  this.dayjs(this.range.end).toISOString() : null;
            let data = {...this.params}
            Object.keys(this.params).forEach((key) => {
                if(['sourceCurrencies', 'destinationCurrencies', 'statuses'].includes(key)){
                    data[key] = JSON.stringify(this.params[key])
                }
            })
            this.$router.replace({
            ...this.$router.currentRoute,
                query: {
                    ...data
                },
            }).catch(() => {});
            this.handleGetAllPayments();
        },
        handleViewDetails(payment){
            this.selectedPayment = payment;
            this.showpaymentDetails = true
        },
        downloadItem(id) {
            window.open(
            `${process.env.VUE_APP_BASE_URL}/media/image/${id}`,
            "_blank" // open in a new window.
            );
      },
      handleInput(e){
        console.log(e)
      },
      handleResize() {
            this.windowWidth = window.innerWidth;
        }
    },
    mounted() {
        if(Object.keys(this.$route.query).length){

            let data = {...this.$route.query}
            Object.keys(this.params).forEach((key) => {
                if(['sourceCurrencies', 'destinationCurrencies', 'statuses'].includes(key)){
                    data[key] = JSON.parse(this.$route.query[key])
                }
            })
            this.params = data
        }
        this.handleGetAllPayments()
        window.addEventListener('resize', this.handleResize);

    },
    beforeDestroy() {
        // Remove event listener when component is destroyed
        window.removeEventListener('resize', this.handleResize);
    },
}

</script>
<style src="vue-select/dist/vue-select.css"></style>
<style scoped>
.purchase-container {
    min-height: calc(100vh - 10rem);
}
.searchContainer {
    border: 1px solid rgba(138, 116, 192, 0.1) !important;
    width: 12rem;
}
</style>
<style>
.v-select-payment{
    width: 12rem
}

.v-select-payment .vs__dropdown-toggle{
    padding: 0.6rem
}
</style>