<template>
    <div class="mt-6">
         <!-- <div v-if="showComplete"> -->
             <h2 class="font-semibold w-full  text-lg mb-4 px-4">Director's details</h2>
             <div class="w-full flex flex-col gap-4 px-4">
                 <div v-for="(director, index) in directors" :key="index" class="form-output bg-white rounded flex justify-between items-center p-2 w-full">
                     <div class="input outcomes">
                         <h1 class="font-semibold">{{ director.firstName }} {{ director.lastName }}</h1>
                         <p class="text-sm">{{ director.jobTitleOrRole }}</p>
                     </div>
                     <div>
                         <Button @click="() => $emit('edit', index)" outline text="Edit" p="p-2 px-4" fontSize="text-xs" />
                     </div>
                 </div>
                 <div class="gap-3 mt-4 continue-Btn w-full flex justify-end items-center mb-4  p-2 text-right h-20">
                     <Button @click="$emit('add')" outline text="Add New Director" />
                     <Button type="submit"  text="Submit" @click="next"/>
                 </div>
             </div>
         <!-- </div> -->
         <!-- <div v-else>
             <Complete  />
         </div> -->
   </div>
 </template>
 
 <script>
 import { Button } from '@/UI/Button';
 import Complete from './Complete.vue';
 export default {
     components:{
         Button,
         Complete
     },
     data(){
         return{
             editDirectorDetail:false,
             showComplete:true
         }
     },
     props:{
         directors:{
             type:Array,
             required: true
         }
     },
     computed:{
         wireAccountId() {
             return this.wireAcctData && this.wireAcctData.wireAccount
                 ? this.wireAcctData.wireAccount.id
                 : null;
         }
     },
     methods: {
        next() {
            this.$emit('next');
        },
    }
 }
 </script>
 
 <style scoped>
     .add-new-Btn{
         background: #F8FAFC;
         color: #202939;
         border: 1px solid #E3E8EF;
     }
     .cancel{
         background: #EEF2F6;
         color: #697586;
     }
     h2{
         color: #202939;
         font-size: 29px;
         font-weight: 400;
         line-height: 34.8px;
         text-align: left;
     }
     input, select{
         background: #E3E8EF;
         color:#9AA4B2;
     }
     .opt{
         color: #697586;
     }
     .editBtn{
         padding: 11px 8px ;
         gap: 4px;
         border-radius: 4px;
         border: 1px ;
         background:  #FCFCFD;
         border: 1px solid  #E3E8EF;
         font-size: 12px;
         font-weight: 400;
         line-height: 14.4px;
         text-align: center;
         color: #202939;
         min-width: 52px;
     }
     .form-output{
         background: #F8FAFC;
         border: 1px solid #E3E8EF;
     }
     .continue-Btn{
        background:#FCFCFD;
        border-top: 1px solid #E3E8EF;
    }
     .direct-details{
         border-bottom: 1px solid #E3E8EF
     }
     .create-acct{
         color: #202939;
     }
     .business-type{
         background: #E3E8EF;
     }
 </style>