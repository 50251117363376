export default {
  toast: {
    description: "",
    display: false,
    type: "",
  },
  modal: {
    description: "",
    display: false,
    type: "",
    title: "",
    callback: null,
    buttonText: "",
  },
};
