<template>
  <div class="login-container h-full grid place-items-center w-10/12 mx-auto">
    <div class="w-full">
      <img
        class="max-h-8 mx-auto mb-6 max-w-full mt-4 md:mt-0"
        src="https://res.cloudinary.com/zillaafrica/image/upload/v1623528889/customer/Zilla_Updated_1_rjqezm.svg"
        alt=""
      />
      <Card
        class="w-full border rounded-lg bg-white border-lightPurple p-8 shadow"
      >
        <h4 class="font-bold md:text-xl text-xl text-center">Admin Login</h4>
        <form @submit.prevent="loginUser">
          <Input
            type="text"
            class="mt-14"
            placeholder="What's your mail?"
            v-model="user.email"
            width="w-full"
            id="username"
            errorText="Please enter a valid email address"
            :validation="rules.email"
            @valid="valid.email = $event"
          />
          <Input
            class="mt-11"
            type="password"
            placeholder="What's your password"
            revealPassword
            v-model="user.password"
            width="w-full"
            id="password"
            errorText="Password field cannot be empty"
            :validation="rules.password"
            @valid="valid.password = $event"
          />
          <div></div>
          <Button
            type="submit"
            text="Login"
            width="w-full mt-12"
            :loading="loading"
            :disabled="disableButton"
          />
        </form>
      </Card>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { Input } from "@/UI/Input";
import { Button } from "@/UI/Button";
export default {
  components: {
    Input,
    Button,
  },
  data() {
    return {
      user: {
        email: "",
        password: "",
      },
      loading: false,
      valid: {
        email: false,
        password: false,
      },
    };
  },
  computed: {
    rules() {
      return {
        username:
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
            this.user.email
          ),
        secret: this.user.password.length !== 0,
      };
    },
    disableButton() {
      return Object.values(this.rules).includes(false);
    },
  },
  methods: {
    ...mapActions("auth", ["login"]),
    ...mapActions("notification", ["showAlert"]),
    loginUser() {
      this.loading = true;
      this.login(this.user)
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped>
.login-container {
  min-height: 100vh;
  max-width: 30rem;
}

/* @media (max-width: 768px) {
    .login-container {
      min-height: calc(100vh - 15rem);
      max-width: 100%;
    }
  } */
</style>
