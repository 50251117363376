<template>
    <RightSidebar display title="Comissions" @close="$emit('close')">
        <template #body>
            <div class="payment-link-container-body">
                <Tab :options="options" v-model="params.commissionType" @input="getWalletHistory"/>
                <Spinner v-if="historyLoading" class="text-center"/>
                <div v-else>
                    <div class="mt-4 payment-details flex items-center justify-between" >
                        <p class="text-xs text-grey-3 fonts-emibold"> Date</p>
                        <p class="text-xs text-grey-3 font-semibold"> Amount</p>
                        <p class="text-dark text-sm font-semibold">Type</p>
                      </div>
                    <div class="mt-4 payment-details flex items-center justify-between" v-for="data in history" :key="data.id">
                        <p class="text-xs text-grey-3"> {{ dayjs(data.createdAt).format("MMM D YYYY") }}</p>
                        <p class="text-xs text-grey-3"> {{ data.amount | formatMoney }}</p>
                        <p class="text-dark text-sm">{{ data.walletActivityType }}</p>
                      </div>
                </div>
            </div>
        </template>
    </RightSidebar>
</template>
<script>
import Tab from '@/UI/Tab/Tab'
import {RightSidebar} from '@/UI/Modals'
import {GET_BROKER_COMMISION_HISTORY} from '@/utils/api/wire'
import Spinner from '@/UI/Spinner'
import dayjs from 'dayjs'

export default {
    props:{
        accountDetails:{
            type: Object,
            default:() => {}
        }
    },
    components:{
        Tab,
        RightSidebar,
        Spinner
    },
    computed:{
        dayjs(){
            return dayjs
        }
    },
    data:() => ({
        options:[
            'PAYMENT',
            'REFERRAL'
        ],
        currentTab:'PAYMENT',
        historyLoading: false,
        params:{
            to:'',
            from :'',
            commissionType:'PAYMENT',
            page:0,
            pageSize:10
        },
        history:[]
    }),
    methods:{
        getWalletHistory(){
            this.historyLoading = true;

            GET_BROKER_COMMISION_HISTORY({
                wireAccountId: this.accountDetails.wireAccount.id,
                params: this.params
            })
                .then(({data}) => {
                    this.history = data.data
                    this.historyLoading = false
                }).catch(() => {
                    this.historyLoading = false
                })
        }
    },
    mounted(){
        this.getWalletHistory()
    }
}
</script>
<style scoped>
.payment-link-container-body {
    padding: 16px 24px;
  }

.payment-details {
    padding-bottom: 8px;
    border-bottom: 1px solid #E3E8EF;
  }
</style>