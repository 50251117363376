var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"rounded-lg border transition-all focus:outline-none grid place-items-center",class:[
  _vm.outline && 'outline-class',
  _vm.active && 'active-class',
  _vm.background.includes('bg-') ? _vm.background : "primary-button",
  _vm.width,
  _vm.opacity ? _vm.opacity : null,
  _vm.p ? _vm.p : 'py-3 px-4  md:p-3 ',
  _vm.color.includes('text-') ? _vm.color : ("text-" + _vm.color),
  _vm.border ? _vm.border : null,
  _vm.borderColor ? _vm.borderColor : null,
  _vm.fontSize ? _vm.fontSize : 'text-sm',
  _vm.shadow && !_vm.disabled ? 'box-shadow' : null,
  _vm.radius.includes('rounded-') ? _vm.radius : ("rounded-" + _vm.radius),
  _vm.loading && 'pointer-events-none cursor-not-allowed opacity-70' ],style:({ background: _vm.background }),attrs:{"disabled":_vm.disabled,"type":_vm.type},on:{"click":_vm.handleClick}},[_c('transition',{attrs:{"name":"slideUp","mode":"out-in"}},[(_vm.loading)?_c('div',{staticClass:"loader",class:[_vm.outline ? 'outline-loader' : 'main-loader']},[_vm._v("Loading...")]):_c('p',{class:[_vm.fontSize ? _vm.fontSize : 'text-sm', _vm.fontWeight ? _vm.fontWeight : 'font-semibold']},[(_vm.preIcon.length)?_c('i',{staticClass:"mr-2 ",class:_vm.preIcon}):_vm._e(),_vm._v(" "+_vm._s(_vm.text)+" ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }