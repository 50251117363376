<template>
  <div class="relative">
    <div class="relative inline-block" :class="[width]">
      <div class="relative">
        <label :for="id" v-if="label.length">{{ label }}  
          <span class="text-brandPurple" v-if="required">*</span>
           <svg-icon class="ml-1" v-if="infoTooltip" style="display: inline; margin-bottom: 2px;" :size="14"  type="mdi" :path="svgPath.alert" v-tooltip="infoTooltip"></svg-icon> </label>
        <div class="flex align-items-center input-container" :class="[label.length ? 'label-exist-style' : null]">
          <div class="partition">{{ symbol }}</div>
          <input
              class="outline-none transition-all input-regular placeholder-borderGrey pl-3"
              :class="[
                p,
                width,
                disabled ? 'disabled' : null,
                displayValue && !showCurrency ? 'add-symbol' : null,
                showCurrency ? 'show-currency' : displayPlaceholder || (displayValue && !error) ? '' : error ? 'error-border' : null,
              ]"
              @blur="displayInput($event, 'blur')"
              @focus="handleFocus"
              @focusout="displayPlaceholder = false; $emit('focusout', value)"
              :required="required"
              :disabled="disabled"
              :id="id"
              :placeholder="placeholder"
              @keypress="isNumber($event)"
              @keyup="isInputActive = true; $emit('keyup')"
              pattern="[0-9]+([\.,][0-9]+)?"
              inputmode="numeric"
              :maxlength="maxlength"
              :autofocus="autofocus"
              ref="currencyInput"
              v-model="displayValue"
          />
           <Select 
            height="h-full"
            width="w-20" 
            :options="options" 
            :value="optionsValue"
            :label="selectLabel" 
            :reduce="reduce" 
            v-if="showCurrency" 
            class="currency-input-select"
            @input="handleSelectInput"/>

        </div>
        <slot name="desc" v-if="desc.length">
          <p class="text-left text-grey text-xs mt-1">{{ desc }}</p>
        </slot>
      </div>
    </div>

    <div class="error-text">
      <transition name="fade">
        <p class="text-brandRed  text-xs mt-1" v-if="error">
          {{ errorText }}
        </p>
      </transition>
    </div>
  </div>
</template>

<script>
import Select from '../Select'
import { mdiAlertCircleOutline } from "@mdi/js";

  export default {
    components:{
      Select
    },
    props: {
      value: {
        type: [Number, String],
        default: 0,
        required: true,
      },
      symbol: {
        type: String,
        default: "₦",
        required: false,
      },
      id: {
        type: String,
        default: "",
        required: false,
      },
      width: {
        type: String,
        default: "w-auto",
        required: false,
      },
      p: {
        type: String,
        default: "py-2",
        required: false,
      },
      disabled: {
        type: Boolean,
        default: false,
        required: false,
      },
      placeholder: {
        type: String,
        default: "",
        required: false,
      },
      required: {
        type: Boolean,
        default: false,
        required: false,
      },
      validation: {
        type: Boolean,
        default: true,
        required: false,
      },
      showCurrency: {
        type: Boolean,
        default: false,
        required: false,
      },
      errorText: {
        type: String,
        default: "error",
        required: false,
      },
      autoComplete: {
        type: String,
        default: "on",
        required: false,
      },
      maxlength: {
        type: String,
        default: "",
        required: false,
      },
      label: {
        type: String,
        default: "",
        required: false,
      },
      options:{
        type: Array,
        default:() => ['NGN'],
        required: false
      },
      selectLabel:{
        type: String,
        default:'label',
        required: false
      },
      reduce:{
        type: Function
      },
      optionsValue:{
        type: [String, Object],
        default:'',
        required: false
      },
      autofocus:{
        type: Boolean,
        default: false,
        required: false
      },
      desc:{
        type: String,
        default:'',
        required: false
      },
      infoTooltip:{
        type: String,
        default:'',
        required: false
    }
    },
    data() {
      return {
        isInputActive: false,
        error: false,
        displayPlaceholder: false,
        selectedCurrency:'NGN',
        svgPath: {
      alert: mdiAlertCircleOutline,
    },
      };
    },
    computed: {
      showPlaceholder() {
        if (this.displayPlaceholder || this.value) {
          return true;
        } else {
          return false;
        }
      },
      displayValue: {
        get() {
          if (this.value !== 0) {
            // return this.value.toFixed().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
            return new Intl.NumberFormat().format(this.value).replace(/(\.|,)00$/g, "");
          }
        },
        set(modifiedValue) {
          let newValue = parseFloat(modifiedValue.replace(/[^\d.]/g, ""));
          if (isNaN(newValue)) {
            newValue = 0;
          }
          if (newValue !== 0) {
            this.$emit("input", newValue);
          } else {
            this.$emit("input", 0);
          }
        },
      },
    },
    methods: {
      displayInput(e) {
        // this.setCursor('blur')
        if (!this.validation) {
          this.error = true;
          this.$emit("valid", false);
        } else {
          this.error = false;
          this.$emit("valid", true);
        }
      },
      handleFocus() {
        this.displayPlaceholder = true;
        this.error = false;
        this.$emit('focus')
        // this.isInputActive = true;
      },
      isNumber(evt) {
        evt = evt ? evt : window.event;
        const charCode = evt.which ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) || this.amount > 1000000) {
          evt.preventDefault();
        } else {
          return true;
        }
      },
      handleSelectInput(value){
        this.$emit('selectCurrency', value)
      },
      formatNumber(n) {
      // format number 1000000 to 1,234,567
        return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      },
      setCursor(blur){
        // console.log('hello')
                    // appends $ to value, validates decimal side
          // and puts cursor back in right position.
          
          // get input value
          var input_val = this.$refs.currencyInput.value;
          
          // don't validate empty input
          if (input_val === "") { return; }
          
          // original length
          var original_len = input_val.length;

          // initial caret position 
          var caret_pos = this.$refs.currencyInput.selectionStart
            
          // check for decimal
          if (input_val.indexOf(".") >= 0) {

            // get position of first decimal
            // this prevents multiple decimals from
            // being entered
            let decimal_pos = input_val.indexOf(".");

            // split number by decimal point
            let left_side = input_val.substring(0, decimal_pos);
            let right_side = input_val.substring(decimal_pos);

            // add commas to left side of number
            left_side = this.formatNumber(left_side);

            // validate right side
            right_side = this.formatNumber(right_side);
            
            // On blur make sure 2 numbers after decimal
            if (blur === "blur") {
              right_side += "00";
            }
            
            // Limit decimal to only 2 digits
            right_side = right_side.substring(0, 2);

            // join number by .
            input_val = left_side + "." + right_side;

          } else {
            // no decimal entered
            // add commas to number
            // remove all non-digits
            input_val = this.formatNumber(input_val);
            // input_val = "$" + input_val;
            // final formatting
            if (blur === "blur") {
              input_val += ".00";
            }
          }
          
          // send updated string to input
          this.$refs.currencyInput.value = input_val;

          // put caret back in the right position
          var updated_len = input_val.length;
          caret_pos = updated_len - original_len + caret_pos;
          this.$refs.currencyInput.setSelectionRange(caret_pos, caret_pos);
      }
    },
  };
</script>

<style scoped>
  /* @import "~@/assets/styles/animation.css"; */
  .input-container {
    border-radius: 8px;
    border: 1px solid #e3e8ef;
    height: 3rem;
    font-size: 14px
  }
  .input-container >>> .eye-wrapper{
    background: none;
    padding: 0 8px;
  }

  .input-container >>> .select-container .input-regular{
    background: none !important;
    font-size:14px;
    padding: 14px 24px 14px 8px;
  }
  .input-container >>> .select-container .input-active{
    border: none !important
  }
  .input-container >>> .select-container .placeholder{
    font-size:14px;
  }
  .input-container >>> .select-container .options{
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  }
  .partition {
    width: 56px;
    background: white;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    display: grid;
    place-items: center;
    color: #697586
  }
  input {
    /* padding: 15px 10px; */
    color: #706d78 !important;
    /* border-radius: 8px; */
    font-size: 14px;
    position: relative;
    z-index: 2;
    -webkit-appearance: none;
    transition: all 0.3s linear;
    background: #F8FAFC;
  }
  .label-exist-style {
    margin-top: 8px;
  }

  label {
    color: #1e1d20;
    font-weight: 400;
    font-size: 14px;
    display: block;
  }
  /* .input-regular {
    border: 1px solid #fcfbff !important;
    background-color: #fcfbff !important;
  }
  .input-active {
    border: 1px solid #d5ccff !important;
    background-color: transparent !important;
    padding-left: 2rem;
  } */
  .show-currency {
    border-left: 1px solid #ededf0 !important;
    background-color: transparent !important;
    
  }
  /*.add-symbol {
    padding-left: 2rem;
  }*/
  .error-border {
    border: 1px solid #ffd5cc !important;
    background: #fff7f5 !important;
    color: #fc3c11 !important;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    color: #706d78 !important;
  }
  input::placeholder {
    color: #9d9d9d;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
  /* input[type="text"] {
      font-size: 16px;
    } */
  .placeholder {
    display: flex;
    height: 100%;
    align-items: center;
    z-index: 1;
  }
  .line-stroke {
    position: absolute;
    height: 2rem;
    width: 0.15rem;
    background-color: #b5b5b5;
    top: -0.35rem;
    transform: rotate(-45deg);
    left: 50%;
  }

  .error-text {
    top: 2.5rem;
  }
  .disabled {
    border-bottom: 1px solid #f2edfd;
  }
  .symbol {
    position: absolute;
    bottom: 1.1rem;
    left: 1rem;
    z-index: 2;
    /* z-index: 10; */
  }

  .symbol-right {
    position: absolute;
    bottom: 1.1rem;
    right: 1rem;
    /* z-index: 10; */
  }
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .error-text {
      top: 2.5rem;
    }

    input {
      font-size: 16px;
    }
    /* .eye-wrapper {
      top: 15%;
    } */
  }
  .error-image {
    right: 0;
    top: 22%;
    z-index: 2;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: all 0.3s cubic-bezier(0.45, 0.25, 0.6, 0.95);
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
</style>
<style>
.currency-input-select  .select-container{
  border: none !important;
  background: none !
}
</style>