<template>
  <div>
    <Card class="h-full employment-container">
      <div class="flex justify-between items-center py-4 table-container">
        <h5 class="text-lg font-semibold">Employment Verification</h5>
        <div class="flex items-center">
          <!-- <Card class="searchContainer mr-2 w-44" p="px-3">
            <Input
              type="filter"
              placeholder="Order ID"
              v-model="params.orderid"
          /></Card> -->
          <Card class="searchContainer w-44 mr-2" p="px-3">
            <Select type="filter" placeholder="Status" v-model="params.status" :options="status" :reduce="(type) => type.value" />
          </Card>
          <DatePicker class="mr-4" v-model="range" is-range :model-config="modelConfig" :masks="masks">
            <template v-slot="{ inputValue, togglePopover }">
              <Card class="flex items-center border searchContainer w-full" p="px-1.5 py-1">
                <button class="p-2 focus:outline-none" @click="togglePopover()">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
                    <path
                      d="M11.09 2h-.272v-.25c0-.425-.354-.75-.818-.75-.464 0-.818.325-.818.75V2H4.818v-.25C4.818 1.325 4.464 1 4 1c-.464 0-.818.325-.818.75V2h-.273C1.845 2 1 2.775 1 3.75v7c0 .975.845 1.75 1.91 1.75h8.18c1.065 0 1.91-.775 1.91-1.75v-7C13 2.775 12.155 2 11.09 2Zm-1.363-.25c0-.15.11-.25.273-.25.164 0 .273.1.273.25v1c0 .15-.11.25-.273.25-.164 0-.273-.1-.273-.25v-1ZM4 1.5c.164 0 .273.1.273.25v1c0 .15-.11.25-.273.25-.164 0-.273-.1-.273-.25v-1c0-.15.11-.25.273-.25Zm-1.09 1h.272v.25c0 .425.354.75.818.75.464 0 .818-.325.818-.75V2.5h4.364v.25c0 .425.354.75.818.75.464 0 .818-.325.818-.75V2.5h.273c.764 0 1.364.55 1.364 1.25v.75H1.544v-.75c0-.7.6-1.25 1.364-1.25Zm9.544 8.25c0 .7-.6 1.25-1.363 1.25H2.909c-.764 0-1.364-.55-1.364-1.25V5h10.91v5.75Z"
                      fill="#9D9D9D"
                      stroke="#9D9D9D"
                      stroke-width=".1"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M3.727 7h6.546c.163 0 .273-.1.273-.25s-.11-.25-.273-.25H3.727c-.163 0-.272.1-.272.25s.109.25.272.25ZM3.727 8.75h6.546c.163 0 .273-.1.273-.25s-.11-.25-.273-.25H3.727c-.163 0-.272.1-.272.25s.109.25.272.25ZM3.727 10.5h6.546c.163 0 .273-.1.273-.25s-.11-.25-.273-.25H3.727c-.163 0-.272.1-.272.25s.109.25.272.25Z"
                      fill="#9D9D9D"
                      stroke="#9D9D9D"
                      stroke-width=".1"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
                <p v-if="inputValue.start === null" @click="togglePopover()" class="text-grey">Date filter</p>
                <p v-else @click="togglePopover()" class="text-sm text-grey">{{ inputValue.start }} - {{ inputValue.end }}</p>
              </Card>
            </template>
          </DatePicker>
          <Button text="Search" p="p-2" @click="handleSearch" />
          <!-- <Card class="searchContainer w-44" p="px-3"> -->
          <!-- </Card> -->
        </div>
      </div>
      <Hr />
      <Spinner class="text-center" v-if="employmentLoading" />
      <!-- <div class="" v-if="!employmentLoading"> -->
      <div class="table-container" v-else>
        <table id="template-table" class="w-full table-auto">
          <thead>
            <tr>
              <th>S/N</th>
              <th>Customer name</th>
              <th>Company</th>
              <th>Phone Number</th>
              <th>Status</th>
              <th>Date</th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody>
            <EmptyState description="No records found" v-if="allEmploymentDocuments.length === 0" />
            <tr v-else v-for="(employment, index) in allEmploymentDocuments" :key="employment.id">
              <th>{{ index + 1 }}</th>
              <th class="cursor-pointer hover:underline">
                <router-link :to="`/customers/${employment.customerId}`">
                  {{ employment.customerName }}
                </router-link>
              </th>
              <th>
                {{ employment.companyName }}
              </th>
              <th>
                {{ employment.phoneNumber }}
              </th>

              <th :class="[employment.status.toLowerCase()]">
                {{ employment.status | capitalize }}
              </th>
              <th>
                {{ dayjs(employment.createdAt).format("MMM D YYYY | hh:mmA") }}
              </th>
              <th>
                <SecondaryButton text="View documents" fontSize="text-xs" class="w-40" @click="viewSingleItem(employment)" />
              </th>
            </tr>
          </tbody>
        </table>

        <Pagination :handleMore="handleMore" :loading="paginateLoading" @paginate="handlePagination" />
      </div>
    </Card>
    <Modal :display="showSingleItemModal" @close="showSingleItemModal = false" title="View employment documents">
      <div class="mb-6">
        <p class="md:font-semibold text-sm">{{ singleItem.customerName }} has submitted the following documents for verification</p>
        <div class="flex items-center justify-between my-4 bg-lightGrey-1 p-4 rounded-lg" v-for="item in singleItem.documents" :key="item.id">
          <p class="font-semibold">
            {{ item.type }}
          </p>
          <div class="flex items-center">
            <!-- <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="10"
              viewBox="0 0 16 10"
              fill="none"
              class="mr-2"
              @click="viewFile(item)"
            >
              <path
                d="M8.00033 0C4.66699 0 1.82033 2.07333 0.666992 5C1.82033 7.92667 4.66699 10 8.00033 10C11.3337 10 14.1803 7.92667 15.3337 5C14.1803 2.07333 11.3337 0 8.00033 0ZM8.00033 8.33333C6.16033 8.33333 4.66699 6.84 4.66699 5C4.66699 3.16 6.16033 1.66667 8.00033 1.66667C9.84033 1.66667 11.3337 3.16 11.3337 5C11.3337 6.84 9.84033 8.33333 8.00033 8.33333ZM8.00033 3C6.89366 3 6.00033 3.89333 6.00033 5C6.00033 6.10667 6.89366 7 8.00033 7C9.10699 7 10.0003 6.10667 10.0003 5C10.0003 3.89333 9.10699 3 8.00033 3Z"
                fill="#1E1452"
              />
            </svg>
            <SecondaryButton
              text="Download"
              background="bg-lightPurple"
              color="text-brandPurple"
              p="py-1 px-4"
              @click="downloadItem(item)"
            /> -->
            <a @click="downloadItem(item)" download target="blank" class="text-brandPurple cursor-pointer font-semibold text-sm"> View</a>
          </div>
        </div>
      </div>
      <div class="flex justify-center items-center mb-2" v-if="singleItem.status === 'PENDING'">
        <SecondaryButton
          text="Approve"
          background="bg-brandPurple"
          color="text-white"
          p="py-2 px-4"
          width="w-24"
          class="mr-2 mt-6"
          fontSize="text-sm"
          @click="showConfirmation('approve')"
        />
        <SecondaryButton
          text="Decline"
          background="bg-white"
          color="text-brandRed"
          border="border"
          width="w-24"
          borderColor="border-brandRed"
          p="py-2 px-4"
          class="mr-2 mt-6"
          fontSize="text-sm"
          @click="showConfirmation('decline')"
        />
      </div>
      <div class="flex justify-center items-center mb-2" v-else-if="singleItem.status === 'APPROVED' || singleItem.status === 'DECLINED'">
        <SecondaryButton
          text="Reopen this document verification"
          background="bg-brandRed"
          color="text-white"
          p="py-3 px-4"
          width="w-full"
          class="mr-2 mt-6"
          fontSize="text-sm"
          @click="showReopenModal()"
        />
      </div>
      <div class="flex justify-center items-center mb-2" v-else>
        <SecondaryButton
          text="Done"
          background="bg-brandPurple"
          color="text-white"
          p="py-2 px-4"
          class="mr-2 mt-6"
          fontSize="text-sm"
          @click="showSingleItemModal = false"
        />
      </div>
    </Modal>
    <Modal :display="showConfirmationModal" @close="showConfirmationModal = false">
      <div class="w-full mx-auto">
        <div v-if="decision === 'approve'">
          <h2 class="text-center text-lg">
            Are you sure you want to
            <span class="font-bold text-lg uppercase">{{ decision }}</span>
            {{ singleItem.customerName }}'s work document(s)?
          </h2>
        </div>

        <div v-if="decision === 'decline'">
          <h2 class="text-center text-lg">
            Please fill in reason why you'd like to
            <span class="font-bold text-lg uppercase">{{ decision }}</span>
            {{ singleItem.customerName }}'s work document(s)?
          </h2>

          <textarea
            id="reason"
            name="reason"
            v-model="reason"
            rows="3"
            class="shadow-sm focus:ring-brandPurple p-3 focus:border-brandPurple mt-6 block w-full border border-grey rounded-md"
            placeholder="What's your reason for declining"
          ></textarea>
        </div>
        <div class="flex justify-center items-center mb-2">
          <SecondaryButton
            v-if="decision === 'approve'"
            text="Approve"
            background="bg-brandPurple"
            color="text-white"
            p="py-2 px-4"
            width="w-24"
            class="mr-2 mt-6"
            :loading="loading"
            fontSize="text-sm"
            @click="updateEmploymentStatus(singleItem.id, 'APPROVED')"
          />
          <SecondaryButton
            v-if="decision === 'decline'"
            text="Decline"
            background="bg-brandRed"
            color="text-white"
            p="py-2 px-4"
            width="w-24"
            class="mr-2 mt-6"
            :loading="loading"
            fontSize="text-sm"
            @click="updateEmploymentStatus(singleItem.id, 'DECLINED')"
          />
          <SecondaryButton
            text="Cancel"
            background="bg-white"
            color="text-black"
            border="border"
            width="w-24"
            borderColor="border-black"
            p="py-2 px-4"
            class="mr-2 mt-6"
            fontSize="text-sm"
            @click="showConfirmationModal = false"
          />
        </div>
      </div>
    </Modal>
    <Modal :display="reopenModal" @close="reopenModal = false">
      <div class="w-full mx-auto">
        <div>
          <h2 class="text-center text-lg">
            Are you sure you want to
            <span class="font-bold text-lg uppercase">REOPEN</span>
            {{ singleItem.customerName }}'s work verification?
          </h2>
        </div>

        <div class="flex justify-center items-center mb-2">
          <SecondaryButton
            text="Reopen"
            background="bg-brandPurple"
            color="text-white"
            p="py-2 px-4"
            width="w-24"
            class="mr-2 mt-6"
            :loading="loading"
            fontSize="text-sm"
            @click="reopenDocument(singleItem.id)"
          />
          <SecondaryButton
            text="Cancel"
            background="bg-white"
            color="text-black"
            border="border"
            width="w-24"
            borderColor="border-black"
            p="py-2 px-4"
            class="mr-2 mt-6"
            fontSize="text-sm"
            @click="reopenModal = false"
          />
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
  import { mapState, mapActions } from "vuex";
  import Hr from "@/UI/Hr";
  import { SecondaryButton } from "@/UI/Button";
  import Spinner from "@/UI/Spinner";
  import dayjs from "dayjs";
  import { Select, Input, SearchInput } from "@/UI/Input";
  import { Button } from "@/UI/Button";
  import { DatePicker } from "v-calendar";
  import { Modal } from "@/UI/Modals";
  import { UPDATE_EMPLOYMENT_VERIFICATION, REOPEN_DOCUMENT } from "@/utils/api/customers";

  export default {
    components: {
      Hr,
      SecondaryButton,
      Spinner,
      Pagination: () => import("@/UI/Pagination"),
      Select,
      Input,
      Button,
      DatePicker,
      SearchInput,
      Modal,
      EmptyState: () => import("@/UI/EmptyState"),
    },
    data: () => ({
      employmentLoading: false,
      loading: false,
      declineLoading: false,
      modelConfig: {
        type: "string",
        mask: "iso",
        start: {
          timeAdjust: "00:00:00",
        },
        end: {
          timeAdjust: "23:59:59",
        },
      },
      range: {
        start: "",
        end: "",
      },
      masks: {
        input: "DD MMM YY",
      },
      params: {
        pageSize: 20,
        page: 0,
        status: "PENDING",
        from: "",
        to: "",
      },
      status: [
        { label: "All", value: "" },
        { label: "Approved", value: "APPROVED" },
        { label: "Pending", value: "PENDING" },
        { label: "Declined", value: "DECLINED" },
        { label: "Expired", value: "EXPIRED" },
      ],
      paginateLoading: false,
      handleMore: false,
      singleItem: {},
      showSingleItemModal: false,
      decision: "",
      showConfirmationModal: false,
      reopenModal: false,
      reason:
        "The document(s) provided could not verify your employment. Please upload a valid employment contract or payslip not older than 3months.",
    }),
    computed: {
      ...mapState({
        allEmploymentDocuments: (state) => state.customers.allEmploymentDocuments,
        // loading: (state) => state.loading.loading,
      }),
      dayjs() {
        return dayjs;
      },
    },
    mounted() {
      this.getEmploymentDocuments();
    },
    methods: {
      ...mapActions("customers", ["getAllEmploymentVerification"]),
      ...mapActions("loading", ["setLoading"]),
      ...mapActions("notification", ["showAlert"]),
      getEmploymentDocuments(type) {
        type === "paginate" ? (this.paginateLoading = true) : (this.employmentLoading = true);
        this.getAllEmploymentVerification({ params: this.params, type: type })
          .then((res) => {
            // console.log(this.params.pageSize);
            // this.employmentLoading = false;
            if (res.data.data.length === this.params.pageSize) {
              this.handleMore = true;
            } else {
              this.handleMore = false;
            }
            type === "paginate" ? (this.paginateLoading = false) : (this.employmentLoading = false);
          })
          .catch(() => {
            type === "paginate" ? (this.paginateLoading = false) : (this.employmentLoading = false);
            // this.employmentLoading = false;
          });
      },
      viewSingleItem(item) {
        this.singleItem = item;
        this.showSingleItemModal = true;
      },

      downloadItem(document) {
        window.open(
          `${process.env.VUE_APP_BASE_URL}/media/image/${document.id}`,
          "_blank" // open in a new window.
        );
      },
      updateEmploymentStatus(id, status) {
        this.loading = true;
        UPDATE_EMPLOYMENT_VERIFICATION(id, {
          decision: status,
          refusalReason: this.reason,
        })
          .then((res) => {
            this.loading = false;
            this.showSingleItemModal = false;
            this.showConfirmationModal = false;
            if (status === "APPROVED") {
              this.showAlert({
                description: "Employment Verification Approved Successfully",
                display: true,
                type: "success",
              });
            } else {
              this.showAlert({
                description: "Employment Verification Declined",
                display: true,
                type: "success",
              });
            }
            this.getEmploymentDocuments({
              params: {
                pageSize: 20,
                page: 0,
                from: "",
                to: "",
                status: "",
              },
            });
          })
          .catch((error) => {
            this.loading = false;
            console.error("There was an error!", error);
          });
      },
      showReopenModal() {
        this.reopenModal = true;
      },
      reopenDocument(id) {
        this.loading = true;
        REOPEN_DOCUMENT(id)
          .then((res) => {
            this.loading = false;
            this.showSingleItemModal = false;
            this.reopenModal = false;
            this.showAlert({
              description: "Employment Verification Document Reopened!",
              display: true,
              type: "success",
            });
            this.getEmploymentDocuments({
              params: {
                pageSize: 20,
                page: 0,
                from: "",
                to: "",
                status: "",
              },
            });
          })
          .catch((error) => {
            this.loading = false;
            console.error("There was an error!", error);
          });
      },
      showConfirmation(decision) {
        this.showConfirmationModal = true;
        this.decision = decision;
      },
      handlePagination() {
        this.paginateLoading = true;
        this.params.page++;
        this.getEmploymentDocuments("paginate");
      },
      handleSearch() {
        this.params.page = 0;
        this.params.from = this.range.start;
        this.params.to = this.range.end;
        this.getEmploymentDocuments();
      },
    },
  };
</script>
<style scoped>
  .searchContainer {
    border: 1px solid rgba(138, 116, 192, 0.1) !important;
    width: 12rem;
  }
  .employment-container {
    min-height: calc(100vh - 10rem);
  }
  .payment-table {
    display: grid;
    grid-template-columns: 2.5rem 25rem 1fr 1fr 1fr 1fr 1fr;
    grid-column-gap: 10px;
    align-items: center;
  }
  .payment-table__col {
    padding: 0.8rem 0.5rem;
    /* white-space: nowrap; */
    font-size: 0.9rem;
  }

  @media (max-width: 768px) {
    .table-container {
      overflow-x: auto;
    }
  }
</style>
