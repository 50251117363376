<template>
    <div>
      <currency-input
      class="mt-4 first-currency-input"
      :label="recieveLabel" 
      :show-currency="true" 
      width="w-full" 
      :options="getDestinationOptions()" 
      :optionsValue="currencyTo"
      @selectCurrency="handleCurrencyToSelect"
      selectLabel="code"
      :symbol="currencySigns[currencyTo]"
      :reduce="(category) => category.code"
      v-model="convertValueTo"
      required
    />
    <!-- <div class="flex items-center justify-between mt-2">
      <p class="font-semibold text-grey text-xs">1 {{ currencyTo }} = {{currencyFrom}}{{exchangeRate}}</p>
      <Spinner v-if="feesLoading"/>
      <p class="text-grey text-xs" v-else>Fees: <span class="text-dark">{{ feesData.currency }}{{ feesData.fee | formatMoney}}</span> </p>
    </div> -->
    <currency-input 
        class="mt-6" 
        :label="topUpLabel" 
        :show-currency="true" 
        width="w-full" 
        v-model="convertFromValue" 
        :optionsValue="currencyFrom"
        :symbol="currencySigns[currencyFrom]"
        @selectCurrency="($event) => currencyFrom = $event"
        :options="getSourceAccount()"
        required
    />
    <div class="flex items-center justify-between mt-4" v-if="type != 'swap'">
      <p class=" text-grey text-xs">Rate: </p>
      <p class="text-grey text-xs">1{{ currencyTo }} = {{currencyFrom}}{{exchangeRate}}</p>
    </div>
    
    <div class="flex items-center justify-between mt-2" v-if="type != 'swap'">
      <p class="text-grey text-xs">Transfer fees:</p>
      <Spinner v-if="feesLoading"/>
      <p class="text-grey text-xs" v-else><span class="text-dark">₦{{ feesData.fee | formatMoney}}</span> </p>
    </div>
    <div class="flex items-center justify-between mt-2" v-if="type != 'swap'">
      <p class=" text-grey font-semibold ">Amount to top up</p>
      <p class="text-grey font-semibold">₦{{ (convertFromValue + feesData.fee) | formatMoney }} </p>
    </div>
  
        <!-- <currency-input
          class="mt-4"
          :label="recieveLabel" 
          :show-currency="true" 
          width="w-full" 
          :options="getDestinationOptions()" 
          :optionsValue="currencyTo"
          @selectCurrency="handleCurrencyToSelect"
          selectLabel="code"
          :symbol="currencySigns[currencyTo]"
          :reduce="(category) => category.code"
          v-model="convertValueTo"
          @input="handleToInput"
        />
    
          <div class="flex items-center justify-between mt-2">
            <p class="font-semibold text-grey text-xs">Rate </p>
            <p class="text-grey text-xs">1{{ currencyTo }} = {{currencyFrom}}{{exchangeRate}}</p>
          </div>
          <div class="flex items-center justify-between mt-2">
            <p class="font-semibold text-grey text-xs">Send value in naira</p>
            <p class="text-grey text-xs">{{ convertFromValue | formatMoney }} </p>
          </div>
          <div class="flex items-center justify-between mt-2">
            <p class="font-semibold text-grey text-xs">Transfer fees</p>
            <Spinner v-if="feesLoading"/>
            <p class="text-grey text-xs" v-else>Fees: <span class="text-dark">{{ feesData.currency }}{{ feesData.fee | formatMoney}}</span> </p>
          </div>
        <currency-input 
          class="mt-8" 
          :label="topUpLabel" 
          :show-currency="true" 
          width="w-full" 
          v-model="topUpAmount" 
          :optionsValue="currencyFrom"
          :symbol="currencySigns[currencyFrom]"
          @selectCurrency="($event) => currencyFrom = $event"
          :options="getSourceAccount()"
          @input="handletopUpInput"
      /> -->
    </div>
</template>
<script>
import {mapState, mapActions} from 'vuex'
import CurrencyInput from '../CurrencyInput'
import Spinner from '../../Spinner'
import {Button} from '@/UI/Button'
export default {
    components:{
        CurrencyInput,
        Spinner,
        Button
    },
    props:{
      value:{
        type:[String,Number],
        default: ''
      },
      recieveLabel:{
        type: String,
        default:'Amount beneficiary will receive'
      },
      topUpLabel:{
        type: String,
        default:'Naira equivalent'
      },
      type:{
        type: String,
        default:''
      }
    },
    data:() => ({
        convertFromValue:0,
        currencyFrom:'',
        currencyTo:'',
        convertValueTo:0,
        currencySigns:{},
        topUpAmount: 0,
        feesLoading: false,
        feesData:{
          currency:'NGN',
          fee: 0
        },
        timer: null
    }),
    computed:{
        ...mapState({
            currencies: (state) => state?.wire?.currencyMappings
        }),
        exchangeRate(){
            let rate = ''
            this.currencies.forEach((item) => {
                if(item.sourceCurrencyCode.code === this.currencyFrom && item.destinationCurrencyCode.code === this.currencyTo){
                    rate = item.exchangeRate
                }
            })
            return rate
        },
    },
    watch:{
      convertValueTo(value){
        this.convertFromValue = (this.convertValueTo * this.exchangeRate)
        this.$emit('input', this.convertValueTo)
        const getFee = () => this.handleGetFees()
        this.debounce(getFee)

      },
      convertFromValue(value){
        this.convertValueTo = value / this.exchangeRate;
        const getFee = () => this.handleGetFees()
        this.debounce(getFee)
      },
      currencyTo(value) {
        if(value){
          this.convertFromValue = (this.convertValueTo * this.exchangeRate)
          this.$emit('input', this.convertValueTo)
          const getFee = () => this.handleGetFees()
          this.debounce(getFee)
        }
      }
    },
    methods:{
      ...mapActions('payments', ['getFees']),
        getSourceAccount(){
        let arrayOfSource = []
        this.currencies.forEach((item) => {
            if(!arrayOfSource.includes(item.sourceCurrencyCode.code)) arrayOfSource.push(item.sourceCurrencyCode.code)
        })
        
        return arrayOfSource
      },
      getDestinationOptions(){
        let destinationSource = [];
        this.currencies.forEach((item) => {
          if(!destinationSource.some((currency) => currency.code === item.destinationCurrencyCode.code)){
            destinationSource.push(item.destinationCurrencyCode)
          }
        })
        return destinationSource
      },
      handleCurrencyToSelect(value){
        this.currencyTo = value;
        this.$emit('destinationCurrency', value)
      },
      handleAmountFromInput(){
        this.$emit('input', this.convertValueTo)
      },
      getCurrencySymbol(){
        this.currencies.forEach((item) => {
          if(!this.currencySigns[item.destinationCurrencyCode.code] ){
            this.currencySigns[item.destinationCurrencyCode.code] = item.destinationCurrencyCode.sign
          }

          if(!this.currencySigns[item.sourceCurrencyCode.code]){
            this.currencySigns[item.sourceCurrencyCode.code] = item.sourceCurrencyCode.sign
          }
        })
      },
      handleGetFees(){
        return new Promise((resolve, reject) => {
          if(this.currencies.length){
            this.feesLoading = true
              this.getFees({
                receiveCurrency: this.currencyTo,
                amount: this.convertValueTo
              }).then(({data}) => {
                this.feesLoading = false;
                this.feesData = data.data.data
                this.$emit('fees', this.feesData)
                resolve(data.data.data)
              }).catch(() => {
                this.feesLoading = false;
              })
          }
        })
      },
      debounce(func, type){
          if (this.timer) {
            clearTimeout(this.timer); // clear any pre-existing timer
          }
          // const context = this; // get the current context
          this.timer = setTimeout(() => {
            func()
            // .then(() => {
            //   if(type === 'amountToRecieve'){
            //     this.topUpAmount = this.convertFromValue + this.feesData.fee
            //   }else{
            //     this.convertValueTo = (this.topUpAmount - this.feesData.fee) / this.exchangeRate
            //     this.convertFromValue = (this.topUpAmount - this.feesData.fee)
            //   }
            // }); // call the function if time expires
          }, 300);
      },
      handleToInput(value){
        this.convertFromValue = (value * this.exchangeRate)
        this.$emit('input', value)
        const getFee = () => this.handleGetFees()
        this.debounce(getFee, 'amountToRecieve')
      },
      handletopUpInput(value){
        // this.convertValueTo = value / this.exchangeRate;
        // this.convertFromValue = (value * this.exchangeRate)

        let initialConversion = value / this.exchangeRate;
        const getFee = () => this.handleGetFees()
        this.debounce(getFee)
      }
    },
    mounted(){
        this.currencyFrom = 'NGN';
        this.handleCurrencyToSelect('USD')
        this.handleAmountFromInput()
        this.getCurrencySymbol()

    }
}
</script>
<style scoped>
.first-currency-input{
  z-index: 24
}
</style>