<template>
    <div>
        <label class="mb-2" :for="id" v-if="label.length">
            {{ label }} <span class="label-required text-brandPurple" v-if="required">*</span>
        </label>

        <vue-tel-input 
            :value="value" 
            @input="updateValue" 
            :enabled-country-code="true"
            :auto-format="true"
            :input-options="inputOptions" 
            :dropdown-options="dropdownOptions" 
            :disabled="disabled" :mode="'international'"
            :defaultCountry="defaultCountry" 
            wrapper-classes="border-0 focus:outline-none pl-12"
            :validCharactersOnly="true"
            :class="[width, phoneIsValid ? 'input-active' : value && !phoneIsValid ? 'tel-error-border' : null]"
            @blur="isBlurred = true"
        />

        <div class="error-text">
            <transition name="slideY">
                <p class="text-brandRed text-xs mt-1" v-if="isBlurred && !phoneIsValid">
                    {{ errorText }}
                </p>
            </transition>
        </div>
    </div>
</template>
  
<script>
import { VueTelInput } from "vue-tel-input";
export default {
    components: {
        VueTelInput,
    },

    data() {
        return {
            phoneIsValid: false,
            phoneInfo: {},
            isBlurred: false,
            internalValue: "",
        };
    },

    props: {
        value: {
            type: String,
            default: "",
            required: false,
        },
        defaultCountry: {
            type: String,
            default: "NG",
            required: false,
        },
        errorText: {
            type: String,
            default: "Phone number is invalid",
            required: false,
        },
        id: {
            type: String,
            default: "",
            required: false,
        },
        label: {
            type: String,
            default: "",
            required: false,
        },
        placeholder: {
            type: String,
            default: "Phone number",
            required: false,
        },
        type: {
            type: String,
            default: "",
            required: false,
        },
        disabled: {
            type: Boolean,
            default: false,
            required: false,
        },
        required: {
            type: Boolean,
            default: false,
            required: false,
        },
        width: {
            type: String,
            default: "w-auto",
            required: false,
        },
    },

    computed: {
        inputOptions() {
            return {
                id: this.id,
                showDialCode: false,
                tabindex: 0,
                required: this.required,
                placeholder: this.placeholder,
                // maxlength: this.maxlength,
            };
        },

        dropdownOptions() {
            return {
                showSearchBox: true,
                showFlags: true,
                showDialCodeInSelection: false,
            };
        },

    },

    methods: {
        updateValue(value, obj) {
            this.$emit("input", value.split(" ").join("")); // Emitting the updated value to the parent component
            this.phoneInfo = obj;
            this.phoneIsValid = obj.valid;
            this.$emit("valid", this.phoneIsValid);
        },
    },
};
</script>  

<style src="vue-tel-input/dist/vue-tel-input.css"></style>
<style scoped>
.label-required{
    color: rgba(105, 86, 200, var(--tw-text-opacity))
  }
</style>
<style>
.label-exist-style {
    margin-top: 8px;
}

label {
    color: #1e1d20;
    font-weight: 400;
    font-size: 14px;
    display: block
}

.vti__dropdown-list {
    border: none;
    box-shadow: 0px 6.65584px 39.9351px rgba(128, 70, 251, 0.2);
    border-radius: 10px;
    width: 320px !important;
}

.vti__dropdown:focus-visible {
    border: 0px !important;
    outline: none !important;
}

.vue-tel-input .dropdown ul {
    z-index: 999;
}

.vti__dropdown-item.highlighted {
    background-color: transparent;
}

.vti__dropdown-item,
.tel-error-border.vti__dropdown-item {
    padding: 10px 0px;
    margin: 0px 15px;
    font-size: 1rem !important;
    border-bottom: 1px solid #EDEDF0;
}

.vti__dropdown-item strong {
    font-weight: 400 !important;
}

.vti__dropdown.open {
    background: none;
    z-index: 10;
}

.vue-tel-input {
    border: 1px solid #ededf0 !important;
    background-color: #fcfbff !important;
    border-radius: 0px !important;
    border-radius: 8px !important;
    padding: 6px 3px;
}

.input-active {
    border: 1px solid #d5ccff !important;
    background-color: transparent !important;
}

.tel-error-border {
    border: 1px solid #ffd5cc !important;
    background: #fff7f5 !important;
}

.tel-error-border .vti__input,
.tel-error-border .vti__selection .vti__country-code,
.tel-error-border .vti__dropdown-arrow {
    color: #fc3c11 !important;
}

.vue-tel-input:focus-within {
    box-shadow: none !important;
}

.vti__dropdown-arrow {
    font-size: 0.8rem;
}

.vti__input:focus,
.vti__input:visited {
    outline: none !important;
}

.vti__dropdown:hover {
    background-color: transparent !important;
}

.vti__input {
    outline: none !important;
    background-color: transparent !important;
}

.vti__flag-wrapper {
    width: 30px;
}

.vti__dropdown-arrow {
    margin-left: 5px;
}

.vti__search_box {
    width: 98% !important;
    margin: 5px 3px;
    border-radius: 6px;
}

.vti__dropdown-list.below {
    top: 45px;
}

.vti__selection {
    font-size: 1rem;
}

.error-text {
    top: 2.5rem;
}

@media only screen and (max-width: 768px) {
    .error-text {
        top: 2.5rem;
    }
}
</style>
