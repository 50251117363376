<template>
  <div class="card transition-all">
    <Input :label="label"  p="px-2.5 py-2" class="mt-4" :placeholder="placeholder" :value="value" :id="id"
      :required="required" :autoComplete="autoComplete" :validation="validation" :errorText="errorText" />
  </div>
</template>
  
<script>
import { Input } from "@/UI/Input";
import { mapActions } from "vuex";
export default {
  components: {
    Input
  },
  props: {
    value: {
      type: String,
      default: "",
      required: true,
    },
    placeholder: {
      type: String,
      default: "Enter address",
    },
    required: {
      type: Boolean,
      default: false,
      required: false,
    },
    width: {
      type: String,
      default: "w-auto",
      required: false,
    },
    label: {
      type: String,
      default: "",
      required: false,
    },
    id: {
      type: String,
      default: "address",
      required: false,
    },
    validation: {
      type: Boolean,
      default: true,
      required: false,
    },
    errorText: {
      type: String,
      default: "A valid address is required",
      required: false,
    },
    autoComplete: {
      type: String,
      default: "on",
      required: false,
    },
  },
  data() {
    return {
      // formattedAddress: "",
      address: {},
      // isAddressValid: false,
      // errorOnAddress: false,
    }
  },

  watch: {
    address(value) {
      this.$emit('update', value);
    },
  },

  methods: {
    ...mapActions("notification", ["showAlert"]),
    // validateAddress() {
    //   if (this.isAddressValid) {
    //     this.errorOnAddress = false;
    //     document.getElementById(`${this.id}`).style.borderColor = "#000";
    //   } else {
    //     this.errorOnAddress = true;
    //     document.getElementById(`${this.id}`).style.borderColor = "#C70039";
    //   }
    // },
    // handleError() {
    //   if (this.isAddressValid) {
    //     this.address.address = "";
    //     this.address.state = "";
    //     this.address.lga = "";
    //   }
    //   document.getElementById(`${this.id}`).style.borderColor = "#000";
    //   this.errorOnAddress = false;
    //   this.isAddressValid = false;
    // },
    googleAutocomplete() {
      document.getElementById(`${this.id}`).placeholder = this.placeholder;

      // let options = {
      //   types: ["geocode"],
      //   componentRestrictions: { country: "ng" },
      // };
      this.autocomplete = new google.maps.places.Autocomplete(
        document.getElementById(`${this.id}`),
        // options
      );
      this.autocomplete.addListener("place_changed", () => {
        // this.isAddressValid = true;
        // this.validateAddress();
        let place = this.autocomplete.getPlace();
        this.$emit('input', place.formatted_address)

        let ac = place.address_components;
        const location = {
          no: "",
          name: "",
          neighborhood: "",
          state: "",
          lga: "",
          country: ""
        };

        ac.forEach((address) => {
          if (address.types[0] === "street_number") {
            location.no = address.long_name;
          }
          if (address.types[0] === "route") {
            location.name = address.long_name;
          }
          if (address.types[0] === "neighborhood") {
            location.neighborhood = address.long_name;
          }
          if (address.types[0] === "administrative_area_level_1") {
            if (address.long_name === "Federal Capital Territory") {
              address.long_name = "FCT";
            }
            location.state = address.long_name.split(" State")[0];
          }
          if (address.types[0] === "administrative_area_level_2") {
            if (address.short_name === "AMAC") {
              address.long_name = "Municipal Area Council";
            }
            location.lga = address.long_name;
          }
          if (address.types[0] === "country") {
            location.country = address.long_name;
          }
        });

        this.address = location;

        // if (location.state === "" || location.lga === "") {
        //   this.isAddressValid = false;
        //   this.showAlert({
        //     display: true,
        //     description:
        //       "Please select a valid street address from the dropdown",
        //     info: "If you can't find your exact address please provide more info in the field below",
        //     type: "error",
        //   });
        // }
      });
    },
    updateInput(value) {
      this.$emit('input', value);
    }

  },
  mounted() {
    this.googleAutocomplete();
  }
};
</script>
<style scoped>
.card {
  border-radius: 8px;
}

.shadow {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
}
</style>
      