import axios from "@/plugins/axios";

export const GET_MERCHANTS = (payload) => {
  return axios.get("/bnpl/merchant", { params: payload });
}

export const GET_MERCHANT_CATEGORIES = (id) => {
  return axios.get(`/bnpl/featured-category/merchant/${id}`);
};

export const GET_MERCHANTS_BY_CATEGORY = (id, payload) => {
  return axios.get(`/bnpl/merchant/featured-category/${id}`, { params: payload });
};

export const GET_FEATURED_CATEGORIES = () => {
  return axios.get("/bnpl/featured-category");
};

export const CREATE_NEW_CATEGORY = (data) => {
  return axios.post(`/bnpl/featured-category`, data);
};

export const UPDATE_CATEGORY = (data) => {
  return axios.put(`/bnpl/featured-category`, data);
};

export const GET_MERCHANT_BY_SUBCATEGORIES = (payload) => {
  return axios.get(`bnpl/merchant/by-subcategory`, { params: payload });
};

export const ADD_SUBCATEGORY = (data) => {
  return axios.post(`/bnpl/v1/featured-subcategory`, data);
};

export const GET_MERCHANT_FEATURED_SUB_CATEGORIES = (payload) => {
  return axios.get(`/bnpl/v1/merchant-featured-subcategory/admin`, { params: payload });
};

export const ADD_MERCHANT_TO_SUBCATEGORY = (data) => {
  return axios.post(`/bnpl/v1/merchant-featured-subcategory/feature/admin`, data);
};

export const UPDATE_SUBCATEGORY = (id, data) => {
  return axios.put(`/bnpl/v1/featured-subcategory/id/${id}`, data);
};

export const GET_SUBCATEGORIES = (payload) => {
  return axios.get(`/bnpl/v1/featured-subcategory/admin`, { params: payload });
};

export const ADD_NEW_MERCHANT_CATEGORY = (data) => {
  return axios.put(`/bnpl/merchant-featured-category/add`, data);
};

export const REMOVE_NEW_MERCHANT_CATEGORY = (data) => {
  return axios.put(`/bnpl/merchant-featured-category/remove`, data);
};

export const ENABLE_MERCHANT_FEATURED = (data) => {
  return axios.put(`/bnpl/merchant/enable-featured`, data);
};

export const UPDATE_MERCHANT_KYC_STATUS = (data) => {
  return axios.put(`/bnpl/merchant/${data.merchantId}/kyc-status/${data.newKycStatus}`);
};

export const DISABLE_MERCHANT_FEATURED = (id) => {
  return axios.put(`/bnpl/merchant/${id}/disable-featured`);
};

export const REMOVE_MERCHANT_FROM_SUBCATEGORY = (id) => {
  return axios.put(`/bnpl/v1/merchant-featured-subcategory/id/${id}/unfeature/admin`);
};

export const GET_UNFEATURED_ELIGIBLE_MERCHANTS = (payload) => {
  return axios.get(`/bnpl/merchant/by-subcategory/unfeatured`, { params: payload });
};


