<template>
  <div>
    <ul class="tg-list">
      <li class="tg-list-item">
        <input
          class="tgl tgl-ios"
          :id="id"
          type="checkbox"
          :value="value"
          :checked="value"
          @input="$emit('input', $event.target.checked)"
          @change="$emit('change', value)"
          :disabled="disabled"
        />
        <label class="tgl-btn" :for="id"></label>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: ["id", "value", "disabled"],
  data() {
    return {
      //   value: false,
    };
  },
  watch: {
    value(value) {
      this.$emit("checked", value);
    },
  },
  methods: {
    sendValue(e) {
      this.$emit("input");
    },
  },
};
</script>
<style scoped>
html,
body {
  display: flex;
  min-height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: sans-serif;
}
ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}
.tg-list {
  text-align: center;
  display: flex;
  align-items: center;
}
/* 
.tg-list-item {
  margin: 0 2em;
} */
h2 {
  color: #777;
}
h4 {
  color: #999;
}
.tgl {
  display: none;
}
.tgl,
.tgl:after,
.tgl:before,
.tgl *,
.tgl *:after,
.tgl *:before,
.tgl + .tgl-btn {
  box-sizing: border-box;
}
.tgl::-moz-selection,
.tgl:after::-moz-selection,
.tgl:before::-moz-selection,
.tgl *::-moz-selection,
.tgl *:after::-moz-selection,
.tgl *:before::-moz-selection,
.tgl + .tgl-btn::-moz-selection {
  background: none;
}
.tgl::selection,
.tgl:after::selection,
.tgl:before::selection,
.tgl *::selection,
.tgl *:after::selection,
.tgl *:before::selection,
.tgl + .tgl-btn::selection {
  background: none;
}
.tgl + .tgl-btn {
  outline: 0;
  display: block;
  width: 44px;
  height: 22.74px;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.tgl + .tgl-btn:after,
.tgl + .tgl-btn:before {
  position: relative;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
}
.tgl + .tgl-btn:after {
  left: 0;
}
.tgl + .tgl-btn:before {
  display: none;
}
.tgl:checked + .tgl-btn:after {
  left: 50%;
}
.tgl-ios + .tgl-btn {
  background: #eaeaea;
  border-radius: 2em;
  padding: 2px;
  transition: all 0.4s ease;
  border: 1px solid #e8eae9;
}

.tgl-ios + .tgl-btn:before {
  background: "#abaaaa";
}
.tgl-ios + .tgl-btn:after {
  border-radius: 2em;
  background: #abaaaa;
  transition: left 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275),
    padding 0.3s ease, margin 0.3s ease;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 1px 0 rgba(0, 0, 0, 0.08);
}
.tgl-ios + .tgl-btn:hover:after {
  will-change: padding;
}
.tgl-ios + .tgl-btn:active {
  box-shadow: inset 0 0 0 2em #e8eae9;
  /* background: #4d2ba1; */
}
.tgl-ios + .tgl-btn:active:after {
  padding-right: 0.8em;
  /* background: #4d2ba1; */
}
.tgl-ios:checked + .tgl-btn {
  background: #ebe3ff;
}
.tgl-ios:checked + .tgl-btn:after {
  background: #4d2ba1;
}
.tgl-ios:checked + .tgl-btn:active {
  box-shadow: none;
}
.tgl-ios:checked + .tgl-btn:active:after {
  margin-left: -0.8em;
}
</style>
