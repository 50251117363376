<template>
   <div>
    <!-- <div>
        <Button text="back" type="submit" class="cancel" @click="back"/>
    </div> -->
        <div class="bg-white p-6 max-w-lg ">
            <div class="text-center">
                <h2 class="text-2xl font-semibold mb-4">Completed!!</h2>
                <p>Processing KYB details</p>
            </div>
            <div class="flex justify-center mt-4">
                <Button @click="completeRegistration" width="w-40" type="submit" text="Okay"/>
            </div>
        </div>
    </div>
</template>

<script>
import {Button} from '@/UI/Button'
export default {
    components:{
        Button
    },
methods:{
    back() {
      this.$emit('back');
    },
    completeRegistration(){
        this.$emit('complete')
    }

}
}
</script>

<style scoped>
.add-new-Btn{
        background: #F8FAFC;
        color: #202939;
        border: 1px solid #E3E8EF;
    }
    .cancel{
        background: #EEF2F6;
        color: #697586;
    }
    input, select{
        background: #E3E8EF;
        color:#9AA4B2;
    }
    .opt{
        color: #697586;
    }
    .skip{
        color: #6F00FF;
    }
    .continue-Btn-area{
        min-height: 80px;
        border-top: 1px solid #E3E8EF
    }
    .direct-details{
        border-bottom: 1px solid #E3E8EF
    }
    .continue-Btn{
        background: #6956C8;
        border: 1px solid #412C96;
        box-shadow: 0px 0px 0px 3px #9386D847;
        box-shadow: 0px 1px 2px 0px #FFFFFF4D inset;
        color: #FFFFFF;
        font-family: Inter;
        font-size: 13px;
        font-weight: 600;
        line-height: 15.6px;
        letter-spacing: 0.03em;
        text-align: center;
    }
    .create-acct{
        color: #202939;
    }
    .business-type{
        background: #E3E8EF;
    }
</style>