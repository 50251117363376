// import axios from "@/plugins/axios";
import {
  CREATE_NEW_PARTNER,
  GET_ALL_PARTNERS,
  GET_PARTNER_REGISTRATION,
  GET_SINGLE_PARTNER_MERCHANTS,
  GET_SINGLE_PARTNER_CUSTOMERS
} from "@/utils/api/partner.js";

export default {
  showPartnerModal({ commit }, payload) {
    commit("SHOW_PARTNER_MODAL", payload);
  },
  showPartnerSuccessModal({ commit }, payload) {
    commit("SHOW_PARTNER_SUCCESS_MODAL", payload);
  },
  createNewPartner({ commit }, payload) {
    return new Promise((resolve, reject) => {
      CREATE_NEW_PARTNER(payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getAllPartnerRegistration({ commit }, payload) {
    return new Promise((resolve, reject) => {
      GET_PARTNER_REGISTRATION(payload)
        .then(({ data }) => {
          payload.type === "paginate"
            ? commit("ADD_PARTNER_REGISTRATION", data.data)
            : commit("GET_PARTNER_REGISTRATION", data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getAllPartners({ commit }, payload) {
    return new Promise((resolve, reject) => {
      GET_ALL_PARTNERS(payload)
        .then(({ data }) => {
          payload.type === "paginate"
            ? commit("ADD_PARTNER", data.data)
            : commit("GET_PARTNER", data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getAllPartnerMerchants({ commit }, payload) {
    return new Promise((resolve, reject) => {
      GET_SINGLE_PARTNER_MERCHANTS(payload)
        .then(({ data }) => {
          payload.type === "paginate"
            ? commit("ADD_PARTNER_MERCHANTS", data.data)
            : commit("GET_PARTNER_MERCHANTS", data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getAllPartnerCustomers({ commit }, payload) {
    return new Promise((resolve, reject) => {
      GET_SINGLE_PARTNER_CUSTOMERS(payload)
        .then(({ data }) => {
          payload.type === "paginate"
            ? commit("ADD_PARTNER_CUSTOMERS", data.data)
            : commit("GET_PARTNER_CUSTOMERS", data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
