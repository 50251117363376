import store from "../store";

export default {
  methods: {
    copy(value) {
      navigator.clipboard.writeText(value);
      store.dispatch("notification/showToast", {
        display: true,
        type: "success",
        description: "Copied to clipboard",
      });
    },
  },
};
