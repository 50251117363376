<template>
    <div>
        <h5 class="text-lg font-semibold">Owner disputes</h5>
        <Tab :options="tabOptions" v-model="tabValue" class="my-4"/>
        <OwnerRemoval v-if="tabValue === 'User removal'"/>
        <OwnerRoleUpdates v-if="tabValue === 'Update role'"/>
    </div>
</template>
<script>
import Tab from '@/UI/Tab/Tab'
export default {
    components:{
        Tab,
        OwnerRemoval:() => import('./OwnerRemoval.vue'),
        OwnerRoleUpdates:() => import('./OwnerUpdateRole.vue')
    },
    data:() => ({
        tabOptions:[ 'User removal', 'Update role'],
        tabValue:'User removal'
    })
}
</script>