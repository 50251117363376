export default {
  currencyMappings: [],
  allRegistrationData: [],
  allPendingRegistrationData: [],
  currencyList:[
    {
      sign: "₦",
      code: 'NGN',
      icon: 'https://res.cloudinary.com/zillaafrica/image/upload/v1693224261/wire%20merchant%20app/currency%20flags/ngn_ihh7ma.svg',
      name: 'Naira'
    },
    {
      sign: "$",
      code: 'USD',
      icon: 'https://res.cloudinary.com/zillaafrica/image/upload/v1693224261/wire%20merchant%20app/currency%20flags/us_rmkalk.svg',
      name: 'US Dollar'
    },
    {
      sign: "£",
      code: 'GBP',
      icon: 'https://res.cloudinary.com/zillaafrica/image/upload/v1693224261/wire%20merchant%20app/currency%20flags/gbp_nq5j81.svg',
      name: 'Great Britain Pound'
    },
    {
      sign: "AED",
      code: 'AED',
      icon: 'https://res.cloudinary.com/zillaafrica/image/upload/v1693224261/wire%20merchant%20app/currency%20flags/aed_bw2acu.svg',
      name: 'UAE Dirham'
    },
    {
      sign: "¥",
      code: 'CNY',
      icon: 'https://res.cloudinary.com/zillaafrica/image/upload/v1693224261/wire%20merchant%20app/currency%20flags/cny_hor75r.svg',
      name: 'Chinese Yuan"'
    },
  ]
};
