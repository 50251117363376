<template>
    <div
      class="grid bg-layoutGrey-2 w-full"
      :class="[`grid-cols-${options.length}`]"
    >
      <div
        :class="{ active: option === value }"
        @click="$emit('input', option)"
        class="shadow rounded cursor-pointer transition-all"
        v-for="option in options"
        :key="option"
      >
        <p class="text-center text-xs font-semibold py-2">{{ option }}</p>
      </div>
    </div>
  </template>
  
  <script>
    export default {
      props: {
        options: {
          type: Array,
          default: () => [],
          required: true,
        },
        value: {
          type: String,
          default: "",
          required: true,
        },
      },
    };
  </script>

  <style scoped>
    .active {
      background-color: white;
      box-shadow: 0px 1px 2px rgba(30, 29, 32, 0.2);
    }
  
    .active p {
      color: rgba(105, 86, 200);
    }
  </style>
  