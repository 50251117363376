<template>
   <RightSidebar display @close="$emit('close')" title="Senders">
        <template #body>
            <div class="p-4 partner-link-container-body">
                <form class="flex items-center my-4 gap-4" @submit.prevent="partnerRegistration = [], registeredPartner()">
                    <SearchInput @clear="handleClear()" class="my-4" width="w-full" placeholder="Search business name" v-model="params.searchQuery"/>
                    <Button font-size="text-sm" p="p-2" type="submit" text="Search"/>
                </form>
                <EmptyState v-if="!partnerRegistration.length" description="No results found"/>

                <div v-if="partnerRegistration.length" class="table-container">
                    <div class="table-section">
                        
                        <div v-for="(partner) in partnerRegistration" :key="partner.id" class="grid grid-cols-2 content-center py-2 wallet-row cursor-pointer" @click="(getUserPermissions && getUserPermissions.includes($completePermissionFormat('wire_admin_view_senders_details'))) && goToSenderInfo(partner.wireSenderProfileId)">
                            <div class="flex flex-col gap-2">
                                <p class="text-sm text-dark"> <strong>Name:</strong> {{ partner.businessName || 'N/A' }}</p>
                                <p class="text-sm text-dark"><strong>Reg Number:</strong> {{ partner.businessRegistrationNumber }}</p>
                                <p class="text-sm " :class="[partner.kycStatus && partner.kycStatus .toLowerCase()]"><strong>Status:</strong> {{ partner.kycStatus }}</p>
                            </div>
                        </div>
                    </div>
                    <Pagination v-if="showPagination" class="view-more" :meta="pageMeta" @nextPage="handlePagination" />
                </div>
            </div>
        </template>
    </RightSidebar>
</template>

<script>
import {RightSidebar} from '@/UI/Modals'
import { GET_BROKER_SENDERS } from '@/utils/api/wire';
import {mapActions} from 'vuex'
import {SearchInput} from '@/UI/Input'
import {Button, SecondaryButton} from '@/UI/Button'
import {mapGetters} from 'vuex'
export default {
    props:{
        display: {
            type: Boolean,
            default: false,
            required: false
        },
        accountDetails:{
            type: Object,
            default:() => {}
        }
    },
    components:{
        RightSidebar,
        SecondaryButton,
        Pagination: () => import("@/UI/Pagination"),
        Button,
        SearchInput,
        EmptyState:() => import('@/UI/EmptyState')
    },
    data:() => ({
        partnerRegistration:[],
        params:{
            from:'',
            to:'',
            pageSize: 20,
            page:0,
            searchQuery:''
        },
        showPagination:false,
        pageMeta:{}
    }),
    computed:{
        ...mapGetters('auth', ['getUserPermissions'])
    },
    methods:{
        ...mapActions('loading', ['setLoading']),
        registeredPartner(type){
            if(type !== 'paginate')this.setLoading(true);
            GET_BROKER_SENDERS({params: this.params , wireAccountId: this.$route.params.id})
            .then(response => {                                
                this.partnerRegistration.push(...response.data.data)
                this.pageMeta = response.data.meta;
                this.showPagination = response.data.data.length === this.params.pageSize ? true : false;
                this.setLoading(false)
            })
            .catch(error => {
                this.setLoading(false)
            });
        },
        handlePagination() {
            this.paginateLoading = true;
            this.params.page++;
            this.registeredPartner("paginate");
        },
        goToSenderInfo(wireSenderProfileId) {
            const wireAccountId = this.$route.params.id;
            
            this.$router.push({
                name: "single-sender-info",
                params: { wireAccountSendersId: wireSenderProfileId },
                query: { wireAccountId: wireAccountId }
            });
        },
        handleClear(){
            // this.params.searchQuery = '';
            this.partnerRegistration = []
            this.registeredPartner()
        }
    },
    mounted(){
        this.registeredPartner()
    }
}
</script>

<style>
.table-container {
    min-height: 80vh !important;
    position: relative;
  }
  
  .view-more {
    position: absolute;
    bottom: 0;
    right: 6;
    border-top: 1px solid rgb(242, 242, 242);
    width: 100%;
    background-color: #fff;
  }
  
  .table-section {
    max-height: 60% !important;
    overflow: auto !important;
  }
  
  .partner-link-container-body {
    padding: 16px 24px;
  }

  
  
  .wallet-row {
    border-bottom: 1px solid #e3e8ef;
  }
 
</style>