import { GET_ADMIN_PERMISSIONS, GET_ADMIN_ROLES } from "@/utils/api/adminAuthorization";

export default {
  getPermissions({ commit }) {
    return new Promise((resolve, reject) => {
      GET_ADMIN_PERMISSIONS()
        .then(({ data }) => {
          commit("SET_ADMIN_PERMISSIONS", data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getAdminRoles({ commit }) {
    return new Promise((resolve, reject) => {
      GET_ADMIN_ROLES()
        .then(({ data }) => {
          commit("SET_ADMIN_ROLES", data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
