<template>
    <Accounts />
</template>
<script>
import Accounts from '@/components/Account/Accounts'
export default{
    components:{
        Accounts
    }
}
</script>