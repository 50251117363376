import axios from "@/plugins/axios";

export const GET_PURCHASES = (params) => {
  return axios.get(`/bnpl/purchase`, { params: params });
};


export const GET_REFUND_REASONS = () => {
  return axios.get(`/bnpl/purchase/refund/reason`);
};

export const GET_RECOVERY_SCHEDULES = (params) => {
  return axios.get(`/bnpl/recovery-schedule/admin-filter`, { params: params });
};
export const REFUND_MERCHANT = (data) => {
  return axios.put(`/bnpl/purchase/refund/admin`, data);
};
