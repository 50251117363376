<template>
    <Modal :display="display" @close="$emit('close')">
        <p class="font-semibold">Approve KYB status</p>
        <p class="name text-xl font-semibold mt-4">Merchant name: {{wireInfo.registeredBusinessName}}</p>
        <!-- <Select v-model="wireAccountInfo.verificationStatus" :options="kybOptions" class="mt-8" placeholder="Select status"/> -->
        <!-- <Input v-model="wireAccountInfo.declinedReason" class="mt-6" width="w-full"  placeholder="Reason..." v-if="wireAccountInfo.verificationStatus === 'DECLINED'" /> -->
        <Input v-model="wireAccountInfo.registeredBusinessName" placeholder="Registered business name" class="mt-8"  width="w-full" />
        <Button class="mt-8" width="w-full" :loading="loading" @click="handleUpdate"/>
    </Modal>
</template>
<script>
import { Modal } from '@/UI/Modals'
import {Input, Select} from '@/UI/Input'
import {Button} from '@/UI/Button'
import {UPDATE_KYB_STATUS, APPROVE_KYB_ADMIN} from '@/utils/api/wire'
export default {
    props:{
        wireInfo: {
            type: Object,
            default: () => { },
            required: true,
        },
        display: {
            type: Boolean,
            default: false,
            required: true,
        },
    },
    components:{
        Modal,
        Input, 
        Select,
        Button
    },
    data: () => ({
        wireAccountInfo: {
            id: "",
            verificationStatus: "",
            declinedReason:'',
            registeredBusinessName:''
        },
        kybOptions: ['IN_REVIEW', 'APPROVED'],
        loading: false
    }),
    
    mounted(){
        this.wireAccountInfo.id = this.wireInfo.id;
        this.wireAccountInfo.verificationStatus = this.wireInfo.verificationStatus
    },
    methods:{
        handleUpdate(){
            this.loading = true;
            APPROVE_KYB_ADMIN(this.wireAccountInfo)
            .then(() => {
                this.loading = false;
                this.$emit('success')
            }).catch(() => {
                this.loading = false
            })
        }
    }
}
</script>