import cookies from "vue-cookies";
const tokenStorage = localStorage.getItem('token')
const token = tokenStorage ? JSON.parse(tokenStorage) : null;
export default {
  user: {},
  registrationId: "",
  token: token,
  tokenExpire: cookies.get("tokenExpire"),
  status: "",
  currentPartnerName: ""
};
