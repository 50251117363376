<template>
  <Card class="h-full purchase-container">
    <BackNav @click="$router.go(-1)" />

    <div class="flex items-center justify-between">
      <h5 class="text-lg font-semibold">Customer's Issued Cards</h5>
      <div class="flex items-center">
        <DatePicker
          class="mr-4"
          v-model="range"
          is-range
          :model-config="modelConfig"
          :masks="masks"
        >
          <template v-slot="{ inputValue, togglePopover }">
            <Card
              class="flex items-center border searchContainer w-full"
              p="px-1.5 py-1"
            >
              <button class="p-2 focus:outline-none" @click="togglePopover()">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="none"
                >
                  <path
                    d="M11.09 2h-.272v-.25c0-.425-.354-.75-.818-.75-.464 0-.818.325-.818.75V2H4.818v-.25C4.818 1.325 4.464 1 4 1c-.464 0-.818.325-.818.75V2h-.273C1.845 2 1 2.775 1 3.75v7c0 .975.845 1.75 1.91 1.75h8.18c1.065 0 1.91-.775 1.91-1.75v-7C13 2.775 12.155 2 11.09 2Zm-1.363-.25c0-.15.11-.25.273-.25.164 0 .273.1.273.25v1c0 .15-.11.25-.273.25-.164 0-.273-.1-.273-.25v-1ZM4 1.5c.164 0 .273.1.273.25v1c0 .15-.11.25-.273.25-.164 0-.273-.1-.273-.25v-1c0-.15.11-.25.273-.25Zm-1.09 1h.272v.25c0 .425.354.75.818.75.464 0 .818-.325.818-.75V2.5h4.364v.25c0 .425.354.75.818.75.464 0 .818-.325.818-.75V2.5h.273c.764 0 1.364.55 1.364 1.25v.75H1.544v-.75c0-.7.6-1.25 1.364-1.25Zm9.544 8.25c0 .7-.6 1.25-1.363 1.25H2.909c-.764 0-1.364-.55-1.364-1.25V5h10.91v5.75Z"
                    fill="#9D9D9D"
                    stroke="#9D9D9D"
                    stroke-width=".1"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M3.727 7h6.546c.163 0 .273-.1.273-.25s-.11-.25-.273-.25H3.727c-.163 0-.272.1-.272.25s.109.25.272.25ZM3.727 8.75h6.546c.163 0 .273-.1.273-.25s-.11-.25-.273-.25H3.727c-.163 0-.272.1-.272.25s.109.25.272.25ZM3.727 10.5h6.546c.163 0 .273-.1.273-.25s-.11-.25-.273-.25H3.727c-.163 0-.272.1-.272.25s.109.25.272.25Z"
                    fill="#9D9D9D"
                    stroke="#9D9D9D"
                    stroke-width=".1"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
              <p
                v-if="inputValue.start === null"
                @click="togglePopover()"
                class="text-grey"
              >
                Date filter
              </p>
              <p v-else @click="togglePopover()" class="text-sm text-grey">
                {{ inputValue.start }} - {{ inputValue.end }}
              </p>
            </Card>
          </template>
        </DatePicker>
        <Button
          text="Search filer"
          @click="handleSearch"
          class="mr-2"
          p="p-2"
        />
        <Button outline text="Clear filter" @click="clearSearch" p="p-2" />
      </div>
    </div>
    <Hr />
    <Spinner class="text-center" v-if="loading" />
    <div class="table-container" v-else>
      <EmptyState
        description="No records found"
        v-if="!customersIssuedCards.length"
      />

      <div v-else>
        <p>Showing {{ customersIssuedCards.length }} cards</p>
        <div class="grid grid-cols-3">
          <div v-for="card in customersIssuedCards" :key="card.id">
            <div>
              <div
                 v-if="card.card"
                @click="getCardDetails(card)"
                class="card-image purple-icon cursor-pointer relative mx-auto mt-6"
                :class="[card.card.status === 'BLOCKED' ? 'opacity-50' : '']"
              >
                <p class="absolute left-2 top-3 font-semibold text-white">
                  {{ card.card.expirationMonth }}/{{ card.card.expirationYear }}
                </p>
                <p
                  v-if="card.card.status === 'BLOCKED'"
                  class="absolute right-2 top-3 text-darkGrey rounded-full bg-white text-xs px-3 py-2"
                >
                  Blocked
                </p>
                <div
                  class="absolute left-2 flex items-center text-lg font-bold text-white justify-center top-0 bottom-0 text-darkGrey"
                >
                  <div>
                    <p>{{ card.card.pan }}</p>
                    <p
                      v-if="
                        card.card.customization.holderFirstName ||
                        card.card.customization.holderLastName
                      "
                      class="uppercase text-sm"
                    >
                      <span v-if="card.card.customization.holderFirstName">{{
                        card.card.customization.holderFirstName
                      }}</span>
                      <span v-if="card.card.customization.holderLastName">
                        {{ card.card.customization.holderLastName }}</span
                      >
                    </p>
                  </div>
                </div>
                <div class="logo-card flex justify-between items-center">
                  <div class="logo">
                    <img
                      src="https://res.cloudinary.com/zillaafrica/image/upload/v1663580773/customer/Group_13111_z0tw4h.svg"
                      alt=""
                    />
                  </div>
                  <div class="brand">
                    <span class="uppercase text-white font-bold text-lg">{{
                      card.card.brand
                    }}</span>
                  </div>
                </div>
                <img
                  class="wave"
                  src="https://res.cloudinary.com/zillaafrica/image/upload/v1663580774/customer/Union_1_gkjcox.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Pagination
        :handleMore="handleMore"
        :loading="paginateLoading"
        @paginate="handlePagination"
      />
    </div>
    <Modal
      :display="showIssuedCardsModal"
      size="large"
      @close="showIssuedCardsModal = false"
    >
      <SingleIssuedCards :cardId="singleCardId" @unblock="getCustomersIssuedCards()" />
    </Modal>
  </Card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Hr from "@/UI/Hr";
import EmptyState from "@/UI/EmptyState";
import Pagination from "@/UI/Pagination";
import { Button, SecondaryButton } from "@/UI/Button";
import { BackNav } from "@/UI/Navigation";
import { Modal } from "@/UI/Modals";
import { DatePicker } from "v-calendar";
export default {
  components: {
    Hr,
    Pagination,
    EmptyState,
    Button,
    SecondaryButton,
    Modal,
    DatePicker,
    Spinner: () => import("@/UI/Spinner"),
    SingleIssuedCards: () => import("./SingleIssuedCards.vue"),
    BackNav,
  },
  data: () => ({
    paginateLoading: false,
    handleMore: false,
    modelConfig: {
      type: "string",
      mask: "iso",
      start: {
        timeAdjust: "00:00:00",
      },
      end: {
        timeAdjust: "23:59:59",
      },
    },
    range: {
      start: "",
      end: "",
    },
    masks: {
      input: "DD MMM YY",
    },
    params: {
      page: 0,
      pageSize: 12,
      sortOrder: "ASC",
      from: "",
      to: "",
    },
    loading: false,
    showIssuedCardsModal: false,
    singleCardId:""
  }),
  computed: {
    ...mapState({
      customersIssuedCards: (state) => state.customers.allCustomersIssuedCards,
    }),
    dayjs() {
      return dayjs;
    },
  },

  mounted() {
    this.getCustomersIssuedCards();
  },

  methods: {
    ...mapActions("customers", ["getAllCustomersIssuedCards"]),
    ...mapActions("loading", ["setLoading"]),

    getCustomersIssuedCards(type) {
      type === "paginate"
        ? (this.paginateLoading = true)
        : (this.loading = true);
      this.getAllCustomersIssuedCards({
        params: this.params,
        type: type,
        userId: this.$route.params.id,
      })
        .then((res) => {
          if (res.data.data.length === this.params.pageSize) {
            this.handleMore = true;
          } else {
            this.handleMore = false;
          }
          type === "paginate"
            ? (this.paginateLoading = false)
            : (this.loading = false);
        })
        .catch(() => {
          type === "paginate"
            ? (this.paginateLoading = false)
            : (this.loading = false);
        });
    },
    handlePagination() {
      this.paginateLoading = true;
      this.params.page++;
      this.getCustomersIssuedCards("paginate");
    },

    clearSearch() {
      this.params.page = 0;
      this.params.to = "";
      this.params.from = "";
      this.getCustomersIssuedCards();
    },
    handleSearch() {
      this.params.page = 0;
      this.params.from = this.range.start;
      this.params.to = this.range.end;
      this.getCustomersIssuedCards();
    },

    getCardDetails(card) {
      this.singleCardId = card.id;
      this.showIssuedCardsModal = true;
    },
  },
};
</script>

<style scoped>
.searchContainer {
  border: 1px solid rgba(138, 116, 192, 0.1) !important;
  width: 12rem;
}
.card-image-container {
  max-width: 21rem;
}
.card-image {
  position: relative;
  /* display: grid; */
  margin-top: 2.75rem;
  height: 14rem;
  max-width: 21rem;
  border-radius: 19px;
  transition: all 0.3s linear;
  box-shadow: 0px 11px 4px rgba(0, 0, 0, 0.03), 0px 6px 4px rgba(0, 0, 0, 0.09),
    0px 3px 3px rgba(0, 0, 0, 0.15), 0px 1px 1px rgba(0, 0, 0, 0.17),
    0px 0px 0px rgba(0, 0, 0, 0.18);
  overflow: hidden;
  /* filter: drop-shadow(0px 77px 31px rgba(0, 0, 0, 0.03))
      drop-shadow(0px 43px 26px rgba(0, 0, 0, 0.11))
      drop-shadow(0px 19px 19px rgba(0, 0, 0, 0.19))
      drop-shadow(0px 5px 11px rgba(0, 0, 0, 0.21))
      drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.22)); */
}
.customize--card {
  max-width: 21rem;
  margin: auto;
}

.card-image .wave {
  position: absolute;
  bottom: 0;
}

.card-image .brand {
  width: 3.875rem;
  height: auto;
}
.card-image .logo {
  width: 6.875rem;
  height: auto;
}

.logo-card {
  position: absolute;
  bottom: 1.5rem;
  left: 1rem;
  right: 1rem;
  z-index: 10;
}
.black {
  background-image: url("https://res.cloudinary.com/zillaafrica/image/upload/q_auto/v1663579293/customer/ZCard00_1_oz6i2c.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.purple {
  background-image: url("https://res.cloudinary.com/zillaafrica/image/upload/q_auto/v1663344397/customer/ZCard01_2_npv5dd.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.red {
  background-image: url("https://res.cloudinary.com/zillaafrica/image/upload/q_auto/v1663579250/customer/ZWall01_Color_corrected_2_eo7hht.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.blue {
  background-image: url("https://res.cloudinary.com/zillaafrica/image/upload/q_auto/v1663579244/customer/Card_D00_1_jjizgb.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.purple-icon {
  background: linear-gradient(
      111.74deg,
      #c6003b -198.45%,
      #6a30c3 22.71%,
      #63dfdf 191.46%
    ),
    #6a30c3;
}
</style>