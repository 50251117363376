import {
  GET_MERCHANTS,
  GET_UNFEATURED_ELIGIBLE_MERCHANTS,
  GET_FEATURED_CATEGORIES,
  GET_MERCHANTS_BY_CATEGORY,
  GET_MERCHANT_BY_SUBCATEGORIES,
  GET_SUBCATEGORIES
} from "@/utils/api/merchants";

export default {
  getAllMerchants({ commit }, payload) {
    return new Promise((resolve, reject) => {
      GET_MERCHANTS(payload.params)
        .then(({ data }) => {
          payload.type === "paginate"
            ? commit("ADD_MERCHANTS", data.data)
            : commit("GET_MERCHANTS", data.data);
          // commit("GET_MERCHANTS", data.data);
          //   console.log(data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getUnfeaturedEligibleMerchants({ commit }, payload) {
    return new Promise((resolve, reject) => {
      GET_UNFEATURED_ELIGIBLE_MERCHANTS(payload.params)
        .then(({ data }) => {
          payload.type === "paginate"
            ? commit("ADD_UNFEATURED_ELIGIBLE_MERCHANTS", data.data)
            : commit("GET_UNFEATURED_ELIGIBLE_MERCHANTS", data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getFeaturedCategories({ commit }) {
    return new Promise((resolve, reject) => {
      GET_FEATURED_CATEGORIES()
        .then(({ data }) => {
          commit("FEATURED_CATEGORIES", data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getMerchantSubCategories({ commit }, payload) {
    return new Promise((resolve, reject) => {
      GET_SUBCATEGORIES(payload.params)
        .then(({ data }) => {
          payload.type === "paginate"
            ? commit("ADD_MERCHANT_SUBCATEGORIES", data.data)
            : commit("GET_MERCHANT_SUBCATEGORIES", data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getMerchantByCategory({ commit }, payload) {
    return new Promise((resolve, reject) => {
      GET_MERCHANTS_BY_CATEGORY(payload.id, payload.params)
        .then(({ data }) => {
          payload.type === "paginate"
            ? commit("ADD_CATEGORY_MERCHANTS", data.data)
            : commit("GET_CATEGORY_MERCHANTS", data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getMerchantBySubCategory({ commit }, payload) {
    return new Promise((resolve, reject) => {
      GET_MERCHANT_BY_SUBCATEGORIES(payload.params)
        .then(({ data }) => {
          payload.type === "paginate"
            ? commit("ADD_SUBCATEGORY_MERCHANTS", data.data)
            : commit("GET_SUBCATEGORY_MERCHANTS", data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
