<template>
  <div>
    <Card class="h-full employment-container">
      <div class="flex justify-between items-center py-4 table-container">
        <h5 class="text-lg font-semibold">Bank Statement Verification</h5>
        <div class="flex items-center">
          <Card class="mr-2" p="px-3">
            <SearchInput
              v-model="params.customerId"
              class="bg-lightGrey hidden md:flex"
              width="w-72"
              placeholder="Search user ID"
            />
          </Card>
          <Card class="searchContainer w-44 mr-2" p="px-3">
            <Select
              type="filter"
              placeholder="Status"
              v-model="params.dataStatus"
              :options="status"
              :reduce="(type) => type.value"
            />
          </Card>
          <DatePicker
            class="mr-4"
            v-model="range"
            is-range
            :model-config="modelConfig"
            :masks="masks"
          >
            <template v-slot="{ inputValue, togglePopover }">
              <Card
                class="flex items-center border searchContainer w-full"
                p="px-1.5 py-1"
              >
                <button class="p-2 focus:outline-none" @click="togglePopover()">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="none"
                  >
                    <path
                      d="M11.09 2h-.272v-.25c0-.425-.354-.75-.818-.75-.464 0-.818.325-.818.75V2H4.818v-.25C4.818 1.325 4.464 1 4 1c-.464 0-.818.325-.818.75V2h-.273C1.845 2 1 2.775 1 3.75v7c0 .975.845 1.75 1.91 1.75h8.18c1.065 0 1.91-.775 1.91-1.75v-7C13 2.775 12.155 2 11.09 2Zm-1.363-.25c0-.15.11-.25.273-.25.164 0 .273.1.273.25v1c0 .15-.11.25-.273.25-.164 0-.273-.1-.273-.25v-1ZM4 1.5c.164 0 .273.1.273.25v1c0 .15-.11.25-.273.25-.164 0-.273-.1-.273-.25v-1c0-.15.11-.25.273-.25Zm-1.09 1h.272v.25c0 .425.354.75.818.75.464 0 .818-.325.818-.75V2.5h4.364v.25c0 .425.354.75.818.75.464 0 .818-.325.818-.75V2.5h.273c.764 0 1.364.55 1.364 1.25v.75H1.544v-.75c0-.7.6-1.25 1.364-1.25Zm9.544 8.25c0 .7-.6 1.25-1.363 1.25H2.909c-.764 0-1.364-.55-1.364-1.25V5h10.91v5.75Z"
                      fill="#9D9D9D"
                      stroke="#9D9D9D"
                      stroke-width=".1"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M3.727 7h6.546c.163 0 .273-.1.273-.25s-.11-.25-.273-.25H3.727c-.163 0-.272.1-.272.25s.109.25.272.25ZM3.727 8.75h6.546c.163 0 .273-.1.273-.25s-.11-.25-.273-.25H3.727c-.163 0-.272.1-.272.25s.109.25.272.25ZM3.727 10.5h6.546c.163 0 .273-.1.273-.25s-.11-.25-.273-.25H3.727c-.163 0-.272.1-.272.25s.109.25.272.25Z"
                      fill="#9D9D9D"
                      stroke="#9D9D9D"
                      stroke-width=".1"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
                <p
                  v-if="inputValue.start === null"
                  @click="togglePopover()"
                  class="text-grey"
                >
                  Date filter
                </p>
                <p v-else @click="togglePopover()" class="text-sm text-grey">
                  {{ inputValue.start }} - {{ inputValue.end }}
                </p>
              </Card>
            </template>
          </DatePicker>
          <Button text="Search" p="p-2" @click="handleSearch" />
          <!-- <Card class="searchContainer w-44" p="px-3"> -->
          <!-- </Card> -->
        </div>
      </div>
      <Hr />
      <Spinner class="text-center" v-if="bankLoading" />
      <!-- <div class="" v-if="!bankLoading"> -->
      <div class="table-container" v-else>
        <table id="template-table" class="w-full table-auto">
          <thead>
            <tr>
              <th>S/N</th>
              <th>User ID</th>
              <th>Source</th>
              <th>Account number</th>
              <th>Bank</th>
              <!-- <th>Start Date</th>
              <th>End Date</th> -->
              <th>Total Credit Turnover</th>
              <th>Average Daily Balance</th>
              <th>Transaction Count</th>
              <th>Status</th>
              <th>Date</th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody>
            <EmptyState
              description="No records found"
              v-if="allBankStatements.length === 0"
            />
            <tr
              v-else
              v-for="(item, index) in allBankStatements"
              :key="item.id"
            >
              <th>{{ index + 1 }}</th>
              <th>{{ item.userId }}</th>
              <th>{{ item.accountStatementSource }}</th>
              <th>
                {{ item.accountNumber }}
              </th>
              <th>
                <span v-if="item.bank">{{ item.bank }}</span>
                <span v-else>Not available</span>
              </th>
              <!-- <th>{{ item.startDate }}</th>
              <th>{{ item.endDate }}</th> -->
              <th>
                {{ item.totalCreditTurnover | formatMoney }}
              </th>
              <th>{{ item.averageDailyBalance | formatMoney }}</th>
              <th>{{ item.transactionCount }}</th>

              <th :class="[item.dataStatus.toLowerCase()]">
                {{ item.dataStatus | capitalize }}
              </th>
              <th>
                {{ dayjs(item.createdAt).format("MMM D YYYY | hh:mmA") }}
              </th>
              <th>
                <SecondaryButton
                  text="Details"
                  fontSize="text-xs"
                  class="w-40"
                  @click="viewSingleItem(item)"
                />
              </th>
            </tr>
          </tbody>
        </table>

        <Pagination
          :handleMore="handleMore"
          :loading="paginateLoading"
          @paginate="handlePagination"
        />
      </div>
    </Card>
    <Modal
      :display="showSingleItemModal"
      @close="showSingleItemModal = false"
      title="View pdf details"
    >
      <div class="mt-6" v-if="singleItem.accountActivity">
        <p class="text-sm text-black md:mb-1">Account Activity</p>
        <h3 class="text-black md:text-lg font-semibold">
          {{ singleItem.accountActivity }}
        </h3>
      </div>

      <div class="mt-6" v-if="singleItem.averageTotalCreditTurnover">
        <p class="text-sm text-black md:mb-1">Average Total Credit Turnover</p>
        <h3 class="text-black md:text-lg font-semibold">
          {{ singleItem.averageTotalCreditTurnover | formatMoney }}
        </h3>
      </div>

      <div class="mt-6" v-if="singleItem.transactionCount">
        <p class="text-sm text-black md:mb-1">No. of transactions</p>
        <h3 class="text-black md:text-lg font-semibold">
          {{ singleItem.transactionCount }}
        </h3>
      </div>
      <div class="flex justify-between items-center my-3">
        <p>PDF statement for {{ singleItem.userId }}</p>
        <SecondaryButton
          text="View file"
          background="bg-brandPurple"
          color="text-white"
          p="py-2 px-4"
          class="ml-2"
          width="w-40"
          fontSize="text-sm"
          @click="downloadItem(pdfDetails.statementFileId)"
        />
      </div>
      <div class="grid grid-cols-10 place-center mb-8">
        <Input
          class="mt-10 col-span-8"
          disabled
          type="password"
          placeholder="PDF password"
          revealPassword
          width="w-full"
          v-model="pdfDetails.pdfPassword"
          id="pdf-password"
        />
        <div class="col-span-2 place-self-end">
          <SecondaryButton
            text="Copy"
            width="md:w-20"
            @click="copy(pdfDetails.pdfPassword)"
          />
        </div>
      </div>

      <div class="flex justify-center items-center mb-2">
        <SecondaryButton
          text="APPROVE"
          v-if="
            singleItem.dataStatus === dataStatus.pendingAdminReview ||
            singleItem.dataStatus === dataStatus.flagged
          "
          p="py-2 px-4"
          width="w-full"
          class="mr-2 mt-6"
          :loading="loading"
          fontSize="text-sm"
          @click="updateStatus(pdfDetails.statementFileId, 'AVAILABLE')"
        />
        <SecondaryButton
          v-if="singleItem.dataStatus === dataStatus.pendingAdminReview"
          text="FLAG"
          background="bg-lightRed"
          color="text-brandRed"
          p="py-2 px-4"
          width="w-full"
          class="mr-2 mt-6"
          :loading="flagLoading"
          fontSize="text-sm"
          @click="updateStatus(pdfDetails.statementFileId, 'FLAGGED')"
        />
      </div>
    </Modal>
  </div>
</template>
  <script>
import { mapState, mapActions } from "vuex";
import Hr from "@/UI/Hr";
import { SecondaryButton, Button } from "@/UI/Button";
import Spinner from "@/UI/Spinner";
import dayjs from "dayjs";
import { Select, Input, SearchInput } from "@/UI/Input";
import { DatePicker } from "v-calendar";
import { Modal } from "@/UI/Modals";
import { DATA_STATUS } from "@/components/data";
import {
  GET_PDF_FILE_DETAILS,
  UPDATE_FINANCIAL_HEALTH_STATUS,
} from "@/utils/api/customers";
import copy from "@/mixins/copy";

export default {
  mixins: [copy],
  components: {
    Hr,
    SecondaryButton,
    Spinner,
    Pagination: () => import("@/UI/Pagination"),
    Select,
    Input,
    Button,
    DatePicker,
    SearchInput,
    Modal,
    EmptyState: () => import("@/UI/EmptyState"),
  },
  data: () => ({
    bankLoading: false,
    loading: false,
    declineLoading: false,
    modelConfig: {
      type: "string",
      mask: "iso",
      start: {
        timeAdjust: "00:00:00",
      },
      end: {
        timeAdjust: "23:59:59",
      },
    },
    status: [
      { label: "All", value: "" },
      { label: "Available", value: "AVAILABLE" },
      { label: "Pending", value: "PENDING" },
      { label: "Flagged", value: "FLAGGED" },
      { label: "Failed", value: "FAILED" },
      { label: "Review", value: "PENDING_ADMIN_REVIEW" },
    ],
    dataStatus: DATA_STATUS,
    range: {
      start: "",
      end: "",
    },
    masks: {
      input: "DD MMM YY",
    },
    params: {
      pageSize: 20,
      page: 0,
      dataStatus: "",
      from: "",
      to: "",
      customerId: null,
    },
    paginateLoading: false,
    handleMore: false,
    singleItem: {},
    showSingleItemModal: false,
    decision: "",
    showConfirmationModal: false,
    pdfDetails: {},
    flagLoading: false,
  }),
  computed: {
    ...mapState({
      allBankStatements: (state) => state.customers.allBankStatements,
    }),
    dayjs() {
      return dayjs;
    },
  },
  mounted() {
    this.getBankDocuments();
  },
  methods: {
    ...mapActions("customers", ["getAllBankStatments"]),
    ...mapActions("loading", ["setLoading"]),
    ...mapActions("notification", ["showAlert"]),
    getBankDocuments(type) {
      type === "paginate"
        ? (this.paginateLoading = true)
        : (this.bankLoading = true);
      this.getAllBankStatments({ params: this.params, type: type })
        .then((res) => {
          if (res.data.data.length === this.params.pageSize) {
            this.handleMore = true;
          } else {
            this.handleMore = false;
          }
          type === "paginate"
            ? (this.paginateLoading = false)
            : (this.bankLoading = false);
        })
        .catch(() => {
          type === "paginate"
            ? (this.paginateLoading = false)
            : (this.bankLoading = false);
        });
    },

    viewSingleItem(item) {
      this.setLoading(true);
      this.singleItem = item;
      GET_PDF_FILE_DETAILS(item.id)
        .then((res) => {
          this.setLoading(false);
          this.showSingleItemModal = true;
          this.pdfDetails = res.data.data;
        })
        .catch(() => {
          this.setLoading(false);
          console.error("There was an error!");
        });
    },

    downloadItem(document) {
      window.open(
        `${process.env.VUE_APP_BASE_URL}/media/file/${document}`,
        "_blank"
      );
    },

    updateStatus(id, status) {
      if (status === "AVAILABLE") {
        this.loading = true;
      } else {
        this.flagLoading = true;
      }
      UPDATE_FINANCIAL_HEALTH_STATUS({
        accountFinancialHealthId: this.singleItem.id,
        newStatus: status,
      })
        .then((res) => {
          this.loading = false;
          this.flagLoading = false;
          this.showSingleItemModal = false;
          if (status === "AVAILABLE") {
            this.showAlert({
              description: "Bank Statement has been approved Successfully",
              display: true,
              type: "success",
            });
          } else {
            this.showAlert({
              description: "Bank Statement has been flagged successfully",
              display: true,
              type: "success",
            });
          }
          this.getBankDocuments({
            params: {
              pageSize: 20,
              page: 0,
              from: "",
              to: "",
              dataStatus: "PENDING_ADMIN_REVIEW",
              customerId: "",
            },
          });
        })
        .catch((error) => {
          this.flagLoading = false;
          this.loading = false;
          console.error("There was an error!", error);
        });
    },
    handlePagination() {
      this.paginateLoading = true;
      this.params.page++;
      this.getBankDocuments("paginate");
    },
    handleSearch() {
      this.params.page = 0;
      this.params.from = this.range.start;
      this.params.to = this.range.end;
      this.getBankDocuments();
    },
  },
};
</script>
  <style scoped>
.searchContainer {
  border: 1px solid rgba(138, 116, 192, 0.1) !important;
  width: 12rem;
}
.employment-container {
  min-height: calc(100vh - 10rem);
}
.payment-table {
  display: grid;
  grid-template-columns: 2.5rem 25rem 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-column-gap: 10px;
  align-items: center;
}
.payment-table__col {
  padding: 0.8rem 0.5rem;
  /* white-space: nowrap; */
  font-size: 0.9rem;
}

@media (max-width: 768px) {
  .table-container {
    overflow-x: auto;
  }
}
</style>
  