<template>
    <CurrencyMappings/>
</template>
<script>
import CurrencyMappings from '@/components/CurrencyMappings'
export default {
    components:{
        CurrencyMappings
    }
}
</script>