<template>
  <div>
    <Card>
        <p class="font-semibold text-lg">Senders Details</p>
      <!-- <SendersDetails @updateKyb="fetchSenderDetails()" :businessDirector="businessDirector" :accountData="accountData" class="mt-4" :businessRegistrationInfo="businessRegistrationInfo" /> -->
      <KybDetails @updateKyb="fetchSenderDetails()" :businessDirector="businessDirector" :accountData="accountData" class="mt-4" :businessRegistrationInfo="businessRegistrationInfo" />
    </Card>
  </div>
</template>

<script>
import { GET_SENDER_DETAILS } from '@/utils/api/wire';
import {Modal, RightSidebar} from '@/UI/Modals'
import { mapActions } from 'vuex';
import {Select} from '@/UI/Input'
// import SendersDetails from './SendersDetails.vue';
import  KybDetails from './KybDetails.vue'

export default {
  props:{
    display: {
      type: Boolean,
      default: false,
      required: false
    },
    accountDetails:{
      type: Object,
      default:() => {}
    }
  },
  components:{
    // SendersDetails,
    RightSidebar,
    Modal,
    Select,
    KybDetails
  },
  data:() => ({
    showDeclinedModalforKyb: false,
    businessRegistrationInfo:{},
    businessDirector:[],
    accountData:{},
    loading: false,
    // senderData: {}
  }),
  methods:{
    ...mapActions('loading', ['setLoading']),
    fetchSenderDetails() {
        
      this.setLoading(true)  
      const wireSenderProfileId = this.$route.params.wireAccountSendersId;
      const wireAccountId = this.$route.query.wireAccountId;


      GET_SENDER_DETAILS({ wireSenderProfileId, wireAccountId })
        .then(({data}) => {  
        //   this.senderData = data.data.businessKybDetails
          this.businessRegistrationInfo = data.data.businessKybDetails.businessRegistrationInfo
          this.businessDirector= data.data.businessKybDetails.businessDirector
          this.accountData = data.data
          this.loading = false
          this.setLoading(false)
        })
        .catch(error => {
          this.setLoading(false)
          console.error('Error fetching sender details:', error);
        });
    },
    downloadItem(id) {
      window.open(
      `${process.env.VUE_APP_BASE_URL}/media/image/${id}`,
      "_blank" // open in a new window.
      );
    },
  },
  mounted() {
    this.fetchSenderDetails();
  }
}
</script>

<style>

</style>