<template>
  <div class="relative">
    <p class="label-text" v-if=label.length>{{ label }} <span class="text-brandPurple" v-if="required">*</span> </p>
    <div class="flex justify-between items-center input input-regular">
      <!-- <p v-if="!name.length">{{ text }}</p> -->
      <!-- <p class v-if="!multiple">{{ textHolder | truncate(30) }}</p> -->
      <p class >{{ placeholder }}</p>
      <label class="" :class="[width ? width : null]" :for="id">
        <div>
          <!-- <img
          class="h-8 w-8 mx-auto"
          src="@/assets/icons/uploadImage.svg"
          alt=""
        /> -->
          <!-- <Button text="Upload" /> -->

          <div class="upload-button">
            <div class="lds-dual-ring" v-if="loading"></div>

            <div v-else>
              <p class="flex items-center cursor-pointer" v-if="!uploaded">
                <span class="text-sm mr-2">Attach</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="16"
                  viewBox="0 0 8 16"
                  fill="none"
                >
                  <path
                    d="M6.99984 4.50002V11.5534C6.99984 12.9467 5.97984 14.1867 4.59317 14.32C2.99984 14.4733 1.6665 13.2267 1.6665 11.6667V3.42668C1.6665 2.55335 2.29317 1.76002 3.15984 1.67335C4.15984 1.57335 4.99984 2.35335 4.99984 3.33335V10.3333C4.99984 10.7 4.69984 11 4.33317 11C3.9665 11 3.6665 10.7 3.6665 10.3333V4.50002C3.6665 4.22668 3.43984 4.00002 3.1665 4.00002C2.89317 4.00002 2.6665 4.22668 2.6665 4.50002V10.24C2.6665 11.1133 3.29317 11.9067 4.15984 11.9934C5.15984 12.0934 5.99984 11.3133 5.99984 10.3333V3.44668C5.99984 2.05335 4.97984 0.813349 3.59317 0.680016C2.0065 0.526683 0.666504 1.77335 0.666504 3.33335V11.5134C0.666504 13.4267 2.0665 15.14 3.97317 15.32C6.1665 15.52 7.99984 13.8134 7.99984 11.6667V4.50002C7.99984 4.22668 7.77317 4.00002 7.49984 4.00002C7.2265 4.00002 6.99984 4.22668 6.99984 4.50002Z"
                    fill="#1E1452"
                  />
                </svg>
              </p>

              <p class="flex items-center cursor-pointer" v-else @click.stop="deleteFile()">
                <span class="text-sm mr-2 text-brandRed">Delete</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="16"
                  viewBox="0 0 8 16"
                  fill="none"
                >
                  <path
                    d="M6.99984 4.50002V11.5534C6.99984 12.9467 5.97984 14.1867 4.59317 14.32C2.99984 14.4733 1.6665 13.2267 1.6665 11.6667V3.42668C1.6665 2.55335 2.29317 1.76002 3.15984 1.67335C4.15984 1.57335 4.99984 2.35335 4.99984 3.33335V10.3333C4.99984 10.7 4.69984 11 4.33317 11C3.9665 11 3.6665 10.7 3.6665 10.3333V4.50002C3.6665 4.22668 3.43984 4.00002 3.1665 4.00002C2.89317 4.00002 2.6665 4.22668 2.6665 4.50002V10.24C2.6665 11.1133 3.29317 11.9067 4.15984 11.9934C5.15984 12.0934 5.99984 11.3133 5.99984 10.3333V3.44668C5.99984 2.05335 4.97984 0.813349 3.59317 0.680016C2.0065 0.526683 0.666504 1.77335 0.666504 3.33335V11.5134C0.666504 13.4267 2.0665 15.14 3.97317 15.32C6.1665 15.52 7.99984 13.8134 7.99984 11.6667V4.50002C7.99984 4.22668 7.77317 4.00002 7.49984 4.00002C7.2265 4.00002 6.99984 4.22668 6.99984 4.50002Z"
                    fill="#FC3C11"
                  />
                </svg>
              </p>
            </div>
          </div>
        </div>
        <input
          class="hidden"
          type="file"
          :id="id"
          @change.stop=" uploadImage($event)"
        />
      </label>
    </div>
    <div class="file-options-container" v-if="multitpleFileData.length">
      <div class="flex items-center justify-between file-item" v-for="item in multitpleFileData" :key="item.name" >
        <div class="flex items-center text-grey text-sm">
          <i class="ri-file-text-line mr-1"></i>
          <p class="text-xs">{{item.name | truncate(30)}}</p>
        </div>
        <div class="flex items-center">
          <p class="text-brandPurple underline font-semibold text-xs mr-3 cursor-pointer" @click="downloadItem(item.data.id)">View</p>
          <i class="ri-delete-bin-line text-sm text-grey cursor-pointer" @click="deleteFile(item.data.id)"></i>
        </div>
      </div>
  </div>
    <div class="file-options-container" v-if="!multiple && name.length">
      <div class="flex items-center justify-between file-item">
        <div class="flex items-center text-grey text-sm">
          <i class="ri-file-text-line mr-1"></i>
          <p class="text-xs">{{name | truncate(30)}}</p>
        </div>
        <div class="flex items-center">
          <p class="text-brandPurple underline font-semibold text-xs mr-3 cursor-pointer" @click="downloadItem(imageData.id)">View</p>
          <i class="ri-delete-bin-line text-sm text-grey cursor-pointer" @click="deleteFile()"></i>
        </div>
      </div>
  </div>
    <slot name="desc">
      <p class="text-left text-grey text-xs mt-1">{{ desc }}</p>
    </slot>

    <!-- 
    <AnimateHeight>
      <div
        class="p-4 rounded flex justify-between mt-2 bg-dashGrey"
        v-show="uploadSuccess"
      >
        <div class="flex items-center">
          <div class="mr-2">
          </div>
          <div>
            <p class="text-brandGreen text-sm">{{ name }}</p>
            <p class="text-grey text-sm">{{ size }}KB</p>
          </div>
        </div>
     
      </div>
    </AnimateHeight> -->
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { UPLOAD_IMAGE } from "@/utils/api/media";
import AnimateHeight from "../../AnimateHeight";
import { Button, SecondaryButton } from "@/UI/Button";
import file from "@/mixins/file";

export default {
  mixins: [file],
  components: {
    AnimateHeight,
    Button,
    SecondaryButton,
  },
  props: {
    id: {
      type: String,
      deafult: "",
      required: false,
    },
    value: {
      type: [String, Array],
      default: "",
      // required: true,
    },
    text: {
      type: String,
      default: "",
      required: false,
    },
    validation: {
      type: Boolean,
      default: false,
      required: false,
    },
    fileType: {
      type: Array,
      default: () => [],
      required: true,
    },
    width: {
      type: String,
      default: "w-auto",
      required: false,
    },
    fileSize: {
      type: Number,
      default: 5000,
      required: false,
    },
    desc: {
      type: String,
      default: "Maximum file size: 5mb",
      required: false,
    },
    label: {
        type: String,
        default: "",
        required: false,
    },
    required: {
      type: Boolean,
      default: false,
      required: false
    },
    placeholder:{
      type: String,
      default:'Upload a file',
      required: false
    },
    multiple:{
      type: Boolean,
      default: false,
      required: false
    },
    maxUploadNumber:{
      type: Number,
      default: 2,
      required: false
    }
  },
  // mounted() {
  //   if (this.value.length > 0) {
  //     this.uploaded = true;
  //   }
  // },
  data() {
    return {
      loading: false,
      name: "",
      size: "",
      image: "",
      uploadSuccess: false,
      imageData: "",
      multitpleFileData:[]
      // uploaded: false,
      // accept: ['.png'],
    };
  },
  computed:{
    textHolder(){
      if(this.name.length){
        return this.name
      }else{
        return this.placeholder
      }
    },
    uploaded() {
      return !this.multiple && !!this.value;
    }
  },
  methods: {
    ...mapActions("notification", ["showToast"]),
    valid() {},
    uploadImage(e) {
      if(this.multiple && this.maxUploadNumber == this.multitpleFileData.length) return this.showToast({
        display: true,
        description: `Maximum number of files (${this.maxUploadNumber}) uploaded.`,
        icon: "error",
      })
    if (this.fileType.length) {
      const fileName = e.target.files[0].name.split(".");
      const extension = fileName[fileName.length - 1];
      if (this.fileType.includes(extension)) {
        const fileSize = e.target.files[0].size / 1000;
        if (fileSize <= this.fileSize) {
          this.loading = true;
          const formData = new FormData();
          formData.append("file", e.target.files[0]);
          UPLOAD_IMAGE(formData)
            .then((res) => {
              this.loading = false;
              this.uploadSuccess = true;
              if(this.multiple){
                // adding the data so when we want to delete,wecan remove it
                this.multitpleFileData.push({
                  name: e.target.files[0].name,
                  data: res.data.data
                })
                let arrayOfIds = this.multitpleFileData.map((item) => item.data.id);
                this.$emit('upload', arrayOfIds);
                this.$emit('input', arrayOfIds);
              }else{
                this.name= e.target.files[0].name
                this.imageData = res.data.data;
                this.$emit("fileUploaded", e.target.files[0].name);
                this.$emit("upload", res.data.data);
                this.$emit("input", res.data.data);
              }
              this.showToast({
                description: "File uploaded successfully",
                display: true,
                icon: "success",
              });
            })
            .catch(() => {
              this.loading = false;
              this.showToast({
                title: " 😌",
                description: "Upload Failed, Please try again",
                display: true,
                icon: "error",
              });
            });
        } else {
          this.showToast({
            display: true,
            description: `File size cannot be more than ${
              this.fileSize >= 1000 ? this.fileSize / 1000 : this.fileSize
            }${this.fileSize < 1000 ? "KB" : "MB"}`,
            icon: "error",
          });
        }
      } else {
        // Handle invalid file type
        this.showToast({
              display: true,
              description: "Please upload a supported file format",
              icon: "error",
        });
        valid = false;
      }
    } else {
      // Handle empty file type
    }
  },
  deleteFile(id) {
      // this.uploaded = false;
      if(this.multiple){
        let index =   this.multitpleFileData.findIndex((item) => item.data.id == id);
        this.multitpleFileData.splice(index,1);
        this.$emit('input', this.multitpleFileData)
      }else{
        this.name = "";
        this.$emit("deleted");
      }
    },
    downloadItem(id) {
            window.open(
            `${process.env.VUE_APP_BASE_URL}/media/image/${id}`,
            "_blank" // open in a new window.
            );
    },
  },
};
</script>
<style scoped>
label {
  display: inline-block;
}
.input {
  /* border: 1px solid hsl(251, 100%, 90%); */
  padding: 14px 10px;
  color: #706d78 !important;
  border-radius: 8px;
  font-size: 18px;
  background-color: transparent;
  position: relative;
  z-index: 2;
  -webkit-appearance: none;
  transition: all 0.3s linear;
  height: 3rem;
}
.label-text{
  color: #1e1d20;
    font-weight: 400;
    font-size: 14px;
    display: block;
    margin-bottom: 8px
}
.input-regular {
  border: 1px solid #ededf0 !important;
  background-color: #fff !important;
}
.input-active {
  border: 1px solid #d5ccff !important;
  background-color: transparent !important;
}
.error-border {
  border: 1px solid #ffd5cc !important;
  background: #fff7f5 !important;
  color: #fc3c11 !important;
}
.file-options-container{
  border:1px solid #E3E8EF;
  border-radius: 8px
}

.file-item{
  padding: 4px 12px;
  border-bottom: 1px solid #E3E8EF
}

.file-item p{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.file-item:last-child{
  border:none
}
.placeholder {
  color: #9d9d9d;
}
.line {
  /* border: 1px solid black; */
  background: black;
  height: 1px;
  margin-top: 0.3rem;
}
.bg {
  background-color: #eefcff;
}
.slide-enter-to,
.slide-leave {
  max-height: 100px;
  overflow: hidden;
}
.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}
.lds-dual-ring {
  display: inline-block;
  width: 22px;
  height: 22px;
  position: absolute;
  right: 1rem;
  z-index: 5;
  bottom: 1.3rem
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 22px;
  height: 22px;
  margin: 8px;
  border-radius: 50%;
  border: 3px solid #023a59;
  border-color: #023a59 transparent #023a59 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
