<template>
  <Card class="h-full purchase-container">
    <h5 class="text-lg font-semibold">Company List</h5>
    <Hr />
    <Spinner class="text-center" v-if="loading" />
    <div class="table-container" v-else>
      <table id="template-table" class="w-full table-auto">
        <thead>
          <tr>
            <th>S/N</th>
            <th>Name</th>
            <th>Status</th>
            <th>Domains</th>
            <th>Address</th>
            <th>Action</th>
          </tr>
        </thead>

        <tbody>
          <EmptyState
            description="No records found"
            v-if="allCompanies.length === 0"
          />
          <tr v-else v-for="(company, index) in allCompanies" :key="company.id">
            <th>{{ index + 1 }}</th>
            <th>
              {{ company.companyName | capitalize }}
            </th>
            <th
              :class="[
                company.eligibility.toLowerCase() === 'pending'
                  ? 'pending'
                  : company.eligibility.toLowerCase() === 'not_eligible'
                  ? 'failed'
                  : 'success',
              ]"
            >
              {{ company.eligibility.replace(/_/g, " ") | capitalize }}
            </th>
            <th>
              <a
                class="underline"
                :href="`https://www.${email}`"
                v-for="email in company.emailDomains"
                :key="email"
                target="blank"
              >
                {{ email }},
              </a>
            </th>
            <th>
              {{ company.officeAddress }}
            </th>
            <th class="flex">
              <SecondaryButton
                v-if="company.eligibility !== 'ELIGIBLE'"
                text="Approve"
                background="#007E33"
                color="white"
                @click="makeEligible(company.id)"
                width="w-20"
                class="ml-2"
              />
              <SecondaryButton
                v-if="company.eligibility !== 'NOT_ELIGIBLE'"
                text="Dissaprove"
                background="#ff4444"
                color="white"
                @click="cancelEligible(company.id)"
                width="w-20"
                class="ml-2"
              />
            </th>
          </tr>
        </tbody>
      </table>
    </div>
    <Pagination
      :handleMore="handleMore"
      :loading="paginateLoading"
      @paginate="handlePagination"
    />
  </Card>
</template>
<script>
import { mapActions, mapState } from "vuex";
import Hr from "@/UI/Hr";
import { SecondaryButton } from "@/UI/Button";
export default {
  components: {
    Hr,
    EmptyState: () => import("@/UI/EmptyState"),
    Spinner: () => import("@/UI/Spinner"),
    Pagination: () => import("@/UI/Pagination"),
    SecondaryButton,
  },
  data: () => ({
    loading: false,
    paginateLoading: false,
    handleMore: false,
    params: {
      page: 0,
      pageSize: 20,
    },
    eligibleLoading: false,
  }),
  computed: {
    ...mapState({
      allCompanies: (state) => state.customers.allCompanies,
    }),
  },
  mounted() {
    this.getCompanies();
  },
  methods: {
    ...mapActions("customers", [
      "getAllCompanies",
      "makeCompanyEligible",
      "makeCompanyIneligible",
    ]),
    ...mapActions("loading", ["setLoading"]),
    ...mapActions("notification", ["showToast"]),
    getCompanies(type) {
      type === "paginate"
        ? (this.paginateLoading = true)
        : (this.loading = true);
      this.getAllCompanies({ params: this.params, type: type })
        .then((data) => {
          if (data?.data.content.length === this.params.pageSize) {
            this.handleMore = true;
          } else {
            this.handleMore = false;
          }
          type === "paginate"
            ? (this.paginateLoading = false)
            : (this.loading = false);
        })
        .catch(() => {
          type === "paginate"
            ? (this.paginateLoading = false)
            : (this.loading = false);
        });
    },
    handlePagination() {
      this.paginateLoading = true;
      this.params.page++;
      this.getCompanies("paginate");
    },
    makeEligible(id) {
      this.setLoading(true);
      this.makeCompanyEligible(id)
        .then(() => {
          this.setLoading(false);
          this.showToast({
            type: "success",
            display: true,
            description: "Company sucessfully approved",
          });
          this.params.page = 0;
          this.getCompanies();
        })
        .catch(() => {
          this.setLoading(false);
        });
    },
    cancelEligible(id) {
      this.setLoading(true);
      this.makeCompanyIneligible(id)
        .then(() => {
          this.setLoading(false);
          this.showToast({
            type: "success",
            display: true,
            description: "Company sucessfully disapproved",
          });
          this.params.page = 0;
          this.getCompanies();
        })
        .catch(() => {
          this.setLoading(false);
        });
    },
  },
};
</script>
<style scoped>
.purchase-container {
  min-height: calc(100vh - 6rem);
}
.payment-table {
  display: grid;
  grid-template-columns: 2.5rem 1fr 8rem 18rem 1fr 1fr;
  grid-column-gap: 10px;
  align-items: center;
}
.payment-table__col {
  padding: 0.8rem 0.5rem;
  font-size: 0.9rem;
}
.table-container {
  overflow: auto;
}
</style>
