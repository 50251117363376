import axios from "@/plugins/axios";

export const GET_CURRENCY_MAPPINGS = () => {
  return axios.get(`/bnpl/v1/currency-mapping`);
};

export const CREATE_NEW_MAPPINGS = (data) => {
  return axios.post(`/bnpl/v1/currency-mapping`, data);
};

export const UPDATE_CURRENCY_MAPPINGS = (data) => {
  return axios.put(`/bnpl/v1/currency-mapping`, data);
};

// account
export const GET_ALL_REGISTRATION = (params) => {
  return axios.get(`/bnpl/v1/wire-account/admin-filter`, { params: params });
};

export const GET_ALL_PENDING_REGISTRATION = (params) => {
  return axios.get(`/bnpl/v1/wire-account-registration`, { params: params });
};

export const UPDATE_MERCHANT_FOR_WIRE = ({ merchantId, phoneNumber }) => {
  return axios.put(`/bnpl/v1/wire-account-registration/activate-merchant/merchant-id/${merchantId}?phoneNumber=${phoneNumber}`);
};

export const GET_ALL_PAYMENTS = (url) => {
  return axios.get(url);
};

export const UPDATE_WIRE_ACCOUNT = (data) => {
  return axios.put(`/bnpl/v1/wire-account/admin-update`, data);
};

export const CREATE_WIRE_ACCOUNT = (data) => {
  return axios.post(`/bnpl/v1/wire-account-registration/admin`, data);
};
export const BUSINESS_WIRE_REGISTRATION = ({ data, wireAccountId }) => {
  return axios.put(`/bnpl/v1/wire-account/${wireAccountId}/submit-business-registration-information-by-admin`, data);
};
//

export const UPDATE_BUSINESS_WIRE_REGISTRATION = (data) => {
  return axios.put(`/bnpl/v1/business-registration-info/admin`, data)
}
export const ADD_NEW_DIRECTOR_INFO = ({ data, wireAccountId }) => {
  return axios.put(`/bnpl/v1/wire-account/${wireAccountId}/add-business-director-by-admin`, data);
};

export const EDIT_WIRE_DIRECTOR_INFO = ({data, wireAccountId, }) => {
  return axios.put(`/bnpl/v1/business-director/admin`, data);
};

export const DECLINE_DIRECTOR = (data) => {
  return axios.put(`/bnpl/v1/business-director/admin-decline`, data)
}

export const DECLINE_KYB = (data) => {
  return axios.put(`/bnpl/v1/business-registration-info/admin-decline`, data)
}


export const UPDATE_SENDERS_FORCED_KYB = ({data, wireAccountId}) => {
  return axios.put(`/bnpl/v1/wire-sender-profile/wire-account/${wireAccountId}/admin-update-forced-kyc-status`, data)
}

export const DEACTIVATE_ACCOUNT = (wireAccountId) => {
  return axios.put(`/bnpl/v1/wire-account/${wireAccountId}/deactivate`)
}
export const REACTIVATE_ACCOUNT = (wireAccountId) => {
  return axios.put(`/bnpl/v1/wire-account/${wireAccountId}/reactivate`)
}

export const BLOCK_ACCOUNT = (wireAccountId) => {
  return axios.put(`/bnpl/v1/wire-account/${wireAccountId}/block`)
}

export const UN_BLOCK_ACCOUNT = (wireAccountId) => {
  return axios.put(`/bnpl/v1/wire-account/${wireAccountId}/unblock`)
}

export const GET_RATE_ADJUSTMENT  = (wireAccountId) => {
  return axios.get(`/bnpl/v1/wire-rate-adjustment/wire-account/${wireAccountId}/get-account-rate-adjustment`)
}
 
// export const CREATE_WIRE_ACCOUNT = (data) => {
//   return axios.post(`/bnpl/v1/wire-account-registration/admin`, data)
// }



// payments
export const UPDATE_WIRE_PAYMENT = (data) => {
  return axios.put(`/bnpl/v1/wire-payment/admin-update`, data);
};

export const UPDATE_WIRE_PAYMENT_COMPLETE = (data) => {
  // return axios.put(`/bnpl/v1/wire-payment/admin-update-to-complete`, data)
  return axios.put(`/bnpl/v1/wire-payment/admin-complete`, data);
};

export const UPDATE_WIRE_PAYMENT_DEClINED = (data) => {
  return axios.put(`/bnpl/v1/wire-payment/admin-decline`, data);
};
export const UPDATE_KYB_STATUS = (data) => {
  return axios.put(`/bnpl/v1/business-registration-info/admin-review`, data);
};

export const APPROVE_KYB_ADMIN = (data) => {
  return axios.put(`/bnpl/v1/business-registration-info/admin-approve`, data)
}

export const UPDATE_WIRE_PAYMENT_SCHEDULE = (wirePaymentId) => {
  return axios.put(`/bnpl/v1/wire-payment/${wirePaymentId}/admin-schedule`);
};

export const UPDATE_WIRE_PAYMENT_PROCESSING = (data) => {
  // return axios.put(`/bnpl/v1/wire-payment/${wirePaymentId}/admin-process`)
  return axios.put(`/bnpl/v1/wire-payment/admin-process`, data);
};

export const MAKE_PAYMENT = ({ data, wireAccountId }) => {
  return axios.post(`/bnpl/v1/wire-payment/wire-account/${wireAccountId}/create-for-customer`, data);
};

export const CALCULATE_PAYMENT_FEE = (data) => {
  return axios.put(`/bnpl/v1/wire-payment/calculate-fee-admin`, data);
};

export const GET_USER_PAYMENT_BENEFICIARY = (wireAccountId, params) => {
  return axios.get(`/bnpl/v1/wire-beneficiary/wire-account/${wireAccountId}`, {params: params});
};

export const GET_SINGLE_BENEFICIARY_DETAILS = (wireBeneficiary, wireAccountId) => {
  return axios.get(`/bnpl/v1/wire-beneficiary/${wireBeneficiary}/wire-account/${wireAccountId}`);
};

export const LOOKUP_SWIFT = (swiftCodeOrRoutingNumber) => {
  // return axios.get(`/bnpl/v1/wire-payment/swift-code-or-routing-number/${swiftCodeOrRoutingNumber}`)
  return axios.get(`/bnpl/v1/wire-payment/swift-code-or-routing-number/${swiftCodeOrRoutingNumber}/admin`);
};

export const VIEW_PAYMENT_DETAILS = ({ wirePaymentId}) => {
  return axios.get(`/bnpl/v1/wire-payment/${wirePaymentId}/get-payment-details-admin`);
};

export const VIEW_ACCOUNT_DETAILS = (wireAccountId) => {
  return axios.get(`/bnpl/v1/wire-account/${wireAccountId}/admin-view`);
};

export const GET_CURRENCIES = () => {
  return axios.get(`/bnpl/v1/currency-info`);
};

export const GET_ACCOUNT_WALLET_HISTORY = (wireAccountId, params) => {
  return axios.get(`/bnpl/v1/wire-account/${wireAccountId}/wallet-history/admin-filter`, { params: params });
};

export const GET_ACCOUNT_PARTNER_REGISTERED= (data) => {
  return axios.get(`/bnpl/partner-registration`, data);
};

export const GET_PAYMENT_RECIEPT = (wirePaymentId) => {
  return axios.get(`/bnpl/v1/request-wire-payment-receipt/${wirePaymentId}/get-payment-receipt-admin`)
}



export const UPDATE_PAYMENT_DETAILS = (data) => {
  return axios.put(`/bnpl/v1/wire-payment/admin-update-payment-details`, data)
}
// payouts

export const GET_PAYOUTS_WALLETS = () => {
  return axios.get(`/bnpl/v1/admin-payout/wallet`);
};

export const GET_BENEFICIARIES = (params) => {
  return axios.get(`/bnpl/v1/admin-payout/saved-beneficiaries`, {params: params});
};

export const GET_PAYOUT_HISTORY = (params) => {
  return axios.get(`/bnpl/v1/admin-payout`, { params: params });
};
export const INITIATE_PAYOUT = (payload) => {
  return axios.post(`/bnpl/v1/admin-payout/initiate`, payload);
};

export const BANK_LIST = () => {
  return axios.get(`/payments/bank`);
};

export const BANK_ACCOUNT_LOOKUP = (data) => {
  return axios.post(`/payments/lookup/account-number`, data);
};

// payment discount

export const GET_RATE_ADJUSTMENTS_LIST = (params) => {
  return axios.get(`/bnpl/v1/wire-rate-adjustment/get-all-rate-adjustment`, { params: params });
};

export const DEACTIVATE_RATE_DISCOUNT = (data) => {
  return axios.put(`/bnpl/v1/wire-rate-adjustment/deactivate`, data);
};

export const CREATE_RATE_DISCOUNT = (data) => {
  return axios.post(`/bnpl/v1/wire-rate-adjustment/create`, data);
};

// owner disputes

export const GET_INITIATED_OWNER_REMOVAL = () => {
  return axios.get(`/bnpl/v1/wire-user/deletion-log/get-all-initiated`);
};

export const COMPLETE_INITAITED_OWNER_REMOVAL = ({ deletionLogId }) => {
  return axios.put(`/bnpl/v1/wire-user/deletion-log/${deletionLogId}/admin-complete-initiated-owner-account-removal`);
};

export const CANCEL__INITIATED_OWNER_REMOVAL = ({ deletionLogId }) => {
  return axios.put(`/bnpl/v1/wire-user/deletion-log/${deletionLogId}/admin-cancel-initiated-owner-account-removal`);
};

export const SEND_ACCOUNT_DELETION_EMAIL = ({ deletionLogId }) => {
  return axios.put(`/bnpl/v1/wire-user/deletion-log/${deletionLogId}/admin-send-owner-account-removal-email-notification`);
};

// owner role update

export const GET_INITIATED_OWNER_UPDATE = () => {
  return axios.get(`/bnpl/wire-account-authorization-config/role-update-logs/get-all-initiated`);
};

export const COMPLETE_INITIATED_OWNER_ROLE_UPDATE = ({ roleUpdateLogId }) => {
  return axios.put(`/bnpl/wire-account-authorization-config/role-update-log/${roleUpdateLogId}/admin-complete-initiated-owner-account-role-update`);
};

export const CANCEL_INITIATED_OWNER_ROLE_UPDATE = ({ roleUpdateLogId }) => {
  return axios.put(`/bnpl/wire-account-authorization-config/role-update-log/${roleUpdateLogId}/admin-cancel-initiated-owner-account-role-update`);
};

export const SEND_OWNER_ROLE_UPDATE_EMAIL = ({ roleUpdateLogId }) => {
  return axios.put(
    `/bnpl/wire-account-authorization-config/role-update-log/${roleUpdateLogId}/admin-owner-account-role-update-send-email-notification`
  );
};

// broker
export const UPDATE_BROKER_ACCESS_STATUS = (data) => {
  return axios.put(`/bnpl/v1/wire-account/admin/update-broker-access-status`, data);
};

export const FORCED_BROKER_ELIGIBILITY = (data) => {
  return axios.put(`/bnpl/v1/wire-account/update-forced-broker-access-eligibility-status`, data);
};

export const GET_BROKER_WALLET_BALANCE = (wireAccountId) => {
  return axios.get(`/bnpl/v1/wire-commission_wallet/wire-account/${wireAccountId}/admin-get-commission-wallets`);
};

export const GET_BROKER_COMMISION_HISTORY = ({ wireAccountId, params }) => {
  return axios.get(`/bnpl/v1/wire-commission_wallet/wire-account/${wireAccountId}/admin-get-commission-wallets-history`, { params: params });
};

export const GET_REFERRED_BROKER_LIST = ({ referrerId, params }) => {
  return axios.get(`/bnpl/v1/wire/referral/${referrerId}/admin-fetch-referred-accounts`, { params: params });
};

export const GET_PENDING_REFERRED_BROKER_LIST = () => {
  return axios.get(`/bnpl/v1/wire/referral/admin-fetch-all-pending-signup-referred-accounts`);
};


export const GET_BROKER_SENDERS = ({wireAccountId, params}) => {
  return axios.get(`/bnpl/v1/wire-sender-profile/wire-account/${wireAccountId}/admin-get-all`, {params: params})
}


export const GET_SENDER_DETAILS = ({ wireSenderProfileId, wireAccountId }) => {
  return axios.get(`/bnpl/v1/wire-sender-profile/${wireSenderProfileId}/wire-account/${wireAccountId}/admin-get-details`);
};



