import axios from "@/plugins/axios";

export const GET_ADMIN_PERMISSIONS = () => {
  return axios.get(`/bnpl/admin-authorization-config/get-permissions`);
};

export const GET_ADMIN_ROLES = () => {
  return axios.get(`/bnpl/admin-authorization-config/get-roles`);
};

export const ASSIGN_ADMIN_ROLE = (data) => {
  return axios.put(`/bnpl/admin-authorization-config/assign-role`, data);
};

export const CREATE_ADMIN_ROLE = (data) => {
  return axios.put(`/bnpl/admin-authorization-config/create-role`, data);
};

export const ADD_PERMISSION_TO_ROLE = (data) => {
  return axios.put(`/bnpl/admin-authorization-config/add-permission-to-role`, data);
};
export const LIST_ADMIN_USERS = (params) => {
  return axios.get(`/lighthouse-user`, params);
};

export const GET_ROLES_BY_ID = (id) => {
  return axios.get(`/bnpl/admin-authorization-config/get-roles/principal/${id}`);
};

export const REMOVE_ROLE_FROM_USER = (id) => {
  return axios.put(`/bnpl/admin-authorization-config/unassign-role/principal-resource-role/${id}`);
};

export const GET_ROLE_PEERMISSIONS = (id) => {
  return axios.get(`/bnpl/admin-authorization-config/get-permissions/resource-role/${id}`);
};

export const REMOVE_PERMISSION_FROM_ROLE = (data) => {
  return axios.put(`/bnpl/admin-authorization-config/remove-permission-from-role`, data);
};
