<template>
  <Login />
</template>
<script>
  import Login from "@/components/Auth/Login";
  export default {
    components: {
      Login,
    },
  };
</script>
