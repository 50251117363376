<template>
  <div>
    <div v-if="showNav" class="overlay z-20"></div>
    <div class="flex justify-between items-center px-6 py-4 bg-white header">
      <div class="h-6 w-20 md:h-8 md:w-24">
        <img
          class="max-h-full max-w-full"
          src="https://res.cloudinary.com/zillaafrica/image/upload/v1623528889/customer/Zilla_Updated_1_rjqezm.svg"
          alt=""
        />
      </div>
      <svg
        @click.stop="showNav = !showNav"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="20"
        fill="none"
      >
        <path
          d="M0 1.83h24M0 9.83h24M0 17.83h24"
          stroke="#7E7694"
          stroke-width="3"
        />
      </svg>
    </div>
    <transition name="slide">
      <div
        v-if="showNav"
        v-click-outside="handleOptionsOutside"
        class="
          z-50
          sidebar-menu
          inset-y-0
          left-0
          w-2/3
          bg-white
          overflow-y-auto
          sm:hidden
        "
      >
        <div class="relative w-full">
          <div class="sidebar-content mt-8">
            <div class="nav-item pl-8 mt-6">
              <div
                class="
                  main-head
                  flex
                  align-items
                  justify-between
                  cursor-pointer
                "
                @click="showCustomers = !showCustomers"
              >
                <p class="text-lightPurple-2">Customers</p>
                <img
                  class="h-3 my-auto"
                  src="https://res.cloudinary.com/zillaafrica/image/upload/v1650622183/13863747411556280997_aifldl.svg"
                  alt=""
                />
              </div>
              <!-- customer nav options -->
              <AnimateHeight>
                <div
                  class="bg-white rounded px-2 py-4 menu-sub mt-2"
                  v-if="showCustomers"
                >
                  <div @click.stop="showNav = !showNav">
                    <router-link to="/" class="flex items-center pl-2">
                      <p class="text-lightPurple-2 p-2">All Customers</p>
                    </router-link>
                  </div>

                  <div @click.stop="showNav = !showNav">
                    <router-link
                      to="/verify-employment"
                      class="flex items-center pl-2 mt-4"
                    >
                      <p class="text-lightPurple-2 p-2">Verify employment</p>
                    </router-link>
                  </div>
                  <div @click.stop="showNav = !showNav">
                    <router-link
                      to="/customers/company"
                      class="flex items-center pl-2 mt-4"
                    >
                      <p class="text-lightPurple-2 p-2">Company</p>
                    </router-link>
                  </div>
                  <div @click.stop="showNav = !showNav">
                    <router-link
                      to="/verify-bank"
                      class="flex items-center pl-2 mt-4"
                    >
                      <p class="text-lightPurple-2">Bank Statement</p>
                    </router-link>
                  </div>
                </div>
              </AnimateHeight>
            </div>

            <div @click.stop="showNav = !showNav">
              <router-link
                to="/merchants"
                class="flex nav-item items-center pl-8 mt-6"
              >
                <p class="text-lightPurple-2">Merchants</p>
              </router-link>
            </div>
            <div @click.stop="showNav = !showNav">
              <router-link
                to="/purchases"
                class="flex nav-item items-center pl-8 mt-6"
              >
                <p class="text-lightPurple-2">Purchases</p>
              </router-link>
            </div>
            <div class="nav-item pl-8 mt-6">
              <div
                class="
                  main-head
                  flex
                  align-items
                  justify-between
                  cursor-pointer
                "
                @click="showPartners = !showPartners"
              >
                <p class="text-lightPurple-2">Partners</p>
                <img
                  class="h-3 my-auto"
                  src="https://res.cloudinary.com/zillaafrica/image/upload/v1650622183/13863747411556280997_aifldl.svg"
                  alt=""
                />
              </div>
              <!-- partner nav options -->
              <AnimateHeight>
                <div
                  class="bg-white rounded px-2 py-4 menu-sub mt-2"
                  v-if="showPartners"
                >
                  <div @click.stop="showNav = !showNav">
                    <router-link
                      to="/partner-registration"
                      class="flex items-center pl-2"
                    >
                      <p class="text-lightPurple-2 p-2">Partner Registration</p>
                    </router-link>
                  </div>

                  <div @click.stop="showNav = !showNav">
                    <router-link
                      to="/partners"
                      class="flex items-center pl-2 mt-4"
                    >
                      <p class="text-lightPurple-2 p-2">All Partners</p>
                    </router-link>
                  </div>
                </div>
              </AnimateHeight>
            </div>
            <div>
              <div @click="logout" class="flex nav-item items-center pl-8 mt-6">
                <p class="text-lightPurple-2">Logout</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { Button } from "@/UI/Button";
import AnimateHeight from "@/UI/AnimateHeight";
import { mapActions } from "vuex";

export default {
  components: {
    Button,
    AnimateHeight,
  },
  data: () => ({
    showNav: false,
    openPopover: false,
    showPartners: false,
    showCustomers: false,
  }),
  computed: {
    // logo() {
    //   return `${process.env.VUE_APP_BASE_URL}/media/image/${this.merchant.merchant.logoId}`;
    // },
  },
  watch: {
    showNav(value) {
      if (value) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    },
  },
  methods: {
    ...mapActions("auth", ["logout"]),
    handleOptionsOutside() {
      this.showNav = false;
    },
  },
};
</script>
<style scoped>
svg {
  fill: #acacba;
}
.header {
  background-color: #f6f7ff;
  box-shadow: 0px 2px 4px #d9dcf2;
}
.overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(235, 227, 255, 0.8) !important;
}
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.5s ease;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(-100%);
  transition: all 0.4s ease-in 0s;
}
.sidebar-menu {
  position: fixed;
  z-index: 200;
  opacity: none;
  height: 100%;
  box-shadow: 0px 6.65584px 39.9351px rgba(128, 70, 251, 0.2);
}
.nav-item {
  padding: 0.8rem 2rem 0.8rem 3rem;
}
.sidebar-content a.router-link-exact-active {
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  background-color: #f6f5ff;
}
.sidebar-content a.router-link-exact-active p {
  color: #000000;
  font-weight: bold;
}
.sidebar-content a.router-link-exact-active svg {
  fill: #000000;
}
.logout-card div:hover {
  color: #c70039;
  fill: #c70039;
  stroke: #c70039;
}

.sidebar {
  overflow: auto;
}

.sidebar::-webkit-scrollbar {
  width: 5px !important;
}
.sidebar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #d3d3d3;
  border-radius: 10px;
}
.sidebar::-webkit-scrollbar-thumb {
  background: #c0c0c0;
  border-radius: 10px;
}
</style>
