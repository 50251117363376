<template>
  <!-- <div> -->
  <div class="spinner-container">
    <div class="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
  <!-- </div> -->
</template>
<script>
export default {
  name: "Spinner",
};
</script>
<style scoped>
.spinner-container {
  /* background-color: rgba(235, 227, 255, 0.8); */
  /* height: 100%;
    width: 100%; */
  /* display: grid; */
  /* place-items: center; */
  z-index: 10;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 1.7rem;
  height: 1.7rem;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  margin: 8px;
  border: 2px solid black;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: black transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
