import { GET_CURRENCY_MAPPINGS, CREATE_NEW_MAPPINGS, UPDATE_CURRENCY_MAPPINGS, GET_ALL_REGISTRATION, GET_ALL_PENDING_REGISTRATION, GET_CURRENCIES } from "@/utils/api/wire";
export default {
  getCurrencyMappings({ commit }, payload) {
    return new Promise((resolve, reject) => {
      GET_CURRENCY_MAPPINGS(payload)
        .then((res) => {
          commit("SET_CURRENCY_MAPPINGS", res.data.data);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  createNewCurrencyMappings({ }, payload) {
    return new Promise((resolve, reject) => {
      CREATE_NEW_MAPPINGS(payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateCurrencyyMap({ }, payload) {
    return new Promise((resolve, reject) => {
      UPDATE_CURRENCY_MAPPINGS(payload)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getAllRegistration({ commit }, payload) {
    return new Promise((resolve, reject) => {
      GET_ALL_REGISTRATION(payload.params)
        .then((response) => {
          payload.type === "paginate"
            ? commit("ADD_ALL_REGISTRATON_DATA", response.data.data)
            : commit("GET_ALL_REGISTRATON_DATA", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getAllPendingRegistration({ commit }, payload) {
    return new Promise((resolve, reject) => {
      GET_ALL_PENDING_REGISTRATION(payload.params)
        .then((response) => {
          payload.type === "paginate"
            ? commit("ADD_ALL_PENDING_REGISTRATON_DATA", response.data.data)
            : commit("GET_ALL_PENDING_REGISTRATON_DATA", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getAllCurrencyAction({commit}){
    return new Promise((resolve, reject) => {
      GET_CURRENCIES().then(({data}) => {
        commit('SET_CURRENCIES', data.data);
        resolve(data)
      }).catch((error) => {
        reject(error)
      })
    })
  }
};
