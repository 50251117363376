<template>
  <Payments />
</template>
<script>
  import Payments from "@/components/bnpl/Purchases/Payments";
  export default {
    components: {
      Payments,
    },
  };
</script>
