<template>
    <RightSidebar display @close="$emit('close')" title="Referral List">
        <template #body>
            <div class="payment-link-container-body">
                <div class="mt-4 payment-details flex items-center justify-between">
                    <p class="text-sm font-semibold text-grey-3">Email</p>
                    <p class="text-dark text-sm">Date</p>
                  </div>
                <div class="mt-4 payment-details flex items-center justify-between" v-for="referral in referralList" :key="referral.referredWireAccountName">
                    <p class="text-xs text-grey-3"> {{ referral.referredWireAccountName }}</p>
                    <p class="text-dark">{{ referral.lastTransactionDate ? dayjs(referral.lastTransactionDate).format("MMM D YYYY") : 'Pending'}}</p>
                  </div>
            </div>
        </template>
    </RightSidebar>
</template>
<script>
import {mapActions} from 'vuex'
import {RightSidebar} from '@/UI/Modals'
import {GET_REFERRED_BROKER_LIST} from '@/utils/api/wire'
import Hr from '@/UI/Hr'
import dayjs from 'dayjs'
export default {
    props:{
        accountDetails:{
            type: Object,
            default:() => {}
        }
    },
    components:{
        RightSidebar,
        Hr
    },
    data:() => ({
        referralList:[]
    }),
    computed:{
        dayjs(){
            return dayjs
        }
    },
    methods:{
        ...mapActions('loading', ['setLoading']),
        getReferralList(){
            this.setLoading(true);
            GET_REFERRED_BROKER_LIST({referrerId :this.accountDetails.wireAccount.id})
                .then(({data}) => {
                    this.referralList = data.data
                    this.setLoading(false)
                }).catch(() => {
                    this.setLoading(false)
                })
        }

    },
    mounted(){
        this.getReferralList()
    }
}
</script>
<style scoped>
.payment-link-container-body {
    padding: 16px 24px;
  }

.payment-details {
    padding-bottom: 8px;
    border-bottom: 1px solid #E3E8EF;
  }
</style>