<template>
    <div class="purchase-container">
        <Card class="h-full">
            <h5 class="text-lg font-semibold">Wire Registration <span class="text-sm text-grey">with activation code</span></h5>
            <div class="flex justify-center" v-if="loading">
                <Spinner/>
            </div>
            <div class="table-container" v-else>
                <table id="template-table" class="w-full table-auto">
                    <thead>
                        <tr>
                            <th class="text-xs" >S/N</th>
                            <th class="text-xs">Business Name</th>
                            <th class="text-xs">Id</th>
                            <th class="text-xs">Created At</th>
                            <th class="text-xs">Status</th>
                            <th class="text-xs">Fullname</th>
                            <th class="text-xs">Email</th>
                            <th class="text-xs">Phone Number</th>
                            <th class="text-xs">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(data, index) in allPendingRegistration" :key="data.id">
                            <td class="text-xs">{{ index + 1 }}</td>
                            <td class="capitalize text-elipsis break-all text-xs">{{ data.businessName}}</td>
                            <td class="text-xs">{{ data.id }}</td>
                            <td class="text-xs">{{ dayjs(data.createdAt).format("MMM D YYYY | hh:mmA") }}</td>
                            <td class="text-xs">{{data.wireAccountRegistrationStatus }}</td>
                            <td class="capitalize text-xs">{{ data.firstName | capitalize}} {{ data.lastName | capitalize }}</td>
                            <td class="break-all text-xs">{{ data.email }}</td>
                            <td class="text-xs">{{ data.phoneNumber }}</td>
                            
                            <td class="text-xs"> <div class="grid grid-cols-2">
                                <SecondaryButton text="Copy link" width="w-24" @click="copy(getLink(data))" class="mr-14"/>
                            </div>
                            </td>
                        </tr>

                    </tbody>
                </table>
                <Pagination :meta="pageMeta"  :loading="paginateLoading" @nextPage="handlePagination" />
            </div>
        </Card>
    </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import { SecondaryButton } from "@/UI/Button";
import Spinner from '@/UI/Spinner'
import dayjs from 'dayjs'
import Pagination from "@/UI/Pagination";
import copy from "@/mixins/copy";
export default {
    mixins: [copy],
    components: {
        Spinner,
        SecondaryButton,
        Pagination
    },
    data: () => ({
        loading: false,
        paginateLoading: false,
        handleMore: false,
        params: {
            page: 0,
            pageSize: 20,
            email: "",
        },
        pageMeta:{}
    }),

    computed: {
        ...mapState({
            allPendingRegistration: (state) => state.wire.allPendingRegistrationData
        }),
        dayjs() {
            return dayjs
        }
    },
    methods: {
        ...mapActions('wire', ['getAllPendingRegistration']),
        listPendingRegistration(type) {
             type === "paginate" ? (this.paginateLoading = true) : (this.loading = true);
            this.getAllPendingRegistration({params:this.params, type: type})
            .then((res) => {
            // if (res.data.data.length === this.params.pageSize) {
            //   this.handleMore = true;
            // } else {
            //   this.handleMore = false;
            // }
            type === "paginate" ? (this.paginateLoading = false) : (this.loading = false);
            this.pageMeta = res.data.meta
            
            }).catch(() => {
                type === "paginate" ? (this.paginateLoading = false) : (this.loading = false);

            })
        },
        handlePagination() {
            this.paginateLoading = true;
            this.params.page++;
            this.listPendingRegistration("paginate");
        },
        getLink(item) {
            return `${process.env.VUE_APP_WIRE_URL}/complete-registration/?code=${item.activationCode}`
        },
    },
    mounted() {
        this.listPendingRegistration()
    }
}
</script>
<style scoped>
.purchase-container {
    min-height: calc(100vh - 10rem);
}
</style>