<template>
    <!-- <right-sidebar :display="display" v-if="display" @close="$emit('close')" title="Payment details"> -->
      <div id="payment-reciept" style="width: 550px; margin: auto">
        <div  class="payment-link-container-body">
          <div class="flex items-center justify-between">
            <p class=" text-primary font-bold text-xl">Receipt</p>
            <img
              class="h-6 max-w-full mt-4"
              src="https://res.cloudinary.com/zillaafrica/image/upload/v1623528889/customer/Zilla_Updated_1_rjqezm.svg"
              alt=""
            />
        </div>
          <p class="text-xs text-grey-3 mt-3">Amount sent</p>
          <div class="mt-0 currency-container" v-if="paymentDetails.receiveCurrency">
            <p class="text-dark text-2xl inline-block"><span class="font-semibold">{{ paymentDetails.receiveAmount | formatMoney(paymentDetails.receiveCurrency.sign) }}</span> 
            </p>
            <p class="uppercase text-xxs inline-block rounded px-3 pb-2 ml-2"
            :class="paymentDetails.wirePaymentStatus.toLowerCase()"> {{ paymentDetails.wirePaymentStatus }}</p>
          </div>
          <div class="beneficiary-container mt-4">
            <p class="text-grey text-sm">Beneficiary details</p>
            <div class="border rounded border-grey-1 p-4 mt-2 bg-light-grey">
              <div class="grid grid-cols-2 justify-between">
                <p class="text-grey text-xs">Beneficiary’s name</p>
                <p class="font-semibold text-xs text-right" v-if="paymentDetails.beneficiary">{{ paymentDetails.beneficiary.name | capitalize  }}</p>
              </div>
              <div class="grid grid-cols-2 justify-between mt-3" v-if="paymentDetails.beneficiary">
                <p class="text-grey text-xs">Beneficiary’s swift code/routing no</p>
                <p class="font-semibold text-xs text-right">{{ paymentDetails.beneficiary.swiftCode || paymentDetails.beneficiary.routingNumber }}</p>
              </div>
              <div class="grid grid-cols-2 justify-between mt-3">
                <p class="text-grey text-xs">Beneficiary’s account number</p>
                <p class="font-semibold text-xs text-right" v-if="paymentDetails.beneficiary">{{  paymentDetails.beneficiary.accountNumber }}</p>
              </div>
              <div class="grid grid-cols-2 justify-between mt-3">
                <p class="text-grey text-xs">Beneficiary’s address</p>
                <p class="font-semibold text-xs text-right" v-if="paymentDetails.beneficiary">{{  paymentDetails.beneficiary.address  }}</p>
              </div>
            </div>
          </div>

          <div class="mt-4">
            <p class="text-grey text-sm">Sender’s details</p>
            <div class="border rounded border-grey-1 p-4 mt-2 bg-light-grey">
              <!-- <div class="grid grid-cols-2 justify-between">
                <p class="text-grey text-xs">From</p>
                <p class="font-semibold text-xs text-right">{{ dashboardData.businessKybDetails.businessRegistrationInfo.registeredBusinessName }}</p>
              </div> -->
              <div class="grid grid-cols-2 justify-between" v-if="paymentDetails.senderBusinessName">
                <p class="text-grey text-xs">From</p>
                <p class="font-semibold text-xs text-right">{{ paymentDetails.senderBusinessName }}</p>
              </div>
              <div class="grid grid-cols-2 justify-between">
                <p class="text-grey text-xs">Initiated by</p>
                <p class="font-semibold text-xs text-right capitalize">{{ paymentDetails.initiatorUserName | capitalize}}</p>
              </div>
              <div class="grid grid-cols-2 gap-3 justify-between mt-3" v-if="paymentDetails.senderAddress">
                <p class="text-grey text-xxs">Address</p>
                <p class="font-semibold text-xs text-right">{{ paymentDetails.senderAddress.main  }}</p>
              </div>
              <!-- <div class="grid grid-cols-2 gap-3 justify-between mt-3">
                <p class="text-grey text-xxs">Address</p>
                <p class="font-semibold text-xs text-right">{{ dashboardData.businessKybDetails.businessRegistrationInfo.address.main  }}</p>
              </div> -->
            </div>
          </div>
          <div class="mt-4">
            <p class="text-grey text-sm">Other details</p>
            <div class="border rounded border-grey-1 p-4 mt-2 bg-light-grey">
              <div class="grid grid-cols-2 justify-between">
                <p class="text-grey text-xs">Date sent</p>
                <p class="font-semibold text-xs text-right">{{dayjs(paymentDetails.createdAt).format("MMM D YYYY | hh:mmA")  }}</p>
              </div>
              <div class="grid grid-cols-2 justify-between mt-3"  v-if="paymentDetails.wirePaymentStatus && !paymentDetails.wirePaymentStatus.includes['COMPLETED','CANCELLED'] && paymentDetails.estimatedCompletedAt">
                <p class="text-grey text-xs">Estimated delivery</p>
                <p class="font-semibold text-xs text-right">{{ dayjs(paymentDetails.estimatedCompletedAt).format("MMM D YYYY") }}</p>
              </div>
              <div class="grid grid-cols-2 justify-between mt-3" v-if="paymentDetails.wirePaymentStatus == 'COMPLETED' && paymentDetails.completedAt">
                <p class="text-grey text-xs">Date of delivery</p>
                <p class="font-semibold text-xs text-right">{{ dayjs(paymentDetails.completedAt).format("MMM D YYYY") }}</p>
              </div>
              <div class="grid grid-cols-2 justify-between mt-3" v-if="paymentDetails.serviceProviderDetails && paymentDetails.serviceProviderDetails.trackingCode">
                <p class="text-grey text-xs">Tracking reference</p>
                <p class="font-semibold text-xs text-right">{{ paymentDetails.serviceProviderDetails.trackingCode}}</p>
              </div>
              <div class="grid grid-cols-2 justify-between mt-3" v-if="paymentDetails.serviceProviderDetails && paymentDetails.serviceProviderDetails.trackingCodeType">
                <p class="text-grey text-xs">Tracking code type</p>
                <p class="font-semibold text-xs text-right">{{ paymentDetails.serviceProviderDetails.trackingCodeType}}</p>
              </div>
            </div>
            
          </div>
  
          <div class="mt-6 pb-4">
            <p class="font-semibold text-dark">Zilla Finance Inc</p>
            <div class="flex items-center">
              <i class="ri-building-2-line mr-1 text-xxs2 text-grey"></i>
              <p class="text-xxs text-grey">256, Chapman Rd, Newark, Delaware, USA</i></p>
            </div>
            <div class="flex items-center">
                <i class="ri-building-2-line mr-1 text-xxs2 text-grey"></i>
                <p class="text-xxs text-grey">Workcity, Polystar building, 2nd round about, Lekki Phase1, Lagos, Nigeria</i></p>
            </div>
            <p class="mt-4 text-xxs2 text-grey">If you have any questions, please let us know. You can reach us on <span class="text-brandPurple">08147198097 </span> or email us at <span class="text-brandPurple">support@zilla.africa</span>  (9am to 6pm Monday to Friday, and 10am to 5pm Saturday and Sunday).</p>
          </div>
        </div>
    </div>
    <!-- </right-sidebar> -->
  </template>
  <script>
//   import { RightSidebar } from "@/UI/Modals";
  import dayjs from "dayjs";
  import {mapState} from 'vuex'

  export default {
    components: {
    //   RightSidebar,
    },
    props:{
        paymentDetails:{
            type:Object,
            required: false,
            default: () => {}
        }
    },
    computed: {
      ...mapState({
          dashboardData:(state) => state?.dashboard?.dashboardData
      }),
      dayjs() {
        return dayjs;
      },
    },
    data:() => ({
      loading: false
    })

  
    // watch: {
    //   display(value) {
    //     if (value) {
    //       this.getPaymentDetails();
    //     }
    //   },
    // },
  
    // methods: {
    //   ...mapActions("payments", ["getSinglePayment"]),
    //   getPaymentDetails() {
    //     this.loading = true;
    //     this.getSinglePayment(this.payment.id)
    //       .then((res) => {
    //         this.paymentDetails = res.data;
    //         this.loading = false;
    //       })
    //       .catch(() => {
    //         this.loading = false;
  
    //       });
    //   }
    // }
  };
  </script>
  <style scoped>
  .payment-link-container-body {
    padding: 16px 24px 0 24px;
  }
  .success,
.completed,
.completed {
  color: #31d583;
  background: rgb(209, 250, 223, 0.5);
  border: 1px solid #31d583;
}

.pending,
.in_review,
.scheduled,
.processing {
  color: #FFC130;
  background: rgb(255, 240, 204, 0.5);
  border: 1px solid #FFC130;
}
.cancelled,
.declined {
  color: #F97066;
  background: rgb(254, 228, 226, 0.5);
  border: 1px solid #F97066;
}
.currency-container{
  margin-top: -1rem
}
  </style>
  