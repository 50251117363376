<template>
    <Payments/>
</template>
<script>
import Payments from '@/components/Payments'
export default{
    components:{
        Payments
    }
}
</script>