<template>
  <p class="text-grey text-center">{{ description }}</p>
</template>
<script>
  export default {
    props: {
      description: {
        type: String,
        default: "",
        required: true,
      },
    },
  };
</script>
