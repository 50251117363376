<template>
    <div class="h-full">
        <h5 class="text-lg font-semibold">Wire Payouts</h5>
        <div class="flex items-center justify-between mt-4">
            <div class="flex items-center">
                <p class="text-2xl font-bold mr-4">{{ activeWalletBalance | formatMoney }}</p>
                <Select label="label" :reduce="(item) => item.totalBalance" :options="payoutWallets" v-model="activeWalletBalance" width="w-96"/>
            </div>
            <div class="flex items-center justify-content-between">
                <!-- <DatePicker class="mr-4" v-model="range" is-range :model-config="modelConfig" :masks="masks">
                    <template v-slot="{ inputValue, togglePopover }">
                        <Card class="flex items-center border searchContainer w-full" p="px-1.5 py-1">
                            <button class="p-2 focus:outline-none" @click="togglePopover()">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
                                    <path
                                        d="M11.09 2h-.272v-.25c0-.425-.354-.75-.818-.75-.464 0-.818.325-.818.75V2H4.818v-.25C4.818 1.325 4.464 1 4 1c-.464 0-.818.325-.818.75V2h-.273C1.845 2 1 2.775 1 3.75v7c0 .975.845 1.75 1.91 1.75h8.18c1.065 0 1.91-.775 1.91-1.75v-7C13 2.775 12.155 2 11.09 2Zm-1.363-.25c0-.15.11-.25.273-.25.164 0 .273.1.273.25v1c0 .15-.11.25-.273.25-.164 0-.273-.1-.273-.25v-1ZM4 1.5c.164 0 .273.1.273.25v1c0 .15-.11.25-.273.25-.164 0-.273-.1-.273-.25v-1c0-.15.11-.25.273-.25Zm-1.09 1h.272v.25c0 .425.354.75.818.75.464 0 .818-.325.818-.75V2.5h4.364v.25c0 .425.354.75.818.75.464 0 .818-.325.818-.75V2.5h.273c.764 0 1.364.55 1.364 1.25v.75H1.544v-.75c0-.7.6-1.25 1.364-1.25Zm9.544 8.25c0 .7-.6 1.25-1.363 1.25H2.909c-.764 0-1.364-.55-1.364-1.25V5h10.91v5.75Z"
                                        fill="#9D9D9D" stroke="#9D9D9D" stroke-width=".1" stroke-linejoin="round" />
                                    <path
                                        d="M3.727 7h6.546c.163 0 .273-.1.273-.25s-.11-.25-.273-.25H3.727c-.163 0-.272.1-.272.25s.109.25.272.25ZM3.727 8.75h6.546c.163 0 .273-.1.273-.25s-.11-.25-.273-.25H3.727c-.163 0-.272.1-.272.25s.109.25.272.25ZM3.727 10.5h6.546c.163 0 .273-.1.273-.25s-.11-.25-.273-.25H3.727c-.163 0-.272.1-.272.25s.109.25.272.25Z"
                                        fill="#9D9D9D" stroke="#9D9D9D" stroke-width=".1" stroke-linejoin="round" />
                                </svg>
                            </button>
                            <p v-if="inputValue.start === null" @click="togglePopover()" class="text-grey">
                                Date filter
                            </p>
                            <p v-else @click="togglePopover()" class="text-sm text-grey">
                                {{ inputValue.start }} - {{ inputValue.end }}
                            </p>
                        </Card>
                    </template>
                </DatePicker>
                <Button text="Filter" class="ml-4"/> -->
                <Button text="Make Payout" class="ml-2" @click="showPayout = true" 
                    v-if="getUserPermissions && getUserPermissions.includes($completePermissionFormat('admin_payout_initiate'))"
                />
            </div>
        </div>
        <Tab :options="tabOptions" v-model="tabValue" class="my-4"/>
        <PayoutList v-if="tabValue == 'Payout'" :refreshPayout="refreshPayout" />
        <BeneficiaryList v-if="tabValue == 'Beneficiary'" @beneficiaryPayout="handleBeneficiaryPayout"/>
        <LargeModal :display="showPayout" @close="showPayout = false">
            <div class="w-4/12 mx-auto">
                <p class="text-dark text-lg font-semibold my-4">Fill Payout Data</p>
                <CurrencyInput placeholder="Amount" width="w-full" v-model="payoutData.amount"/>
                <Select placeholder="Bank" :options="bankList" label="prettyName" :reduce="(item) => item.id" class="mt-6" v-model="payoutData.bankId" zIndex="10"/>
                <Input class="mt-6" placeholder="Account number" width="w-full" v-model="payoutData.accountNumber"/>
                <div class="relative">
                        <Input placeholder="Account name" class="mt-6" width="w-full" disabled v-model="payoutData.accountName"/>
                        <Spinner class="absolute right-0 top-0" v-if="lookupLoading"/>
                </div>
                <Select width="w-full" :options="payoutWallets" label="label" :reduce="((item) => item.id)" v-model="payoutData.walletId" class="mt-4" placeholder="Wallet" zIndex="5"/>
                <Input placeholder="Payment reason" class="mt-6" width="w-full" v-model="payoutData.paymentReason"/>
                <Input placeholder="Payment narratiion" class="mt-6" width="w-full" v-model="payoutData.paymentNarration"/>
                <p class="mt-4 text-grey text-sm">Attach files</p>
                <Upload class="mt-2" @upload="handleUploadFile" :fileType="['jpg', 'jpeg', 'png', 'svg']"/>
                <Upload class="mt-2" @upload="handleUploadFile" :fileType="['jpg', 'jpeg', 'png', 'svg']"/>
                <Upload class="mt-2" @upload="handleUploadFile" :fileType="['jpg', 'jpeg', 'png', 'svg']"/>
                <Upload class="mt-2" @upload="handleUploadFile" :fileType="['jpg', 'jpeg', 'png', 'svg']"/>
                <div class="flex items-center mt-6" width="w-full">
                <input type="checkbox" v-model="payoutData.saveBeneficiary"/>
                <p class="text-sm ml-2 mt-1">Save beneficiary</p>
            </div>
                <Button width="w-full" text="Submit" class="mt-6" @click="showPayoutConfirmation = true" />
            </div>
            <Modal :display="showPayoutConfirmation" @close="showPayoutConfirmation = false">
                <p class="text-dark text-xl font-semibold">Are you sure you want to complete this payout to {{payoutData.accountName}}?</p>
                <div class="flex items-center justify-end mt-4">
                    <Button outline text="Cancel" @click="showPayoutConfirmation = false"/>
                    <Button text="Make payout" class="ml-3" @click="initiatePayout" :loading="payoutLoading"/>
                </div>
            </Modal>
        </LargeModal>
     
    </div>
</template>
<script>
import { Button } from '@/UI/Button'
import {  GET_PAYOUTS_WALLETS , BANK_LIST , INITIATE_PAYOUT, BANK_ACCOUNT_LOOKUP } from '@/utils/api/wire'
import { DatePicker } from "v-calendar";
import { Modal,LargeModal } from '@/UI/Modals'
import { Input , CurrencyInput, Select, Upload } from '@/UI/Input'
import Tab from '@/UI/Tab/Tab'
import PayoutList from './PayoutList.vue'
import BeneficiaryList from './Beneiciary.vue';
import Spinner from '@/UI/Spinner';
import {mapGetters} from 'vuex'
export default {
    components:{
        Button,
        DatePicker,
        Modal,
        Input,
        CurrencyInput,
        Select,
        Upload,
        Tab,
        PayoutList,
        BeneficiaryList,
        LargeModal,
        Spinner
    },
    data:() => ({
        tabValue:'Payout',
        payoutWallets:[],
        modelConfig: {
            type: "string",
            mask: "iso",
            start: {
                timeAdjust: "00:00:00",
            },
            end: {
                timeAdjust: "23:59:59",
            },
        },
        range: {
            start: "",
            end: "",
        },
        masks: {
            input: "DD MMM YY",
        },
        showPayout: false,
        refreshPayout: false,
        payoutData: {
            walletId: "",
            amount: 0,
            paymentReason: "",
            paymentNarration: "",
            proofs: [],
            accountNumber: "",
            accountName: "",
            bankId: "",
            saveBeneficiary: false
        },
        payoutLoading: false,
        bankList:[],
        activeWalletBalance:'',
        lookupLoading: false,
        showPayoutConfirmation: false
    }),
    computed: {
        dayjs() {
            return dayjs
        },
        ...mapGetters('auth', ['getUserPermissions']),
        tabOptions(){
            if(this.getUserPermissions && this.getUserPermissions.includes(this.$completePermissionFormat('wire_beneficiary_fetch'))) return ['Payout', 'Beneficiary'];
            return ['Payout']
        }
    },
    watch:{
        'payoutData.accountNumber'(value){
            if(value.length === 10){
                this.getBankLookup()
            }
        },
        'payoutData.bankId'(to , from){
            // console.log
            this.payoutData.accountName = '';
            this.payoutData.accountNumber =  ''
        }
    },
    methods:{
       
      
        getWalletPayouts(){
            GET_PAYOUTS_WALLETS().then(({data}) => {
                this.payoutWallets = data.data;
                this.activeWalletBalance = this.payoutWallets[0].totalBalance
            })
        },
        getBankList(){
            BANK_LIST()
                .then(({data}) => {
                    this.bankList = data.data
                }).catch((error) => {})
        },
        initiatePayout(){
            this.payoutLoading = true;
            INITIATE_PAYOUT(this.payoutData).then(() => {
                this.payoutLoading = false;
                this.showPayout = false;
                this.tabValue = 'Payout';
                this.refreshPayout = true
            }).catch(() => {
                this.payoutLoading = false;
            })
        },
        handleUploadFile(e){
            this.payoutData.proofs.push(e.id)
        },
        handleBeneficiaryPayout(data){
            this.payoutData.bankId = data.bankId;
            this.$nextTick(() => {
                this.payoutData.accountName = data.name;
                this.payoutData.accountNumber = data.number;
                this.showPayout = true
            }, 3000)
            
        },
        getBankLookup(){
            this.lookupLoading = true;
            const bank = this.bankList.find((item) => item.id === this.payoutData.bankId)
            BANK_ACCOUNT_LOOKUP({
                accountNumber: this.payoutData.accountNumber,
                bankCode: bank.code
            }).then(({data}) => {
                    this.payoutData.accountName = data.data.accountName
                this.lookupLoading = false
            }).catch(() => {
                this.lookupLoading = false
            })
        }
    },
    mounted(){
        this.getWalletPayouts();
        this.getBankList()
    }
}
</script>
<style scoped>
.purchase-container {
    min-height: calc(100vh - 10rem);
}
.searchContainer {
    border: 1px solid rgba(138, 116, 192, 0.1) !important;
    width: 12rem;
}
</style>