<template>
    <Modal :display="display" @close="$emit('close')" title="Update Merchant">
        <Input width="w-full" placeholder="Business name" class="mt-8" v-model="wireAccountInfo.name"
            errorText="Business name is required" :validation="rules.name" disabled />
        <div class="mt-6">
            <label for="kycStatus">Select KYC status</label>
            <v-select id="kycStatus" :options="kycOptions" class="w-full" placeholder="Select KYC status" label="type"
                :required="!wireAccountInfo.forcedKycStatus" v-model="wireAccountInfo.forcedKycStatus"></v-select>
        </div>

        <Button text="Enable wire account" width="w-full" class="mt-10" @click="updateWireAccount" :disabled="disableButton"
            :loading="loading" />
    </Modal>
</template>

<script>
import { Input } from "@/UI/Input";
import { Button } from "@/UI/Button";
import { Modal } from "@/UI/Modals";
import vSelect from "vue-select";
import { UPDATE_WIRE_ACCOUNT } from '@/utils/api/wire'
import { mapActions } from "vuex";
export default {
    components: {
        Input,
        Button,
        "v-select": vSelect,
        Modal
    },
    props: {
        wireInfo: {
            type: Object,
            default: () => { },
            required: true,
        },
        display: {
            type: Boolean,
            default: false,
            required: true,
        },
    },
    data: () => ({
        wireAccountInfo: {
            id: "",
            name: "",
            forcedKycStatus: "",
        },
        kycOptions: ["ON", "OFF", "NEUTRAL"],
        loading: false
    }),

    mounted() {
        this.wireAccountInfo.id = this.wireInfo.id;
        this.wireAccountInfo.name = this.wireInfo.wireAccountName;
    },

    computed: {
        rules() {
            return {
                id: this.wireAccountInfo.id.length > 0,
                name: this.wireAccountInfo.name.length > 0,
                kycStatus: this.wireAccountInfo.forcedKycStatus.length > 0,
            };
        },
        disableButton() {
            return Object.values(this.rules).includes(false);
        },
    },

    methods: {
        ...mapActions("notification", ["showAlert"]),
        updateWireAccount() {
            this.loading = true;
            UPDATE_WIRE_ACCOUNT(this.wireAccountInfo).then(() => {
                this.loading = false
                this.showAlert({
                    display: true,
                    description: `Wire account updated successfully`,
                    type: "success",
                });
                this.$emit('success')

            }).catch(() => {
                this.loading = false

            })
        }
    }
}
</script>

<style src="vue-select/dist/vue-select.css"></style>