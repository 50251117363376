import axios from "axios";
import cookies from "vue-cookies";
// import router from "../router";
import store from "../store";

// const token = cookies.get("token");
const tokenStorage = localStorage.getItem('token')
const token = tokenStorage ? JSON.parse(tokenStorage) : null;
const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  // headers: {
  //   Authorization: `Bearer ${token}`,
  // },
});

// axiosInstance.interceptors.request.use((config) => {
//   if (token) {
//     config.headers["Authorization"] = `Bearer ${token}`;
//     return config;
//   }
//   return config;
// });

axiosInstance.interceptors.response.use(
  function (response) {
    // console.log(response);
    return response;
  },
  function (error) {
    // console.log(error.response.status);
    // if (error.response.status === 404 || error.response.status === 401) {
    //   console.log(error.response.data.message);
    //   return error;
    // } else {

    if (error?.response?.status != 401) {
      if (error?.response?.status != 404) {
        if (error?.response?.data?.message) {
          store.dispatch("notification/showAlert", {
            display: true,
            type: "error",
            description: error.response.data.message,
          });
        } else {
          store.dispatch("notification/showAlert", {
            display: true,
            type: "error",
            description:
              "We are sorry this happened. Please check your internet connection and try again",
            title: "An error occurred",
          });
        }
      }
    }

    if (error.response.status === 401) {
      // console.log("401");
      // store.dispatch("notification/showAlert", {
      //   display: true,
      //   type: "error",
      //   description: "You are not authorized",
      // });
      //   cookies.remove("token");
      store.dispatch("auth/logout");

      //   router.push("/login");
    }
    // }
    return Promise.reject(error);
  }
);

export default axiosInstance;
