<template>
  <div>
    <Card class="h-full partner-container">
      <div class="flex justify-end">
        <SecondaryButton
          text="+ Add new partner"
          background="bg-brandPurple"
          color="text-white"
          :shadow="true"
          class="mb-4 mt-4 md:mt-0"
          p="px-8 py-3"
          @click="showPartnerModal(true)"
        />
      </div>
      <div class="flex justify-between items-center py-4">
        <h5 class="text-lg font-semibold">Partner Registration</h5>
        <div class="flex items-center">
          <!-- <Card class="searchContainer mr-2 w-44" p="px-3">
            <Input
              type="filter"
              placeholder="Order ID"
              v-model="params.orderid"
          /></Card>
          <Card class="searchContainer w-44 mr-2" p="px-3">
            <Select
              type="filter"
              placeholder="Status"
              v-model="params.paymentStatus"
              :options="paymentStatus"
              :reduce="(type) => type.value"
            />
          </Card>
          <Card class="searchContainer w-44 mr-2" p="px-3">
            <Select
              v-model="params.paymentPlan"
              type="filter"
              placeholder="Payment plan"
              :options="paymentOptions"
              :reduce="(type) => type.value"
            />
          </Card> -->
          <DatePicker
            class="mr-4"
            v-model="range"
            is-range
            :model-config="modelConfig"
            :masks="masks"
          >
            <template v-slot="{ inputValue, togglePopover }">
              <Card
                class="flex items-center border searchContainer w-full"
                p="px-1.5 py-1"
              >
                <button class="p-2 focus:outline-none" @click="togglePopover()">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="none"
                  >
                    <path
                      d="M11.09 2h-.272v-.25c0-.425-.354-.75-.818-.75-.464 0-.818.325-.818.75V2H4.818v-.25C4.818 1.325 4.464 1 4 1c-.464 0-.818.325-.818.75V2h-.273C1.845 2 1 2.775 1 3.75v7c0 .975.845 1.75 1.91 1.75h8.18c1.065 0 1.91-.775 1.91-1.75v-7C13 2.775 12.155 2 11.09 2Zm-1.363-.25c0-.15.11-.25.273-.25.164 0 .273.1.273.25v1c0 .15-.11.25-.273.25-.164 0-.273-.1-.273-.25v-1ZM4 1.5c.164 0 .273.1.273.25v1c0 .15-.11.25-.273.25-.164 0-.273-.1-.273-.25v-1c0-.15.11-.25.273-.25Zm-1.09 1h.272v.25c0 .425.354.75.818.75.464 0 .818-.325.818-.75V2.5h4.364v.25c0 .425.354.75.818.75.464 0 .818-.325.818-.75V2.5h.273c.764 0 1.364.55 1.364 1.25v.75H1.544v-.75c0-.7.6-1.25 1.364-1.25Zm9.544 8.25c0 .7-.6 1.25-1.363 1.25H2.909c-.764 0-1.364-.55-1.364-1.25V5h10.91v5.75Z"
                      fill="#9D9D9D"
                      stroke="#9D9D9D"
                      stroke-width=".1"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M3.727 7h6.546c.163 0 .273-.1.273-.25s-.11-.25-.273-.25H3.727c-.163 0-.272.1-.272.25s.109.25.272.25ZM3.727 8.75h6.546c.163 0 .273-.1.273-.25s-.11-.25-.273-.25H3.727c-.163 0-.272.1-.272.25s.109.25.272.25ZM3.727 10.5h6.546c.163 0 .273-.1.273-.25s-.11-.25-.273-.25H3.727c-.163 0-.272.1-.272.25s.109.25.272.25Z"
                      fill="#9D9D9D"
                      stroke="#9D9D9D"
                      stroke-width=".1"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
                <p
                  v-if="inputValue.start === null"
                  @click="togglePopover()"
                  class="text-grey"
                >
                  Date filter
                </p>
                <p v-else @click="togglePopover()" class="text-sm text-grey">
                  {{ inputValue.start }} - {{ inputValue.end }}
                </p>
              </Card>
            </template>
          </DatePicker>
          <Button text="Search" p="p-2" @click="handleSearch" />
          <!-- <Card class="searchContainer w-44" p="px-3"> -->
          <!-- </Card> -->
        </div>
      </div>
      <Hr />
      <Spinner class="text-center" v-if="partnerLoading" />
      <div class="" v-if="!partnerLoading">
        <div>
          <div class="payment-table">
            <div class="payment-table__col font-semibold">S/N</div>
            <div class="payment-table__col font-semibold">First Name</div>
            <div class="payment-table__col font-semibold">Last Name</div>
            <div class="payment-table__col font-semibold">Phone Number</div>
            <div class="payment-table__col font-semibold">Commisioned</div>
            <div class="payment-table__col font-semibold">Date Created</div>
            <div class="payment-table__col font-semibold">
              Activation Action
            </div>
          </div>
          <Hr />
        </div>
        <EmptyState
          description="No records found"
          v-if="allPartnerRegistration.length === 0"
        />
        <div v-else>
          <div
            v-for="(partner, index) in allPartnerRegistration"
            :key="partner.id"
          >
            <div class="payment-table">
              <div class="payment-table__col">{{ index + 1 }}</div>
              <div class="payment-table__col">
                {{ partner.firstName | capitalize }}
              </div>
              <div class="payment-table__col font-semibold">
                {{ partner.lastName | capitalize }}
              </div>
              <div class="payment-table__col">
                {{ partner.phoneNumber }}
              </div>
              <div class="payment-table__col text-center">
                <span
                  v-if="partner.commissionFlag === 'COMMISSIONED'"
                  class="
                    text-success text-center
                    rounded-full
                    successBg
                    text-sm
                    px-4
                    py-2
                  "
                  >Yes</span
                >
                <span
                  v-else
                  class="
                    sandbox
                    cursor-pointer
                    text-center
                    rounded-full
                    border
                    text-xs
                    px-4
                    py-1
                  "
                  >No</span
                >
              </div>

              <div class="payment-table__col">
                {{ dayjs(partner.createdAt).format("MMM D YYYY | hh:mmA") }}
              </div>
              <div class="flex">
                <!-- <SecondaryButton
                  text="View more"
                  @click="handleSinglePartner(partner)"
                /> -->
                <SecondaryButton
                  text="Resend"
                  p="py-2 px-3"
                  width="w-20"
                  @click="
                    handleSinglePartner(partner),
                      resendActivationSMS(partner.id)
                  "
                  :loading="resendLoading"
                />

                <SecondaryButton
                  text="Copy"
                  p="py-2 px-3 ml-2"
                  width="w-20"
                  background="bg-brandPurple"
                  color="text-white"
                  @click="copy(partnerWebapp + partner.id)"
                  :loading="resendLoading"
                />
              </div>
            </div>
            <Hr />
          </div>
        </div>

        <Pagination
          :handleMore="handleMore"
          :loading="paginateLoading"
          @paginate="handlePagination"
        />
      </div>
    </Card>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import Hr from "@/UI/Hr";
import Spinner from "@/UI/Spinner";
import dayjs from "dayjs";
import { Modal } from "@/UI/Modals";
import { Select, Input, SearchInput } from "@/UI/Input";
import { Button, SecondaryButton } from "@/UI/Button";
import { DatePicker } from "v-calendar";
import { RESEND_ACTIVATION_SMS } from "@/utils/api/partner.js";
import copy from "@/mixins/copy";

export default {
  mixins: [copy],
  components: {
    Hr,
    SecondaryButton,
    Modal,
    Spinner,
    Pagination: () => import("@/UI/Pagination"),
    Select,
    Input,
    Button,
    SecondaryButton,
    DatePicker,
    SearchInput,
    EmptyState: () => import("@/UI/EmptyState"),
  },
  data: () => ({
    partnerLoading: false,
    resendLoading: false,
    singlePartner: {},
    modelConfig: {
      type: "string",
      mask: "iso",
      start: {
        timeAdjust: "00:00:00",
      },
      end: {
        timeAdjust: "23:59:59",
      },
    },
    range: {
      start: "",
      end: "",
    },
    masks: {
      input: "DD MMM YY",
    },
    params: {
      pageSize: 20,
      page: 0,
      from: "",
      to: "",
    },
    paginateLoading: false,
    handleMore: false,
    partnerWebapp: "",
  }),
  computed: {
    ...mapState({
      allPartnerRegistration: (state) => state.partner.partnerRegistration,
      loading: (state) => state.loading.loading,
    }),
    dayjs() {
      return dayjs;
    },
  },
  mounted() {
    this.getPartnerRegistration();
    this.partnerWebapp = `${process.env.VUE_APP_PARTNER_BASE_URL}/complete-registration/`;
  },
  methods: {
    ...mapActions("partner", ["getAllPartnerRegistration", "showPartnerModal"]),
    ...mapActions("loading", ["setLoading"]),
    ...mapActions("notification", ["showAlert"]),
    getPartnerRegistration(type) {
      type === "paginate"
        ? (this.paginateLoading = true)
        : (this.partnerLoading = true);
      this.getAllPartnerRegistration({ params: this.params, type: type })
        .then((res) => {
          console.log(res.data.length);
          if (res.data.length === this.params.pageSize) {
            this.handleMore = true;
          } else {
            this.handleMore = false;
          }
          type === "paginate"
            ? (this.paginateLoading = false)
            : (this.partnerLoading = false);
        })
        .catch(() => {
          type === "paginate"
            ? (this.paginateLoading = false)
            : (this.partnerLoading = false);
        });
    },
    handlePagination() {
      this.paginateLoading = true;
      this.params.page++;
      this.getPartnerRegistration("paginate");
    },
    handleSearch() {
      this.params.page = 0;
      this.params.from = this.range.start;
      this.params.to = this.range.end;
      this.getPartnerRegistration();
    },
    handleSinglePartner(partner) {
      this.singlePartner = partner;
      this.showOrderDetails = true;
    },
    resendActivationSMS(id) {
      this.setLoading(true);
      RESEND_ACTIVATION_SMS(id)
        .then((res) => {
          this.showAlert({
            description: "Activation SMS resent successfully",
            display: true,
            type: "success",
          });
          this.setLoading(false);
        })
        .catch((e) => {
          console.log(e);
          this.setLoading(false);
        });
    },
  },
};
</script>
<style scoped>
.searchContainer {
  border: 1px solid rgba(138, 116, 192, 0.1) !important;
  width: 12rem;
}
.partner-container {
  min-height: calc(100vh - 10rem);
}
.payment-table {
  display: grid;
  grid-template-columns: 2.5rem 12rem 1fr 1fr 0.8fr 1.5fr 1fr;
  grid-column-gap: 10px;
  align-items: center;
}
.payment-table__col {
  padding: 0.8rem 0.5rem;
  /* white-space: nowrap; */
  font-size: 0.9rem;
}
</style>
