<template>
  <!-- <Portal :disabled="disablePortal"> -->
  <transition name="slideUp">
    <div class="container-body p-4" v-if="display">
      <div class="flex justify-end relative">
        <img
          class="h-6 w-6 cursor-pointer"
          src="https://res.cloudinary.com/zillaafrica/image/upload/v1623525601/customer/Union_yx6ulk.svg"
          alt="cancel"
          @click="$emit('close')"
        />
      </div>
      <!-- <div class="flex justify-end"> -->

      <div>
        <slot />
      </div>
    </div>
  </transition>
  <!-- </Portal> -->
</template>
<script>
  // import { Portal } from "@linusborg/vue-simple-portal";
  export default {
    props: {
      display: {
        type: Boolean,
        default: false,
        required: true,
      },
    },
    data: () => ({
      disablePortal: false,
    }),
  };
</script>
<style scoped>
  @import "~@/assets/styles/animation.css";
  .container-body {
    position: fixed !important;
    top: 0;
    left: 0;
    height: 100vh;
    overflow: auto;
    width: 100%;
    background-color: #f6f7ff;
    z-index: 20;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .completed {
    background-color: #c70039;
  }
  img {
    /* position: absolute; */
    right: 2rem;
    top: 2rem;
  }
  .line {
    background: linear-gradient(
      90deg,
      #c70039 3.72%,
      rgba(184, 184, 184, 0) 108.64%
    );
    border-radius: 100px;
  }
</style>
<style>
  .body {
    font-family: "Aventa", Helvetica;
  }
</style>
